import {
  GET_HOTLIST,
  GET_HOTLIST_ANALYTICS,
  GET_HOTLIST_BY_IDS,
  setHotlist,
} from "../actions/hotlist";
import { setError } from "../actions/common";
import parse from "../../utils/parsers/hotlist-parser";
import parseAnalytics from "../../utils/parsers/hotlist-analytics-parser";

const hotlistFlow = ({ api, log }) => ({ dispatch }) => (next) => async (
  action
) => {
  next(action);

  if (action.type === GET_HOTLIST) {
    try {
      dispatch(setHotlist({ loading: true }));
      const hotlist = await api.hotlist.getAll();
      log("Successfully fetched the hotlist");
      dispatch(setError(null));
      dispatch(setHotlist({ hotlistData: parse(hotlist), loading: false }));
    } catch (error) {
      dispatch(setHotlist({ loading: false }));
      log("Error while fetching the hotlist");
    }
  }

  if (action.type === GET_HOTLIST_ANALYTICS) {
    try {
      dispatch(setHotlist({ analyticsLoading: true }));
      const analytics = await api.hotlist.getAnalytics();
      log("Successfully fetched the hotlist");
      dispatch(setError(null));
      dispatch(
        setHotlist({
          analytics: parseAnalytics(analytics),
          analyticsLoading: false,
        })
      );
    } catch (error) {
      dispatch(setHotlist({ analyticsLoading: false }));
      log("Error while fetching the hotlist");
    }
  }

  if (action.type === GET_HOTLIST_BY_IDS) {
    try {
      dispatch(setHotlist({ loading: true }));
      const hotlist = await api.hotlist.getByIds(action.payload.ids);
      log("Successfully fetched the hotlist");
      dispatch(setError(null));
      dispatch(setHotlist({ hotlistData: parse(hotlist), loading: false }));
    } catch (error) {
      dispatch(setHotlist({ loading: false }));
      log("Error while fetching the hotlist");
    }
  }
};

export default [hotlistFlow];
