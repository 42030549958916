import Card from "../Card";
import positionsImage from "../../assets/metrics-positions.svg";
import resumeImage from "../../assets/metrics-resumes.svg";
import participantsImage from "../../assets/metrics-participants.svg";
import ratingsImage from "../../assets/metrics-ratings.svg";
import "./styles.css";

interface Props {
  positions: number;
  resumes: number;
  participants: number;
  ratings: number;
}

const renderDetails = (image: string, value: number, title: string) => {
  return (
    <div className="contest-metric-icon">
      <img src={image} alt={title} className="contest-metrics-icon" />
      <p className="contest-metrics-icon-value">{value}</p>
      <p className="contest-metrics-icon-text">{title}</p>
    </div>
  );
};

const ContestMetrics: React.FC<Props> = (props) => {
  const { positions, resumes, participants, ratings } = props;
  return (
    <Card>
      <div className="contest-metrics-container">
        <p className="contest-metrics-title">Contest Metrics</p>
        <div className="contest-metrics-icon-container">
          {renderDetails(positionsImage, positions, "Positions")}
          {renderDetails(resumeImage, resumes, "Resumes")}
          {renderDetails(participantsImage, participants, "Participants")}
          {renderDetails(ratingsImage, ratings, "Ratings")}
        </div>
      </div>
    </Card>
  );
};

export default ContestMetrics;
