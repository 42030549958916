export const ONBOARD_RECRUITER = "ONBOARD_RECRUITER";
export const SET_ONBOARDED_RECRUITER = "SET_ONBOARDED_RECRUITER";

export const onboardRecruiter = (data) => {
  return {
    type: ONBOARD_RECRUITER,
    payload: data,
  };
};

export const setOnboardedUser = (data) => {
  return {
    type: SET_ONBOARDED_RECRUITER,
    payload: data,
  };
};
