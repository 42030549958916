import { GET_PARTNER, setPartner } from "../actions/partner";
import { setError } from "../actions/common";
import parse from "../../utils/parsers/partner-parser";

const partnerFlow = ({ api, log }) => ({ dispatch }) => (next) => async (
  action
) => {
  next(action);

  if (action.type === GET_PARTNER) {
    try {
      dispatch(setPartner({ loading: true }));
      const partner = await api.partner.getById(action.payload);
      log("Successfully fetched the partner data");
      dispatch(setError(null));
      dispatch(setPartner({ partner: parse(partner), loading: false }));
    } catch (error) {
      const payload = {
        error,
        message: "Error while fetching the partner details",
      };
      dispatch(setError(payload));
      dispatch(setPartner({ loading: false }));
      log("Error while fetching the partner details");
    }
  }
};

export default [partnerFlow];
