import "./styles.scss";
import QuoteImage from "../../assets/quotes.svg";
interface Prop {
  data: {
    quote: string;
    title: string;
    supportedBy: string;
    date: string;
    profileImage: string;
  };
}
const CustomerSaysCard = (props: Prop) => {
  const { data } = props;
  return (
    <div className="customer-says-card">
      <div className="customer-says-container">
        <div
          style={{
            backgroundImage: `url(${QuoteImage})`,
            backgroundRepeat: "no-repeat",
            width: "4.125rem",
            height: "3.6744rem",
            left: "-8.125rem",
            top: "-3.9762rem",
            position: "relative",
          }}
        ></div>
        <p className="customer-says-text">{data.quote}</p>
      </div>
      <div className="customer-says-profile">
        <img
          src={`${process.env.REACT_APP_PUBLIC_BUCKET_URL}${data.profileImage}`}
          style={{
            border: ".1563rem solid #4D6CD9",
            boxSizing: "border-box",
            borderRadius: "2rem",
            width: "3rem",
            height: "3rem",
          }}
        />
        <div className="customer-says-title-container">
          <label className="customer-says-title">{data.title}</label>
          <label className="customer-says-desc">
            {data.supportedBy} - {data.date}
          </label>
        </div>
      </div>
    </div>
  );
};

export default CustomerSaysCard;
