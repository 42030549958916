import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveLink } from "../../redux/actions/menu";
import { useHistory } from "react-router-dom";
import "./styles.scss";
import Background from "../../assets/login-background.svg";
import Status from "../../components/Status";
import CompanyDetails from "../../components/CompanyDetails";
import Billing from "../../components/BillingDetails";
import AddTeam from "../../components/AddTeam";
import CompanyBackground from "../../components/CompanyBackground";
import Card from "../../components/Card";
import { HOME } from "../../utils/constants/routes";
import {
  RECRUITMENT_COMPANY,
  EDIT_FR_PROFILE_STEPS,
  EDIT_PROFILE_STEPS,
  USER_URL_MAPPING,
} from "../../utils/constants/index";
import FreelanceRecruiterDetails from "../../components/FreelanceRecruiterDetails";
import FreelanceRecruiterBilling from "../../components/FreelanceRecruiterBilling";
import FreelanceRecruiterBackground from "../../components/FreelanceRecruiterBackground";

import {
  getRecruiterBillingDetails,
  getRecruiterCompanyDetails,
  getRecruiterBackground,
  getRecruiter,
  postRecruiterBackground,
  postRecruiterCompanyDetails,
  postRecruiterTeamDetails,
  postRecruiterBillingDetails,
} from "../../redux/actions/recruiterProfile";

import {
  recruiterBackgroundSelector,
  companyDetailsSelector,
  recruiterBillingSelector,
  recruiterSelector,
  errorSelector,
  recruitersLoadingSelector,
  userInfoSelector,
} from "../../redux/selectors";
import { getLocalStorage } from "../../utils/storage-manager";

const EditProfile = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const companyDetails = useSelector(companyDetailsSelector);
  const companyBackground = useSelector(recruiterBackgroundSelector);
  const billingDetails = useSelector(recruiterBillingSelector);
  const recruiterDetails = useSelector(recruiterSelector);
  const loading = useSelector(recruitersLoadingSelector);
  const error = useSelector(errorSelector);
  const userInfo = useSelector(userInfoSelector);

  const recruiterId = userInfo.userId
    ? userInfo.userId
    : parseInt(getLocalStorage("userId", 0));
  const userStage = userInfo.userStage
    ? userInfo.userStage
    : parseInt(getLocalStorage("userStage", 1));
  const userRole = userInfo.userRole
    ? userInfo.userRole
    : getLocalStorage("userRole", RECRUITMENT_COMPANY);
  const isOnboardingViaEmailInvite = userInfo.inviteSource ? true : false;
  const urlEndPoint = USER_URL_MAPPING[userRole];
  const totalSteps =
    userRole === RECRUITMENT_COMPANY
      ? EDIT_PROFILE_STEPS.length
      : EDIT_FR_PROFILE_STEPS.length;

  const [currentStep, setCurrentStep] = useState(userStage);
  const [nextStep, setNextStep] = useState(0);
  dispatch(setActiveLink(HOME));

  useEffect(() => {
    if (nextStep === 0) {
      return;
    }
    if (error) {
      setNextStep(0);
    }
    if (!loading && !error && nextStep !== 0) {
      nextStep > totalSteps ? setCurrentStep(0) : setCurrentStep(nextStep);
    }
  }, [error, loading]);

  useEffect(() => {
    setNextStep(0);
    window.scrollTo(0, 0);
    switch (currentStep) {
      case 1:
        dispatch(
          getRecruiterCompanyDetails(recruiterId, urlEndPoint, currentStep)
        );
        break;
      case 2:
        dispatch(getRecruiterBackground(recruiterId, urlEndPoint, currentStep));
        break;
      case 3:
        dispatch(
          getRecruiterBillingDetails(recruiterId, urlEndPoint, currentStep)
        );
        break;
      case 4:
        dispatch(getRecruiter(recruiterId, urlEndPoint, currentStep));
        break;
    }
  }, [currentStep]);

  const gotoNextPage = (data) => {
    switch (currentStep) {
      case 1:
        dispatch(
          postRecruiterCompanyDetails(
            recruiterId,
            data,
            urlEndPoint,
            currentStep
          )
        );
        setNextStep(currentStep + 1);
        break;
      case 2:
        dispatch(
          postRecruiterBackground(recruiterId, data, urlEndPoint, currentStep)
        );
        setNextStep(currentStep + 1);
        break;
      case 3:
        dispatch(
          postRecruiterBillingDetails(
            recruiterId,
            data,
            urlEndPoint,
            currentStep
          )
        );
        setNextStep(currentStep + 1);
        break;
      case 4:
        dispatch(postRecruiterTeamDetails(recruiterId, data, currentStep));
        setNextStep(currentStep + 1);
        break;
      default:
        skipEdit();
        break;
    }
    return;
  };

  const gotoStep = (toStep: number) => {
    setCurrentStep(toStep);
  };

  const skipEdit = () => {
    history.push(HOME);
  };

  const renderPage = () => {
    switch (currentStep) {
      case 1:
        return userRole === RECRUITMENT_COMPANY ? (
          <CompanyDetails
            onNext={gotoNextPage}
            onSkip={skipEdit}
            onGotoStep={gotoStep}
            currentStep={currentStep}
            userStage={userStage}
            data={companyDetails}
            loading={loading}
            error={error}
            disabled={isOnboardingViaEmailInvite}
          />
        ) : (
          <FreelanceRecruiterDetails
            onNext={gotoNextPage}
            onSkip={skipEdit}
            onGotoStep={gotoStep}
            currentStep={currentStep}
            userStage={userStage}
            data={companyDetails}
            loading={loading}
            error={error}
            disabled={isOnboardingViaEmailInvite}
          />
        );
      case 2:
        return userRole === RECRUITMENT_COMPANY ? (
          <CompanyBackground
            onNext={gotoNextPage}
            onSkip={skipEdit}
            onGotoStep={gotoStep}
            currentStep={currentStep}
            userStage={userStage}
            data={companyBackground}
            loading={loading}
            error={error}
            disabled={isOnboardingViaEmailInvite}
          />
        ) : (
          <FreelanceRecruiterBackground
            onNext={gotoNextPage}
            onSkip={skipEdit}
            onGotoStep={gotoStep}
            currentStep={currentStep}
            userStage={userStage}
            data={companyBackground}
            loading={loading}
            error={error}
            disabled={isOnboardingViaEmailInvite}
          />
        );
      case 3:
        return userRole === RECRUITMENT_COMPANY ? (
          <Billing
            onNext={gotoNextPage}
            onSkip={skipEdit}
            onGotoStep={gotoStep}
            currentStep={currentStep}
            userStage={userStage}
            data={billingDetails}
            loading={loading}
            error={error}
            disabled={isOnboardingViaEmailInvite}
          />
        ) : (
          <FreelanceRecruiterBilling
            onNext={gotoNextPage}
            onSkip={skipEdit}
            onGotoStep={gotoStep}
            currentStep={currentStep}
            userStage={userStage}
            data={billingDetails}
            loading={loading}
            error={error}
            disabled={isOnboardingViaEmailInvite}
          />
        );
      case 4:
        return (
          <AddTeam
            onNext={gotoNextPage}
            onSkip={skipEdit}
            onGotoStep={gotoStep}
            currentStep={currentStep}
            userStage={userStage}
            data={recruiterDetails}
            loading={loading}
            error={error}
            disabled={isOnboardingViaEmailInvite}
          />
        );
      default:
        return (
          <Status
            onNext={gotoNextPage}
            message="Your profile creation is complete"
            buttonLabel="Go To Dashboard"
          />
        );
    }
  };

  return (
    <div>
      <div className="edit-profile-banner-container">
        <img
          className="edit-profile-banner"
          style={{
            backgroundImage: `url(${Background})`,
            backgroundSize: "contain",
            position: "relative",
            backgroundRepeat: "no-repeat",
          }}
        />
        <div className="edit-profile-heading-container">
          <p className="edit-profile-heading">
            {currentStep > totalSteps
              ? "Profile is successfully completed!"
              : "Complete Your Profile"}
          </p>
        </div>
      </div>
      <div className="edit-profile-background">
        <div className="edit-profile-card">
          <Card>{renderPage()}</Card>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
