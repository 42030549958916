import axios from "axios";
import {
  RECRUITER_PROFILE,
  RECRUITER_INVITE,
} from "../../../utils/constants/urls";
import { getLocalStorage } from "../../../utils/storage-manager";

const addToken = () => {
  const token = getLocalStorage("token", null);
  if (token) {
    axios.defaults.headers["Authorization"] = "Bearer " + token;
  } else {
    axios.defaults.headers["Authorization"] = null;
  }
};

export default {
  getRecruiterCompanyDetails: async (id, role) => {
    addToken();
    const response = await axios.get(
      `${RECRUITER_PROFILE}/${id}/${role}/details`
    );
    return response.data;
  },

  getRecruiterBillingDetails: async (id, role) => {
    addToken();
    const response = await axios.get(
      `${RECRUITER_PROFILE}/${id}/${role}/billing`
    );
    return response.data;
  },

  getRecruiterBackground: async (id, role) => {
    addToken();
    const response = await axios.get(
      `${RECRUITER_PROFILE}/${id}/${role}/background`
    );
    return response.data;
  },

  getRecruiter: async (id) => {
    addToken();
    const response = await axios.get(`${RECRUITER_PROFILE}/${id}`);
    return response.data;
  },

  postRecruiterCompanyDetails: async (id, payload, role) => {
    addToken();

    const response = await axios.post(
      `${RECRUITER_PROFILE}/${id}/${role}/details`,
      payload
    );

    return response.data;
  },

  postRecruiterTeamDetails: async (id, payload) => {
    addToken();
    const response = await axios.post(`${RECRUITER_INVITE}`, payload);
    return response.data;
  },

  postRecruiterBillingDetails: async (id, payload, role) => {
    addToken();

    const response = await axios.post(
      `${RECRUITER_PROFILE}/${id}/${role}/billing`,
      payload
    );

    return response.data;
  },

  postRecruiterBackground: async (id, payload, role) => {
    addToken();
    const response = await axios.post(
      `${RECRUITER_PROFILE}/${id}/${role}/background`,
      payload
    );

    return response.data;
  },

  getAllRecruiters: async () => {
    const response = await axios.get(RECRUITER_PROFILE);

    return response.data;
  },

  getRecruiterById: async (id) => {
    const response = await axios.get(`${RECRUITER_PROFILE}/${id}`);

    return response.data;
  },
};
