import { Link, useHistory } from "react-router-dom";
import Card from "../Card";
import Button from "../Button";
import ratingImage from "../../assets/rating-star.svg";
import openContestsImage from "../../assets/open-contests.svg";
import trophyImage from "../../assets/trophy.svg";
import companyImage from "../../assets/company.svg";
import { EMPLOYER_DETAILS } from "../../utils/constants/routes";
import styles from "./styles.module.scss";

interface EmployerDetails {
  id: number;
  name: string;
  brandName: string;
  coverImage: string;
  logo: string;
  rating: number;
  reviews: number;
  openContests: number;
  contestsConducted: number;
}

interface Props {
  employerDeatils: EmployerDetails;
}

const EmployerListCard: React.FC<Props> = (props) => {
  const { employerDeatils } = props;
  const {
    id,
    brandName,
    coverImage,
    logo,
    rating,
    reviews,
    openContests,
    contestsConducted,
  } = employerDeatils;

  const history = useHistory();

  const handleViewDetails = () => {
    history.push(EMPLOYER_DETAILS.replace(":id", `${id}`));
  };

  return (
    <div className={styles["employer-list-card-wrapper"]}>
      <Card>
        <div className={styles["employer-list-card-container"]}>
          <img
            src={coverImage}
            alt={brandName}
            className={styles["employer-list-card-cover-image"]}
          />
          <div className={styles["employer-list-card-logo-container"]}>
            <img
              src={logo}
              alt={brandName}
              className={styles["employer-list-card-logo"]}
            />
          </div>
          <div className={styles["employer-list-card-name-icon-container"]}>
            <div className={styles["employer-list-card-name-container"]}>
              <p className={styles["employer-list-card-name"]}>{brandName}</p>
              <div className={styles["employer-list-card-rating-container"]}>
                <img src={ratingImage} />
                <p className={styles["employer-list-card-rating"]}>{rating}</p>
                <p
                  className={styles["employer-list-card-reviews"]}
                >{`(${reviews.toLocaleString("en-IN")} reviews)`}</p>
              </div>
            </div>
            <div className={styles["employer-list-card-icon-container"]}>
              <img src={openContestsImage} />
              <p className={styles["employer-list-card-icon-text"]}>
                {openContests} Open Contests
              </p>
            </div>
            <div className={styles["employer-list-card-icon-container"]}>
              <img src={trophyImage} />
              <p className={styles["employer-list-card-icon-text"]}>
                {contestsConducted} Contests Conducted
              </p>
            </div>
            <div className={styles["employer-list-card-icon-container"]}>
              <img src={companyImage} />
              <Link
                to={{
                  pathname: EMPLOYER_DETAILS.replace(":id", `${id}`),
                  state: { activeTab: 2 },
                }}
              >
                <p
                  className={`${styles["employer-list-card-icon-text"]} ${styles["employer-list-card-icon-text-hover"]}`}
                >
                  Company FAQs
                </p>
              </Link>
            </div>
            <Button
              label="View Profile"
              variant="outlined"
              className={styles["employer-list-card-button"]}
              onClick={handleViewDetails}
            />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default EmployerListCard;
