import "./styles.scss";
import {
  hiringhoodVsOtherData,
  hiringhoodVsOthersHeaders,
} from "../../utils/constants/site-data";
import Table from "../Table";

const HiringhoodVsOthers = () => (
  <div className="hh-vs-others-container">
    <Table headers={hiringhoodVsOthersHeaders} data={hiringhoodVsOtherData} />
  </div>
);

export default HiringhoodVsOthers;
