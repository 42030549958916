import { useState, useMemo } from "react";
import PartnerCard from "../PartnerCard";
import Pagination from "../Pagination";
import PartnerFilters from "../PartnerFilters";
import "./styles.css";

interface PartnerDetails {
  id: number;
  name: string;
  coverImage: string;
  logo: string;
}

interface Props {
  partners: PartnerDetails[];
  numberofPartnerPerPage?: number;
  partnersData: any;
  onReset?: any;
}

const PartnerGrid: React.FC<Props> = (props) => {
  const { partners, partnersData, onReset } = props;

  const [currentPage, setCurrentPage] = useState(1);

  const [filteredPartners, setFilteredPartners] = useState([...partners]);

  const pageData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * 12;
    const lastPageIndex = firstPageIndex + 12;
    return filteredPartners.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, filteredPartners]);

  const onPageChange = (page: number) => {
    setCurrentPage(page);
    window.scrollTo(0, 0);
  };

  const onFiltersChanged = (list) => {
    setFilteredPartners([...list]);
  };

  return (
    <div className="partners-grid-container">
      <div className="partners-grid-title-container">
        <p className="partners-grid-title">Partner Directory</p>
      </div>
      <div className="partners-grid-cards-container">
        <div className="partners-grid-filters">
          <PartnerFilters
            partnersData={partnersData}
            onFiltersChanged={onFiltersChanged}
            onReset={onReset}
          />
        </div>
        <div className="partners-grid-cards-list-container">
          {pageData && pageData.length === 0 && (
            <p className="partners-grid-no-partners">
              There are currently no partners on board
            </p>
          )}
          {pageData.length > 0 && (
            <div className="partners-grid-partners-grid-container">
              {pageData &&
                pageData.length > 0 &&
                pageData.map((partnerDetails: any, index: number) => {
                  return (
                    <PartnerCard partnerDetails={partnerDetails} key={index} />
                  );
                })}
            </div>
          )}
        </div>
      </div>
      <Pagination
        className="partners-grid-pagination-container"
        currentPage={currentPage}
        totalCount={filteredPartners.length}
        pageSize={12}
        onPageChange={onPageChange}
      />
    </div>
  );
};

export default PartnerGrid;
