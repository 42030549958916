import axios from "axios";
import { BLOGS_API } from "../../../utils/constants/urls";

export default {
  getAll: async (type) => {
    let response;
    if (type) {
      response = await axios.get(`${BLOGS_API}&type=${type}`);
    } else {
      response = await axios.get(BLOGS_API);
    }

    return response.data;
  },
};
