import { RECRUITER_TYPES } from "../constants";

const parse = (response: any) => {
  const item = response["data"];
  const recruiter = item["attributes"];

  const isFreelanceRecruiter = recruiter["role"] === "freelance-recruiter";
  const isArray =
    Array.isArray(item["company"]) && item["company"].length === 0;
  const hasCompanyDetails = !isFreelanceRecruiter && !isArray;
  const company = hasCompanyDetails ? item["company"]["attributes"] : null;

  const parsedRecruiterDetails: any = {};
  parsedRecruiterDetails.id = recruiter["ID"];
  parsedRecruiterDetails.name = isFreelanceRecruiter
    ? recruiter["display_name"]
    : hasCompanyDetails
    ? item["company"]["attributes"]["title"]
    : null;
  parsedRecruiterDetails.type = isFreelanceRecruiter
    ? RECRUITER_TYPES[0]
    : RECRUITER_TYPES[1];
  parsedRecruiterDetails.rank = recruiter["user_stats"]["rank"];
  parsedRecruiterDetails.rating = recruiter["user_stats"]["rating"];
  parsedRecruiterDetails.bountyEarned = `₹${recruiter["user_stats"][
    "bounty"
  ].toLocaleString("en-IN")}`;
  parsedRecruiterDetails.positionsClosed =
    recruiter["user_stats"]["positions_closed"];
  parsedRecruiterDetails.hitRatio = recruiter["user_stats"]["hit_rate"];
  parsedRecruiterDetails.speedOfUploading =
    recruiter["user_stats"]["speed_of_uploading"];
  parsedRecruiterDetails.uploadQuality =
    recruiter["user_stats"]["upload_quality"];
  parsedRecruiterDetails.jsReviews =
    recruiter["user_stats"]["job_seeker_reviews"];
  parsedRecruiterDetails.employerReviews =
    recruiter["user_stats"]["employer_reviews"];
  parsedRecruiterDetails.responsiveness =
    recruiter["user_stats"]["responsiveness"];
  parsedRecruiterDetails.competitionsParticipated = Array.isArray(
    item["contest"]
  )
    ? 0
    : item["contest"]["participating"]
    ? item["contest"]["participating"].length
    : 0;
  parsedRecruiterDetails.contestsWon = recruiter["user_stats"]["contest_won"];
  parsedRecruiterDetails.experience =
    recruiter["duration_of_working_as_a_freelancer"] &&
    Number(recruiter["duration_of_working_as_a_freelancer"]);
  parsedRecruiterDetails.activeSince = new Date(
    recruiter["user_registered"]
  ).getUTCFullYear();
  parsedRecruiterDetails.consolationPrizesWon =
    recruiter["user_stats"]["consolation_prizes_won"];
  parsedRecruiterDetails.description = company
    ? company["about_company"]
    : recruiter["description"];
  parsedRecruiterDetails.roles = company
    ? company["five_functional_roles_recruiting_expertise"].join(", ")
    : recruiter["5_functional_roles_recruiting_expertise"].join(", ");
  parsedRecruiterDetails.levels = company
    ? company["levels_of_hire_talent_mostly"].join(", ")
    : recruiter["levels_of_hire_talent_mostly"].join(", ");

  parsedRecruiterDetails.skills = company
    ? company["top_five_skill_sets_specialised_in_sourcing_talent"]
      ? company["top_five_skill_sets_specialised_in_sourcing_talent"].join(", ")
      : ""
    : recruiter["top_5_skill_sets_specialised_in_sourcing_talent"]
    ? recruiter["top_5_skill_sets_specialised_in_sourcing_talent"].join(", ")
    : "";

  parsedRecruiterDetails.locations = company
    ? company["job_locations_recruit_mostly"].join(", ")
    : recruiter["job_locations_recruit_mostly"].join(", ");

  parsedRecruiterDetails.industry = company
    ? company["five_industries_recruiting_expertise"]
      ? company["five_industries_recruiting_expertise"].join(", ")
      : ""
    : recruiter["top_5_industries_specialised_in_recruiting_expertise"].join(
        ", "
      );

  if (typeof item["certificates"] === "object") {
    const certificates = {};
    certificates["participation"] = item["certificates"]["participated"];
    certificates["winning"] = item["certificates"]["winning"];
    parsedRecruiterDetails.certificates = certificates;
  }
  parsedRecruiterDetails.contests = [];
  parsedRecruiterDetails.profilesSubmitted = Number(
    item["statistics"]["profiles_submitted"]
  );

  parsedRecruiterDetails.stats = {};
  parsedRecruiterDetails.stats["myStatsData"] = {
    profilesSubmitted: Number(item["statistics"]["profiles_submitted"]),
    profilesShortListed: Number(item["statistics"]["profiles_shortlisted"]),
    l1Done: Number(item["statistics"]["l1_done"]),
    l2Done: Number(item["statistics"]["l2_done"]),
    hrDone: Number(item["statistics"]["hr_done"]),
    offerOut: Number(item["statistics"]["offers_rolled_out"]),
    joined: Number(item["statistics"]["job_joined"]),
  };
  parsedRecruiterDetails.stats["contestStatsData"] = {
    profilesSubmitted: item["statistics"]["profiles_submitted"] ? "100%" : "0",
    profilesShortListed: item["statistics"]["profiles_submitted"]
      ? `${(
          (Number(item["statistics"]["profiles_shortlisted"]) * 100) /
          Number(item["statistics"]["profiles_submitted"])
        ).toFixed(2)}%`
      : "0",
    l1Done: item["statistics"]["profiles_submitted"]
      ? `${(
          (Number(item["statistics"]["l1_done"]) * 100) /
          Number(item["statistics"]["profiles_submitted"])
        ).toFixed(2)}%`
      : "0",
    l2Done: item["statistics"]["profiles_submitted"]
      ? `${(
          (Number(item["statistics"]["l2_done"]) * 100) /
          Number(item["statistics"]["profiles_submitted"])
        ).toFixed(2)}%`
      : "0",
    hrDone: item["statistics"]["profiles_submitted"]
      ? `${(
          (Number(item["statistics"]["hr_done"]) * 100) /
          Number(item["statistics"]["profiles_submitted"])
        ).toFixed(2)}%`
      : "0",
    offerOut: item["statistics"]["profiles_submitted"]
      ? `${(
          (Number(item["statistics"]["offers_rolled_out"]) * 100) /
          Number(item["statistics"]["profiles_submitted"])
        ).toFixed(2)}%`
      : "0",
    joined: item["statistics"]["profiles_submitted"]
      ? `${(
          (Number(item["statistics"]["job_joined"]) * 100) /
          Number(item["statistics"]["profiles_submitted"])
        ).toFixed(2)}%`
      : "0",
  };
  parsedRecruiterDetails.profileImage = hasCompanyDetails
    ? item["company"]["attributes"]["logo"]["url"]
    : recruiter["upload_profile_image"]["url"];

  return parsedRecruiterDetails;
};

export default parse;
