import "./styles.scss";
import { ReactComponent as DeleteIcon } from "../../assets/delete.svg";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import * as Constants from "../../utils/constants/index";
import DeleteRecruiter from "../DeleteRecruiter";
import TextValidator from "../../components/TextValidator";
import PhoneNumberValidator from "../../components/PhoneNumberValidator";

interface Recruiter {
  name: string;
  email: string;
  phoneExtension: string;
  phoneNumber: string;
}

interface Props {
  index: number;
  onDelete: any;
  onChange: any;
  register?: any;
  name: string;
  errors: any;
}

const initialize = () => {
  return { name: "", email: "", phoneExtension: "+91", phoneNumber: "" };
};

const AddRecruiter = (props: Props) => {
  const { index, onDelete, onChange, register, name, errors = {} } = props;
  const [recData, setRecData] = useState(initialize);

  useEffect(() => {
    onChange(index, recData);
  }, [recData]);

  const handleChange = (fieldname: string, value) => {
    setRecData({ ...recData, [fieldname]: value });
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const onDeleteConfirm = () => {
    setOpen(false);
    onDelete(index);
  };

  return (
    <div className="div-form">
      <div className="div-row-flex">
        <p className="sub-heading">Recruiter Detail {index + 1}</p>
        <DeleteIcon className="delete-recruiter" onClick={handleOpen} />
      </div>
      <div className="div-row-flex">
        <TextValidator
          id={`${name}.name`}
          className="email-input"
          label="Full Name"
          register={register}
          value={recData.name}
          onChange={(event) => handleChange("name", event.target.value)}
          errorMessage={errors.name?.message}
        />
      </div>

      <div className="div-row-flex">
        <TextValidator
          id={`${name}.email`}
          className="email-input"
          label="Official Email Address*"
          register={register}
          value={recData.email}
          onChange={(event) => handleChange("email", event.target.value)}
          errorMessage={errors.email?.message}
        />
      </div>
      <div className="div-row-flex">
        <PhoneNumberValidator
          id={`${name}.phoneNumber`}
          label="Enter Phone Number*"
          register={register}
          phone={recData.phoneNumber}
          phoneExt={recData.phoneExtension}
          onPhoneChange={(event) =>
            handleChange("phoneNumber", event.target.value)
          }
          onExtChange={(event) =>
            handleChange("phoneExtension", event.target.value)
          }
          errorMessage={
            errors.phoneExtension
              ? errors.phoneExtension.message
              : errors.phoneNumber?.message
          }
        />
      </div>
      <div className="delete-rec-modal">
        <DeleteRecruiter
          open={open}
          onClose={handleClose}
          onConfirm={onDeleteConfirm}
          recruiter={recData}
        />
      </div>
    </div>
  );
};

export default AddRecruiter;
