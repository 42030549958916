import "./styles.scss";
interface Props {
  icon: string;
  title: string;
  stat: string;
  description: string;
}
const DrivingImpactCard = (props: Props) => {
  const { icon, title, stat, description } = props;
  return (
    <div className="driving-impact-card">
      <label className="driving-impact-card-title">{title}</label>
      <div className="driving-impact-card-icon-container">
        <img src={icon} alt={title} />
      </div>
      <div className="driving-impact-card-desc-container">
        <label className="driving-impact-card-stat">{stat}</label>
        <label className="driving-impact-card-desc">{description}</label>
      </div>
    </div>
  );
};
export default DrivingImpactCard;
