import { useEffect, useState } from "react";
import { OutlinedInput, InputAdornment } from "@material-ui/core";
import signupImage from "../../assets/signup.png";
import userIcon from "../../assets/user.svg";
import Card from "../Card";
import { validateEmail, validateOtpInput } from "../../utils/validators";
import OtpInput from "react-otp-input";
import { LOGIN, ON_BOARDING } from "../../utils/constants/routes";
import { Link, useHistory } from "react-router-dom";
import { Timer } from "../../utils/timer";
import LoadingButton from "@mui/lab/LoadingButton";
import OnboardAs from "../../components/OnboardAs";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import api from "../../utils/api/apis";
import Toast from "../../utils/toast";
import ErrorBoundary from "../../utils/errorBoundary";
import { COPYRIGHT } from "../../utils/constants/index";
import Button from "@mui/material/Button";
import linkedin from "../../assets/linkedin-color.svg";
import google from "../../assets/google.svg";
import { GoogleLogin } from "react-google-login";
import { getQueryParams } from "../../utils/util";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import { setUserInfoFromEmailInvite } from "../../redux/actions/user";
import { setLocalStorage } from "../../utils/storage-manager";
import "./styles.scss";

const SCREENS = {
  SIGNUP: "signup-view",
  OTP: "otp-view",
  CREATE_PASSWORD: "create-password-view",
  ONBOARD: "onboard",
};

const initialize = (userInfo) => {
  return {
    email: userInfo ? userInfo.invitee_email : "",
    password: "",
    rePassword: "",
    showPassword: false,
    showRePassword: false,
  };
};

const Signup = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [values, setValues] = useState(initialize(null));
  const [loading, setLoading] = useState(false);
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [currentView, setCurrentView] = useState(SCREENS.SIGNUP);
  const [invalidOtp, setInvalidOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const [resendOtpActive, setResendOtpActive] = useState(false);
  const [restartTimer, setRestartTimer] = useState(false);
  const [userExistsError, setUserExistsError] = useState(null);
  const [socialLoading, setSocialLoading] = useState(false);
  const [socialError, setSocialError] = useState(null);

  const queryParams = getQueryParams();
  const [onBoardAs, setOnboardAs] = useState("");
  useEffect(() => {
    if (queryParams) {
      try {
        const token: any = jwt_decode(queryParams["rc"]);
        const userData = token.data.user;
        setValues(initialize(userData));
        dispatch(setUserInfoFromEmailInvite({ userSignUpInfo: userData }));
        setOnboardAs(userData.user);
      } catch (error) {
        console.log(error);
      }
    } else {
      dispatch(setUserInfoFromEmailInvite({ userSignUpInfo: null }));
    }
  }, []);

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters"),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password")], "Passwords must match"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  function onSubmit(data) {
    const payload = {
      password: data.password,
      email: values.email,
    };
    setLoading(false);
    api
      .createPassword(payload)
      .then((res) => {
        if (res.status === 200) {
          setCurrentView(SCREENS.ONBOARD);
        } else if (res.status >= 400) {
          Toast.Error(res.data.message);
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        Toast.Error(e);
      });
    return false;
  }

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    if (prop === "email") {
      validateEmail(event.target.value)
        ? setIsInvalidEmail(false)
        : setIsInvalidEmail(true);
    }
  };

  const onSendOTP = () => {
    setLoading(true);
    const payload = {
      email: values.email,
      rc: (queryParams && queryParams["rc"]) || null,
    };
    api
      .registerUser(payload)
      .then((res) => {
        if (
          (res.status === 200 &&
            !res.data.message.toLowerCase().includes("user already exists")) ||
          (res.status === 404 && !res.data.data.attributes.user_status)
        ) {
          setCurrentView(SCREENS.OTP);
          const isFreelanceRecruiter =
            res.data.data.attributes["role"] === "freelance-recruiter";
          const name = isFreelanceRecruiter
            ? res.data.data.attributes["display_name"]
            : res.data.data.company["attributes"]["title"];
          setLocalStorage("user", name);
          setLocalStorage("userId", res.data.data.attributes["ID"]);
        } else if (
          res.status === 409 ||
          res.data.message.toLowerCase().includes("user already exists")
        ) {
          setUserExistsError(
            "A user already exists with the given email address. Sign in to continue"
          );
        } else if (res.status >= 400 && res.status !== 409) {
          Toast.Error(res.data.message);
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log("error", e);
        Toast.Error(e);
      });
  };

  const navigateTo = (currentView) => {
    setCurrentView(currentView);
    setInvalidOtp(false);
    setOtp("");
    if (currentView === SCREENS.OTP) {
      setValues({
        ...values,
        password: "",
        rePassword: "",
        showPassword: false,
        showRePassword: false,
      });
    }
  };

  const handleOtpInput = (otp) => {
    setOtp(otp);
    if (validateOtpInput(otp)) {
      setInvalidOtp(false);
    } else {
      setInvalidOtp(true);
    }
  };

  const signUp = () => {
    const payload = {
      email: values.email,
      otp: otp,
    };
    api
      .validateOtp(payload)
      .then((res) => {
        if (res.status === 200) {
          setLocalStorage("token", res.headers.token);
          setCurrentView(SCREENS.CREATE_PASSWORD);
        } else if (res.status >= 400) {
          setInvalidOtp(true);
          Toast.Error(res.data.message);
        }
        setLoading(false);
      })
      .catch((e) => {
        setInvalidOtp(true);
        setLoading(false);
        Toast.Error(e);
      });
  };

  const resendOtp = () => {
    if (resendOtpActive) {
      setLoading(true);
      setResendOtpActive(false);
      const payload = {
        email: values.email,
      };
      api
        .requestOtp(payload)
        .then((res) => {
          if (res.status === 200) {
            setRestartTimer(true);
            setResendOtpActive(false);
            Toast.Success(res.data.message);
          } else if (res.status >= 400) {
            Toast.Error(res.data.message);
          }
          setLoading(false);
        })
        .catch((e) => {
          Toast.Error(e);
        });
    }
  };

  const singupView = () => {
    return (
      <div className="signup-form">
        <p className="signup-welcome">Welcome</p>
        <p className="signup-sign-in-text">Sign up your account with</p>
        <div></div>
        <p className="signup-input-label">Enter Email ID</p>
        <OutlinedInput
          className="signup-input"
          type="email"
          value={values.email}
          placeholder="Email ID"
          onChange={handleChange("email")}
          error={isInvalidEmail || userExistsError}
          autoComplete=""
          disabled={loading}
          endAdornment={
            <InputAdornment position="end">
              <img src={userIcon} alt="User" />
            </InputAdornment>
          }
        />
        {isInvalidEmail && (
          <p className="signup-error-label">Invalid Email Address</p>
        )}
        {userExistsError && (
          <p className="otp-error-label">{userExistsError}</p>
        )}
        <LoadingButton
          className="sendOtp-button"
          variant="contained"
          disabled={values.email === "" || isInvalidEmail}
          sx={{ backgroundColor: "#4D6CD9", marginTop: "2rem" }}
          onClick={onSendOTP}
          loading={loading}
        >
          Send OTP
        </LoadingButton>

        {/* <SocialLogin /> */}
      </div>
    );
  };

  const otpView = () => {
    return (
      <div className="signup-form">
        <div className="back-button" onClick={() => navigateTo(SCREENS.SIGNUP)}>
          <div>
            <div className="arrow arrow-left"></div>&nbsp;Back
          </div>
        </div>
        <div className="enter-otp-label">Enter OTP</div>
        <div className="otp-info">
          4 digit OTP has been sent to your Email Address
        </div>
        <OtpInput
          value={otp}
          onChange={handleOtpInput}
          shouldAutoFocus={true}
          numInputs={4}
          isInputNum={true}
          inputStyle={"otpInputContainer"}
          hasErrored={invalidOtp}
          errorStyle={"otpInputContainer-error"}
        />
        {invalidOtp && (
          <p className="otp-error-label">OTP is invalid, please try again</p>
        )}
        <LoadingButton
          className="signup-button"
          variant="contained"
          sx={{ backgroundColor: "#4D6CD9", marginBottom: "14rem" }}
          disabled={!validateOtpInput(otp)}
          onClick={signUp}
          loading={loading}
        >
          sign Up
        </LoadingButton>
        <div className="resend-otp-label">
          <p
            className={
              resendOtpActive
                ? "resend-otp-label-active"
                : "resend-otp-label-inactive"
            }
            onClick={resendOtp}
          >
            Resend OTP
          </p>
          <p className="otp-timer">
            &nbsp;(00:
            {
              <Timer
                seconds={"59"}
                restartTimer={restartTimer}
                onEndTimer={() => {
                  setResendOtpActive(true);
                }}
                changeReset={() => setRestartTimer(false)}
              />
            }
            )
          </p>
        </div>
      </div>
    );
  };

  const createPasswordView = () => {
    return (
      <form onSubmit={handleSubmit(onSubmit)} className="signup-form">
        <div className="back-button" onClick={() => navigateTo(SCREENS.OTP)}>
          <div>
            <div className="arrow arrow-left"></div>&nbsp;Back
          </div>
        </div>
        <div className="create-password">Create Password</div>

        <input
          name="password"
          type="password"
          placeholder="Enter Password"
          {...register("password")}
          className={`password-input ${errors.password ? "is-invalid" : ""}`}
        />
        <div className="invalid-feedback">{errors.password?.message}</div>
        <input
          name="confirmPassword"
          type="password"
          placeholder="Re-enter Password"
          {...register("confirmPassword")}
          className={`password-input ${
            errors.confirmPassword ? "is-invalid" : ""
          }`}
        />
        <div className="invalid-feedback">
          {errors.confirmPassword?.message}
        </div>

        <button type="submit" className="create-password-proceed">
          Register
        </button>
        <div
          className="skip-now"
          onClick={() => setCurrentView(SCREENS.ONBOARD)}
        >
          Skip for now
        </div>
      </form>
    );
  };

  const SocialLogin = () => {
    const GOOGLE_LOGIN_CLENT_ID = process.env.REACT_APP_GOOGLE_LOGIN_CLENT_ID;

    const onSuccess = (response) => {
      const { profileObj } = response;
      const { email } = profileObj;
      const payload = {
        email,
      };

      setSocialError(null);
      setSocialLoading(true);
      api
        .registerUser(payload)
        .then((res) => {
          if (
            (res.status === 200 &&
              !res.data.message
                .toLowerCase()
                .includes("user already exists")) ||
            (res.status === 404 && !res.data.data.attributes.user_status)
          ) {
            setCurrentView(SCREENS.OTP);
            const isFreelanceRecruiter =
              res.data.data.attributes["role"] === "freelance-recruiter";
            const name = isFreelanceRecruiter
              ? res.data.data.attributes["display_name"]
              : res.data.data.company["attributes"]["title"];
            setLocalStorage("user", name);
            setLocalStorage("userId", res.data.data.attributes["ID"]);
            setValues({ ...values, email });
          } else if (
            res.status === 409 ||
            res.data.message.toLowerCase().includes("user already exists")
          ) {
            setSocialError(
              "A user already exists with the given email address. Sign in to continue"
            );
          } else if (res.status >= 400 && res.status !== 409) {
            Toast.Error(res.data.message);
          }
          setSocialLoading(false);
        })
        .catch((e) => {
          setSocialError("There was an error authenticating with Google");
          setSocialLoading(false);
          console.log("error", e);
          Toast.Error(e);
        });
    };

    const onFailure = (response) => {
      console.log(response);
    };

    return (
      <div className="social-buttons-container">
        <div className="horizontal-divider">
          <p className="divider"></p>
          <p>&emsp;Or&emsp;</p>
          <p className="divider"></p>
        </div>
        <GoogleLogin
          clientId={GOOGLE_LOGIN_CLENT_ID}
          render={(renderProps) => (
            <LoadingButton
              className="social-button"
              variant="outlined"
              sx={{ marginTop: "2rem" }}
              onClick={() => {
                renderProps.onClick();
                setSocialError(null);
              }}
              loading={socialLoading}
              disabled={socialLoading}
            >
              <img src={google} alt="Google" /> &nbsp; Continue with Google
            </LoadingButton>
          )}
          buttonText="Continue with Google"
          onSuccess={onSuccess}
          onFailure={onFailure}
          cookiePolicy={"single_host_origin"}
        />
        <Button
          className="social-button"
          disabled={false}
          variant="outlined"
          startIcon={<img src={linkedin} alt="linkedin" />}
        >
          Continue With LinkedIn
        </Button>
        <p className="otp-error-label">{socialError}</p>
      </div>
    );
  };

  function renderSwitch(currentView) {
    switch (currentView) {
      case SCREENS.SIGNUP:
        return singupView();
      case SCREENS.OTP:
        return otpView();
      case SCREENS.CREATE_PASSWORD:
        return createPasswordView();
      default:
        return singupView();
    }
  }

  if (currentView === SCREENS.ONBOARD) {
    if (onBoardAs) {
      history.push({
        pathname: ON_BOARDING,
        state: onBoardAs,
      });
      return <></>;
    } else {
      return <OnboardAs />;
    }
  } else
    return (
      <ErrorBoundary>
        <div className="signup-wrapper">
          <Card>
            <div className="signup-container">
              <img src={signupImage} alt="Signup" />
              <div className="signup-form-container">
                {renderSwitch(currentView)}
                <div className="bottom-signin-container">
                  <div className="bottom-signin-label">
                    Already have an account?
                    <Link to={LOGIN}>
                      <span className="bottom-signin-label-link">
                        &nbsp;Sign In here
                      </span>
                    </Link>
                  </div>
                </div>
                <p className="signup-copyright">{COPYRIGHT}</p>
              </div>
            </div>
          </Card>
        </div>
      </ErrorBoundary>
    );
};

export default Signup;
