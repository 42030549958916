const parse = (response: any) => {
  const lables = ["0 to 30 days", "31 to 60 days"];

  const keys = Object.keys(response.data);

  const parsedData = keys.map((item: any, index: number) => {
    const data = response.data[item];
    return {
      label: lables[index],
      data: data.map((item) => {
        return {
          name: item["bucket_name"],
          junior: item["junior"].count,
          juniorJSIds: item["junior"].js.join(","),
          senior: item["senior"].count,
          seniorJSIds: item["senior"].js.join(","),
          lead: item["lead"].count,
          leadJSIds: item["lead"].js.join(","),
          architect: item["architect"].count,
          architectJSIds: item["architect"].js.join(","),
        };
      }),
    };
  });

  return parsedData;
};

export default parse;
