import {
  GET_COMPANY_DETAILS,
  GET_BACKGROUND_DETAILS,
  GET_BILLING_DETAILS,
  GET_RECRUITER_DETAILS,
  POST_COMPANY_DETAILS,
  POST_TEAM_DETAILS,
  POST_BACKGROUND_DETAILS,
  POST_BILLING_DETAILS,
  GET_RECRUITERS,
  GET_RECRUITER,
  setRecruiterCompanyDetails,
  setRecruiterBackground,
  setRecruiterBillingDetails,
  setRecruiters,
  setRecruiter,
} from "../actions/recruiterProfile";
import { setError } from "../actions/common";
import {
  parseCompany,
  parseUserStage,
  parseBackground,
  parseBilling,
  parseCompanyRecruiter,
  payloadForCompanyDetails,
  payloadForTeamDetails,
  payloadForBackgroundDetails,
  payloadForBillingDetails,
} from "../../utils/parsers/recruiter-profile-parser";
import parseRecruiters from "../../utils/parsers/recruiters-parser";
import { setCurrentUser } from "../actions/user";
import parseRecruiterDetails from "../../utils/parsers/recruiter-parser";
import { getStageNameForRecruiterType } from "../../utils/util";

const recruiterProfile = ({ api, log }) => ({ dispatch }) => (next) => async (
  action
) => {
  next(action);
  const userStageName = getStageNameForRecruiterType(
    action.role,
    action.stage
  ).toLowerCase();
  if (action.type === GET_COMPANY_DETAILS) {
    try {
      dispatch(setRecruiters({ loading: true }));
      dispatch(setError(null));
      const companyDetailsData = await api.recruiterProfile.getRecruiterCompanyDetails(
        action.payload,
        action.role
      );
      log(`Successfully fetched the ${userStageName}`);
      dispatch(
        setRecruiterCompanyDetails({
          companyDetails: parseCompany(companyDetailsData.data, action.role),
        })
      );
      dispatch(
        setCurrentUser({ userStage: parseUserStage(companyDetailsData) })
      );
    } catch (error) {
      const payload = {
        error,
        message: `Error while fetching the ${userStageName}`,
      };
      dispatch(setError(payload));
      log(payload);
    }
    dispatch(setRecruiters({ loading: false }));
  }

  if (action.type === GET_BILLING_DETAILS) {
    try {
      dispatch(setRecruiters({ loading: true }));
      dispatch(setError(null));
      const billingData = await api.recruiterProfile.getRecruiterBillingDetails(
        action.payload,
        action.role
      );
      log(`Successfully fetched the ${userStageName}`);
      dispatch(
        setRecruiterBillingDetails({
          recruiterBilling: parseBilling(billingData.data, action.role),
        })
      );
      dispatch(setCurrentUser({ userStage: parseUserStage(billingData) }));
    } catch (error) {
      const payload = {
        error,
        message: `Error while fetching the ${userStageName}`,
      };
      dispatch(setError(payload));
      log(payload);
    }
    dispatch(setRecruiters({ loading: false }));
  }

  if (action.type === GET_BACKGROUND_DETAILS) {
    try {
      dispatch(setRecruiters({ loading: true }));
      dispatch(setError(null));
      const backgroundData = await api.recruiterProfile.getRecruiterBackground(
        action.payload,
        action.role
      );
      log(`Successfully fetched the ${userStageName}`);
      dispatch(
        setRecruiterBackground({
          recruiterBackground: parseBackground(
            backgroundData.data,
            action.role
          ),
        })
      );
      dispatch(setCurrentUser({ userStage: parseUserStage(backgroundData) }));
    } catch (error) {
      const payload = {
        error,
        message: `Error while fetching the ${userStageName}`,
      };
      dispatch(setError(payload));
      log(payload);
    }
    dispatch(setRecruiters({ loading: false }));
  }

  if (action.type === GET_RECRUITER_DETAILS) {
    try {
      dispatch(setRecruiter({ loading: true }));
      dispatch(setError(null));
      const recruiterData = await api.recruiterProfile.getRecruiterById(
        action.payload
      );
      log(`Successfully fetched the ${userStageName}`);
      dispatch(
        setRecruiter({
          recruiterData: parseCompanyRecruiter(recruiterData.data),
        })
      );
      dispatch(setCurrentUser({ userStage: parseUserStage(recruiterData) }));
    } catch (error) {
      const payload = {
        error,
        message: `Error while fetching the ${userStageName}`,
      };
      dispatch(setError(payload));
      log(payload);
    }
    dispatch(setRecruiter({ loading: false }));
  }

  if (action.type === POST_COMPANY_DETAILS) {
    try {
      dispatch(setRecruiters({ loading: true }));
      dispatch(setError(null));
      const payload = payloadForCompanyDetails(action.payload, action.role);
      const companyDetailsData = await api.recruiterProfile.postRecruiterCompanyDetails(
        action.id,
        payload,
        action.role
      );
      log(`Successfully updated the ${userStageName} details`);
    } catch (error) {
      const payload = {
        error,
        message: `Error while updating the ${userStageName} details. Please try again.`,
      };
      dispatch(setError(payload));
      log(payload);
    }
    dispatch(setRecruiters({ loading: false }));
  }

  if (action.type === POST_TEAM_DETAILS) {
    try {
      dispatch(setRecruiters({ loading: true }));
      dispatch(setError(null));
      const payload = payloadForTeamDetails(action.payload);
      const teamData = await api.recruiterProfile.postRecruiterTeamDetails(
        action.id,
        payload
      );
      log("Successfully updated the team details");
    } catch (error) {
      const payload = {
        error,
        message: "Error while updating the team details. Please try again.",
      };
      dispatch(setError(payload));
      log(payload);
    }
    dispatch(setRecruiters({ loading: false }));
  }

  if (action.type === POST_BILLING_DETAILS) {
    try {
      dispatch(setRecruiters({ loading: true }));
      dispatch(setError(null));
      const payload = payloadForBillingDetails(action.payload, action.role);
      const billignData = await api.recruiterProfile.postRecruiterBillingDetails(
        action.id,
        payload,
        action.role
      );
      log(`Successfully updated the ${userStageName}`);
    } catch (error) {
      const payload = {
        error,
        message: `Error while updating the ${userStageName}. Please try again.`,
      };
      dispatch(setError(payload));
      log(payload);
    }
    dispatch(setRecruiters({ loading: false }));
  }

  if (action.type === POST_BACKGROUND_DETAILS) {
    try {
      dispatch(setRecruiters({ loading: true }));
      dispatch(setError(null));
      const payload = payloadForBackgroundDetails(action.payload, action.role);
      const backgroundData = await api.recruiterProfile.postRecruiterBackground(
        action.id,
        payload,
        action.role
      );
      log(`Successfully updated the ${userStageName}`);
    } catch (error) {
      const payload = {
        error,
        message: `Error while updating the ${userStageName}. Please try again.`,
      };
      dispatch(setError(payload));
      log(payload);
    }
    dispatch(setRecruiters({ loading: false }));
  }

  if (action.type === GET_RECRUITERS) {
    try {
      dispatch(setRecruiters({ loading: true }));
      dispatch(setError(null));
      const recruiters = await api.recruiterProfile.getAllRecruiters();
      log("Successfully fetched the recruiters data");
      dispatch(setError(null));
      dispatch(
        setRecruiters({
          recruiters: parseRecruiters(recruiters),
        })
      );
    } catch (error) {
      const payload = {
        error,
        message: "Error while retrieving the recruiters",
      };
      dispatch(setError(payload));
      log(payload);
    }
    dispatch(setRecruiters({ loading: false }));
  }

  if (action.type === GET_RECRUITER) {
    try {
      dispatch(setRecruiter({ loading: true }));
      dispatch(setError(null));
      const recruiter = await api.recruiterProfile.getRecruiterById(
        action.payload
      );
      log("Successfully fetched the recruiter data");
      dispatch(setError(null));
      dispatch(
        setRecruiter({
          loading: false,
          recruiter: parseRecruiterDetails(recruiter),
        })
      );
    } catch (error) {
      log("Error while retrieving the recruiter");
      const payload = {
        error,
        message: "Error while retrieving the recruiter",
      };
      dispatch(setError(payload));
      dispatch(setRecruiter({ loading: false }));
    }
  }
};

export default [recruiterProfile];
