import { useState } from "react";
import Card from "../Card";
import Button from "../Button";
import wantedImage from "../../assets/wanted.svg";
import JSConnectDialog from "../JSConnectDialog";
import "./styles.css";
import StyledCheckbox from "../Checkbox";
import { useDispatch } from "react-redux";
import {
  setSelectedHotlist,
  unsetSelectedHotlist,
} from "../../redux/actions/hotlist";

interface JSDetails {
  id: string;
  role: string;
  primarySkill: string;
  experience: string;
  expectedCTC: string;
  currentLocation: string;
  joiningDate: string;
  preferredLocations: string;
  currentCTC: string;
  secondarySkills: string;
  employmentStatus: string;
  uploadedOn: string;
}

interface Props {
  jsDetails: JSDetails,
  checkedList: string[]
}

const HotlistListCard: React.FC<Props> = (props) => {
  const { jsDetails, checkedList } = props;
  const [showDialog, setShowDialog] = useState(false);

  const handleConnect = () => {
    setShowDialog(true);
  };

  const onClose = () => {
    setShowDialog(false);
  };

  const dispatch = useDispatch();
  function setSelectedJobIds(
    row: JSDetails,
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const {
      target: { checked },
    } = event;
    if (checked) {
      dispatch(setSelectedHotlist(row));
    } else {
      dispatch(unsetSelectedHotlist(row.id));
    }
  }
  return (
    <>
      {showDialog && <JSConnectDialog onClose={onClose} />}
      <Card>
        <div className="hotlist-list-card-container">
          <div className="hotlist-list-card-container-section-1">
            <div className="hotlist-list-card-container-section-1-image-container">
              <img src={wantedImage} alt="Job Seeker" />
              <p className="hotlist-list-card-role">{jsDetails.role}</p>
              <p>{`Uploaded On ${jsDetails.uploadedOn}`}</p>
            </div>

            <div className="hotlist-list-card-container-section-1-details">
              <p className="hotlist-list-card-id">{`ID - ${jsDetails.id}`}</p>
              <p>
                <b>{jsDetails.currentLocation}</b>
              </p>
              <div className="hotlist-list-card-details-container">
                <div className="hotlist-list-card-details">
                  <p>Experience :</p>
                  <p>
                    <b>{jsDetails.experience}</b>
                  </p>
                </div>
                <div className="hotlist-list-card-details">
                  <p>Current CTC :</p>
                  <p>
                    <b>{jsDetails.currentCTC}</b>
                  </p>
                </div>
                <div className="hotlist-list-card-details">
                  <p>Expected CTC :</p>
                  <p>
                    <b>{jsDetails.expectedCTC}</b>
                  </p>
                </div>
                <div className="hotlist-list-card-details">
                  <p>Earliest Joining Date :</p>
                  <p>
                    <b>{jsDetails.joiningDate}</b>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="hotlist-list-card-container-section-2">
            <p className="hotlist-list-card-container-section-2-text">
              Primary Skill :
            </p>
            <p>
              <b>{jsDetails.primarySkill}</b>
            </p>
            <p className="hotlist-list-card-container-section-2-text">
              Secondary Skills :
            </p>
            <p>
              <b>{jsDetails.secondarySkills}</b>
            </p>
            <p className="hotlist-list-card-container-section-2-text">
              Preferred Work Location(s) :
            </p>
            <p>
              <b>{jsDetails.preferredLocations}</b>
            </p>
            <p className="hotlist-list-card-container-section-2-text">
              Current Employment Status :
            </p>
            <p>
              <b>{jsDetails.employmentStatus}</b>
            </p>
            <div className="hotlist-card-add-list-card">
              <label>
                <StyledCheckbox
                  onChange={(e) => setSelectedJobIds(jsDetails, e)}
                  checked={checkedList.includes(jsDetails.id)}
                />
                <span style={{ cursor: "pointer" }}>Add To List</span>
              </label>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default HotlistListCard;
