import { GET_PARTNERS, setPartners } from "../actions/partners";
import { setError } from "../actions/common";
import parse from "../../utils/parsers/partners-parser";

const partnersFlow = ({ api, log }) => ({ dispatch }) => (next) => async (
  action
) => {
  next(action);

  if (action.type === GET_PARTNERS) {
    try {
      dispatch(setPartners({ loading: true }));
      const partners = await api.partners.getAll(action.payload);
      log("Successfully fetched the partners data");
      dispatch(setError(null));
      dispatch(setPartners({ partnersData: parse(partners), loading: false }));
    } catch (error) {
      const payload = {
        error,
        message: "Error while fetching the partners details",
      };
      dispatch(setError(payload));
      dispatch(setPartners({ loading: false }));
      log("Error while fetching the partners details");
    }
  }
};

export default [partnersFlow];
