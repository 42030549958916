import { SET_BLOGS } from "../actions/blogs";

const blogsReducer = (state = { blogs: [] }, action) => {
  switch (action.type) {
    case SET_BLOGS:
      return action.payload;
    default:
      return state;
  }
};

export default blogsReducer;
