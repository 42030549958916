import { useEffect, useState } from "react";
import Button from "../Button";
import Card from "../Card";
import wantedImage from "../../assets/wanted.svg";
import JSConnectDialog from "../JSConnectDialog";
import "./styles.css";
import { useDispatch } from "react-redux";
import StyledCheckbox from "../Checkbox";
import {
  setSelectedHotlist,
  unsetSelectedHotlist,
} from "../../redux/actions/hotlist";
interface JSDetails {
  id: string;
  role: string;
  primarySkill: string;
  experience: string;
  expectedCTC: string;
  currentLocation: string;
  joiningDate: string;
  preferredLocations: string;
  uploadedOn: string;
}

interface Props {
  jsDetails: JSDetails,
  checkedList: string[]
}

const ContestCard: React.FC<Props> = (props) => {
  const { jsDetails , checkedList} = props;
  const [hovered, setHovered] = useState(false);
  const dispatch = useDispatch();
  function setSelectedJobIds(
    row: JSDetails,
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const {
      target: { checked },
    } = event;
    if (checked) {
      dispatch(setSelectedHotlist(row));
    } else {
      dispatch(unsetSelectedHotlist(row.id));
    }
  }
  return (
    <div
      className={`hotlist-card-wrapper ${
        hovered ? "hotlist-card-wrapper-hovered" : ""
      }`}
    >
      <Card>
        <div
          onMouseEnter={() => {
            setHovered(true);
          }}
          onMouseLeave={() => {
            setHovered(false);
          }}
          onTouchStart={() => {
            setHovered(true);
          }}
          onTouchEnd={() => {
            setHovered(false);
          }}
          className="hotlist-card-container"
        >
          <p className="hotlist-card-id">{`ID - ${jsDetails.id}`}</p>
          <div className="hotlist-card-add-list-card">
            <label>
              <StyledCheckbox
                onChange={(e) => setSelectedJobIds(jsDetails, e)}
                checked={checkedList.includes(jsDetails.id)}
              />
              <span style={{ cursor: "pointer" }}>Add To List</span>{" "}
            </label>
          </div>
          <p>{`Uploaded On ${jsDetails.uploadedOn}`}</p>
          <img
            src={wantedImage}
            alt="Job Seeker"
            className="hotlist-card-wanted-image"
          />
          <p className="hotlist-card-role">{jsDetails.role}</p>
          <hr className="hotlist-card-separator" />
          <div className="hotlist-card-details-container">
            <div className="hotlist-card-details">
              <p>Primary Skill :</p>
              <p>
                <b>{jsDetails.primarySkill}</b>
              </p>
            </div>
            <div className="hotlist-card-details">
              <p>Experience :</p>
              <p>
                <b>{jsDetails.experience}</b>
              </p>
            </div>
            <div className="hotlist-card-details">
              <p>Expected CTC :</p>
              <p>
                <b>{jsDetails.expectedCTC}</b>
              </p>
            </div>
            <div className="hotlist-card-details">
              <p>Current Location :</p>
              <p>
                <b>{jsDetails.currentLocation}</b>
              </p>
            </div>
          </div>
          <hr className="hotlist-card-separator" />
          <div className="hotlist-card-hovered-container">
            <p className="hotlist-card-hovered-text">Earliest Joining Date :</p>
            <p>
              <b>{jsDetails.joiningDate}</b>
            </p>
            <p className="hotlist-card-hovered-text">Preferred Locations :</p>
            <p>
              <b>{jsDetails.preferredLocations}</b>
            </p>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default ContestCard;
