const parse = (response: any) => {
  const links: any = {};
  links.dashboard = response.data["dashboard"];
  links.contestActivity = response.data["contest_activity"];
  links.contestBookmarked = response.data["contest_bookmarked"];
  links.contestRejected = response.data["contest_rejected"];

  return links;
};

export default parse;
