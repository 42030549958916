import "./styles.scss";
import blueUnderline from "../../assets/blue-underline.svg";

interface Props {
  text: string; //Heading text
}
const Heading = (props: Props): JSX.Element => {
  const { text } = props;

  return (
    <div className="heading-container">
      <p className="heading-label">{text}</p>

      <img src={blueUnderline} className="underline" />
    </div>
  );
};

export default Heading;
