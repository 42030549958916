import { useEffect, useState } from "react";
import dots from "../../assets/dots.svg";
import "./styles.css";

const Timer = (props: any) => {
  const { endDate } = props;
  const endTime = endDate.getTime();
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const getFormattedText = (value: number) => {
    let timerValue = `${value}`;
    if (value < 10) {
      timerValue = `0${value}`;
    }

    const formattedText = [];

    for (let i = 0; i < timerValue.length; i++) {
      formattedText[i] = (
        <p className="countdown-timer-time">{timerValue[i]}</p>
      );
    }

    return formattedText;
  };

  useEffect(() => {
    const myInterval = setInterval(() => {
      const currentTime = new Date().getTime();
      const duration = endTime - currentTime;

      const daysRemaining = Math.floor(duration / (1000 * 60 * 60 * 24));
      const hoursRemaining = Math.floor(
        (duration % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutesRemaining = Math.floor(
        (duration % (1000 * 60 * 60)) / (1000 * 60)
      );
      const secondsRemaining = Math.floor((duration % (1000 * 60)) / 1000);

      if (duration < 0) {
        clearInterval(myInterval);
      } else {
        setDays(daysRemaining);
        setHours(hoursRemaining);
        setMinutes(minutesRemaining);
        setSeconds(secondsRemaining);
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <div className="countdown-timer-wrapper">
      <div className="countdown-timer-container">
        <div className="countdown-timer-time-value-container">
          <div> {getFormattedText(days)}</div>
          <p className="countdown-timer-label">Days</p>
        </div>
        <div className="countdown-timer-time-container">
          <div className="countdown-timer-time-value-container">
            <div>{getFormattedText(hours)}</div>
            <p className="countdown-timer-label">Hours</p>
          </div>
          <img src={dots} alt="Dots" className="countdown-timer-dots" />
          <div className="countdown-timer-time-value-container">
            <div> {getFormattedText(minutes)}</div>
            <p className="countdown-timer-label">Minutes</p>
          </div>
          <img src={dots} alt="Dots" className="countdown-timer-dots" />
          <div className="countdown-timer-time-value-container">
            <div>{getFormattedText(seconds)}</div>
            <p className="countdown-timer-label">Seconds</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timer;
