import { GET_EMPLOYER, setEmployer } from "../actions/employer";
import { setError } from "../actions/common";
import parse from "../../utils/parsers/employer-parser";

const employerFlow = ({ api, log }) => ({ dispatch }) => (next) => async (
  action
) => {
  next(action);

  if (action.type === GET_EMPLOYER) {
    try {
      dispatch(setEmployer({ loading: true }));
      const employer = await api.employer.getById(action.payload);
      log("Successfully fetched the employer data");
      dispatch(setError(null));
      dispatch(setEmployer({ employer: parse(employer), loading: false }));
    } catch (error) {
      const payload = {
        error,
        message: "Error while fetching the employer details",
      };
      dispatch(setError(payload));
      dispatch(setEmployer({ loading: false }));
      log("Error while fetching the employer details");
    }
  }
};

export default [employerFlow];
