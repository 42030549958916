import "./styles.scss";

interface Props {
  icon: string;
  title: string;
  description: string;
  height?: string;
  width?: string;
}
const InfoCard = (props: Props) => {
  const { icon, title, description, height, width } = props;
  const page = window.location.pathname;
  return (
    <div
      className={page === "/" ? "info-card info-card-homepage" : `info-card`}
      style={{ height: height, width: width }}
    >
      <div className="info-card-icon-container">
        <img src={icon} alt={title} />
      </div>
      <label className="info-card-title">{title}</label>
      <label className="info-card-desc">{description}</label>
    </div>
  );
};

export default InfoCard;
