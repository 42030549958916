import { GET_BLOGS, setBlogs } from "../actions/blogs";
import { setError } from "../actions/common";
import parse from "../../utils/parsers/blogs-parser";

const blogsFlow = ({ api, log }) => ({ dispatch }) => (next) => async (
  action
) => {
  next(action);

  if (action.type === GET_BLOGS) {
    try {
      dispatch(setBlogs({ loading: true }));
      const blogs = await api.blogs.getAll(action.payload.type);
      log("Successfully fetched the blogs data");
      dispatch(setError(null));
      dispatch(setBlogs({ blogs: parse(blogs), loading: false }));
    } catch (error) {
      log("Error while fetching the blogs");
      dispatch(setBlogs({ loading: false }));
      const payload = {
        error,
        message: "Error while fetching the blogs",
      };
      dispatch(setError(payload));
    }
  }
};

export default [blogsFlow];
