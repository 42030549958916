import { RECRUITER_TYPES } from "../constants";

const parse = (response: any) => {
  const allCities = [];
  const recruiters = response.data.map((item: any) => {
    const recruiter = item["attributes"];

    const isFreelanceRecruiter = recruiter["role"] === "freelance-recruiter";
    const isArray =
      Array.isArray(item["company"]) && item["company"].length === 0;
    const hasCompanyDetails = !isFreelanceRecruiter && !isArray;

    const parsedRecruiterDetails: any = {};
    parsedRecruiterDetails.id = recruiter["ID"];
    parsedRecruiterDetails.name = isFreelanceRecruiter
      ? recruiter["display_name"]
      : hasCompanyDetails
      ? item["company"]["attributes"]["title"]
      : null;
    parsedRecruiterDetails.type = isFreelanceRecruiter
      ? RECRUITER_TYPES[0]
      : RECRUITER_TYPES[1];
    parsedRecruiterDetails.rank = recruiter["user_stats"]["rank"];
    parsedRecruiterDetails.rating = recruiter["user_stats"]["rating"];
    parsedRecruiterDetails.bountyEarned = `₹${recruiter["user_stats"][
      "bounty"
    ].toLocaleString("en-IN")}`;
    parsedRecruiterDetails.positionsClosed =
      recruiter["user_stats"]["positions_closed"];
    parsedRecruiterDetails.hitRatio = recruiter["user_stats"]["hit_rate"];
    parsedRecruiterDetails.competitionsParticipated = Array.isArray(
      item["contest"]
    )
      ? 0
      : item["contest"]["participating"]
      ? item["contest"]["participating"].length
      : 0;
    parsedRecruiterDetails.contestsWon = recruiter["user_stats"]["contest_won"];
    parsedRecruiterDetails.experience =
      recruiter["duration_of_working_as_a_freelancer"] &&
      Number(recruiter["duration_of_working_as_a_freelancer"]);
    parsedRecruiterDetails.activeSince = new Date(
      recruiter["user_registered"]
    ).getUTCFullYear();
    parsedRecruiterDetails.consolationPrizesWon =
      recruiter["user_stats"]["consolation_prizes_won"];
    parsedRecruiterDetails.numberOfRecruiters =
      hasCompanyDetails &&
      item["company"]["attributes"]["no_recruiters_company_holds"];
    const city =
      hasCompanyDetails && item["company"]["attributes"]["company_full_address"]
        ? `${item["company"]["attributes"]["company_full_address"]["city"]}`
        : recruiter["address_details"] &&
          `${recruiter["address_details"]["city"]}`;
    const location =
      hasCompanyDetails && item["company"]["attributes"]["company_full_address"]
        ? `${item["company"]["attributes"]["company_full_address"]["city"]}, ${item["company"]["attributes"]["company_full_address"]["state"]}`
        : recruiter["address_details"] &&
          `${recruiter["address_details"]["city"]}, ${recruiter[
            "address_details"
          ]["state"].toString()}`;
    parsedRecruiterDetails.location = location;
    parsedRecruiterDetails.filterRecruiter = isFreelanceRecruiter
      ? RECRUITER_TYPES[0]
      : RECRUITER_TYPES[1];
    parsedRecruiterDetails.filterRating = [
      recruiter["user_stats"]["rating"],
      recruiter["user_stats"]["rating"],
    ];
    parsedRecruiterDetails.filterCity = [city];
    parsedRecruiterDetails.bountyAmount = recruiter["user_stats"]["bounty"];
    parsedRecruiterDetails.profileImage = hasCompanyDetails
      ? item["company"]["attributes"]["logo"]["url"]
      : recruiter["upload_profile_image"]["url"];

    allCities.push(...parsedRecruiterDetails.filterCity);

    return parsedRecruiterDetails;
  });

  const parsedResponse = {
    recruiters,
    allCities: [...new Set(allCities)],
  };

  return parsedResponse;
};

export default parse;
