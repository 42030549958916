import { useSelector, RootStateOrAny } from "react-redux";
import { useHistory } from "react-router-dom";
import ContestDetail from "../ContestDetail";
import Button from "../Button";
import Card from "../Card";
import ContestIocns from "../ContestIcons";
import companyImage from "../../assets/company.svg";
import experienceImage from "../../assets/experience.svg";
import domainSkillsImage from "../../assets/domainSkills.svg";
import noticeImage from "../../assets/notice.svg";
import locationImage from "../../assets/location.svg";
import calendarImage from "../../assets/calendar.svg";
import positionsImage from "../../assets/positions.svg";
import ctcImage from "../../assets/ctc.svg";
import skillsImage from "../../assets/skills.svg";
import degreeImage from "../../assets/degree.svg";
import tagImage from "../../assets/tag.svg";
import {
  setSessionStorage,
  getLocalStorage,
} from "../../utils/storage-manager";
import { getImageForBadge } from "../../utils/util";
import CashReward from "../CashReward";
import { LOGIN } from "../../utils/constants/routes";
import "./styles.css";

interface ContestDetails {
  id: string;
  contestCreatedDuration?: string;
  employmentType: string;
  jobTitle: string;
  cashReward: string;
  company: string;
  experience: string;
  tools: string;
  noticePeriod: string;
  locations: string;
  interviewDays: string;
  positions: string;
  ctc: string;
  skills: string;
  degree: string;
  tags: string;
  rewards: Record<string, unknown>;
  badge: string;
  buttonText: string;
  buttonEnabled: boolean;
  iconsToShow: Array<string>;
  iconStatus?: string;
  contestUrl?: string;
}

interface Props {
  contestDetails: ContestDetails;
}

const renderDetails = (image: string, text: any, tooltip: string) => {
  return (
    text && (
      <div className="contest-details-detail-container">
        <img src={image} alt="Icon" title={tooltip} />
        <p className="contest-details-detail-text">{text}</p>
      </div>
    )
  );
};

const ContestDetailCard: React.FC<Props> = (props) => {
  const { contestDetails } = props;

  const history = useHistory();

  setSessionStorage("contestId", contestDetails.id);

  const isAuthenticated = useSelector(
    (state: RootStateOrAny) =>
      state.session.authenticated || getLocalStorage("token", null)
  );

  const handleBeginHunt = () => {
    if (isAuthenticated) {
      window.open(contestDetails.contestUrl);
    } else {
      history.push({
        pathname: LOGIN,
        state: { contestId: contestDetails.id },
      });
    }
  };
  return (
    <Card>
      <div className="contest-detail-card-container">
        {getImageForBadge(contestDetails.badge) && (
          <img
            src={getImageForBadge(contestDetails.badge)}
            alt="Badge"
            className="contest-detail-badge"
          />
        )}
        <div className="contest-detail-details-container">
          <ContestDetail contestDetails={contestDetails} />
          <div className="contest-detail-cash-rewards-container">
            <CashReward amount={contestDetails.cashReward} />
          </div>
          <Button
            label={contestDetails.buttonText}
            variant="primary"
            className="contest-details-card-button"
            onClick={() => {
              handleBeginHunt();
            }}
            disabled={!contestDetails.buttonEnabled}
          />
        </div>
        <div className="contest-detail-icons">
          <ContestIocns
            id={contestDetails.id}
            iconsToShow={contestDetails.iconsToShow}
            iconStatus={contestDetails.iconStatus}
          />
        </div>

        <div className="contest-details-section1">
          {renderDetails(companyImage, contestDetails.company, "Company")}
          {renderDetails(
            experienceImage,
            contestDetails.experience,
            "Experience"
          )}
          {renderDetails(
            domainSkillsImage,
            contestDetails.tools,
            "Primary Skills"
          )}
          {renderDetails(
            noticeImage,
            contestDetails.noticePeriod,
            "Notice Period"
          )}
          {renderDetails(locationImage, contestDetails.locations, "Locations")}
          {renderDetails(
            calendarImage,
            contestDetails.interviewDays,
            "Drive Type"
          )}
        </div>
        <div className="contest-details-section2">
          {renderDetails(positionsImage, contestDetails.positions, "Positions")}
          {renderDetails(ctcImage, contestDetails.ctc, "CTC")}
          {renderDetails(
            skillsImage,
            contestDetails.skills,
            "Secondary Skills"
          )}
          {renderDetails(
            degreeImage,
            contestDetails.degree,
            "Education Qualification"
          )}
          {renderDetails(tagImage, contestDetails.tags, "Domain(s)")}
        </div>
      </div>
    </Card>
  );
};

export default ContestDetailCard;
