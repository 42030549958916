import { SET_CURRENT_USER, SET_USER_SIGNUP_INFO } from "../actions/user";

const userReducer = (state = { userSignUpInfo: {} }, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return { ...state, ...action.payload };
    case SET_USER_SIGNUP_INFO:
      return action.userSignUpInfo;
    default:
      return state;
  }
};

export default userReducer;
