export const GET_EMPLOYER = "GET_EMPLOYER";
export const SET_EMPLOYER = "SET_EMPLOYER";

export const getEmployer = (id) => {
  return {
    type: GET_EMPLOYER,
    payload: id,
  };
};

export const setEmployer = (data) => {
  return {
    type: SET_EMPLOYER,
    payload: data,
  };
};
