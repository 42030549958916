import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import ContestDetailCard from "../../components/ContestDetailCard";
import ContestTimer from "../../components/ContestTimer";
import ContestQuota from "../../components/ContestQuota";
import Timeline from "../../components/Timeline";
import ContestTabs from "../../components/ContestTabs";
import Error from "../../components/Error";
import { setActiveLink } from "../../redux/actions/menu";
import { getContest } from "../../redux/actions/contest";
import {
  contestLoadingSelector,
  errorSelector,
  contestSelector,
} from "../../redux/selectors";
import "./styles.css";
import { getLocalStorage } from "../../utils/storage-manager";

const ContestPage = () => {
  const dispatch = useDispatch();
  const loading = useSelector(contestLoadingSelector);
  const error = useSelector(errorSelector);
  const { id } = useParams<{ id: string }>();
  const recruiterId = parseInt(getLocalStorage("userId", 0));

  const contestDetails = useSelector(contestSelector);

  useEffect(() => {
    dispatch(setActiveLink("Hiring Contests"));
    dispatch(getContest(id, recruiterId));
    window.scrollTo(0, 0);
  }, [dispatch, id]);

  const [activeStopIndex, setActiveStopIndex] = useState(0);

  useEffect(() => {
    contestDetails &&
      contestDetails.timelineData &&
      contestDetails.timelineData.forEach((data, index) => {
        if (data.isActiveRound) {
          setActiveStopIndex(index);
        }
      });
  }, [contestDetails]);

  return (
    <div>
      {loading && <CircularProgress className="loading" />}
      {!loading && !error && (
        <div className="contest-wrapper">
          <div className="contest-container">
            <div className="contest-details">
              <ContestDetailCard contestDetails={contestDetails} />
              <div className="contest-timer-quota-container">
                <ContestTimer
                  endDate={contestDetails.endDate}
                  jobPostedDuration={contestDetails.jobPostedDuration}
                />
                <div className="contest-quota-wrapper">
                  <ContestQuota
                    bonus={contestDetails.bonus}
                    profilesMatched={contestDetails.profilesMatched}
                    quota={contestDetails.quota}
                  />
                </div>
              </div>
            </div>
            <div className="contest-timeline-container">
              <Timeline
                timelineData={contestDetails.timelineData}
                activeStopIndex={activeStopIndex}
              />
            </div>
          </div>
          <ContestTabs
            aboutContest={contestDetails.aboutContest}
            domains={contestDetails.domains}
            jobDescription={contestDetails.jobDescription}
            rewards={contestDetails.rewards}
            partners={contestDetails.partners}
            faq={contestDetails.faq}
            termsAndConditions={contestDetails.termsAndConditions}
            contestStats={contestDetails.contestStats}
            contestMetrics={contestDetails.contestMetrics}
            employerId={contestDetails.employerId}
            tasks={contestDetails.tasks}
            background={contestDetails.background}
            skills={contestDetails.jobSkills}
          />
        </div>
      )}
      {!loading && error && <Error />}
    </div>
  );
};

export default ContestPage;
