import axios from "axios";
import { getLocalStorage } from "../storage-manager";

// Create axios client, pre-configured with baseURL
const axioss = axios.create({
  baseURL: process.env.REACT_APP_URL_PREFIX,
  timeout: 10000,
});

const addAuth = () => {
  const token = getLocalStorage("token", null);
  if (token) {
    axioss.defaults.headers["Authorization"] = "Bearer " + token;
  } else {
    axioss.defaults.headers["Authorization"] = null;
  }
};

const removeAuth = () => {
  delete axioss.defaults.headers["Authorization"];
};

axioss.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      const token = getLocalStorage("token", null);
      if (token) {
        console.log("Token :", token);
      } else {
        console.log("No token found");
      }
    } else {
      return Promise.reject(error);
    }
  }
);

const makeGet = (url) => {
  return axioss
    .get(url)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

const makePost = (url, payload) => {
  return axioss
    .post(url, payload)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export function get(url) {
  addAuth();
  return makeGet(url);
}

export function unathorisedGet(url) {
  removeAuth();
  return makeGet(url);
}

export function post(url, payload) {
  addAuth();
  return makePost(url, payload);
}

export function unathorisedPost(url, payload) {
  removeAuth();
  return makePost(url, payload);
}
