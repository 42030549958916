import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Button from "../Button";
import Card from "../Card";
import TextFilter from "../TextFilter";
import SliderFilter from "../SliderFilter";
import CheckboxFilter from "../CheckboxFilter";
import filterImage from "../../assets/filter.svg";
import styles from "./styles.module.scss";

const experienceMarks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 10,
    label: "10",
  },
  {
    value: 20,
    label: "20",
  },
  {
    value: 30,
    label: "30",
  },
  {
    value: 40,
    label: "40",
  },
  {
    value: 50,
    label: "50",
  },
  {
    value: 60,
    label: "60",
  },
];

const ctcMarks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 10,
    label: "10",
  },
  {
    value: 30,
    label: "30",
  },
  {
    value: 60,
    label: "60",
  },
  {
    value: 100,
    label: "100",
  },
];

const HotlistFilters = (props) => {
  const { hotlistData, hotlist, onFiltersChanged, onClose, onReset } = props;
  const [idFilter, setIdFilter] = useState(hotlist);
  const [experienceFilter, setExperienceFilter] = useState(hotlist);
  const [expectedCTCFilter, setExpectedCTCFilter] = useState(hotlist);
  const [currentCTCFilter, setCurrentCTCFilter] = useState(hotlist);
  const [joiningPeriodFilter, setJoiningPeriodFilter] = useState(hotlist);
  const [roleFilter, setRoleFilter] = useState(hotlist);
  const [secondarySkillsFilter, setSecondarySkillsFilter] = useState(hotlist);
  const [primarySkillsFilter, setPrimarySkillsFilter] = useState(hotlist);
  const [workingStatusFilter, setWorkingstatusFilter] = useState(hotlist);
  const [currentLocationFilter, setCurrentLocationFilter] = useState(hotlist);
  const [preferredLocationsFilter, setPreferredLocationsFilter] = useState(
    hotlist
  );

  const hasObject = (array, object) => {
    return array.filter((item) => item.id === object.id).length > 0;
  };

  const getCommonList = (
    experienceFilter,
    expectedCTCFilter,
    idFilter,
    currentCTCFilter,
    joiningPeriodFilter,
    roleFilter,
    secondarySkillsFilter,
    primarySkillsFilter,
    workingStatusFilter,
    currentLocationFilter,
    preferredLocationsFilter
  ) => {
    const commonList = [];
    const clonedList = JSON.parse(JSON.stringify(hotlist));
    clonedList.forEach((item) => {
      if (
        hasObject(experienceFilter, item) &&
        hasObject(expectedCTCFilter, item) &&
        hasObject(idFilter, item) &&
        hasObject(currentCTCFilter, item) &&
        hasObject(joiningPeriodFilter, item) &&
        hasObject(roleFilter, item) &&
        hasObject(secondarySkillsFilter, item) &&
        hasObject(primarySkillsFilter, item) &&
        hasObject(workingStatusFilter, item) &&
        hasObject(currentLocationFilter, item) &&
        hasObject(preferredLocationsFilter, item)
      ) {
        commonList.push(item);
      }
    });

    onFiltersChanged(commonList);
  };

  const onExperienceChange = (list) => {
    setExperienceFilter([...list]);
    setExperienceFilter((state) => {
      getCommonList(
        state,
        expectedCTCFilter,
        idFilter,
        currentCTCFilter,
        joiningPeriodFilter,
        roleFilter,
        secondarySkillsFilter,
        primarySkillsFilter,
        workingStatusFilter,
        currentLocationFilter,
        preferredLocationsFilter
      );
      return state;
    });
  };

  const onExpectedCTCChange = (list) => {
    setExpectedCTCFilter([...list]);
    setExpectedCTCFilter((state) => {
      getCommonList(
        experienceFilter,
        state,
        idFilter,
        currentCTCFilter,
        joiningPeriodFilter,
        roleFilter,
        secondarySkillsFilter,
        primarySkillsFilter,
        workingStatusFilter,
        currentLocationFilter,
        preferredLocationsFilter
      );
      return state;
    });
  };

  const onIdChange = (list) => {
    setIdFilter([...list]);
    setIdFilter((state) => {
      getCommonList(
        experienceFilter,
        expectedCTCFilter,
        state,
        currentCTCFilter,
        joiningPeriodFilter,
        roleFilter,
        secondarySkillsFilter,
        primarySkillsFilter,
        workingStatusFilter,
        currentLocationFilter,
        preferredLocationsFilter
      );
      return state;
    });
  };

  const onCurrentCTCChange = (list) => {
    setCurrentCTCFilter([...list]);
    setCurrentCTCFilter((state) => {
      getCommonList(
        experienceFilter,
        expectedCTCFilter,
        idFilter,
        state,
        joiningPeriodFilter,
        roleFilter,
        secondarySkillsFilter,
        primarySkillsFilter,
        workingStatusFilter,
        currentLocationFilter,
        preferredLocationsFilter
      );
      return state;
    });
  };

  const onJoiningPeriodChange = (list) => {
    setJoiningPeriodFilter([...list]);
    setJoiningPeriodFilter((state) => {
      getCommonList(
        experienceFilter,
        expectedCTCFilter,
        idFilter,
        currentCTCFilter,
        state,
        roleFilter,
        secondarySkillsFilter,
        primarySkillsFilter,
        workingStatusFilter,
        currentLocationFilter,
        preferredLocationsFilter
      );
      return state;
    });
  };

  const onRoleChange = (list) => {
    setRoleFilter([...list]);
    setRoleFilter((state) => {
      getCommonList(
        experienceFilter,
        expectedCTCFilter,
        idFilter,
        currentCTCFilter,
        joiningPeriodFilter,
        state,
        secondarySkillsFilter,
        primarySkillsFilter,
        workingStatusFilter,
        currentLocationFilter,
        preferredLocationsFilter
      );
      return state;
    });
  };

  const onSecondarySkillChange = (list) => {
    setSecondarySkillsFilter([...list]);
    setSecondarySkillsFilter((state) => {
      getCommonList(
        experienceFilter,
        expectedCTCFilter,
        idFilter,
        currentCTCFilter,
        joiningPeriodFilter,
        roleFilter,
        state,
        primarySkillsFilter,
        workingStatusFilter,
        currentLocationFilter,
        preferredLocationsFilter
      );
      return state;
    });
  };

  const onPrimarySkillChange = (list) => {
    setPrimarySkillsFilter([...list]);
    setPrimarySkillsFilter((state) => {
      getCommonList(
        experienceFilter,
        expectedCTCFilter,
        idFilter,
        currentCTCFilter,
        joiningPeriodFilter,
        roleFilter,
        secondarySkillsFilter,
        state,
        workingStatusFilter,
        currentLocationFilter,
        preferredLocationsFilter
      );
      return state;
    });
  };

  const onWorkingStatusChange = (list) => {
    setWorkingstatusFilter([...list]);
    setWorkingstatusFilter((state) => {
      getCommonList(
        experienceFilter,
        expectedCTCFilter,
        idFilter,
        currentCTCFilter,
        joiningPeriodFilter,
        roleFilter,
        secondarySkillsFilter,
        primarySkillsFilter,
        state,
        currentLocationFilter,
        preferredLocationsFilter
      );
      return state;
    });
  };

  const onCurrentLocationChange = (list) => {
    setCurrentLocationFilter([...list]);
    setCurrentLocationFilter((state) => {
      getCommonList(
        experienceFilter,
        expectedCTCFilter,
        idFilter,
        currentCTCFilter,
        joiningPeriodFilter,
        roleFilter,
        secondarySkillsFilter,
        primarySkillsFilter,
        workingStatusFilter,
        state,
        preferredLocationsFilter
      );
      return state;
    });
  };

  const onPreferredLocationsChange = (list) => {
    setPreferredLocationsFilter([...list]);
    setPreferredLocationsFilter((state) => {
      getCommonList(
        experienceFilter,
        expectedCTCFilter,
        idFilter,
        currentCTCFilter,
        joiningPeriodFilter,
        roleFilter,
        secondarySkillsFilter,
        primarySkillsFilter,
        workingStatusFilter,
        currentLocationFilter,
        state
      );
      return state;
    });
  };

  return (
    <Card>
      <div className={styles["hotlist-filters-container"]}>
        <div className={styles["hotlist-filters-title-container"]}>
          <img src={filterImage} />
          <p className={styles["hotlist-filters-title"]}>Filter Search</p>
          {onClose && (
            <div
              onClick={onClose}
              style={{ cursor: "pointer", marginLeft: "auto" }}
            >
              <CloseIcon />
            </div>
          )}
        </div>
        <Button
          className={styles["hotlist-filters-clear-filters-button"]}
          label="Clear Filters"
          variant="outlined"
          onClick={onReset}
        />
        <hr className={styles["hotlist-filters-separator"]} />
        <TextFilter
          filterName="Id"
          placeHolder="Id"
          list={hotlist}
          field="id"
          onFilterChanged={onIdChange}
        />
        <hr className={styles["hotlist-filters-separator"]} />
        <TextFilter
          filterName="Role"
          placeHolder="Role"
          list={hotlist}
          field="role"
          onFilterChanged={onRoleChange}
        />
        <hr className={styles["hotlist-filters-separator"]} />
        <CheckboxFilter
          filterName="Primary Skills"
          filters={hotlistData.allPrimarySkills}
          field="filterPrimarySkills"
          list={hotlist}
          onFilterChanged={onPrimarySkillChange}
        />
        <hr className={styles["hotlist-filters-separator"]} />
        <CheckboxFilter
          filterName="Secondary Skills"
          filters={hotlistData.allSecondarySkills}
          field="filterSecondarySkills"
          list={hotlist}
          onFilterChanged={onSecondarySkillChange}
        />
        <hr className={styles["hotlist-filters-separator"]} />
        <CheckboxFilter
          filterName="Current Location"
          filters={hotlistData.allLocations}
          field="filterCurrentLocation"
          list={hotlist}
          onFilterChanged={onCurrentLocationChange}
        />
        <hr className={styles["hotlist-filters-separator"]} />
        <CheckboxFilter
          filterName="Preferred Locations"
          filters={hotlistData.allLocations}
          field="filterPreferredLocations"
          list={hotlist}
          onFilterChanged={onPreferredLocationsChange}
        />
        <hr className={styles["hotlist-filters-separator"]} />
        <SliderFilter
          filterName="Current CTC (in lakhs)"
          marks={ctcMarks}
          min={0}
          max={100}
          step={10}
          range={[0, 100]}
          list={hotlist}
          field="filterCurrentCTC"
          onFilterChanged={onCurrentCTCChange}
        />
        <hr className={styles["hotlist-filters-separator"]} />
        <SliderFilter
          filterName="Expected CTC (in lakhs)"
          marks={ctcMarks}
          min={0}
          max={100}
          step={10}
          range={[0, 100]}
          list={hotlist}
          field="filterExpectedCTC"
          onFilterChanged={onExpectedCTCChange}
        />
        <hr className={styles["hotlist-filters-separator"]} />
        <SliderFilter
          filterName="Experience (in years)"
          marks={experienceMarks}
          min={0}
          max={60}
          step={1}
          range={[0, 60]}
          list={hotlist}
          field="filterExperience"
          onFilterChanged={onExperienceChange}
        />
        <hr className={styles["hotlist-filters-separator"]} />
        <SliderFilter
          filterName="Joining Period (in days)"
          marks={experienceMarks}
          min={0}
          max={60}
          step={1}
          range={[0, 60]}
          list={hotlist}
          field="filterJoiningPeriod"
          onFilterChanged={onJoiningPeriodChange}
        />
        <hr className={styles["hotlist-filters-separator"]} />
        <CheckboxFilter
          filterName="Current Employment Status"
          filters={hotlistData.allEmploymentStatus}
          field="filterWorkingStatus"
          list={hotlist}
          onFilterChanged={onWorkingStatusChange}
        />
        <hr className={styles["hotlist-filters-separator"]} />
      </div>
    </Card>
  );
};

export default HotlistFilters;
