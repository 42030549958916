import "./styles.scss";

interface Props {
  icon: string;
  metric: string;
  description: string;
  bgColor: string;
  descFontSize?: string;
  cardSize?: string;
}

const MetricCard = (props: Props) => {
  const { icon, metric, description, bgColor, descFontSize, cardSize } = props;
  return (
    <div
      className="metric-card"
      style={{ backgroundColor: bgColor, width: cardSize, height: cardSize }}
    >
      <div className="metric-card-icon-container">
        <img src={icon} alt={metric} />
      </div>
      <label className="metric-card-title">{metric}</label>
      <label className="metric-card-desc" style={{ fontWeight: descFontSize }}>
        {description}
      </label>
    </div>
  );
};

export default MetricCard;
