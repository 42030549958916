export const GET_EMPLOYERS = "GET_EMPLOYERS";
export const SET_EMPLOYERS = "SET_EMPLOYERS";

export const getEmployers = () => {
  return {
    type: GET_EMPLOYERS,
  };
};

export const setEmployers = (data) => {
  return {
    type: SET_EMPLOYERS,
    payload: data,
  };
};
