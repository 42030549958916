import {
  parseRecruiterInfo,
  preparePayload,
} from "../../utils/parsers/onboard-parser";
import { setLocalStorage } from "../../utils/storage-manager";
import { setError } from "../actions/common";
import { ONBOARD_RECRUITER, setOnboardedUser } from "../actions/onboard";
import { setCurrentUser } from "../actions/user";

const onboardRecruiter = ({ api, log }) => ({ dispatch }) => (next) => async (
  action
) => {
  next(action);

  if (action.type === ONBOARD_RECRUITER) {
    try {
      dispatch(setOnboardedUser({ loading: true }));
      dispatch(setError(null));
      const userData = await api.onboard.onboardFormSubmit(
        preparePayload(action.payload)
      );
      log(`Successfully onboarded recruiter`);

      const { ...rest } = userData.data.data.attributes;
      const parsedUser = parseRecruiterInfo(action.payload, rest);
      dispatch(
        setOnboardedUser({
          onboardedUser: parsedUser,
        })
      );
      dispatch(setCurrentUser(parsedUser));

      setLocalStorage("user", rest["display_name"]);
      setLocalStorage("userId", rest["ID"]);
      setLocalStorage("userRole", rest["role"]);
      setLocalStorage("userStage", rest["user_stage"]);
      setLocalStorage("inviteSource", rest["invite_source"]);
    } catch (error) {
      const payload = {
        error,
        message: "Error while onboarding recruiter",
      };
      dispatch(setError(payload));
      log(payload);
    }
    dispatch(setOnboardedUser({ loading: false }));
  }
};

export default [onboardRecruiter];
