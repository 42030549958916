import styles from "./style.module.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { createChunks } from "../../utils/util";
import HeroImage from "../HeroImage";

type button = {
  label: string;
  variant: string;
};

interface HeroImageProps {
  title: string;
  content: string;
  button?: button;
  image: string;
}

interface Props {
  heroImages: HeroImageProps[];
}

const HeroImageContainer: React.FC<Props> = (props) => {
  const { heroImages } = props;
  return (
    <div className={styles["hero-images-container"]}>
      <Carousel
        showThumbs={false}
        showStatus={false}
        infiniteLoop
        stopOnHover={false}
        showArrows={false}
        showIndicators={heroImages.length > 1}
        autoPlay
        interval={10000}
      >
        {createChunks(heroImages, 1).map((item, index, chunk) => {
          return (
            <>
              {chunk[index].map((heroImage) => {
                return (
                  <div
                    className={styles["hero-image-container-hero-image"]}
                    key={heroImage.title}
                  >
                    <HeroImage
                      title={heroImage.title}
                      content={heroImage.content}
                      button={heroImage.button}
                      image={heroImage.image}
                    />
                  </div>
                );
              })}
            </>
          );
        })}
      </Carousel>
    </div>
  );
};

export default HeroImageContainer;
