import { useState, useMemo } from "react";
import { RECRUITER_TYPES } from "../../utils/constants";
import RecruiterProfileCard from "../RecruiterProfileCard";
import RecruiterProfileListCard from "../RecruiterProfileListCard";
import Pagination from "../Pagination";
import gridViewImage from "../../assets/grid-view.png";
import gridViewActiveImage from "../../assets/grid-view-active.svg";
import listViewImage from "../../assets/list-view.png";
import listViewActiveImage from "../../assets/list-view-active.svg";
import SelectValidator from "../SelectValidator";
import RecruiterFilters from "../../components/RecruiterFilters";
import "./styles.css";

type RecruitersType = typeof RECRUITER_TYPES[number];

interface RecruiterDetails {
  id: number;
  name: string;
  location: string;
  type: RecruitersType;
  rank: number;
  bountyEarned: string;
  rating: number;
  positionsClosed: number;
  hitRatio: string;
  contestsWon: number;
  experience?: number;
  competitionsParticipated?: number;
  numberOfRecruiters?: number;
  profileImage?: string;
}

interface Props {
  recruiters: RecruiterDetails[];
  numberOfRecruiterssPerPage?: number;
  recruitersData: any;
  onReset?: any;
}

const Recruiters: React.FC<Props> = (props) => {
  const {
    recruiters,
    numberOfRecruiterssPerPage = 12,
    recruitersData,
    onReset,
  } = props;
  const [perPage, setPerPage] = useState(numberOfRecruiterssPerPage);

  const [currentPage, setCurrentPage] = useState(1);
  const [gridView, setGridView] = useState(true);

  const [sortedRecruiters, setSortedRecruiters] = useState([...recruiters]);

  const pageData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * perPage;
    const lastPageIndex = firstPageIndex + perPage;
    return sortedRecruiters.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, sortedRecruiters, perPage]);

  const sortOptions = [
    "Bounty Earned",
    "Rating",
    "Positions Closed",
    "Contests Won",
  ];

  const [activeSort, setActiveSort] = useState(sortOptions[0]);

  const numberPerPage = ["12", "24", "36", "48"];

  const onPageChange = (page: number) => {
    setCurrentPage(page);
    window.scrollTo(0, 0);
  };

  const handlePerPage = (event) => {
    setPerPage(Number(event.target.value));
  };

  const handleSort = (sortedRecruiters, value) => {
    let updatedRecruiters = sortedRecruiters;
    if (value === "Bounty Earned") {
      updatedRecruiters = updatedRecruiters.sort(function (
        recruiter1,
        recruiter2
      ) {
        return recruiter2.bountyAmount - recruiter1.bountyAmount;
      });
    }
    if (value === "Rating") {
      updatedRecruiters.sort(function (recruiter1, recruiter2) {
        return recruiter2.rating - recruiter1.rating;
      });
    }
    if (value === "Positions Closed") {
      updatedRecruiters.sort(function (recruiter1, recruiter2) {
        return recruiter2.positionsClosed - recruiter1.positionsClosed;
      });
    }
    if (value === "Contests Won") {
      updatedRecruiters.sort(function (recruiter1, recruiter2) {
        return recruiter1.contestsWon - recruiter2.contestsWon;
      });
    }

    setSortedRecruiters([...updatedRecruiters]);
  };

  const onFiltersChanged = (list) => {
    handleSort(list, activeSort);
  };

  return (
    <div className="recruiters-container">
      <div className="recruiters-title-container">
        <p className="recruiters-title">Recruiters</p>
        <div className="recruiters-sort-container">
          <img
            src={gridView ? gridViewActiveImage : gridViewImage}
            alt="Grid View"
            onClick={() => {
              setGridView(true);
            }}
            className="recruiters-icon"
          />
          <img
            src={gridView ? listViewImage : listViewActiveImage}
            alt="List View"
            onClick={() => {
              setGridView(false);
            }}
            className="recruiters-icon recruiters-list-view-icon"
          />
          <div className="recruiters-sort">
            <SelectValidator
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              register={() => {}}
              label="Sort by"
              list={sortOptions}
              value={sortOptions[0]}
              callback={(e) => {
                setActiveSort(e.target.value);
                handleSort(sortedRecruiters, e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <div className="recruiters-cards-container">
        <div className="recruiters-filters">
          <RecruiterFilters
            recruitersData={recruitersData}
            onFiltersChanged={onFiltersChanged}
            onReset={onReset}
          />
        </div>
        <div>
          {pageData && pageData.length === 0 && (
            <p className="recruiters-no-recruiters">
              There are currently no recruiters on board{" "}
            </p>
          )}
          {gridView && (
            <div className="recruiters-recruiters-container">
              {pageData &&
                pageData.length > 0 &&
                pageData.map((recruiterDetails: any, index: number) => {
                  return (
                    <RecruiterProfileCard
                      recruiterDetails={recruiterDetails}
                      key={index}
                    />
                  );
                })}
            </div>
          )}
          {!gridView &&
            pageData &&
            pageData.length > 0 &&
            pageData.map((contest: any, index: number) => {
              return (
                <div className="recruiters-list-card" key={index}>
                  <RecruiterProfileListCard
                    recruiterDetails={contest}
                    key={index}
                  />
                </div>
              );
            })}
        </div>
      </div>
      <div className="recruiters-per-page-container">
        <div className="recruiters-per-page">
          <SelectValidator
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            register={() => {}}
            label=""
            list={numberPerPage}
            value={numberPerPage[0]}
            callback={handlePerPage}
            disabled={currentPage !== 1}
          />
        </div>
        <p className="recruiters-per-page-text">Per Page</p>
      </div>
      <Pagination
        className="recruiters-pagination-container"
        currentPage={currentPage}
        totalCount={sortedRecruiters.length}
        pageSize={perPage}
        onPageChange={onPageChange}
      />
    </div>
  );
};

export default Recruiters;
