import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import Cookies from "universal-cookie";
import { setAuthenticationStatus } from "../../redux/actions/login";
import { resetLocalStorage } from "../../utils/storage-manager";
import { HOME } from "../../utils/constants/routes";

const Logout = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const cookies = new Cookies();
    const allCookies = cookies.getAll();
    if (Object.keys(allCookies).length > 0) {
      Object.keys(allCookies).forEach((key) => {
        if (key.includes("wordpress_logged_in")) {
          cookies.remove(key, { path: "/", domain: ".hiringhood.com" });
        }
      });
    }
    dispatch(setAuthenticationStatus(false));
    resetLocalStorage("token");
    resetLocalStorage("user");
    resetLocalStorage("userId");
    resetLocalStorage("userRole");
    resetLocalStorage("userStage");
    resetLocalStorage("inviteSource");
    resetLocalStorage("profilePic");
    history.push(HOME);
  }, []);

  return <></>;
};

export default Logout;
