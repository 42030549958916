import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import Error from "../../components/Error";
import { setActiveLink } from "../../redux/actions/menu";
import { getEmployer } from "../../redux/actions/employer";
import {
  employerLoadingSelector,
  errorSelector,
  employerSelector,
} from "../../redux/selectors";
import EmployerTabs from "../../components/EmployerTabs";
import "./styles.css";

const EmployerPage = () => {
  const dispatch = useDispatch();
  const loading = useSelector(employerLoadingSelector);
  const error = useSelector(errorSelector);
  const { id } = useParams<{ id: string }>();

  const employerDetails = useSelector(employerSelector);

  useEffect(() => {
    dispatch(setActiveLink("Employers"));
    dispatch(getEmployer(id));
    window.scrollTo(0, 0);
  }, [dispatch, id]);

  return (
    <div>
      {loading && <CircularProgress className="loading" />}
      {!loading && !error && (
        <div className="employer-wrapper">
          <EmployerTabs employerDetails={employerDetails} />
        </div>
      )}
      {!loading && error && <Error />}
    </div>
  );
};

export default EmployerPage;
