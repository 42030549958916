import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import Error from "../../components/Error";
import PartnersGrid from "../../components/PartnersGrid";
import { setActiveLink } from "../../redux/actions/menu";
import { getPartners } from "../../redux/actions/partners";
import {
  errorSelector,
  partnersLoadingSelector,
  partnersSelector,
} from "../../redux/selectors";
import "./styles.css";

const PartnersPage = () => {
  const dispatch = useDispatch();

  const loading = useSelector(partnersLoadingSelector);
  const error = useSelector(errorSelector);
  const partnersData = useSelector(partnersSelector);
  const [canRenderComponent, setCanRenderComponent] = useState(false);

  const [rerender, setRerender] = useState(false);

  function handleReset() {
    setRerender(true);
  }

  useEffect(() => {
    dispatch(setActiveLink("Partners"));
    dispatch(getPartners());
    setCanRenderComponent(true);
    if (rerender) {
      setRerender(false);
    }
  }, [rerender]);

  return (
    <div>
      {loading && <CircularProgress className="loading" />}
      {!loading && !error && canRenderComponent && (
        <>
          <div className="partners-container">
            <PartnersGrid
              partners={partnersData.partners}
              partnersData={partnersData}
              onReset={handleReset}
            />
          </div>
        </>
      )}
      {!loading && error && <Error />}
    </div>
  );
};

export default PartnersPage;
