import { UPLOAD_FILE, setUploadedFileInfo } from "../actions/uploadFile";
import { setError } from "../actions/common";

const uploadFileFlow = ({ api, log }) => ({ dispatch }) => (next) => async (
  action
) => {
  next(action);

  if (action.type === UPLOAD_FILE) {
    try {
      dispatch(setUploadedFileInfo({ loading: true }));
      dispatch(setError(null));
      const uploadedFileInfo = await api.uploadFile.uploadFile(action.payload);
      log(`Successfully uploaded the file`);

      uploadedFileInfo.data.key = action.key;
      dispatch(
        setUploadedFileInfo({
          loading: false,
          fileInfo: uploadedFileInfo.data,
        })
      );
    } catch (error) {
      const payload = {
        error,
        message: `Error while uploading the file`,
      };
      dispatch(setError(payload));
      log(payload);
      dispatch(setUploadedFileInfo({ loading: false }));
    }
  }
};

export default [uploadFileFlow];
