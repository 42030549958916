import { useEffect, useState } from "react";
import "./styles.scss";
import Heading from "../Heading";
import Button from "../Button";
import Copyright from "../Copyright";
import Checkbox from "../Checkbox";
import OnboardOptions from "../OnboardOptions";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";
import TextValidator from "../TextValidator";
import SelectValidator from "../SelectValidator";
import * as Constants from "../../utils/constants/index";
import PhoneNumberValidator from "../PhoneNumberValidator";
import { useDispatch, useSelector } from "react-redux";
import {
  errorSelector,
  onboardSelector,
  onboardSelectorLoading,
  signUpUserInfoSelector,
} from "../../redux/selectors";
import { onboardRecruiter } from "../../redux/actions/onboard";

interface FormProps {
  formData: FormData;
  onboardAs?: string;
  callbackFunction: any;
}

interface FormData {
  firstname: string;
  lastname: string;
  email: string;
  phoneExt: string;
  phone: string;
  altPhone: string;
  surveyAnswer: string;
  linkedIn: string;
  surveyAnswerOther: string;
}

const OnboardingForm = (props: FormProps) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [onboardAs, setOnboardAs] = useState(
    location.state !== undefined ? location.state.toString() : ""
  );
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [values, setValues] = useState(props.formData);
  const loading = useSelector(onboardSelectorLoading);
  const error = useSelector(errorSelector);
  const onboardedRecrutier = useSelector(onboardSelector);

  const userInfo = useSelector(signUpUserInfoSelector);
  const isOnboardingViaEmailInvite =
    userInfo && userInfo.company ? true : false;

  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required("First name is required"),
    lastname: Yup.string().trim().required("Last name is required"),
    email: Yup.lazy((value) => {
      if (onboardAs == Constants.RECRUITMENT_COMPANY) {
        return Yup.string()
          .trim()
          .email("Email address is invalid")
          .required("Email address is required");
      }
      return Yup.mixed().notRequired();
    }),
    phoneExt: Yup.string()
      .trim()
      .notRequired()
      .default("+91")
      .matches(Constants.PHONE_EXT_REGEX, "Phone extension is not valid"),
    phone: Yup.string()
      .trim()
      .required("Phone number is required")
      .matches(Constants.PHONE_REGEX, "Phone number is not valid"),
    altPhone: Yup.string()
      .trim()
      .nullable()
      .notRequired()
      .matches(Constants.LANDLINE_REGEX, {
        message: "Phone number is not valid",
        excludeEmptyString: true,
      })
      .test({
        name: "altPhone",
        message:
          "Alternate phone number cannot be same as primary phone number",
        test: function (value) {
          if (value.length === 0) {
            return true;
          }
          if (
            value === this.parent.phone ||
            value === this.parent.phoneExt + this.parent.phone
          )
            return false;
          return true;
        },
      }),
    linkedIn: Yup.lazy((value) => {
      if (onboardAs == Constants.FREELANCER) {
        return Yup.string()
          .url("LinkedIn URL is invalid")
          .required("linkedIn address is required");
      }
      return Yup.mixed().notRequired();
    }),
    surveyAnswer: Yup.string()
      .default(values.surveyAnswer)
      .required("Please select an option"),
    surveyAnswerOther: Yup.string()
      .trim()
      .notRequired()
      .default("")
      .test({
        name: "OtherSurveyAnswer",
        message: "This answer cannot be empty",
        test: function (value) {
          value = value.trim();
          if (
            this.parent.surveyAnswer.toLowerCase() === "other" &&
            value === ""
          )
            return false;
          return true;
        },
      }),
  });
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    shouldFocusError: true,
    criteriaMode: "all",
  });

  useEffect(() => {
    Object.keys(values).map((key) => {
      setValue(key, values[key]);
    });
  }, []);

  const handleChange = (formItem: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValues({ ...values, [formItem]: event.target.value });
    setValue(formItem, event.target.value, { shouldValidate: true });

    if (formItem === "phone" || formItem === "phoneExt") {
      trigger("altPhone");
    }
    return;
  };

  const onboardSelect = (selectedValue: string) => {
    setOnboardAs(selectedValue);
    setValues({ ...values, ["email"]: "", ["linkedIn"]: "" });
    setValue("email", "", { shouldValidate: true });
    setValue("linkedIn", "", { shouldValidate: true });
  };

  const onDeclaration = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTermsAgreed(e.target.checked);
  };

  const onClickProceed = (data) => {
    data.onboardAs = onboardAs;
    data.company = userInfo && userInfo.company;
    dispatch(onboardRecruiter(data));
  };

  useEffect(() => {
    if (onboardedRecrutier) {
      props.callbackFunction("CreateAccount");
      window.scrollTo(0, 0);
    }
  }, [onboardedRecrutier]);

  return (
    <div className="onboarding-form-container">
      <div className="onboarding-heading-div">
        <Heading text="Fill up the following details" />
      </div>
      <form onSubmit={handleSubmit(onClickProceed)}>
        <div className="onboarding-fields-container">
          <div className="onboarding-div-field">
            <OnboardOptions
              onClick={onboardSelect}
              radioState={onboardAs}
              preSelected={isOnboardingViaEmailInvite ? onboardAs : undefined}
            />
          </div>

          <div className="onboarding-div-field onboarding-div-multi-field">
            <TextValidator
              id="firstname"
              className="name-input"
              label="Enter First Name*"
              value={values.firstname}
              register={register}
              onChange={handleChange("firstname")}
              errorMessage={errors.firstname?.message}
            />
            <TextValidator
              id="lastname"
              className="name-input"
              label="Enter Last Name*"
              value={values.lastname}
              register={register}
              onChange={handleChange("lastname")}
              errorMessage={errors.lastname?.message}
            />
          </div>
          {onboardAs == Constants.RECRUITMENT_COMPANY ? (
            <div className="onboarding-div-field">
              <TextValidator
                id="email"
                className="email-input"
                label="Enter Your Official Email Address*"
                value={values.email}
                register={register}
                onChange={handleChange("email")}
                errorMessage={errors.email?.message}
              />
            </div>
          ) : (
            <div className="onboarding-div-field">
              <TextValidator
                id="linkedIn"
                className="email-input"
                label="Enter LinkedIn profile URL*"
                value={values.linkedIn}
                register={register}
                onChange={handleChange("linkedIn")}
                errorMessage={errors.linkedIn?.message}
              />
            </div>
          )}
          <div className="onboarding-div-field ">
            <PhoneNumberValidator
              id="phone"
              label="Enter Phone Number*"
              register={register}
              phone={values.phone}
              phoneExt={values.phoneExt}
              onPhoneChange={handleChange("phone")}
              onExtChange={handleChange("phoneExt")}
              errorMessage={
                errors.phoneExt
                  ? errors.phoneExt.message
                  : errors.phone?.message
              }
            />
          </div>
          <div className="onboarding-div-field">
            <TextValidator
              id="altPhone"
              className="email-input"
              label="Enter Alternate Phone Number"
              value={values.altPhone}
              register={register}
              onChange={handleChange("altPhone")}
              errorMessage={errors.altPhone?.message}
            />
          </div>
          <div className="onboarding-div-field-survey info-dropdown">
            <SelectValidator
              name="surveyAnswer"
              label="How did you come to know about us?*"
              value={values.surveyAnswer}
              list={Constants.SURVEY_LIST}
              callback={handleChange("surveyAnswer")}
              register={register}
              errorMessage={errors.surveyAnswer?.message}
            />
            {values.surveyAnswer.toLowerCase() === "other" && (
              <TextValidator
                id="surveyAnswerOther"
                className="email-input"
                label=""
                placeholder="Enter other answer"
                value={values.surveyAnswerOther}
                register={register}
                onChange={handleChange("surveyAnswerOther")}
                errorMessage={errors.surveyAnswerOther?.message}
              />
            )}
          </div>

          <div className="disclaimer-and-submit-container">
            <div className="disclamer-info-container">
              <Checkbox
                aria-labelledby="disclaimer-text"
                onChange={onDeclaration}
              />
              <p id="disclaimer-text">
                I have read and agree to the{" "}
                <a
                  href={
                    onboardAs === Constants.RECRUITMENT_COMPANY
                      ? `${process.env.REACT_APP_PUBLIC_BUCKET_URL}legal-docs/Terms+of+Use_Recruitment+Agency.pdf`
                      : `${process.env.REACT_APP_PUBLIC_BUCKET_URL}legal-docs/Terms+of+Use_Freelance+recruitment+vendor.pdf`
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of Use
                </a>{" "}
                and{" "}
                <a
                  href={`${process.env.REACT_APP_PUBLIC_BUCKET_URL}legal-docs/Privacy+Policy_HiringHood.pdf`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </p>
            </div>
            <Button
              variant="primary"
              label="Submit Details"
              disabled={!isValid || !termsAgreed || loading}
            />
          </div>
          <p className={"invalid-feedback"}>{error?.message}</p>
        </div>
        <Copyright />
      </form>
    </div>
  );
};

export default OnboardingForm;
