import axios from "axios";
import {TOKEN_URL} from "./api-urls";

interface keycloakToken {
    config: any,
    data: any,
    headers: any,
    request: any,
    status: number,
    statusText: string
}

export const fetchToken = async () => {
    const params = new URLSearchParams();
    params.append("client_id", process.env.REACT_APP_CLIENT_ID || "");
    params.append("username", process.env.REACT_APP_CLIENT_USERNAME || "");
    params.append("password", process.env.REACT_APP_CLIENT_PASSWORD || "");
    params.append("grant_type", "password");

    const currentTime = new Date().getTime();

    const tokenData = await axios.post(TOKEN_URL, params);

    const token = {
        data: tokenData.data,
    } as keycloakToken

    return {
        token: token.data.access_token,
        expiresIn: currentTime + token.data.expires_in * 1000,
    }
};

const Keycloak = {
    fetchToken,
};

export default Keycloak;
