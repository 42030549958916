import moment from "moment";

const getFormattedDate = (dateToConvert: any) => {
  const formattedDate = moment(dateToConvert, "YYYY-MM-DD").format(
    "DD/MM/YYYY"
  );

  return formattedDate;
};

const parse = (response: any) => {
  const hotlist = [];
  const allSecondarySkills = [];
  const allPrimarySkills = [];
  const allLocations = [];
  response.data.map((item: any) => {
    const jsDetails: any = {};
    jsDetails.id = item["js_unique_id"];
    jsDetails.role = item["current_job_role"];
    jsDetails.primarySkill = item["primary_skills"].join(", ");
    const experienceYears = item["experience_years"];
    const experienceMonths = item["experience_months"];
    const expectedCTCLakhs = item["expected_ctc_lakhs"];
    const expectedCTCMonths = item["expected_ctc_thousand"];
    const expectedCTC =
      expectedCTCLakhs > 0
        ? expectedCTCLakhs +
          (expectedCTCMonths > 0 ? expectedCTCMonths / 100 : 0)
        : expectedCTCMonths > 0
        ? expectedCTCMonths / 100
        : 0;
    const cureentCTCLakhs = item["current_ctc_lakhs"];
    const curentCTCMonths = item["current_ctc_thousand"];
    const currentCTC =
      cureentCTCLakhs > 0
        ? cureentCTCLakhs + (curentCTCMonths > 0 ? curentCTCMonths / 100 : 0)
        : curentCTCMonths > 0
        ? curentCTCMonths / 100
        : 0;
    jsDetails.experience =
      experienceYears > 1
        ? `${experienceYears} years`
        : experienceYears === 1
        ? `1 year`
        : "";
    jsDetails.experience = `${jsDetails.experience} ${
      experienceMonths > 1
        ? `${experienceMonths} months`
        : experienceMonths === 1
        ? `1 month`
        : ""
    }`;
    jsDetails.expectedCTC = `${expectedCTC} LPA`;
    jsDetails.currentLocation = item["current_location"];
    jsDetails.preferredLocations = item["preferred_locations"].join(", ");
    jsDetails.currentCTC = `${currentCTC} LPA`;
    jsDetails.secondarySkills = item["secondary_skills"].join(", ");
    jsDetails.employmentStatus =
      item["current_status_type"] === "working"
        ? "Serving Notice Period"
        : "Immediate Joiner";
    jsDetails.joiningDate = getFormattedDate(item["earliest_joining_date"]);
    jsDetails.uploadedOn = getFormattedDate(item["uploaded_on"]);

    jsDetails.filterExperience = [
      item["experience_years"] + item["experience_months"] / 12,
      item["experience_years"] + item["experience_months"] / 12,
    ];
    jsDetails.filterExpectedCTC = [expectedCTC, expectedCTC];
    jsDetails.filterCurrentCTC = [currentCTC, currentCTC];
    jsDetails.filterPrimarySkills = item["primary_skills"];
    jsDetails.filterSecondarySkills = item["secondary_skills"];
    jsDetails.filterWorkingStatus = [jsDetails.employmentStatus];
    jsDetails.filterCurrentLocation = [jsDetails.currentLocation];
    jsDetails.filterPreferredLocations = item["preferred_locations"];

    allPrimarySkills.push(...jsDetails.filterPrimarySkills);
    allSecondarySkills.push(...jsDetails.filterSecondarySkills);
    allLocations.push(jsDetails.currentLocation);
    allLocations.push(...jsDetails.filterPreferredLocations);

    const diff = Math.round(
      moment(item["earliest_joining_date"], "YYYY-MM-DD").diff(
        moment(),
        "days",
        true
      )
    );
    jsDetails.filterJoiningPeriod = [diff > 0 ? diff : 0, diff > 0 ? diff : 0];

    hotlist.push(jsDetails);
  });

  return {
    count: response.recordsTotal,
    hotlist,
    allPrimarySkills: [...new Set(allPrimarySkills)],
    allSecondarySkills: [...new Set(allSecondarySkills)],
    allEmploymentStatus: ["Serving Notice Period", "Immediate Joiner"],
    allLocations: [...new Set(allLocations)],
  };
};

export default parse;
