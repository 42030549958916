import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Button from "../Button";
import TextValidator from "../TextValidator";
import subscribe from "../../assets/subscribe.svg";
import styles from "./style.module.scss";

const Subscribe = () => {
  const [emailId, setEmailId] = useState("");

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .email("Email address is invalid")
      .required("Email address is required"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    shouldFocusError: true,
    criteriaMode: "all",
  });

  const handleChange = (formItem: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEmailId(event.target.value);
    setValue(formItem, event.target.value, { shouldValidate: true });

    return;
  };

  return (
    <div className={styles["subscribe-container"]}>
      <p className={styles["subscribe-title"]}>
        Subscribe To Our Newsletter And Stay Updated!
      </p>
      <img
        src={subscribe}
        alt="Subscribe"
        className={styles["subscribe-image"]}
      />
      <form>
        <TextValidator
          id="email"
          label="Your Email*"
          value={emailId}
          register={register}
          onChange={handleChange("email")}
          errorMessage={errors.email?.message}
        />
      </form>
      <Button
        label="Subscribe"
        className={styles["subscribe-button"]}
        disabled={!isValid}
      />
    </div>
  );
};

export default Subscribe;
