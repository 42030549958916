import React from "react";
import "./styles.scss";

/*
TextField type values:
text: allows all keys - default
phone: allows only numbers. 
number: allows only number. Shows number ticker for the text input.
TODO: email, website, phone extension. 
TODO: validations for types of text inputs.
TODO: show error/success indicator
TODO: shift placeholder to top of input when in focus. 
*/
interface Props {
  type?: string;
  id?: string;
  placeholder?: string;
  value?: string;
  required?: boolean;
  className?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
}

const TextField = (props: Props): JSX.Element => {
  const {
    type,
    id,
    placeholder,
    required,
    value,
    className = "",
    onChange,
    disabled = false,
  } = props;

  return (
    <input
      type={type}
      placeholder={placeholder}
      required={required}
      value={value}
      className={"text-input " + className}
      id={id}
      onChange={onChange}
      disabled={disabled}
      onKeyPress={(event) => {
        if (type === "phone") {
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
          }
        }
      }}
    />
  );
};
export default TextField;
