import { SET_ERROR } from "../actions/common";

const commonReducer = (state = { error: null }, action) => {
  switch (action.type) {
    case SET_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

export default commonReducer;
