import moment from "moment";

const parse = (response: any) => {
  const allLocations = [];
  const allEmploymentTypes = [];
  const allJobRoles = [];
  const allEmployers = [];
  const contests = response.data.map((item: any) => {
    const contest = item["attributes"];
    const postedDate = new Date(contest["date"]).getTime();

    const endDate = moment(
      contest["rounds"]["countdown"]["timer"],
      "DD/MM/YYYY hh:mm a"
    )
      .toDate()
      .getTime();

    const currentDate = new Date().getTime();

    const postedDuration = Math.ceil(
      (currentDate - postedDate) / (1000 * 24 * 60 * 60)
    );

    const endDuration = Math.floor(
      (endDate - currentDate) / (1000 * 24 * 60 * 60)
    );

    const parsedContestDetails: any = {};
    parsedContestDetails.id = item["id"];
    parsedContestDetails.tag = contest["sticker_customization"];
    parsedContestDetails.badge = contest["select_badge"];
    parsedContestDetails.contestCreatedDuration = `${
      postedDuration === 0 ? "Today" : `${postedDuration}d ago`
    }`;
    parsedContestDetails.postedDuration = postedDuration;
    parsedContestDetails.employmentType =
      contest["card_elements"]["card_taglines"];
    parsedContestDetails.jobTitle = contest["contest_title"];
    parsedContestDetails.company = contest["about_employer"][1];

    parsedContestDetails.cashReward = `₹${contest["bounty"].toLocaleString(
      "en-IN"
    )}`;
    parsedContestDetails.reward = contest["bounty"];
    parsedContestDetails.experience = contest["experience"]
      ? `${contest["experience"]["from"]} to ${contest["experience"]["to"]} yrs`
      : null;
    parsedContestDetails.totalQuota =
      contest["total_application_limit_per_contest"];
    parsedContestDetails.daysLeft = endDuration < 0 ? 0 : endDuration;

    parsedContestDetails.positions =
      contest["number_of_position"] === 1
        ? `${contest["number_of_position"]} Position`
        : `${contest["number_of_position"]} Positions`;
    parsedContestDetails.numberOfPositions = contest["number_of_position"];
    const budgetUnit =
      contest["budget_ctc"]["units"] === "thousands" ? "K PA" : "L PA";
    parsedContestDetails.ctc = `${contest["budget_ctc"]["from"]}${budgetUnit} to ${contest["budget_ctc"]["to"]}${budgetUnit}`;
    const multiplicationFactor =
      contest["budget_ctc"]["units"] === "thousands" ? 1000 : 100000;
    parsedContestDetails.minBudget =
      Number(contest["budget_ctc"]["from"]) * multiplicationFactor;
    parsedContestDetails.maxBudget =
      Number(contest["budget_ctc"]["to"]) * multiplicationFactor;

    parsedContestDetails.locations = contest["contest_locations"].join(", ");
    parsedContestDetails.filterLocations = contest["contest_locations"];
    parsedContestDetails.filterExperience = contest["experience"]
      ? [
          Number(contest["experience"]["from"]),
          Number(contest["experience"]["to"]),
        ]
      : [0, 0];
    parsedContestDetails.filterEmploymentType = [
      contest["card_elements"]["card_taglines"],
    ];
    parsedContestDetails.filterJobRole = [contest["contest_title"]];
    parsedContestDetails.filterEmployer = [contest["about_employer"][1]];

    allLocations.push(...parsedContestDetails.filterLocations);
    allEmploymentTypes.push(...parsedContestDetails.filterEmploymentType);
    allJobRoles.push(...parsedContestDetails.filterJobRole);
    allEmployers.push(...parsedContestDetails.filterEmployer);

    return parsedContestDetails;
  });

  const parsedResponse = {
    contests,
    allLocations: [...new Set(allLocations)],
    allEmploymentTypes: [...new Set(allEmploymentTypes)],
    allJobRoles: [...new Set(allJobRoles)],
    allEmployers: [...new Set(allEmployers)],
  };

  return parsedResponse;
};

export default parse;
