import "./styles.scss";

interface TableCellData {
  text: string;
  icon: string;
}
const TableCell = (props) => {
  const { cellValue } = props;
  return (
    <>
      {typeof cellValue === "string" ? (
        <> {cellValue}</>
      ) : (
        <div className="table-cell-div">
          <img
            className="table-cell-icon"
            src={(cellValue as TableCellData).icon}
            alt={(cellValue as TableCellData).text}
          />
          {(cellValue as TableCellData).text}
        </div>
      )}
    </>
  );
};

export default TableCell;
