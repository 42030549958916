import styles from "./style.module.scss";

interface Props {
  // title to show
  title: string;
}

const Tag: React.FC<Props> = (props) => {
  const { title } = props;
  return <p className={styles["tag"]}>{title}</p>;
};

export default Tag;
