export const GET_BLOGS = "GET_BLOGS";
export const SET_BLOGS = "SET_BLOGS";

export const getBlogs = (type) => {
  return {
    type: GET_BLOGS,
    payload: { type },
  };
};

export const setBlogs = (data) => {
  return {
    type: SET_BLOGS,
    payload: data,
  };
};
