import "./styles.scss";

interface Step {
  key: number;
  index: number;
  name: string;
}

interface InputProps {
  currentStep: number;
  totalSteps: number;
  callBackFunction: (toStep: number) => void;
  stepNames: string[];
  userStage?: number;
}

interface StepProps {
  index: number;
  stepName?: string;
}

const GetStyleType = (index: number, currentStep: number) => {
  if (index === currentStep) return "-active";
  else if (index < currentStep) return "-done";
  else if (index > currentStep) return "-not-done";
  return "";
};

const Breadcrumbs = (inputProps: InputProps): JSX.Element => {
  const {
    currentStep,
    totalSteps,
    callBackFunction,
    stepNames,
    userStage,
  } = inputProps;
  const steps: Step[] = [];

  for (let index = 1; index <= totalSteps; index++)
    steps.push({
      key: index,
      name: stepNames[index - 1],
      index,
    });

  const StepTrack = (props: StepProps) => {
    const { index } = props;

    if (index < totalSteps) {
      const styleType =
        index < currentStep ? "-done" : index < userStage ? "-active" : "";

      return <div className={`step-track${styleType}`}></div>;
    }
    return <></>;
  };

  const StepNumber = (props: StepProps) => {
    const { index } = props;
    if (index <= totalSteps) {
      const styleType = GetStyleType(index, currentStep);
      return (
        <div className={`step${styleType}`}>
          <label className={`step-number${styleType}`}>{index}</label>
        </div>
      );
    }
    return <></>;
  };

  const StepLabel = (props: StepProps) => {
    const { index, stepName } = props;

    if (index <= totalSteps) {
      const styleType = GetStyleType(index, currentStep);
      return (
        <div className="step-name-div">
          <label className={`step-name${styleType}`}>{stepName}</label>
        </div>
      );
    }
    return <></>;
  };

  const StepCount = (props: StepProps) => {
    const { index, stepName } = props;

    if (index <= totalSteps) {
      return (
        <div className="step-tracker-div">
          <div
            className="step-button"
            onClick={(event) => {
              index > userStage
                ? event.preventDefault()
                : callBackFunction(index);
            }}
          >
            <div className="step-block-div">
              <StepTrack index={index} />
              <StepNumber index={index} />
            </div>
            <StepLabel stepName={stepName} index={index} />
          </div>
        </div>
      );
    }
    return <></>;
  };

  return (
    <div className="breadcrumbs" style={{ width: `${totalSteps * 8}rem` }}>
      {steps.map((step) => (
        <StepCount key={step.key} index={step.index} stepName={step.name} />
      ))}
    </div>
  );
};

export default Breadcrumbs;
