import Integration from "../../assets/integration.svg";
import Recrutier from "../../assets/recruiter.svg";
import Partners from "../../assets/partners.svg";
import Candidates from "../../assets/candidates.svg";
import Revenue from "../../assets/revenue.svg";
import ProfileOwnership from "../../assets/profile-ownership.svg";
import PartnersLarge from "../../assets/partners-200px.svg";
import CandidatesLarge from "../../assets/candidates-200px.svg";
import HiringLarge from "../../assets/hiring-200px.svg";
import Contests from "../../assets/contests.svg";
import Resumes from "../../assets/resumes.svg";
import Recrutiers from "../../assets/recruiters.svg";
import Rewards from "../../assets/rewards-80px.svg";
import GreenCheckImage from "../../assets/mark-green.svg";
import RedErrorImage from "../../assets/mark-red.svg";
import OneShop from "../../assets/one-shop.svg";
import QuickCash from "../../assets/quick-cash.svg";
import Hiring from "../../assets/hiring.svg";
import VattedRecruiters from "../../assets/vatted-recruiters.svg";
import Bandwidth from "../../assets/bandwidth.svg";
import Position from "../../assets/position.svg";
import Employer from "../../assets/employer.svg";
import CandidatesWhite from "../../assets/candidates-white.svg";
import PartnersWhite from "../../assets/partners-white.svg";
import ProfileOwnershipWhite from "../../assets/profile-ownership-white.svg";
import RupeeBag from "../../assets/rupee-bag.svg";
import ResumeLarge from "../../assets/resume-large.svg";
import Growth from "../../assets/growth.svg";
import Recognition from "../../assets/recognition.svg";
import Prize from "../../assets/prize-badge.svg";
import contest from "../../assets/home-page/contest.svg";
import contestSelected from "../../assets/home-page/contest-selected.svg";
import recruiter from "../../assets/home-page/recruiter.svg";
import recruiterSelected from "../../assets/home-page/recruiter-selected.svg";
import employer from "../../assets/home-page/employer.svg";
import employerSelected from "../../assets/home-page/employer-selected.svg";
import jobSeeker from "../../assets/home-page/job-seeker.svg";
import jobSeekerSelected from "../../assets/home-page/job-seeker-selected.svg";
import partner from "../../assets/home-page/partner.svg";
import partnerSelected from "../../assets/home-page/partner-selected.svg";
import referrals from "../../assets/home-page/referrals.svg";
import referralsSelected from "../../assets/home-page/referrals-selected.svg";
import { ABOUT_US, CONTESTS, FAQ, SIGNUP } from "./routes";

export const whatsInItForRecrutiersInfoArray = [
  {
    icon: Revenue,
    title: "Maximum Revenue",
    description:
      "Earn upto 25% higher fee for each candidate you successfully onboard",
  },
  {
    icon: Candidates,
    title: "Multiple Empanelment",
    description:
      "Just sign up with Hiringhood and you are automatically empaneled to multiple Employers",
  },
  {
    icon: Partners,
    title: "Higher Hit rate",
    description:
      "Increase the probability of placing the job seekers through exclusive eco-system.",
  },
  {
    icon: ProfileOwnership,
    title: "Secure Ownership",
    description:
      "Retain ownership up to 12 months across all employers through our platform",
  },
  {
    icon: Recrutier,
    title: "Fast Paced Interview Process",
    description:
      "Accelerated recruitment through weekend drives leveraging dedicated interview panels.",
  },
  {
    icon: Integration,
    title: "Best in Class Curation",
    description: "Leverage our 3rd party assessment platforms for curation.",
  },
];

export const siteMetricsArray = [
  {
    icon: Contests,
    metric: "250+",
    description: "Contests",
    bgColor: "#4898F0",
  },
  {
    icon: Resumes,
    metric: "30K",
    description: "Resumes",
    bgColor: "#00C15D",
  },
  {
    icon: Recrutiers,
    metric: "100+",
    description: "Recrutiers",
    bgColor: "#F5BC2F",
  },
  {
    icon: Rewards,
    metric: "1.25 Cr",
    description: "Rewards",
    bgColor: "#EF706F",
  },
];

export const hiringhoodVsOthersHeaders = ["", "Hiringhood", "Others"];

export const hiringhoodVsOtherData = [
  {
    feature: "Earning Potential",
    hh: { text: "Upto 10.33%", icon: GreenCheckImage },
    others: { text: "Upto 8.33%", icon: RedErrorImage },
  },

  {
    feature: "Sourcing Model",
    hh: { text: "Bounty Hunt", icon: GreenCheckImage },
    others: { text: "Generic", icon: RedErrorImage },
  },

  {
    feature: "Recruiter Dashboard",
    hh: { text: "Free", icon: GreenCheckImage },
    others: { text: "Paid", icon: RedErrorImage },
  },
  {
    feature: "Recruiter Profiles",
    hh: { text: "Free", icon: GreenCheckImage },
    others: { text: "Not Available", icon: RedErrorImage },
  },

  {
    feature: "Profile Ownership",
    hh: { text: "Upto 12 months across the platform", icon: GreenCheckImage },
    others: { text: "6 months/client", icon: RedErrorImage },
  },

  {
    feature: "Payout",
    hh: { text: "Best in Market. As early as 45 days", icon: GreenCheckImage },
    others: { text: "3 Months", icon: RedErrorImage },
  },

  {
    feature: "Invite only Recruitment",
    hh: { text: "Yes", icon: GreenCheckImage },
    others: { text: "No", icon: RedErrorImage },
  },

  {
    feature: "Closure SLA - Turn Around Time",
    hh: { text: "4 weeks or less ", icon: GreenCheckImage },
    others: { text: "2 months or more", icon: RedErrorImage },
  },
];

export const drivingImpactInfo = [
  {
    icon: CandidatesLarge,
    title: "Candidate Experience",
    stat: "+166%",
    description: "More Applications",
  },
  {
    icon: HiringLarge,
    title: "Employer Time to Hire",
    stat: "+63%",
    description: "Faster Response Time",
  },
  {
    icon: PartnersLarge,
    title: "Recruiter Productivity",
    stat: "+166%",
    description: "More Time to Spend on Relationship Building",
  },
];

export const whyHiringhoodRecruitersArray = [
  {
    icon: Revenue,
    title: "Maximum Revenue",
    description: "Up to 10.33% on CTC, 25% higher than the industry standard",
  },
  {
    icon: OneShop,
    title: "One Stop Shop",
    description:
      "Just sign up with Hiringhood and you are automatically empaneled to multiple Employers",
  },
  {
    icon: QuickCash,
    title: "Quick to Cash",
    description: "Best in Market. As early as 45 days",
  },

  {
    icon: ProfileOwnership,
    title: "Profile Ownership",
    description:
      "Twelve month ownership of profiles sourced irrespective of the clients across our platform",
  },
];

export const whyHiringhoodEmployersArray = [
  {
    icon: Hiring,
    title: "Accelerated Hiring",
    description:
      "Hoards of bounty recruiters compete for closure at war footing.",
  },
  {
    icon: Integration,
    title: "Integration with 3rd parties",
    description:
      "Leverage integrations with ATS, Curation, Assessment, Evaluation, BGV & other services ",
  },
  {
    icon: Bandwidth,
    title: "Engineering Bandwidth",
    description: "Save cost, time & effort of your engineering teams ",
  },
  {
    icon: VattedRecruiters,
    title: "Vetted Recruiters",
    description:
      "Engage qualified recruiters with relevant domain expertise related to your JD",
  },
];

export const whyHiringHoodRecruitersText = {
  title: "Recruiters",
  description:
    "Hiringhood brings a unique bounty hunting recruitment model to the table which the recruiters can leverage to maximize revenue on one hand and multiple their empanelment on the other. Recruiters now get exclusive ownership of resumes they upload eliminating the fear of losing job seekers due to duplication.",
};

export const whyHiringhoodEmployersText = {
  title: "Employers",
  description:
    "Gone are the days when companies had to wait for 6 months to find an employee. Hiringhood has found a unique method to accelerate hiring for employers by aggregating the best recruiters in the job market for a specific domain/ technology, vetting them to chase talent immediately available at a premium fulfillment fee.",
};

export const clients = [
  {
    name: "techsophy",
    logo: "techsophy_logo.png",
    website: "https://www.techsophy.com/",
  },
  {
    name: "Construction Specialties",
    logo: "constructionSpecialities_logo.png",
    website: "https://www.c-sgroup.com/",
  },
  {
    name: "ATMECS Global",
    logo: "atmecs_global.png",
    website: "https://www.atmecs.com/",
  },
  {
    name: "Dalhyd",
    logo: "Dalhyd_Logo.jpg",
    website: "https://www.dalhyd.com/",
  },
  {
    name: "Okoders",
    logo: "Okoders.png",
    website: "http://okoders.com/",
  },
  {
    name: "nslhub",
    logo: "nslhub.svg",
    website: "https://nslhub.com/",
  },
  {
    name: "Pactera EDGE",
    logo: "pacteraedge.png",
    website: "https://www.pacteraedge.com/",
    background: "rgb(28, 68, 127)",
  },
  {
    name: "Locuz",
    logo: "locuz.png",
    website: "https://www.locuz.com/",
  },
  {
    name: "KoFax",
    logo: "kofax.svg",
    website: "https://www.kofax.com/",
  },
  {
    name: "Blueetra",
    logo: "blumetra.svg",
    website: "https://blumetra.com/",
  },
  {
    name: "Knot Solutions",
    logo: "knotsolution.png",
    website: "https://knotsolutions.com/",
  },
  {
    name: "ACL Digital",
    logo: "acl-digital.jpeg",
    website: "https://www.acldigital.com/",
  },
  {
    name: "Proarch",
    logo: "proarch.webp",
    website: "https://www.proarch.com/",
  },
  {
    name: "Harsco",
    logo: "harsco.png",
    website: "https://www.harsco.com/",
  },
  {
    name: "RaceAhead",
    logo: "aheadrace.png",
    website: "https://www.aheadrace.com/",
    background: "#f36c21",
  },
  {
    name: "Indian Staffing Federation",
    logo: "isf.png",
    website: "https://www.indianstaffingfederation.org/",
  },
];

export const valuesAtOurCoreText =
  " Offer best-in-class recruiter aggregation & market-place platform for accelerated hiring, using technologies, that help all key stakeholders in the echo system.";

export const ourCommitmentArray = [
  {
    icon: Position,
    metric: "02",
    description: "Years of work together",
    bgColor: "#EF706F",
  },
  {
    icon: PartnersWhite,
    metric: "09+",
    description: "Projects Completed",
    bgColor: "#EF9949",
  },
  {
    icon: CandidatesWhite,
    metric: "500+",
    description: "Recruiters on the platfrom",
    bgColor: "#ECB223",
  },
  {
    icon: ProfileOwnershipWhite,
    metric: "84%",
    description: "Of all Hiring Contests Closed Successfully",
    bgColor: "#1CCC71",
  },
  {
    icon: Employer,
    metric: "100+",
    description: "Number of job seekers placed",
    bgColor: "#14B1DE",
  },
  {
    icon: RupeeBag,
    metric: "₹5Cr+",
    description: "Bounty amount distributed",
    bgColor: "#6F8BEF",
  },
];

export const ourCommitmentTopics = [
  {
    icon: ResumeLarge,
    title: "Resume",
    description:
      "We believe that great resumes speak on behalf of the recruiters. We take pride in helping all recruiters to source great resumes and show case their incredible expertise.",
  },
  {
    icon: Growth,
    title: "Growth",
    description:
      "The Recruiter Journey does not end after sourcing the resume. The platform learns from previous contests and matches the best recruiters to a contest and informs them.",
  },
  {
    icon: Recognition,
    title: "Recognition",
    description:
      "We celebrate recruiters. We know the importance of being recognized for your work and skills in sourcing candidates.",
  },
];

export const whatCustomersAreSayingArray = [
  {
    quote:
      "Thanks for helping us source a few of our critical roles over the last six months. It enabled us to mitigate some of the program risks we were dealing. I am looking forward to continued assistance and want to grow this relationship further in other work areas, thus enabling us to reach new heights..",
    title: "Dilip Kumar Solasa",
    supportedBy: "TechSophy",
    date: "April 2022",
    profileImage: "Dilip-ProfilePic-TechSophy.jpg",
  },
  {
    quote:
      'I was just talking with someone tonight that said "Recruiters are a waste of time" and I said "Not HRS!" and I went on to explain what a positive experience I had with you when you placed me in my role.',
    title: "Project Manager - Biopharma Company",
    supportedBy: "HRS",
    date: "Dec 2018",
    profileImage: "avatar-male.png",
  },
  {
    quote:
      "I have been working with Eve, in HRS, for the past month. This was my first experience working with a recruiter and it has been amazing. I couldn´t have asked for more.",
    title: "PhD graduate",
    supportedBy: "HRS",
    date: "May 2021",
    profileImage: "avatar-male.png",
  },
];

export const whatMakesUsDifferentArray = [
  {
    icon: Prize,
    metric: "Bounty Hunting",
    description:
      "We plan to tackle the hard to chew problem of hiring the best candidates with a unique bounty hunting model paired with subject matter expertise of recruiters, streamlined into sub domains on the platform. Our funnel generates the best talent against  a Job Description available in the job marketplace.",
    bgColor: "#FFFFFF",
  },
];

export const homePageFAQ = [
  {
    image: contest,
    selectedImage: contestSelected,
    title: "Contest",
    faq: [
      {
        question: "What is a Contest?",
        answer:
          "Contest is nothing but a job requisition from the Employer which needs to be filled in an accelerated manner. It consists of vital information, such as Job description with Skill, Experience, Timeline, location(s), salary range, placement fee offered,educational qualification etc. A contest can have multiple positions.",
      },
      {
        question: "How can I participate in a Contest?",
        answer:
          "Contest is nothing but a job requisition from the Employer which needs to be filled in an accelerated manner. It consists of vital information, such as Job description with Skill, Experience, Timeline, location(s), salary range, placement fee offered,educational qualification etc. A contest can have multiple positions.",
      },
    ],
  },
  {
    image: recruiter,
    selectedImage: recruiterSelected,
    title: "Recruiter",
    faq: [
      {
        question: "How does Hiringhood work for recruiters?",
        answer:
          "If you are an independent recruiter or an employee in a recruitment Company, then working with Hiringhood is easy and rewarding. Here is how it works: Hiringhood has multiple job requisitions from hundreds of employers. Recruiters can fill these positions and earn a placement fee for each successful onboarding. They need to register, review jobs and then start submitting job seeker profiles. If the job seeker you have submitted into the platform is hired post duplication check, you will receive the placement fee.",
      },
      {
        question: "How does a recruiter benefit from Hiringhood?",
        answer:
          "Hiringhood provides access to thousands of jobs from top employers across India all its empanelled Vendors., who may or may not work with independent recruiters and agencies. Instead of wasting time, cold calling & trying to negotiate contracts with employers  you can spend your time recruiting more jobseekers by working with Hiringhood.",
      },
      {
        question:
          "When do I get paid for successful placements? How will I receive my payment?",
        answer:
          "1. You will be paid in 45 days following the date of your jobseeker starts working for the employer. In rare cases, it might take 90 days, but recruiters will be notified. <br/> <br/> 2. You can choose to receive a check via mail, or we can directly deposit your placement fee electronically in an account of your choice following the guarantee period.",
      },
      {
        question:
          "What if the client (employer) hires my jobseeker for another role? Will I be paid?",
        answer:
          "Hiringhood’s agreement with clients (employers) ensures that you will get paid if the client (employer) hires your jobseeker for another role within 90 days from the date of submission.",
      },
      {
        question:
          "What is the ownership period of my submitted jobseeker’s profiles?",
        answer:
          "Hiringhood will provide 90 calendar days of ownership to the recruiters from the date of successful profile submission, good news is that Hiringhood just don’t provide the ownership only for the employer submitted for but also across Hiringhood platform. So you have got plenty of chances for placement conversion.",
      },
      {
        question: "What is the highest placement fee?",
        answer:
          "The highest placement fee for jobs on Hiringhood is 16.66% on the job seeker's offered CTC. The fee is displayed to recruiters next to each job requisition (Contests).",
      },
      {
        question:
          "How long does it usually take for employers to respond to a jobseeker’s profile submission?",
        answer:
          "The average response time for the employer once a jobseeker’s profile has been submitted to the system is 2-3 business days. However, this completely varies Employer to Employer.",
      },
      {
        question: "How can I join as a Recruiter at Hiringhood?",
        answer:
          "Please register yourself in our website www.hiringhood.com under the Recruiter category, so that one of our executives will get in touch with you. Also, you can send your queries to recruiter@hiringhood.com.",
      },
    ],
  },
  {
    image: employer,
    selectedImage: employerSelected,
    title: "Employer",
    faq: [
      {
        question: "How does Hiringhood work for employers?",
        answer:
          "Your jobs go live on the platform: An account manager, who is assigned to you, will work with you to upload a job description as well as notes (called Job Insights) for each of your jobs to help the recruiting community understand who you are looking to hire. <br/> <br/>The search for your perfect job seekers begins: The moment your jobs go live on the Platform, the right set of independent recruiters and recruiting firms on the Hiringhood platform will begin submitting jobseeker’s profiles to Hiringhood.<br/> <br/> You see only the best jobseeker profiles: One dedicated account manager, along with Hiringhood’s technology, will filter all incoming submissions. Information about jobseekers who are approved by your account manager will be sent to you. You can review incoming jobseekers straight from the Hiringhood platform, in your inbox, or even through your Applicant Tracking System (ATS).",
      },
      {
        question: "Why use Hiringhood?",
        answer:
          "By using Hiringhood, you instantly extend your reach to find better job seekers in less time. Traditional talent acquisition methods cannot give you the reach of a large community of recruiters. Having said that, SPEED is our motto. You will receive minimum 5 job seeker’s profile within  24 to 48 hours from the job requirement is given and our speciality is we only sumit profiles with immediate or less than 30 days of notice period, so the chances of conversion is very high and our requirement closing SLA is 2 weeks.",
      },
      {
        question: "How can I reach Hiringhood for questions?",
        answer:
          "You can call your assigned Account Manager at Hiringhood or send an email. Our team is available between 9 AM to 6 PM IST. However we are flexible enough to resolve all your queries at the earliest.",
      },
      {
        question: "How can I join as an Employer at Hiringhood?",
        answer:
          "Please register yourself in our website www.hiringhood.com under the Employer category, so that one of our executives will get in touch with you. Also, you can send your queries to employer@hiringhood.com.",
      },
    ],
  },
  {
    image: jobSeeker,
    selectedImage: jobSeekerSelected,
    title: "Job Seeker",
    faq: [
      {
        question: "Who is a Jobseeker?",
        answer:
          "Job seeker refers to an individual who is actively looking for an employment opportunity/job.",
      },
      {
        question:
          "Can a Job seeker directly submit his/her profile for any open contest/position at Hiringhood platform?",
        answer:
          "No, Hiringhood does not accept any profiles coming directly from. One must be registered with Hiringhood as a Job seeker in order to apply for any open positions at Hiringhood platform.",
      },
      {
        question: "How can I join as a Jobseeker at Hiringhood?",
        answer:
          "Please register yourself in our website www.hiringhood.com under the Jobseeker category, so that one of our executives will get in touch with you. Also, you can send your queries to jobseeker@hiringhood.com.",
      },
    ],
  },
  {
    image: partner,
    selectedImage: partnerSelected,
    title: "Partner",
    faq: [
      {
        question: "Who is called a partner at Hiringhood?",
        answer:
          "We at Hiringhood partnered with different service providers such as, Interview as service, LMS, Resume builder, Assessment Services. They will help Hiringhood in various stages as per their nature of service.",
      },
      {
        question: "How can I join as a Partner at Hiringhood?",
        answer:
          "Please register yourself in our website www.hiringhood.com under the partner category, so that one of our executives will get in touch with you. Also, you can send your queries to partner@hiringhood.com.",
      },
    ],
  },
  {
    image: referrals,
    selectedImage: referralsSelected,
    title: "Referrals",
    faq: [
      {
        question: "What is Referral program?",
        answer:
          "Anyone can enrol in Hiringhood referral program and earn referral fee of up to 500,000 INR on successful conversion by referring any of your friends or colleagues who are looking for job opportunities for any open contest/position in Hiringhood platform and making them join the employer once offered a position.",
      },
      {
        question: "Can I submit a referral directly?",
        answer:
          "No, Hiringhood will not consider any referrals directly from. One must be registered under the Hiringhood Referral program in order to refer their friends or colleagues.",
      },
      {
        question: "Who can join as a referral?",
        answer:
          "Anyone can participate in a referral program other than the users who are registered as Recruiters in Hiringhood.",
      },
      {
        question:
          "What is the difference between Employee referral and Hiringhood Referral?",
        answer:
          "Employee referral is nothing but you are referring your friends or close acquaintances to your company for any open positions and you will be paid a referral fee once they join your company and complete a cool off period.Hiringhood Referral is nothing but you are referring your friends or colleagues to any open positions at Hiringhood platform and get paid a referral fee once they join any one of Hiringhood client’s within 4 months from the referral date. This referral fee paid upon the successful completion of the cool off period.",
      },
      {
        question: "What are the benefits of joining a referral program?",
        answer:
          "Hiringhood Referral is a premium referral program which will pay the premium referral fee upon the conversion of your referral. You can earn up to 500,000 INR for a single referral upon successful conversion.",
      },
      {
        question: "How can I join the Hiringhood referral program?",
        answer:
          "Please register yourself in our website www.hiringhood.com under referral program, so that one of our executives will get in touch with you. Also, you can send your queries to referral@hiringhood.com.",
      },
    ],
  },
];

export const partners = [
  {
    category: "Assessment Platforms",
    partners: [
      {
        name: "Hackerrank",
        logo: "hackerrank.png",
        website: "https://www.hackerrank.com/",
      },
      {
        name: "Hackerearth",
        logo: "hackerearth.svg",
        website: "https://www.hackerearth.com/",
      },
    ],
  },
  {
    category: "Others",
    partners: [
      {
        name: "RChilli",
        logo: "rchilli.png",
        website: "https://www.rchilli.com/",
      },
    ],
  },
];

export const footer = [
  {
    title: "Information",
    content: [
      {
        label: "About Us",
        link: ABOUT_US,
      },
      {
        label: "Terms & Conditions",
      },
      {
        label: "Privacy Policy",
      },
      {
        label: "Careers With Us",
        link: CONTESTS,
      },
      {
        label: "Sitemap",
      },
      {
        label: "Contact Us",
      },
      {
        label: "FAQs",
        link: FAQ,
      },
    ],
  },
  {
    title: "Recruiters",
    content: [
      {
        label: "Register Now",
        link: SIGNUP,
      },
      {
        label: "Search Jobs",
        link: CONTESTS,
      },
      {
        label: "Login",
      },
      {
        label: "Create Job Alert",
        isDisabled: true,
      },
      {
        label: "Report A Problem",
        isDisabled: true,
      },
      {
        label: "Hiringhood Blogs",
      },
      {
        label: "Mobile Site",
        isDisabled: true,
      },
    ],
  },
];

export const team = [
  {
    image: "sarath.png",
    name: "Sarath Yandrapalli",
    role: "Founder and CEO",
    description:
      "Entrepreneur at heart, determined & passionate to solve deep problems with disruption yet, well-balanced business models which has intrinsic value for all key stake holders. Takes pride in working for his Customers, Vendors, Employees & Investors.",
    linkedInUrl: "https://www.linkedin.com/in/sarath-babu-yandrapalli-2048244",
  },
  {
    image: "martin.png",
    name: "Martin Devathala",
    role: "Associate Director - Sales(Customers & Vendors)",
    description:
      "Dynamic and result-oriented professional having solid experience in Customer, Vendor & Talent Acquisition, Talent Engagement, Key Account Management and Customer Success. He is also a passionate Networker, Stake holder manager and Lead communicator.",
    linkedInUrl: "https://www.linkedin.com/in/martincjos",
  },
];
