import { useState, useMemo, useEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import HotlistCard from "../HotlistCard";
import HotlistListCard from "../HotlistListCard";
import Pagination from "../Pagination";
import Button from "../../components/Button";
import HotlistFilters from "../../components/HotlistFilters";
import gridViewImage from "../../assets/grid-view.png";
import gridViewActiveImage from "../../assets/grid-view-active.svg";
import listViewImage from "../../assets/list-view.png";
import listViewActiveImage from "../../assets/list-view-active.svg";
import tableViewImage from "../../assets/table-view.svg";
import tableViewActiveImage from "../../assets/table-view-active.svg";
import { useMediaQuery } from "../../utils/hooks";
import "./styles.css";
import StyledCheckbox from "../Checkbox";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedHotlist,
  unsetSelectedHotlist,
} from "../../redux/actions/hotlist";
import { IHotList } from "../../types/hotlist";
import { hotlistJobSelected } from "../../redux/selectors";

const Hotlist = (props) => {
  const {
    hotlistData,
    hotlist = [],
    onReset,
    numberofJobSeekersPerPage = 12,
    handleConnect
  } = props;

  const [currentPage, setCurrentPage] = useState(1);
  const [filteredJs, setFilteredJs] = useState(hotlist);
  const [gridView, setGridView] = useState(false);
  const [tableView, setTableView] = useState(true);
  const [open, setOpen] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [selectedJSSet, setSelectedJSSet] = useState(() => new Set());
  const [checkedList, setCheckedList] = useState<string[]>([])

  const smallDevice = useMediaQuery("(max-width:900px)");
  const dispatch = useDispatch();
  const selectedJobs = useSelector(hotlistJobSelected);

  const handleCheckboxClear = () => {
    selectedJSSet.forEach(item => {
      dispatch(unsetSelectedHotlist(item));
    });
    setCheckedList([]);
  }

  function setSelectedJobIds(
    row: IHotList,
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const { checked } = event.target;
    const tempCheckedList = [...checkedList];

    if (checked) {
      tempCheckedList.push(row.id);
      setCheckedList(tempCheckedList);
      setSelectedJSSet(prev => new Set(prev).add(row.id));
      dispatch(setSelectedHotlist(row));
    } else {
      const updatedList = tempCheckedList.filter((node) => node != row.id)
      setCheckedList(updatedList);

      setSelectedJSSet(prev => {
        const next = new Set(prev);
        next.delete(row.id);
        return next;
      })

      dispatch(unsetSelectedHotlist(row.id));
    }
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const onFiltersChanged = (list) => {
    setFilteredJs([...list]);
    setCurrentPage(1);
  };

  const onPageChange = (page: number) => {
    setCurrentPage(page);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (smallDevice) {
      setTableView(false);
      setGridView(true);
    } else {
      setTableView(true);
      setGridView(false);
    }
  }, [smallDevice]);

  const pageData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * numberofJobSeekersPerPage;
    const lastPageIndex = firstPageIndex + numberofJobSeekersPerPage;
    return filteredJs.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, filteredJs, numberofJobSeekersPerPage]);

  return (
    <>
      {hotlist.length > 0 && (
        <div className="hotlist-wrapper">
          <div className="hotlist-container">
            <div className="hotlist-js-title-container">
              <p className="hotlist-js-title">Job Seekers</p>
              <div className="hotlist-js-view-container">
                <div className={"hotlist-connect-select"}>{selectedJobs.length} Selected</div>
                <div className="hotlist-connect">
                  <Button
                      onClick={handleCheckboxClear}
                      className="hotlist-connect-button"
                      label="Clear"
                      disabled={!selectedJobs.length}
                  />
                </div>
                <div className="hotlist-connect">
                  <Button
                      onClick={handleConnect}
                      className="hotlist-connect-button"
                      label="Connect"
                      disabled={!selectedJobs.length}
                  />
                </div>
                <img
                  src={tableView ? tableViewActiveImage : tableViewImage}
                  alt="List View"
                  onClick={() => {
                    setTableView(true);
                    setGridView(false);
                  }}
                  className="contests-icon"
                />
                <img
                  src={gridView ? gridViewActiveImage : gridViewImage}
                  alt="Grid View"
                  onClick={() => {
                    setGridView(true);
                    setTableView(false);
                  }}
                  className="contests-icon  contests-list-view-icon"
                />
                <img
                  src={
                    !gridView && !tableView
                      ? listViewActiveImage
                      : listViewImage
                  }
                  alt="List View"
                  onClick={() => {
                    setGridView(false);
                    setTableView(false);
                  }}
                  className="contests-icon contests-list-view-icon"
                />
              </div>
            </div>
            <Button
              className="hotlist-filters"
              label="Filters"
              variant="outlined"
              onClick={() => {
                setCurrentPage(1);
                setOpen(true);
              }}
            ></Button>
            {open && (
              <Drawer anchor="right" open={true}>
                <Box sx={{ width: 300 }} role="presentation">
                  <HotlistFilters
                    hotlistData={hotlistData}
                    hotlist={hotlist}
                    onFiltersChanged={onFiltersChanged}
                    onClose={() => {
                      setOpen(false);
                    }}
                    onReset={onReset}
                  />
                </Box>
              </Drawer>
            )}
            <div className="hotlist-js-list-container">
              <div className="hotlist-js-filter-container">
                <HotlistFilters
                  hotlistData={hotlistData}
                  hotlist={hotlist}
                  onFiltersChanged={onFiltersChanged}
                  onReset={onReset}
                />
              </div>
              <div className="hotlist-js-list-view-container">
                {tableView && hotlist.length > 0 && (
                  <>
                    <div className="grades-table-container">
                      <Paper sx={{ width: "100%", overflow: "hidden" }}>
                        <TableContainer sx={{ maxHeight: 800 }}>
                          <Table stickyHeader aria-label="sticky table">
                            <colgroup>
                              <col style={{ width: "40%" }} />
                              <col style={{ width: "40%" }} />
                              <col style={{ width: "40%" }} />
                              <col style={{ width: "45%" }} />
                              <col style={{ width: "55%" }} />
                              <col style={{ width: "55%" }} />
                              <col style={{ width: "40%" }} />
                              <col style={{ width: "40%" }} />
                              <col style={{ width: "50%" }} />
                              <col style={{ width: "60%" }} />
                              <col style={{ width: "40%" }} />
                            </colgroup>
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  style={{
                                    textAlign: "center",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Select
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "center",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Id
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "center",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Role
                                </TableCell>
                                <TableCell style={{ fontWeight: "bold" }}>
                                  Experience
                                </TableCell>
                                <TableCell
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  Primary Skills
                                </TableCell>
                                <TableCell
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  Secondary Skills
                                </TableCell>
                                <TableCell
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  Current CTC
                                </TableCell>
                                <TableCell
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  Expected CTC
                                </TableCell>
                                <TableCell
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  Current Location
                                </TableCell>
                                <TableCell
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  Preferred Location(s)
                                </TableCell>
                                <TableCell
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  Working Status
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {hotlist
                                .slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                .map((row, index) => {
                                  return (
                                    <TableRow
                                      key={row.id}
                                      sx={{
                                        "& > *": { borderBottom: "unset" },
                                      }}
                                    >
                                      <TableCell>
                                        <StyledCheckbox
                                          onChange={(e) =>
                                            setSelectedJobIds(row, e)
                                          }
                                          checked={checkedList.includes(row.id)}
                                        />
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          textAlign: "center",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {row.id}
                                      </TableCell>
                                      <TableCell>{row.role}</TableCell>
                                      <TableCell>{row.experience}</TableCell>
                                      <TableCell> {row.primarySkill}</TableCell>
                                      <TableCell>
                                        {row.secondarySkills}
                                      </TableCell>
                                      <TableCell>{row.currentCTC}</TableCell>
                                      <TableCell>{row.expectedCTC}</TableCell>
                                      <TableCell>
                                        {row.currentLocation}
                                      </TableCell>
                                      <TableCell>
                                        {row.preferredLocations}
                                      </TableCell>
                                      <TableCell>
                                        {row.employmentStatus}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Paper>
                    </div>
                    <TablePagination
                      rowsPerPageOptions={[50, 100, 200]}
                      component="div"
                      count={hotlist.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </>
                )}
                {gridView && (
                  <div className="hotlist-js-container">
                    {pageData.length > 0 &&
                      pageData.map((js) => {
                        return <HotlistCard
                            key={js.id}
                            jsDetails={js}
                            checkedList={checkedList}
                        />;
                      })}
                  </div>
                )}
                {!gridView &&
                  !tableView &&
                  pageData.length > 0 &&
                  pageData.map((js) => {
                    return (
                      <div key={js.id} className="hotlist-js-list-container">
                        <HotlistListCard
                            key={js.id}
                            jsDetails={js}
                            checkedList={checkedList}
                        />
                      </div>
                    );
                  })}
                {filteredJs.length === 0 && (
                  <p className="hotlist-no-js">
                    There are no job seekers available with the specified
                    filters in the hotlist
                  </p>
                )}
              </div>
            </div>
          </div>
          {!tableView && (
            <Pagination
              className="hotlist-pagination-container"
              currentPage={currentPage}
              totalCount={filteredJs.length}
              pageSize={numberofJobSeekersPerPage}
              onPageChange={onPageChange}
            />
          )}
        </div>
      )}
      {hotlist.length === 0 && (
        <p className="hotlist-no-js">
          There are no job seekers available in the hotlist
        </p>
      )}
    </>
  );
};

export default Hotlist;
