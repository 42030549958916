import statusStories from "../../components/Status/__stories__/status.stories";

export const errorSelector = (state) => state.application.error;

export const contestsSelector = (state) =>
  state.contestsRepository.contestsData;
export const contestsLoadingSelector = (state) =>
  state.contestsRepository.loading !== undefined
    ? state.contestsRepository.loading
    : true;
export const contestSelector = (state) => state.contestRepository.contest;
export const contestLoadingSelector = (state) =>
  state.contestRepository.loading !== undefined
    ? state.contestRepository.loading
    : true;
export const companyDetailsSelector = (state) =>
  state.recruiterProfile.companyDetails;
export const recruiterBackgroundSelector = (state) =>
  state.recruiterProfile.recruiterBackground;
export const recruiterBillingSelector = (state) =>
  state.recruiterProfile.recruiterBilling;
export const recruiterSelector = (state) =>
  state.recruiterProfile.recruiterData;
export const employerSelector = (state) => state.employerRepository.employer;
export const employerLoadingSelector = (state) =>
  state.employerRepository.loading !== undefined
    ? state.employerRepository.loading
    : true;
export const employersSelector = (state) =>
  state.employersRepository.employersData;
export const employersLoadingSelector = (state) =>
  state.employersRepository.loading !== undefined
    ? state.employersRepository.loading
    : true;
export const recruitersLoadingSelector = (state) =>
  state.recruiterProfile.loading !== undefined
    ? state.recruiterProfile.loading
    : true;
export const recruitersSelector = (state) => state.recruiterProfile.recruiters;
export const userInfoSelector = (state) => state.userRepository;
export const signUpUserInfoSelector = (state) =>
  state.userRepository.userSignUpInfo;
export const recruiterDetailsSelector = (state) =>
  state.recruiterProfile.recruiter;
export const recruiterLoadingSelector = (state) =>
  state.recruiterProfile.loading !== undefined
    ? state.recruiterProfile.loading
    : true;
export const uploadFileSelector = (state) => state.uploadFile.fileInfo;
export const uploadFileLoadingSelector = (state) => state.uploadFile.loading;
export const onboardSelector = (state) => state.onboardRecruiter.onboardedUser;
export const onboardSelectorLoading = (state) => state.onboardRecruiter.loading;
export const blogsLoadingSelector = (state) =>
  state.blogsRepository.loading !== undefined
    ? state.blogsRepository.loading
    : true;
export const blogsSelector = (state) => state.blogsRepository.blogs;
export const partnerSelector = (state) => state.partnerRepository.partner;
export const partnerLoadingSelector = (state) =>
  state.partnerRepository.loading !== undefined
    ? state.partnerRepository.loading
    : true;
export const bannersSelector = (state) => state.bannersRepository.banners;
export const bannersLoadingSelector = (state) =>
  state.bannersRepository.loading !== undefined
    ? state.bannersRepository.loading
    : true;
export const partnersSelector = (state) =>
  state.partnersRepository.partnersData;
export const partnersLoadingSelector = (state) =>
  state.partnersRepository.loading !== undefined
    ? state.partnersRepository.loading
    : true;
export const linksSelector = (state) => state.linksRepository.links;
export const hotlistSelector = (state) => state.hotlistRepository;
export const hotlistLoadingSelector = (state) =>
  state.hotlistRepository.loading || state.hotlistRepository.analyticsLoading;
export const hotlistJobSelected = (state) =>
  state.hotlistRepository.jobSelected;
