import "./styles.scss";
interface Props {
  labels: string[];
  colors: string[];
}

const TitleMultiColor = (props: Props) => {
  const { labels, colors } = props;
  return (
    <div className="multi-color-title-div">
      {labels.map((item, index) => (
        <label
          className="multi-color-text"
          style={{ color: colors[index] }}
          key={index}
        >
          {item}
        </label>
      ))}
    </div>
  );
};

export default TitleMultiColor;
