export const getStates = () => {
  return LOCATIONS.map((location) => location.state).sort((a, b) =>
    a.localeCompare(b)
  );
};

export const getCities = () => {
  const allCities = LOCATIONS.map((location) => location.cities);
  const cities = ([] as string[]).concat(...allCities);
  cities.sort((a, b) => a.localeCompare(b));
  return cities;
};

export const LOCATIONS = [
  {
    state: "Andhra Pradesh",
    cities: [
      "Adilabad",
      "Anantapur",
      "Cuddapah",
      "Guntakal",
      "Guntur",
      "Kakinada",
      "Kamalapuram",
      "Kurnool",
      "Nellore",
      "Rajahmundry",
      "Tirupati",
      "Vijayawada",
      "Visakhapatnam / Vizag",
    ],
  },
  { state: "Arunachal Pradesh", cities: ["Itanagar"] },
  { state: "Assam", cities: ["Dibrugarh", "Guwahati", "Silchar", "Tezpur"] },
  {
    state: "Bihar",
    cities: [
      "Aurangabad",
      "Bhagalpur",
      "Gaya",
      "Munger",
      "Muzaffarpur",
      "Patna",
    ],
  },
  {
    state: "Chhattisgarh",
    cities: ["Bhilai", "Bilaspur", "Korba", "Raigarh", "Raipur"],
  },
  { state: "Goa", cities: ["Panjim / Panaji", "Vasco Da Gama"] },
  {
    state: "Gujrat",
    cities: [
      "Ahmedabad",
      "Anand",
      "Ankleshwar",
      "Bharuch",
      "Bhavnagar",
      "Bhuj",
      "Dahej",
      "Gandhinagar",
      "Gandhidham",
      "Gir",
      "Godhra",
      "Jamnagar",
      "Junagadh",
      "Kandla",
      "Lakhtar",
      "Mehsana",
      "Mundra",
      "Porbandar",
      "Rajkot",
      "Surat",
      "Surendranagar",
      "Vadodara / Baroda",
      "Valsad",
      "Vapi",
      "Veraval",
    ],
  },
  {
    state: "Haryana",
    cities: [
      "Ambala",
      "Bawal",
      "Bhiwani",
      "Dharuhera",
      "Faridabad",
      "Gurgaon",
      "Hisar",
      "Jind",
      "Karnal",
      "Kurukshetra",
      "Manesar",
      "Palwal",
      "Panchkula",
      "Panipat",
      "Rewari",
      "Rohtak",
      "Sonepat",
      "Yamunanagar",
    ],
  },
  {
    state: "Himachal Pradesh",
    cities: [
      "Baddi",
      "Chamba",
      "Dalhousie",
      "Dharmasala",
      "Kala",
      "Kullu/Manali",
      "Mandi",
      "Shimla",
    ],
  },
  { state: "Jammu and Kashmir", cities: ["Jammu", "Srinagar"] },
  { state: "Jharkhand", cities: ["Bokaro", "Dhanbad", "Jamshedpur", "Ranchi"] },
  {
    state: "Karnataka",
    cities: [
      "Belgaum",
      "Bellary",
      "Bengaluru / Bangalore",
      "Bidar",
      "Davangere",
      "Dharwad",
      "Gulbarga",
      "Hospet",
      "Hubli",
      "Kolar",
      "Koppal",
      "Mangalore",
      "Mysore",
      "Shimoga",
      "Tumkur",
    ],
  },
  {
    state: "Kerala",
    cities: [
      "Alappuzha / Alleppey",
      "Ernakulam / Kochi / Cochin",
      "Idukki",
      "Kannur / Cannannore",
      "Kasargode",
      "Kollam / Quilon",
      "Kottayam",
      "Kozhikode / Calicut",
      "Malappuram",
      "Palakkad / Palghat",
      "Pathanamthitta",
      "Thrissur / Trichur",
      "Trivandrum",
      "Wayanad",
    ],
  },
  {
    state: "Madhya Pradesh",
    cities: [
      "Bhopal",
      "Gwalior",
      "Indore",
      "Jabalpur",
      "Katni",
      "Ratlam",
      "Rewa",
      "Satna",
      "Ujjain",
    ],
  },
  {
    state: "Maharashtra",
    cities: [
      "Ahmednagar",
      "Akola",
      "Amravati",
      "Baramati",
      "Chandrapur",
      "Chiplun",
      "Dhule",
      "Jalgaon",
      "Khopoli",
      "Kolhapur",
      "Mumbai",
      "Mumbai Suburbs",
      "Nagpur",
      "Nasik",
      "Navi Mumbai",
      "Pune",
      "Raigad",
      "Ratnagiri",
      "Satara",
      "Solapur",
      "Tarapur",
      "Thane",
      "Vasai",
    ],
  },
  { state: "Manipur", cities: ["Imphal"] },
  { state: "Meghalaya", cities: ["Shillong"] },
  { state: "Mizoram", cities: ["Aizawl"] },
  { state: "Nagaland", cities: ["Dimapur", "Kohima"] },
  {
    state: "Odisha",
    cities: [
      "Angul",
      "Bhubaneshwar",
      "Cuttack",
      "Jharsuguda",
      "Paradeep",
      "Puri",
      "Rourkela",
      "Sambalpur",
    ],
  },
  {
    state: "Punjab",
    cities: [
      "Amritsar",
      "Banur",
      "Barnala",
      "Bathinda",
      "Faridkot",
      "Firozpur",
      "Gurdaspur",
      "Hoshiarpur",
      "Jalandhar",
      "Kapurthala",
      "Ludhiana",
      "Moga",
      "Mohali",
      "Morinda",
      "Pathankot",
      "Patiala",
      "Phagwara",
      "Rajpura",
      "Rupnagar",
      "Sangrur",
    ],
  },
  {
    state: "Rajasthan",
    cities: [
      "Ajmer",
      "Alwar",
      "Banswara",
      "Barmer",
      "Bhilwara",
      "Bhiwadi",
      "Bikaner",
      "Ganganagar",
      "Jaipur",
      "Jaisalmer",
      "Jodhpur",
      "Kota",
      "Mount Abu",
      "Nagar",
      "Neemrana",
      "Udaipur",
    ],
  },
  { state: "Sikkim", cities: ["Gangtok"] },
  {
    state: "Tamil Nadu",
    cities: [
      "Chennai",
      "Coimbatore",
      "Cuddalore",
      "Erode",
      "Hosur",
      "Kalpakkam",
      "Kanchipuram",
      "Karur",
      "Madurai",
      "Mamandur",
      "Nagercoil",
      "Ooty",
      "Salem",
      "Thanjavur",
      "Tirunelveli",
      "Trichy",
      "Tuticorin",
      "Vellore",
    ],
  },
  {
    state: "Telangana",
    cities: [
      "Hyderabad / Secunderabad",
      "Karimnagar",
      "Khammam",
      "Nizamabad",
      "Warangal",
    ],
  },
  { state: "Tripura", cities: ["Agartala"] },
  {
    state: "Union Territories",
    cities: [
      "Chandigarh",
      "Dadra and Nagar Haveli",
      "Silvassa",
      "Daman and Diu",
      "Delhi",
      "Lakshadweep",
      "Pondicherry",
      "Andaman and Nicobar Islands",
      "Port Blair",
    ],
  },
  {
    state: "Uttar Pradesh",
    cities: [
      "Agra",
      "Aligarh",
      "Allahabad",
      "Bahraich",
      "Bareilly",
      "Bijnor",
      "Faizabad",
      "Gajraula",
      "Ghaziabad",
      "Gorakhpur",
      "Greater Noida",
      "Jhansi",
      "Kanpur",
      "Lucknow",
      "Mathura",
      "Meerut",
      "Moradabad",
      "Noida",
      "Orai",
      "Saharanpur",
      "Varanasi",
    ],
  },
  {
    state: "Uttarakhand",
    cities: [
      "Dehradun",
      "Haridwar",
      "Haldwani",
      "Kashipur",
      "Pantnagar",
      "Roorkee",
      "Rudrapur",
    ],
  },
  {
    state: "West Bengal",
    cities: [
      "Asansol",
      "Burdwan",
      "Durgapur",
      "Haldia",
      "Kharagpur",
      "Kolkata",
      "Siliguri",
    ],
  },
];
