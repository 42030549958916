export const CONTESTS_API = process.env.REACT_APP_CONTESTS_API;
export const CONTEST_API = process.env.REACT_APP_CONTEST_API;
export const EMPLOYER_API = process.env.REACT_APP_EMPLOYER_API;
export const LOGIN_API = process.env.REACT_APP_LOGIN_API;
export const REWARDS_API = process.env.REACT_APP_REWARDS_API;
export const UPLOAD_FILE = process.env.REACT_APP_UPLOAD_FILE;
export const RECRUITER_PROFILE = process.env.REACT_APP_RECRUITER_PROFILE;
export const RECRUITER_INVITE = process.env.REACT_APP_RECRUITER_INVITE;
export const BLOGS_API = process.env.REACT_APP_BLOGS_API;
export const PARTNER_API = process.env.REACT_APP_PARTNER_API;
export const BANNER_API = process.env.REACT_APP_BANNER_API;
export const LINKS_API = process.env.REACT_APP_LINKS_API;
export const HOTLIST_API = process.env.REACT_APP_HOTLIST_API;
