export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_USER_SIGNUP_INFO = "SET_USER_SIGNUP_INFO";
export const setCurrentUser = (data) => {
  return {
    type: SET_CURRENT_USER,
    payload: data,
    userStage: data.userStage,
  };
};

export const setUserInfoFromEmailInvite = (data) => {
  return {
    type: SET_USER_SIGNUP_INFO,
    userSignUpInfo: data,
  };
};
