interface Action {
  type: string;
  payload: boolean;
}

const onboardReducer = (state = { onboardedUser: null }, action) => {
  switch (action.type) {
    case "SET_ONBOARDED_RECRUITER":
      return action.payload;
    default:
      return state;
  }
};

export default onboardReducer;
