import { useState } from "react";
import Card from "../Card";
import CheckboxFilter from "../CheckboxFilter";
import filterImage from "../../assets/filter.svg";
import styles from "./styles.module.scss";
import Button from "../Button";

const PartnerFilters = (props) => {
  const { partnersData, onFiltersChanged, onReset } = props;
  const partners = partnersData.partners;

  const [locationEmployers, setLocationEmployers] = useState(partners);
  const [categoryEmployers, setCategoryEmployers] = useState(partners);

  const hasObject = (array, object) => {
    return array.filter((item) => item.id === object.id).length > 0;
  };

  const getCommonPartners = (categoryEmployers, locationEmployers) => {
    const clonedPartners = [];
    const clonedEmployers = JSON.parse(JSON.stringify(partners));
    clonedEmployers.forEach((employer) => {
      if (
        hasObject(locationEmployers, employer) &&
        hasObject(categoryEmployers, employer)
      ) {
        clonedPartners.push(employer);
      }
    });

    onFiltersChanged(clonedPartners);
  };

  const onLocationChange = (list) => {
    setLocationEmployers([...list]);
    setLocationEmployers((state) => {
      getCommonPartners(categoryEmployers, state);
      return state;
    });
  };

  const onCategoryChange = (list) => {
    setCategoryEmployers([...list]);
    setCategoryEmployers((state) => {
      getCommonPartners(
        state,

        locationEmployers
      );
      return state;
    });
  };

  return (
    <Card>
      <div className={styles["partner-filters-container"]}>
        <div className={styles["partner-filters-title-container"]}>
          <img src={filterImage} />
          <p className={styles["partner-filters-title"]}>Filter Search</p>
        </div>
        <Button
          className={styles["partner-filters-clear-filters-button"]}
          label="Clear Filters"
          onClick={onReset}
          variant="outlined"
        />
        <hr className={styles["partner-filters-separator"]} />
        <CheckboxFilter
          filterName="Partner Type"
          filters={partnersData.allCategories}
          field="filterCategories"
          list={partners}
          onFilterChanged={onCategoryChange}
        />
        <hr className={styles["partner-filters-separator"]} />
        <CheckboxFilter
          filterName="Locations"
          filters={partnersData.allLocations}
          field="filterLocations"
          list={partners}
          onFilterChanged={onLocationChange}
        />
      </div>
    </Card>
  );
};

export default PartnerFilters;
