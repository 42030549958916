interface Action {
  type: string;
  payload: boolean;
}

const signupReducer = (state = { authenticated: false }, action: Action) => {
  switch (action.type) {
    case "SET_AUTHENTICATION_STATUS":
      return { ...state, authenticated: action.payload };
    default:
      return state;
  }
};

export default signupReducer;
