import "./styles.scss";
import coreValues from "../../assets/values-at-our-core.svg";
import TitleMultiColor from "../TitleMultiColor";
import { valuesAtOurCoreText } from "../../utils/constants/site-data";
import greenLine from "../../assets/green-line.svg";
const CoreValues = () => {
  return (
    <div className="core-values-container">
      <div className="core-values-text-container">
        <div className="core-values-title">
          <TitleMultiColor
            colors={["#F1F7FF", "#38F4A4"]}
            labels={["Values", "At Our Core"]}
          />
          <img src={greenLine} />
        </div>
        <p className="core-values-text-desc">{valuesAtOurCoreText}</p>
      </div>
      <img
        src={coreValues}
        alt="Values At Our Core"
        className="core-values-image"
      />
    </div>
  );
};
export default CoreValues;
