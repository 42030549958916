import { SET_PARTNER } from "../actions/partner";

const partnerReducer = (state = { partner: {} }, action) => {
  switch (action.type) {
    case SET_PARTNER:
      return action.payload;
    default:
      return state;
  }
};

export default partnerReducer;
