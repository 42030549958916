const parse = (response: any) => {
  const banners = [];
  response.data.map((item: any) => {
    const banner = item["attributes"];

    if (banner["banner_active"]) {
      const parsedBannerDetails: any = {};
      parsedBannerDetails.title = banner["banner_title"];
      parsedBannerDetails.content = banner["banner_description"];
      parsedBannerDetails.image = banner["background_image"];
      banners.push(parsedBannerDetails);
    }
  });

  return banners;
};

export default parse;
