import { createSlice, current } from "@reduxjs/toolkit";
import { IHotList } from "../../types/hotlist";
import {
  SET_HOTLIST,
  SET_SELECTED_HOTLIST,
  UNSET_SELECTED_HOTLIST,
} from "../actions/hotlist";

const hotlistReducer = (state = { hotlist: [], jobSelected: [] }, action) => {
  switch (action.type) {
    case SET_HOTLIST:
      return { ...state, ...action.payload };
    case SET_SELECTED_HOTLIST: {
      const temp = state.jobSelected;
      /**
       * this is to avoid the duplicated which may happen during 3 different viewes
       * checkboxes
       */
      const ifDuplicateExists = temp.find((x) => {
        if (x.id == action.payload.id) return true;
        else false;
      });
      if (ifDuplicateExists) {
        return state;
      } else {
        temp.push(action.payload);
        return {
          ...state,
          jobSelected: temp,
        };
      }
    }

    case UNSET_SELECTED_HOTLIST: {
      const temp = state.jobSelected;
      const index = temp.findIndex((x) => x.id === action.payload);
      temp.splice(index, 1);
      return {
        ...state,
        jobSelected: temp,
      };
    }
    default:
      return state;
  }
};

export default hotlistReducer;
