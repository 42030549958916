import moment from "moment";

const parse = (response: any) => {
  const allLocations = [];
  const allSkills = [];
  const employers = response.data.map((item: any) => {
    const employer = item["attributes"];

    const parsedEmployerDetails: any = {};
    parsedEmployerDetails.id = item["id"];
    parsedEmployerDetails.coverImage = employer["employer_cover_image"];
    parsedEmployerDetails.logo = employer["employer_logo"];
    parsedEmployerDetails.name = employer["employer_legal_name"];
    parsedEmployerDetails.brandName = employer["brand_name"];
    parsedEmployerDetails.rating = employer["average_rating"];
    parsedEmployerDetails.reviews = employer["total_reviews_count"];
    parsedEmployerDetails.openContests = employer["open_contests"];
    parsedEmployerDetails.contestsConducted = employer["contest_conducted"];
    parsedEmployerDetails.joiningDate = moment(
      employer["employer_joining_date"],
      "YYYY-MM-DD"
    )
      .toDate()
      .getTime();
    parsedEmployerDetails.filterRating = [
      employer["average_rating"],
      employer["average_rating"],
    ];
    parsedEmployerDetails.filterPositions = [
      employer["no_of_positions"],
      employer["no_of_positions"],
    ];
    parsedEmployerDetails.filterSkills = employer["skills_hiring_for"];
    parsedEmployerDetails.filterLocations = employer["employer_locations"];

    allLocations.push(...parsedEmployerDetails.filterLocations);
    allSkills.push(...parsedEmployerDetails.filterSkills);

    return parsedEmployerDetails;
  });

  const parsedResponse = {
    employers,
    allLocations: [...new Set(allLocations)],
    allSkills: [...new Set(allSkills)],
  };

  return parsedResponse;
};

export default parse;
