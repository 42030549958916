import Card from "../Card";
import bonusImage from "../../assets/bonus.svg";
import matchingProfilesImage from "../../assets/matching-profiles-container.svg";
import quotaImage from "../../assets/quota-container.svg";
import "./styles.css";

interface Props {
  bonus: string;
  profilesMatched: string;
  quota: string;
}

const ContestQuota: React.FC<Props> = (props) => {
  const { bonus, profilesMatched, quota } = props;

  return (
    <Card>
      <div className="contest-quota-container">
        <div className="contest-quota-image-container">
          <img src={bonusImage} alt="Bonus" />
          <p className="contest-quota-text">{bonus}</p>
          <p className="contest-quota-label">Bonus</p>
        </div>
        {profilesMatched && (
          <div className="contest-quota-image-container">
            <img src={matchingProfilesImage} alt="Matching Profiles" />
            <p className="contest-quota-text">{profilesMatched}</p>
            <p className="contest-quota-label">Matching Profiles</p>
          </div>
        )}
        <div className="contest-quota-image-container">
          <img src={quotaImage} alt="Quota" />
          <p className="contest-quota-text">{quota}</p>
          <p className="contest-quota-label">Total Quota</p>
        </div>
      </div>
    </Card>
  );
};

export default ContestQuota;
