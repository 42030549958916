import {
  JOB_REQUISITION_LIST,
  JOB_REQUISITION_OTHER,
  RECRUITMENT_COMPANY,
  USER_URL_MAPPING,
} from "../constants/index";
import { stringToDate, dateToString } from "../util";

export const parseUserStage = (response) => {
  return response.data.attributes.user_stage;
};

export const parseCompany = (response: any, role: string) => {
  let parsedCompany: any = null;
  const attributes = response.attributes;
  const userDetails = {
    displayName: attributes.display_name,
    userStage: attributes.user_Stage,
    email: attributes.user_email,
  };
  const company = response.details;
  if (company) {
    parsedCompany = {};
    if (role === USER_URL_MAPPING[RECRUITMENT_COMPANY]) {
      parsedCompany = {
        companyName: company.title,
        certificate:
          company.upload_certificate === false
            ? ""
            : company.upload_certificate,
        certificateNote: company.certificate_note,
        logo: company.logo,
        yearOfRegistration:
          company.company_year_of_registration === false
            ? ""
            : company.company_year_of_registration,
        firstName: company.spoc_first_name,
        lastName: company.spoc_last_name,
        designation: company.spoc_designation,
        website: company.website,
        emailAddress: company.spoc_email,
        type: company.company_type === false ? "" : company.company_type,
        alternateEmailAddress: company.spoc_alternate_email,
        phoneExt:
          company.spoc_phone_extension === ""
            ? "+91"
            : company.spoc_phone_extension,
        phoneNumber: company.spoc_phone === 0 ? "" : company.spoc_phone,
        landline:
          company.spoc_alternate_phone === 0
            ? ""
            : company.spoc_alternate_phone,
        about: company.about_company,
        companyAddress: {
          line1: company.company_full_address.line_1,
          line2: company.company_full_address.line_2,
          pincode: company.company_full_address.pincode,
          city: company.company_full_address.city,
          state: company.company_full_address.state,
          country: company.company_full_address.country,
        },
      };
    } else {
      parsedCompany = {
        firstName: company.first_name ? company.first_name : "",
        lastName: company.last_name ? company.last_name : "",
        emailAddress: userDetails.email ? userDetails.email : "",
        alternateEmailAddress: company.alternate_email
          ? company.alternate_email
          : "",
        linkedInURL: company.linkedin_profile ? company.linkedin_profile : "",
        dob: company.date_of_birth ? stringToDate(company.date_of_birth) : "",
        gender: company.gender ? company.gender : "",
        education: company.education ? company.education : "",
        phoneExt: company.country_code ? company.country_code : "",
        phoneNumber: company.mobile_number ? company.mobile_number : "",
        landline: company.alternate_number ? company.alternate_number : "",
        alternatePhoneExt:
          company.alternate_phone_extension === ""
            ? "+91"
            : company.alternate_phone_extension,
        about: company.description ? company.description : "",
        logo:
          company.upload_profile_image && company.upload_profile_image.id
            ? company.upload_profile_image
            : "",
        address: {
          line1: company.address_details.enter_line_1_address,
          line2: company.address_details.enter_line_2_address,
          pincode: company.address_details.enter_pincode,
          city: company.address_details.city,
          state: company.address_details.state,
          country: company.address_details.country,
        },
      };
    }
  }

  return parsedCompany;
};

export const parseBackground = (response: any, role: string) => {
  const background = response.background;
  let parsedBackground: any = {};
  if (background) {
    if (role === USER_URL_MAPPING[RECRUITMENT_COMPANY]) {
      parsedBackground = {
        recruitersCount:
          background.no_recruiters_company_holds === false
            ? ""
            : background.no_recruiters_company_holds,
        domesticRecruitementPeriod: background.company_duration_in_domestic_recruiting
          ? parseInt(background.company_duration_in_domestic_recruiting)
          : 1,
        expertiseList: background.five_functional_roles_recruiting_expertise,
        typesOfRequirements: background.type_of_requirements_your_handled,
        levelsList: background.levels_of_hire_talent_mostly,
        jobLocations: background.job_locations_recruit_mostly,
        skillsList:
          background.top_five_skill_sets_specialised_in_sourcing_talent,
        industriesList: background.five_industries_recruiting_expertise,
        jobRequisition:
          typeof background.job_requisitions === "string"
            ? [background.job_requisitions]
            : background.job_requisitions,
        handledRecruitements:
          background.handled_recruitment_drives === "Yes" ? true : false,
        placements: background.placements_made_in_the_last_12_months,
      };
      parsedBackground.jobRequisition.forEach((item, index) => {
        if (JOB_REQUISITION_LIST.indexOf(item) === -1) {
          parsedBackground.jobRequisition.splice(index, 1);
          parsedBackground.jobRequisition.push(JOB_REQUISITION_OTHER);
          parsedBackground.jobRequisitionDetails = item;
          return;
        }
      });
    } else {
      parsedBackground = {
        domesticRecruitementPeriod: background.duration_domestic_recruiting
          ? parseInt(background.duration_domestic_recruiting)
          : 1,
        expertiseList: background["5_functional_roles_recruiting_expertise"],
        typesOfRequirements: background.type_of_requirements_your_handled,
        levelsList: background.levels_of_hire_talent_mostly,
        jobLocations: background.job_locations_recruit_mostly,
        skillsList: background.top_5_skill_sets_specialised_in_sourcing_talent,
        industriesList:
          background.top_5_industries_specialised_in_recruiting_expertise,
        handledRecruitements:
          background.handled_recruitment_drives_in_the_past === "Yes"
            ? true
            : false,
        placements:
          background.placements_count_last_12_months === false
            ? ""
            : background.placements_count_last_12_months,
        haveCertifications:
          background.certifications_in_recruiting === "Yes" ? true : false,
        certificate:
          background.upload_certificate && background.upload_certificate.id
            ? background.upload_certificate
            : "",
        freelancingExperience: background.duration_of_working_as_a_freelancer
          ? parseInt(background.duration_of_working_as_a_freelancer)
          : 1,
      };
    }
  }
  return parsedBackground;
};

export const parseBilling = (response: any, role: string) => {
  const billing = response.billing;
  let parsedBilling: any = {};

  if (billing) {
    parsedBilling = {
      panNumber: billing.pan_number,
      bankData: {
        bankName: billing.bank_details.bank_name,
        accountHolderName: billing.bank_details.account_holder_name,
        accountNumber: billing.bank_details.account_number,
        branch: billing.bank_details.branch,
        ifscCode: billing.bank_details.ifsc_code,
      },
    };
    if (role === USER_URL_MAPPING[RECRUITMENT_COMPANY]) {
      parsedBilling.gstNumber = billing.gst_number;
      parsedBilling.tanNumber = billing.tan_number;
    } else {
      parsedBilling.aadhaarNumber =
        billing.aadhar_card_number === 0 ? "" : billing.aadhar_card_number;
    }
  }

  return parsedBilling;
};

export const parseCompanyRecruiter = (response: any) => {
  const recruiter = response.company;
  const parsedRecruiter: any = {};
  if (recruiter) {
    parsedRecruiter.companyId = recruiter.id;
  }

  return parsedRecruiter;
};

export const payloadForCompanyDetails = (data, role: string) => {
  let payload = {};

  if (data.phoneExt === "") data.phoneExt = "+91";
  if (role === USER_URL_MAPPING[RECRUITMENT_COMPANY])
    payload = {
      company_name: data.companyName,
      upload_certificate: data.certificate ? data.certificate.id : null,
      certificate_note: data.certificateNote,
      logo: data.logo ? data.logo.id : null,
      company_year_of_registration: data.yearOfRegistration,
      spoc_first_name: data.firstName,
      spoc_last_name: data.lastName,
      spoc_designation: data.designation,
      website: encodeURI(data.website),
      company_type: data.type,
      spoc_email: data.emailAddress,
      spoc_alternate_email: data.alternateEmailAddress,
      spoc_phone: data.phoneNumber,
      spoc_phone_extension: data.phoneExt,
      spoc_alternate_phone: data.landline,
      about_company: data.about,
      company_full_address: {
        line_1: data.line1,
        line_2: data.line2,
        pincode: data.pincode,
        city: data.city,
        state: data.state,
        country: data.country,
      },
    };
  else {
    payload = {
      first_name: data.firstName,
      last_name: data.lastName,
      alternate_email: data.alternateEmailAddress,
      mobile_number: data.phoneNumber,
      country_code: data.phoneExt,
      alternate_number: data.alternate_phone,
      linkedin_profile: encodeURI(data.linkedInURL),
      date_of_birth: dateToString(data.dob),
      gender: data.gender,
      education: data.education,
      description: data.about,
      upload_profile_image: data.logo ? data.logo.id : null,
      address_details: {
        enter_line_1_address: data.line1,
        enter_line_2_address: data.line2,
        enter_pincode: data.pincode,
        city: data.city,
        state: data.state,
        country: data.country,
      },
    };
  }
  return payload;
};

export const payloadForTeamDetails = (data) => {
  const payload = {
    recruiters: "",
    company_id: data.companyId,
  };

  const recArray = [];
  data.recruiters.forEach((rec) => {
    if (rec.phoneExtension === "") rec.phoneExtension = "+91";
    recArray.push({
      name: rec.name,
      email: rec.email,
      phone_extension: rec.phoneExtension,
      phone_number: rec.phoneNumber,
    });
  });
  payload.recruiters = JSON.stringify(recArray);
  return payload;
};

export const payloadForBillingDetails = (data, role: string) => {
  const payload: any = {
    pan_number: data.panNumber,
    bank_details: {
      bank_name: data.bankName,
      account_holder_name: data.accountHolderName,
      account_number: data.accountNumber,
      branch: data.branch,
      ifsc_code: data.ifscCode,
    },
  };
  if (role === USER_URL_MAPPING[RECRUITMENT_COMPANY]) {
    payload.gst_number = data.gstNumber;
    payload.tan_number = data.tanNumber;
  } else payload.aadhar_card_number = parseInt(data.aadhaarNumber);
  return payload;
};

export const payloadForBackgroundDetails = (data, role: string) => {
  const payload: any = {
    type_of_requirements_your_handled: data.typesOfRequirements,
    levels_of_hire_talent_mostly: data.levelsList,
    job_locations_recruit_mostly: data.jobLocations,
  };
  if (role === USER_URL_MAPPING[RECRUITMENT_COMPANY]) {
    payload.five_functional_roles_recruiting_expertise = data.expertiseList;
    payload.no_recruiters_company_holds = data.recruitersCount;
    payload.company_duration_in_domestic_recruiting =
      data.domesticRecruitementPeriod;
    payload.five_industries_recruiting_expertise = data.industriesList;
    payload.handled_recruitment_drives = data.handledRecruitements
      ? "Yes"
      : "No";
    const removeIndex = data.jobRequisition.indexOf(JOB_REQUISITION_OTHER);
    if (removeIndex !== -1) {
      data.jobRequisition.push(data.jobRequisitionDetails);
      data.jobRequisition.splice(removeIndex, 1);
    }
    payload.job_requisitions = data.jobRequisition;
    payload.placements_made_in_the_last_12_months = data.placements;
    payload.top_five_skill_sets_specialised_in_sourcing_talent =
      data.skillsList;
  } else {
    payload["5_functional_roles_recruiting_expertise"] = data.expertiseList;
    payload.duration_domestic_recruiting = data.domesticRecruitementPeriod;
    payload.duration_of_working_as_a_freelancer = data.freelancingExperience;
    payload.certifications_in_recruiting = data.haveCertifications
      ? "Yes"
      : "No";
    payload.handled_recruitment_drives_in_the_past = data.handledRecruitements
      ? "Yes"
      : "No";
    payload.top_5_industries_specialised_in_recruiting_expertise =
      data.industriesList;
    payload.placements_count_last_12_months = data.placements;
    payload.top_5_skill_sets_specialised_in_sourcing_talent = data.skillsList;
    payload.upload_certificate =
      data.haveCertifications && data.certificate ? data.certificate.id : null;
  }

  return payload;
};
