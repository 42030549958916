export const URL_PREFIX = process.env.REACT_APP_URL_PREFIX;

const URL = {
  REGISTER: URL_PREFIX + "/register",
  LOGIN: URL_PREFIX + "/login",
  CREATE_PASSWORD: URL_PREFIX + "/password/create",
  VALIDATE_PASSWORD: URL_PREFIX + "/otp/validate",
  VALIDATE_OTP: URL_PREFIX + "/otp/validate",
  REQUEST_OTP: URL_PREFIX + "/otp",
  RESET_PASSWORD: URL_PREFIX + "/password/reset",
  ONBOARD: URL_PREFIX + "/recruiter",
  UPLOAD_FILE: URL_PREFIX + "file/upload",
};

export default URL;
