import moment from "moment";

const parse = (response: any) => {
  const blogs = response.map((item) => {
    const parsedData: any = {};
    parsedData.id = item.id;
    parsedData.featureImage = item["fimg_url"];
    parsedData.date = moment(item.date, "YYYY-MM-DD").format("Do MMMM YYYY");
    parsedData.title = item["title"]["rendered"];
    parsedData.excerpt = item["excerpt"]["rendered"];
    parsedData.link = item["link"];

    return parsedData;
  });

  return blogs;
};

export default parse;
