import { useState } from "react";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { setActiveLink } from "../../redux/actions/menu";
import {
  HOME,
  CONTESTS,
  ABOUT_US,
  RECRUITERS,
  EMPLOYERS,
  PARTNERS,
  HOTLIST,
  LOGIN,
} from "../../utils/constants/routes";
import styles from "./style.module.scss";

const showEmployersMenu = process.env.REACT_APP_SHOW_EMPLOYERS_MENU;
const showRecruitersMenu = process.env.REACT_APP_SHOW_RECRUITERS_MENU;
const showPartnersMenu = process.env.REACT_APP_SHOW_PARTNERS_MENU;

const menus = [
  {
    label: "Home",
    link: HOME,
  },
  {
    label: "Hiring Contests",
    link: CONTESTS,
  },
  {
    label: "Hot-list",
    link: HOTLIST,
  },
  {
    label: "About Us",
    link: ABOUT_US,
  },
];

if (showEmployersMenu === "true") {
  menus.splice(menus.length - 1, 0, {
    label: "Employers",
    link: EMPLOYERS,
  });
}
if (showRecruitersMenu === "true") {
  menus.splice(menus.length - 1, 0, {
    label: "Partners",
    link: PARTNERS,
  });
}
if (showPartnersMenu === "true") {
  menus.splice(menus.length - 1, 0, {
    label: "Recruiters",
    link: RECRUITERS,
  });
}
const Menu = () => {
  const activeLink = useSelector(
    (state: RootStateOrAny) => state.menu.activeLink
  );
  const dispatch = useDispatch();

  const onMenuItemClick = (label: string) => {
    dispatch(setActiveLink(label));
  };

  const [open, setOpen] = useState(false);

  return (
    <>
      <div className={styles["menu-items"]}>
        {menus.map((menu, index) => {
          return (
            <div
              key={index}
              className={`${styles["menu-item"]} ${
                activeLink === menu.label ? styles["menu-item-active"] : ""
              }`}
            >
              {menu.link && (
                <Link
                  to={menu.link}
                  className={styles["menu-anchor"]}
                  onClick={() => onMenuItemClick(menu.label)}
                >
                  {menu.label}
                </Link>
              )}
              {!menu.link && menu.label}
            </div>
          );
        })}
      </div>
      <div
        className={styles["menu-icon"]}
        onClick={() => {
          setOpen(true);
        }}
      >
        <MenuIcon sx={{ fontSize: 30 }} />
      </div>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Box
          sx={{ width: 270 }}
          role="presentation"
          onClick={() => {
            setOpen(false);
          }}
          onKeyDown={() => {
            setOpen(false);
          }}
        >
          {" "}
          {menus.map((menu, index) => {
            return (
              <Link
                key={index}
                to={menu.link}
                className={styles["menu-anchor"]}
                onClick={() => onMenuItemClick(menu.label)}
              >
                <div className={styles["mobile-menu-item"]}>{menu.label}</div>
              </Link>
            );
          })}
          <Link to={LOGIN} className={styles["menu-anchor"]}>
            <div className={styles["mobile-menu-item"]}>Sign In</div>
          </Link>
        </Box>
      </Drawer>
    </>
  );
};

export default Menu;
