import moment from "moment";

const getFormattedDate = (dateToConvert: any) => {
  const formattedDate = moment(dateToConvert, "YYYY-MM-DD").format(
    "DD MMMM, YYYY"
  );

  return formattedDate;
};

const parse = (response: any) => {
  const employer = response.data.attributes;

  const parsedEmployerDetails: any = {};

  parsedEmployerDetails.id = response.data.id;
  parsedEmployerDetails.logo = employer["employer_logo"];
  parsedEmployerDetails.coverImage = employer["employer_cover_image"];
  parsedEmployerDetails.companyName = employer["brand_name"];
  parsedEmployerDetails.brandName = employer["brand_name"];
  parsedEmployerDetails.description = employer["short_description"];
  parsedEmployerDetails.website = employer["website"];
  parsedEmployerDetails.joiningDate = moment(
    employer["employer_joining_date"],
    "YYYY-MM-DD"
  ).format("DD.MM.YYYY");
  parsedEmployerDetails.numberOfEmployees = employer[
    "no_of_employees"
  ].toLocaleString("en-IN");
  parsedEmployerDetails.source = employer["source"];
  parsedEmployerDetails.contestsConducted = employer["contest_conducted"];
  parsedEmployerDetails.candidatesPlaced = employer["no_of_candidates_placed"];
  parsedEmployerDetails.yearOfRegistration =
    employer["employer_year_of_registration"];
  parsedEmployerDetails.tags =
    employer["tags"] && employer["tags"]["employer-tag"]
      ? employer["tags"]["employer-tag"]
      : [];
  parsedEmployerDetails.openPositions = employer["no_of_positions"];
  parsedEmployerDetails.hiringDuratioon =
    employer["average_days_to_close_a_position"];
  parsedEmployerDetails.faq = employer["frequently_asked_questions"];
  parsedEmployerDetails.socialMediaHandles =
    employer["all_social_media_handles"];
  parsedEmployerDetails.skills =
    employer["skills_hiring_for"] && employer["skills_hiring_for"];
  parsedEmployerDetails.companyStats = {};
  parsedEmployerDetails.companyStats.numberOfEmployees = `${employer[
    "no_of_employees"
  ].toLocaleString("en-IN")}+ Employees`;
  parsedEmployerDetails.companyStats.hireDuration = `Actively hires within ${employer["average_days_to_close_a_position"]} days`;
  parsedEmployerDetails.companyStats.techStack =
    employer["skills_hiring_for"] && employer["skills_hiring_for"].length > 0
      ? `Mostly hires for ${employer["skills_hiring_for"].toString()}`
      : "";
  parsedEmployerDetails.companyStats.partnership = `Partnered with Hiringhood on ${getFormattedDate(
    employer["employer_joining_date"]
  )}`;
  parsedEmployerDetails.companyStats.locations =
    employer["employer_locations"] && employer["employer_locations"].length > 0
      ? employer["employer_locations"].toString()
      : "";
  parsedEmployerDetails.benefitsOffered =
    employer["benefits"] && employer["benefits"].length > 0
      ? employer["benefits"]
      : [];
  parsedEmployerDetails.address = {
    line1: employer["official_address"]
      ? employer["official_address"]["official_address_line_1"]
      : "",
    line2: employer["official_address"]
      ? employer["official_address"]["official_address_line_2"]
      : "",
    pincode: employer["official_address"]
      ? employer["official_address"]["pincode"]
      : "",
    city: employer["official_address"]
      ? employer["official_address"]["city"]
      : "",
    state: employer["official_address"]
      ? employer["official_address"]["state"]
      : "",
    country: employer["official_address"]
      ? employer["official_address"]["country"]
      : "",
    isdCode: employer["official_address"]
      ? employer["official_address"]["isd_code"]
      : "",
  };

  return parsedEmployerDetails;
};

export default parse;
