import { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import shareImage from "../../assets/share.svg";
import styles from "./styles.module.scss";

interface Props {
  url: string;
}

const Share: React.FC<Props> = (props) => {
  const { url } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <img
        src={shareImage}
        alt="Share"
        onClick={handleClick}
        className={styles["share-icon"]}
        title="Share"
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "Share",
        }}
        className="share-menu"
      >
        <MenuItem
          onClick={handleClose}
          className={styles["share-menu-item-container"]}
        >
          <FacebookShareButton url={url} className={styles["share-menu-item"]}>
            <FacebookIcon size={32} />
            <p className={styles["share-menu-item-text"]}>Facebook</p>
          </FacebookShareButton>
        </MenuItem>
        <MenuItem
          onClick={handleClose}
          className={styles["share-menu-item-container"]}
        >
          <TwitterShareButton url={url} className={styles["share-menu-item"]}>
            <TwitterIcon size={32} />
            <p className={styles["share-menu-item-text"]}>Twitter</p>
          </TwitterShareButton>
        </MenuItem>
        <MenuItem
          onClick={handleClose}
          className={styles["share-menu-item-container"]}
        >
          <LinkedinShareButton url={url} className={styles["share-menu-item"]}>
            <LinkedinIcon size={32} />
            <p className={styles["share-menu-item-text"]}>LinkedIn</p>
          </LinkedinShareButton>
        </MenuItem>
      </Menu>
    </>
  );
};

export default Share;
