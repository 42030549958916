import Card from "../Card";
import { RECRUITER_TYPES } from "../../utils/constants";
import industryImage from "../../assets/industry.svg";
import laptopImage from "../../assets/laptop.svg";
import levelsImage from "../../assets/certificate-badge.svg";
import skillsImage from "../../assets/skills.svg";
import locationImage from "../../assets/location.svg";
import styles from "./style.module.scss";

type RecruitersType = typeof RECRUITER_TYPES[number];

interface RecruiterDetails {
  type: RecruitersType;
  description: string;
  industry: string;
  roles: string;
  levels: string;
  skills: string;
  locations: string;
}

interface Props {
  recruiterDetails: RecruiterDetails;
}

const AboutRecruiter: React.FC<Props> = (props) => {
  const { recruiterDetails } = props;
  const {
    type,
    description,
    industry,
    roles,
    levels,
    skills,
    locations,
  } = recruiterDetails;

  const renderDetails = (icon, label, text) => {
    return (
      <>
        {text && (
          <div className={styles["about-recruiter-icon-container"]}>
            <div className={styles["about-recruiter-icon-image-container"]}>
              <img src={icon} alt={label} />
              <p className={styles["about-recruiter-icons-container-label"]}>
                {label}
              </p>
            </div>

            <p className={styles["about-recruiter-icons-container-text"]}>
              {text}
            </p>
          </div>
        )}
      </>
    );
  };

  return (
    <Card>
      <div className={styles["about-recruiter-container"]}>
        <div
          className={styles["about-recruiter-description"]}
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
        <div className={styles["about-recruiter-specialization"]}>
          <p className={styles["about-recruiter-hire-for"]}>
            {type === RECRUITER_TYPES[0] ? "I hire for" : "We hire for"}
          </p>
          <div className={styles["about-recruiter-icons-container"]}>
            {renderDetails(industryImage, "Industry", industry)}
            {renderDetails(laptopImage, "Roles", roles)}
            {renderDetails(levelsImage, "Levels", levels)}
            {renderDetails(skillsImage, "Skills", skills)}
            {renderDetails(locationImage, "Locations", locations)}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default AboutRecruiter;
