import { useState } from "react";
import { ReactComponent as RcImage } from "../../assets/recruitement-company.svg";
import { ReactComponent as FrImage } from "../../assets/freelance-recruiter.svg";
import Checkbox from "../Checkbox";
import "./styles.scss";
import { RECRUITMENT_COMPANY, FREELANCER } from "../../utils/constants/index";

interface Props {
  radioState: string;
  onClick: (string) => void;
  preSelected?: string;
}

const OnboardOptions = (props: Props): JSX.Element => {
  const { radioState, onClick, preSelected } = props;
  const [selected, setSelected] = useState(radioState);

  const onChange = (event, type) => {
    if (preSelected && preSelected === selected) {
      event.preventDefault();
      return;
    }
    setSelected(type);
    onClick(type);
  };

  return (
    <div className="onboard-selection-container">
      <div
        className="onboard-option-group"
        aria-disabled={preSelected && preSelected !== RECRUITMENT_COMPANY}
        onClick={(event) => onChange(event, RECRUITMENT_COMPANY)}
      >
        <Checkbox
          className="onboard-option-checkbox"
          value={RECRUITMENT_COMPANY}
          name={RECRUITMENT_COMPANY}
          checked={selected === RECRUITMENT_COMPANY}
          onChange={(event) => onChange(event, RECRUITMENT_COMPANY)}
          disabled={preSelected && preSelected !== RECRUITMENT_COMPANY}
        />
        <div
          className={
            selected === RECRUITMENT_COMPANY
              ? "onboard-option-div-active"
              : "onboard-option-div"
          }
        >
          <RcImage
            className={
              selected === RECRUITMENT_COMPANY
                ? "onboard-option-image-active"
                : "onboard-option-image"
            }
          />
        </div>
        <label
          className={
            selected === RECRUITMENT_COMPANY
              ? "onboard-option-label-active"
              : "onboard-option-label"
          }
        >
          Recruitment Company
        </label>
      </div>
      <div
        className="onboard-option-group"
        aria-disabled={preSelected && preSelected !== FREELANCER}
        onClick={(event) => onChange(event, FREELANCER)}
      >
        <Checkbox
          className="onboard-option-checkbox"
          value={FREELANCER}
          name={FREELANCER}
          checked={selected === FREELANCER}
          onChange={(event) => onChange(event, FREELANCER)}
          disabled={preSelected && preSelected !== FREELANCER}
        />
        <div
          className={
            selected === FREELANCER
              ? "onboard-option-div-active"
              : "onboard-option-div"
          }
        >
          <FrImage
            className={
              selected === FREELANCER
                ? "onboard-option-image-active"
                : "onboard-option-image"
            }
          />
        </div>
        <label
          className={
            selected === FREELANCER
              ? "onboard-option-label-active"
              : "onboard-option-label"
          }
        >
          Freelance Recruiter
        </label>
      </div>
    </div>
  );
};

export default OnboardOptions;
