import { useState } from "react";
import { useLocation } from "react-router-dom";
import Card from "../Card";
import FAQ from "../FAQ";
import EmployerDetails from "../EmployerDetails";
import rewardsBadge from "../../assets/rewards-badge.svg";
import groupsBadge from "../../assets/group-badge.svg";
import prizeBadge from "../../assets/prize-badge.svg";
import resumeBadge from "../../assets/resume-badge.svg";
import "./styles.css";

interface Props {
  employerDetails?: any;
}

const EmployerTabs: React.FC<Props> = (props) => {
  const { employerDetails } = props;
  const location: any = useLocation();

  const tabs = ["Employer Details", "Social Media Handles", "FAQs"];

  const activeTab = location && location.state ? location.state.activeTab : 0;

  const [activeTabIndex, setActiveTabIndex] = useState(activeTab);

  const renderSocialMediaHandles = () => {
    if (
      employerDetails.socialMediaHandles &&
      employerDetails.socialMediaHandles.length > 0
    ) {
      return (
        <>
          <p className="employer-tabs-social-media-handle-title">
            Social Media Handles
          </p>
          <>
            {employerDetails.socialMediaHandles.map(
              (socialMediaHandle, index) => {
                return (
                  <>
                    <div
                      key={socialMediaHandle["social_handler_title"]}
                      className="employer-tabs-social-media-handle-section"
                    >
                      <p className="employer-tabs-social-media-handle-section-title">
                        {socialMediaHandle["social_handler_title"]}
                      </p>
                      <div className="employer-tabs-social-media-handle-stats-container">
                        {socialMediaHandle["social_handler_stats"].map(
                          (stat) => {
                            return (
                              <div
                                key={stat["stat_title"]}
                                className="employer-tabs-social-media-handle-stat-container"
                              >
                                <img
                                  src={stat["stat_icon"]}
                                  alt={stat["stat_title"]}
                                />
                                <div className="employer-tabs-social-media-handle-stat-count-container">
                                  <p className="employer-tabs-social-media-handle-stat-count">
                                    {Number(stat["stat_count"])
                                      ? Number(
                                          stat["stat_count"]
                                        ).toLocaleString("en-IN")
                                      : stat["stat_count"]}
                                  </p>
                                  <p className="employer-tabs-social-media-handle-stat-text">
                                    {stat["stat_title"]}
                                  </p>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                    {index !==
                      employerDetails.socialMediaHandles.length - 1 && (
                      <hr className="employer-tabs-social-media-handle-separator" />
                    )}
                  </>
                );
              }
            )}
          </>
        </>
      );
    }

    return (
      <p className="employer-tabs-no-social-media-handles">
        This employer does not have any social media handles
      </p>
    );
  };

  return (
    <>
      <Card>
        <div className="employer-tabs-container">
          {tabs.map((tab, index) => {
            return (
              <div
                key={index}
                className={`employer-tabs-tab ${
                  activeTabIndex === index ? "employer-tabs-active-tab" : ""
                }`}
              >
                <p
                  className={`employer-tabs-text ${
                    activeTabIndex === index ? "employer-tabs-active-text" : ""
                  }`}
                  onClick={() => {
                    setActiveTabIndex(index);
                  }}
                  tabIndex={0}
                  onKeyDown={(event) => {
                    event.keyCode === 13 && setActiveTabIndex(index);
                  }}
                >
                  {tab}
                </p>
              </div>
            );
          })}
        </div>
      </Card>
      {(activeTabIndex === 1 || activeTabIndex === 2) && (
        <div className="employer-tab-container">
          {activeTabIndex === 1 && <>{renderSocialMediaHandles()}</>}
          {activeTabIndex === 2 && <FAQ faq={employerDetails.faq} />}
        </div>
      )}
      {activeTabIndex === 0 && (
        <div className="employer-tabs-details-container">
          <EmployerDetails employerDetails={employerDetails} />
        </div>
      )}
      {activeTabIndex === 0 && (
        <div className="employer-tabs-metrics">
          <div className="employer-tabs-icon-container">
            <img src={rewardsBadge} alt="Number of Positions" />
            <div className="employer-tabs-icon-text-container">
              <p className="employer-tabs-icon-text">
                {employerDetails.openPositions}
              </p>
              <p className="employer-tabs-icon-label">No of positions</p>
            </div>
          </div>
          <div className="employer-tabs-icon-container">
            <img src={groupsBadge} alt="Avg Duration" />
            <div className="employer-tabs-icon-text-container">
              <p className="employer-tabs-icon-text">
                {employerDetails.hiringDuratioon}
              </p>
              <p className="employer-tabs-icon-label">
                Average days to close a position
              </p>
            </div>
          </div>
          <div className="employer-tabs-icon-container">
            <img src={prizeBadge} alt="Contests Conducted" />
            <div className="employer-tabs-icon-text-container">
              <p className="employer-tabs-icon-text">
                {employerDetails.contestsConducted}
              </p>
              <p className="employer-tabs-icon-label">Contest(s) conducted</p>
            </div>
          </div>
          <div className="employer-tabs-icon-container">
            <img src={resumeBadge} alt="Candidates Placed" />
            <div className="employer-tabs-icon-text-container">
              <p className="employer-tabs-icon-text">
                {employerDetails.candidatesPlaced}
              </p>
              <p className="employer-tabs-icon-label">
                Number of job seeker(s) placed
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EmployerTabs;
