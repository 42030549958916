import Button from "@mui/material/Button";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    span: {},
  })
);

export default function CustomizedButtons(props) {
  const classes = useStyles();
  return (
    <Button
      variant="text"
      component="span"
      className={classes.span}
      {...props}
      size="small"
      style={{
        textTransform: "none",
        color: !props.disabled && "#4D6CD9",
        fontSize: "1rem",
        width: "9.375rem",
      }}
    ></Button>
  );
}
