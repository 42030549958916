import { useState } from "react";
import { Link } from "react-router-dom";
import Button from "../Button";
import Card from "../Card";
import Share from "../Share";
import Tooltip from "../Tooltip";
import { RECRUITER_TYPES } from "../../utils/constants";
import { RECRUITER_DETAILS } from "../../utils/constants/routes";
import awardImage from "../../assets/award.svg";
import starImage from "../../assets/star.svg";
import trophyImage from "../../assets/trophy.svg";
import positionsImage from "../../assets/user-closed.svg";
import freelanceRecruiterImage from "../../assets/recruiter-avatar.svg";
import targetImage from "../../assets/target.svg";
import badgesImage from "../../assets/certificate-badge.svg";
import experienceImage from "../../assets/experience.svg";
import prizeImage from "../../assets/prize.svg";
import trophyCircleImage from "../../assets/trophy-circle.svg";
import badgesCircleImage from "../../assets/certificate-badge-circle.svg";
import hiringhoodImage from "../../assets/hiringhood.svg";
import styles from "./styles.module.scss";

type RecruitersType = typeof RECRUITER_TYPES[number];

interface RecruiterDetails {
  id: number;
  name: string;
  location: string;
  type: RecruitersType;
  rank: number;
  bountyEarned: string;
  rating: number;
  positionsClosed: number;
  hitRatio: string;
  contestsWon: number;
  experience?: number;
  competitionsParticipated?: number;
  numberOfRecruiters?: number;
  profileImage?: string;
}

interface Props {
  recruiterDetails: RecruiterDetails;
}

const RecruiterProfileCard: React.FC<Props> = (props) => {
  const { recruiterDetails } = props;
  const {
    id,
    name,
    location,
    type,
    rank,
    rating,
    bountyEarned,
    positionsClosed,
    hitRatio,
    contestsWon,
    experience,
    competitionsParticipated,
    numberOfRecruiters,
    profileImage,
  } = recruiterDetails;
  const [hovered, setHovered] = useState(false);

  const urlToShare = `www.hiringhood.com/recruiter/`;

  const renderTooltipForBadges = () => {
    return (
      <>
        {competitionsParticipated > 0 && (
          <div
            className={
              styles["recruiter-profile-card-stats-container-tootltip"]
            }
          >
            <img src={trophyCircleImage} alt="Competitions Participated" />
            <p
              className={
                styles["recruiter-profile-card-stats-container-tootltip-text"]
              }
            >
              {competitionsParticipated} Competitions Participated
            </p>
          </div>
        )}
        {positionsClosed > 0 && (
          <div
            className={
              styles["recruiter-profile-card-stats-container-tootltip"]
            }
          >
            <img src={badgesCircleImage} alt="Positions Closed" />
            <p
              className={
                styles["recruiter-profile-card-stats-container-tootltip-text"]
              }
            >
              {positionsClosed} Positions Closed
            </p>
          </div>
        )}
        {contestsWon > 0 && (
          <div
            className={
              styles["recruiter-profile-card-stats-container-tootltip"]
            }
          >
            <img src={prizeImage} alt="Contests Won" />
            <p
              className={
                styles["recruiter-profile-card-stats-container-tootltip-text"]
              }
            >
              {contestsWon} Contests Won
            </p>
          </div>
        )}
      </>
    );
  };

  return (
    <div
      className={`${styles["recruiter-profile-card-wrapper"]} ${
        hovered ? styles["recruiter-profile-card-wrapper-hovered"] : ""
      }`}
    >
      <Card>
        <div
          onMouseEnter={() => {
            setHovered(true);
          }}
          onMouseLeave={() => {
            setHovered(false);
          }}
          onTouchStart={() => {
            setHovered(true);
          }}
          onTouchEnd={() => {
            setHovered(false);
          }}
          className={`${styles["recruiter-profile-card-container"]} ${
            hovered ? styles["recruiter-profile-card-container-hovered"] : ""
          }`}
        >
          <div className={styles["recruiter-profile-card-container-share"]}>
            <Share url={`${urlToShare}${id}`} />
          </div>
          <div
            className={
              styles["recruiter-profile-card-profile-details-container"]
            }
          >
            <img
              src={profileImage ? profileImage : freelanceRecruiterImage}
              alt="Avatar"
              className={styles["recruiter-profile-card-profile-image"]}
            />
            <div
              className={styles["recruiter-profile-card-name-container"]}
              title={name}
            >
              <p className={styles["recruiter-profile-card-name"]}>{name}</p>
            </div>
            <div
              className={styles["recruiter-profile-card-location-container"]}
              title={location}
            >
              <p className={styles["recruiter-profile-card-location"]}>
                {location}
              </p>
            </div>
            <p className={styles["recruiter-profile-card-type"]}>{type}</p>
          </div>
          <div
            className={styles["recruiter-profile-card-profile-icons-container"]}
          >
            <div>
              <div
                className={
                  styles["recruiter-profile-card-profile-icon-container"]
                }
              >
                <img src={awardImage} alt="Rank" />
                <div>
                  <p
                    className={
                      styles[
                        "recruiter-profile-card-profile-icons-container-text"
                      ]
                    }
                  >
                    {rank < 10 ? `0${rank}` : rank}
                  </p>
                  <p
                    className={
                      styles[
                        "recruiter-profile-card-profile-icons-container-label"
                      ]
                    }
                  >
                    Rank
                  </p>
                </div>
              </div>
              <div
                className={`${styles["recruiter-profile-card-profile-icon-container"]} ${styles["recruiter-profile-card-profile-icons-container-2"]}`}
              >
                <img src={trophyImage} alt="Bounty Earned" />
                <div>
                  <p
                    className={
                      styles[
                        "recruiter-profile-card-profile-icons-container-text"
                      ]
                    }
                  >
                    {bountyEarned}
                  </p>
                  <p
                    className={
                      styles[
                        "recruiter-profile-card-profile-icons-container-label"
                      ]
                    }
                  >
                    Total Bounty Earned
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div
                className={
                  styles["recruiter-profile-card-profile-icon-container"]
                }
              >
                <img src={starImage} alt="Rating" />
                <div>
                  <p
                    className={
                      styles[
                        "recruiter-profile-card-profile-icons-container-text"
                      ]
                    }
                  >
                    {rating}
                  </p>
                  <p
                    className={
                      styles[
                        "recruiter-profile-card-profile-icons-container-label"
                      ]
                    }
                  >
                    Rating
                  </p>
                </div>
              </div>
              <div
                className={`${styles["recruiter-profile-card-profile-icon-container"]} ${styles["recruiter-profile-card-profile-icons-container-2"]}`}
              >
                <img src={positionsImage} alt="Positions Closed" />
                <div>
                  <p
                    className={
                      styles[
                        "recruiter-profile-card-profile-icons-container-text"
                      ]
                    }
                  >
                    {positionsClosed}
                  </p>
                  <p
                    className={
                      styles[
                        "recruiter-profile-card-profile-icons-container-label"
                      ]
                    }
                  >
                    Positions Closed
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles["recruiter-profile-card-stats-wrapper"]}>
            <div className={styles["recruiter-profile-card-stats-container"]}>
              <div
                className={
                  styles["recruiter-profile-card-stats-image-container"]
                }
              >
                <img src={targetImage} alt="Hit Ratio" />
                <p
                  className={styles["recruiter-profile-card-stats-image-text"]}
                >
                  Hit Ratio:
                </p>
              </div>
              <p
                className={
                  styles["recruiter-profile-card-stats-container-text"]
                }
              >
                {hitRatio}
              </p>
            </div>
            <div className={styles["recruiter-profile-card-stats-container"]}>
              <div
                className={
                  styles["recruiter-profile-card-stats-image-container"]
                }
              >
                <img src={trophyImage} alt="Contests Won" />
                <p
                  className={styles["recruiter-profile-card-stats-image-text"]}
                >
                  Contests Won:
                </p>
              </div>
              <p
                className={
                  styles["recruiter-profile-card-stats-container-text"]
                }
              >
                {contestsWon}
              </p>
            </div>
            <div className={styles["recruiter-profile-card-stats-container"]}>
              <div
                className={
                  styles["recruiter-profile-card-stats-image-container"]
                }
              >
                <img src={badgesImage} alt="Badges" />
                <p
                  className={styles["recruiter-profile-card-stats-image-text"]}
                >
                  Badges:
                </p>
              </div>
              <Tooltip variant="outlined" title={renderTooltipForBadges()}>
                <>
                  {competitionsParticipated > 0 && (
                    <img
                      src={trophyCircleImage}
                      alt="Competitions Participated"
                      className={
                        styles["recruiter-profile-card-stats-container-icon"]
                      }
                    />
                  )}
                  {positionsClosed > 0 && (
                    <img
                      src={badgesCircleImage}
                      alt="Positions Closed"
                      className={
                        styles["recruiter-profile-card-stats-container-icon"]
                      }
                    />
                  )}
                  {contestsWon > 0 && (
                    <img
                      src={prizeImage}
                      alt="Contests Won"
                      className={
                        styles["recruiter-profile-card-stats-container-icon"]
                      }
                    />
                  )}
                </>
              </Tooltip>
            </div>
            {experience && (
              <div className={styles["recruiter-profile-card-stats-container"]}>
                <div
                  className={
                    styles["recruiter-profile-card-stats-image-container"]
                  }
                >
                  <img src={experienceImage} alt="Experience" />
                  <p
                    className={
                      styles["recruiter-profile-card-stats-image-text"]
                    }
                  >
                    Experience:
                  </p>
                </div>
                <p
                  className={
                    styles["recruiter-profile-card-stats-container-text"]
                  }
                >
                  {experience !== 1
                    ? `${experience} Years`
                    : `${experience} Year`}
                </p>
              </div>
            )}
            {numberOfRecruiters && (
              <div className={styles["recruiter-profile-card-stats-container"]}>
                <div
                  className={
                    styles["recruiter-profile-card-stats-image-container"]
                  }
                >
                  <img src={hiringhoodImage} alt="Hiringhood" />
                  <p
                    className={
                      styles["recruiter-profile-card-stats-image-text"]
                    }
                  >
                    Recruiters on Hiringhood:
                  </p>
                </div>
                <p
                  className={
                    styles["recruiter-profile-card-stats-container-text"]
                  }
                >
                  {numberOfRecruiters}
                </p>
              </div>
            )}
          </div>
          <Link to={RECRUITER_DETAILS.replace(":id", `${id}`)}>
            <Button
              label="View Profile"
              className={styles["recruiter-profile-view-profile-button"]}
            />
          </Link>
        </div>
      </Card>
    </div>
  );
};

export default RecruiterProfileCard;
