import TitleMultiColor from "../TitleMultiColor";
import { clients } from "../../utils/constants/site-data";
import styles from "./style.module.scss";

const labels = ["Our", "Clients"];
const colors = ["#626880", "#4D6CD9"];

const OurClients = () => {
  const handleClick = (url) => {
    window.open(url);
  };

  return (
    <>
      <div className={styles["our-clients-title"]}>
        <TitleMultiColor labels={labels} colors={colors} />
      </div>
      <div className={styles["our-clients-clients"]}>
        {clients.map((client) => {
          return (
            <div
              key={client.name}
              className={styles["our-clients-client"]}
              onClick={() => {
                handleClick(client.website);
              }}
              title={client.name}
              style={{
                background: client.background ? client.background : null,
              }}
            >
              <img
                src={`${process.env.REACT_APP_PUBLIC_BUCKET_URL}${client.logo}`}
                alt={client.name}
                className={styles["our-clients-client-logo"]}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default OurClients;
