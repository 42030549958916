import { useEffect } from "react";
import { Route, Switch } from "react-router";
import { Router, useHistory, withRouter } from "react-router-dom";
import tawkTo from "tawkto-react";
import TagManager from "react-gtm-module";
import * as Routes from "./utils/constants/routes";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Contests from "./pages/Contests";
import Contest from "./pages/Contest";
import Recruiters from "./pages/Recruiters";
import RecruiterDetails from "./pages/RecruiterDetails";
import Login from "./pages/Login";
import Signup from "./pages/SignUp";
import Error from "./components/Error";
import AboutUs from "./pages/AboutUs";
import { useDispatch } from "react-redux";
import { setAuthenticationStatus } from "./redux/actions/login";
import Onboarding from "./pages/Onboarding";
import EditProfile from "./pages/EditProfile";
import { getLocalStorage, resetLocalStorage } from "./utils/storage-manager";
import FAQPage from "./pages/FAQ";
import Employers from "./pages/Employers";
import Employer from "./pages/Employer";
import Partner from "./pages/Partner";
import Partners from "./pages/Partners";
import Hotlist from "./pages/Hotlist";
import Logout from "./pages/Logout";
import PersonaRegistrationPage from "./pages/Persona";

const tawkToPropertyId = process.env.REACT_APP_TAWK_TO_PROP_ID;
const tawkToKey = process.env.REACT_APP_TAWK_TO_KEY;

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
};

function App(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const url = window.location.pathname.split("/").pop();

  useEffect(() => {
    tawkTo(tawkToPropertyId, tawkToKey);
    TagManager.initialize(tagManagerArgs);
  }, []);

  useEffect(() => {
    if (getLocalStorage("token", null)) {
      dispatch(setAuthenticationStatus(true));
    } else {
      dispatch(setAuthenticationStatus(false));

      resetLocalStorage("user");
      resetLocalStorage("userId");
      resetLocalStorage("userRole");
      resetLocalStorage("userStage");
      resetLocalStorage("inviteSource");
      resetLocalStorage("profilePic");
    }
  }, [url]);

  const fullScreenRoutes = [
    Routes.LOGIN,
    Routes.SIGNUP,
    Routes.HOME,
    Routes.ABOUT_US,
    Routes.TERMS_OF_USE,
    Routes.PRIVACY_POLICY,
  ];

  return (
    <Router history={history}>
      {fullScreenRoutes.includes(props.location.pathname) && (
        <Switch>
          <Route exact path={Routes.LOGIN} component={Login} />
          <Route exact path={Routes.SIGNUP} component={Signup} />
          <Route exact path={Routes.HOME} component={Home} />
          <Route exact path={Routes.ABOUT_US} component={AboutUs} />
        </Switch>
      )}
      {!fullScreenRoutes.includes(props.location.pathname) && (
        <div className="wrapper">
          <Header />
          <div className="main">
            <Switch>
              <Route exact path={Routes.CONTESTS} component={Contests} />
              <Route exact path={Routes.CONTEST_DETAILS} component={Contest} />
              <Route exact path={Routes.RECRUITERS} component={Recruiters} />
              <Route exact path={Routes.HOTLIST} component={Hotlist} />
              <Route
                exact
                path={Routes.RECRUITER_DETAILS}
                component={RecruiterDetails}
              />
              <Route exact path={Routes.EMPLOYERS} component={Employers} />
              <Route
                exact
                path={Routes.EMPLOYER_DETAILS}
                component={Employer}
              />
              <Route exact path={Routes.PARTNERS} component={Partners} />
              <Route exact path={Routes.PARTNER_DETAILS} component={Partner} />
              <Route exact path={Routes.ON_BOARDING} component={Onboarding} />
              <Route exact path={Routes.EDIT_PROFILE} component={EditProfile} />
              <Route exact path={Routes.FAQ} component={FAQPage} />
              <Route exact path={Routes.LOGOUT} component={Logout} />
              <Route exact path={Routes.PERSONA_REGISTRATION} component={PersonaRegistrationPage} />
              <Route path="*" component={Error} />
            </Switch>
          </div>
          <Footer />
        </div>
      )}
    </Router>
  );
}

export default withRouter(App);
