import { useState } from "react";
import { useHistory } from "react-router-dom";
import "./styles.scss";
import background from "../../assets/login-background.svg";
import Logo from "../../assets/logo2.svg";
import OnboardingForm from "../../components/OnboardingForm";
import Status from "../../components/Status";
import Card from "../../components/Card";
import { RECRUITMENT_COMPANY } from "../../utils/constants/index";
import { EDIT_PROFILE, HOME } from "../../utils/constants/routes";
import { useSelector } from "react-redux";
import { signUpUserInfoSelector } from "../../redux/selectors";

const Onboarding = () => {
  const [page, setPage] = useState("CreateAccount");
  const [selected, setSelected] = useState(RECRUITMENT_COMPANY);
  const history = useHistory();

  const userInfo = useSelector(signUpUserInfoSelector);
  const isOnboardingViaEmailInvite =
    userInfo && userInfo.company ? true : false;

  const gotoNextPage = (currentPage: string) => {
    currentPage === "Onboard" && setPage("CreateAccount");
    currentPage === "CreateAccount" && setPage("OnSuccess");
    if (currentPage === "OnSuccess") {
      isOnboardingViaEmailInvite ? setPage("Home") : setPage("EditProfile");
    }
  };

  const onBoardFormData = {
    firstname: userInfo ? userInfo.name : "",
    lastname: "",
    email: userInfo ? userInfo.invitee_email : "",
    phoneExt: userInfo ? userInfo.phoneExt : "+91",
    phone: userInfo ? userInfo.phoneNumber : "",
    altPhone: "",
    surveyAnswer: "",
    surveyAnswerOther: "",
    linkedIn: "",
    companyId: userInfo ? userInfo.company : "",
  };
  const renderPage = () => {
    if (page === "CreateAccount") {
      return (
        <OnboardingForm
          callbackFunction={gotoNextPage}
          formData={onBoardFormData}
          onboardAs={selected}
        />
      );
    }
    if (page === "OnSuccess") {
      return (
        <Status
          onNext={gotoNextPage}
          message="Your account has been successfully created"
          buttonLabel={
            isOnboardingViaEmailInvite
              ? "Go to Home page"
              : "Edit Profile Details"
          }
        />
      );
    }
    if (page === "EditProfile") {
      history.push(EDIT_PROFILE);
      return;
    }
    if (page === "Home") {
      history.push(HOME);
      return;
    }
  };

  return (
    <div>
      <div className="onboarding-banner-container">
        <img
          className="onboarding-banner"
          style={{
            backgroundImage: `url(${background})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        />
        <div className="onboarding-logo-container">
          <img className="onboarding-logo" src={Logo} alt="Hiringhood Logo" />
        </div>
      </div>

      <div className="onboarding-background">
        <div className="onboarding-card">
          <Card>{renderPage()}</Card>
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
