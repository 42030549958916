import { Carousel } from "react-responsive-carousel";
import { whatMakesUsDifferentArray } from "../../utils/constants/site-data";
import InfoCard from "../InfoCard";
import "./styles.scss";
const WhatMakesUsDifferent = () => {
  return (
    <Carousel
      showIndicators={false}
      autoPlay={false}
      infiniteLoop={true}
      stopOnHover={true}
      showArrows={false}
      centerMode={true}
    >
      {whatMakesUsDifferentArray.map((item, index) => (
        <div className="what-makes-us-different-slide" key={index}>
          <InfoCard
            icon={item.icon}
            title={item.metric}
            description={item.description}
            width="35.1875rem"
            height="17.125rem"
          />
        </div>
      ))}
    </Carousel>
  );
};
export default WhatMakesUsDifferent;
