export const GET_CONTESTS = "GET_CONTESTS";
export const SET_CONTESTS = "SET_CONTESTS";

export const getContests = () => {
  return {
    type: GET_CONTESTS,
  };
};

export const setContests = (data) => {
  return {
    type: SET_CONTESTS,
    payload: data,
  };
};
