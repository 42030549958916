import Accordion from "../Accordion";
import "./styles.css";

const FAQ = (props) => {
  const { hideTitle = false, faq = [] } = props;
  return (
    <div className="faq-container">
      {!hideTitle && <p className="faq-title">Frequently Asked Questions</p>}
      <div className="faq-accordion-wrapper">
        {faq &&
          faq.length > 0 &&
          faq.map((item: any) => {
            return (
              <>
                {item.question && (
                  <div key={item.id} className="faq-accordion-container">
                    <Accordion title={item.question}>
                      <div
                        className="faq-answer-container"
                        dangerouslySetInnerHTML={{
                          __html: item.answer,
                        }}
                      ></div>
                    </Accordion>
                  </div>
                )}
              </>
            );
          })}
      </div>
      {faq.length === 0 && (
        <p className="faq-no-faq">There are no FAQs configured</p>
      )}
    </div>
  );
};

export default FAQ;
