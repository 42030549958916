import { post, unathorisedPost } from "./helper";
import URL from "./urls";

const registerUser = (payload) => {
  return unathorisedPost(URL.REGISTER, payload);
};

const validateOtp = (payload) => {
  return unathorisedPost(URL.VALIDATE_OTP, payload);
};

const requestOtp = (payload) => {
  const url = `${URL.REQUEST_OTP}?email=${payload.email}`;
  return unathorisedPost(url, {});
};

const login = (payload) => {
  return unathorisedPost(URL.LOGIN, payload);
};

const createPassword = (payload) => {
  return post(URL.CREATE_PASSWORD, payload);
};

const resetPassword = (payload) => {
  return post(URL.RESET_PASSWORD, payload);
};

const apis={ registerUser, validateOtp, requestOtp, createPassword ,login, resetPassword }

export default apis;
