import axios from "axios";
import { HOTLIST_API } from "../../../utils/constants/urls";

export default {
  getAll: async () => {
    const response = await axios.get(HOTLIST_API);

    return response.data;
  },

  getAnalytics: async () => {
    const response = await axios.get(`${HOTLIST_API}/buckets`);

    return response.data;
  },

  getByIds: async (ids) => {
    const response = await axios.get(`${HOTLIST_API}/bucket?js=${ids}`);

    return response.data;
  },
};
