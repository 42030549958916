import { RECRUITMENT_COMPANY, FREELANCER } from "../../utils/constants/index";

export const preparePayload = (data) => {
  const payload: any = {
    type: data.onboardAs,
    first_name: data.firstname,
    last_name: data.lastname,
    country_code: data.phoneExt,
    mobile_number: data.phone,
    alternate_number: data.altPhone,
    company_id: data.company,
    source: data.surveyAnswer,
  };
  if (data.surveyAnswer.toLowerCase() === "other") {
    payload.source = data.surveyAnswerOther;
  }
  if (data.email && data.onboardAs === RECRUITMENT_COMPANY) {
    payload.official_email_address = data.email;
  }
  if (data.linkedIn && data.onboardAs === FREELANCER) {
    payload.linkedin_profile = data.linkedIn;
  }
  return payload;
};

export const parseRecruiterInfo = (data, response) => {
  return {
    userId: response["ID"],
    userStage: response["user_stage"],
    userRole: response["role"],
    displayName: response["display_name"],
    inviteSource: response["invite_source"],
    firstName: data.firstname,
    lastName: data.lastname,
    phoneExt: data.phoneExt,
    phoneNumber: data.phone,
    altPhoneNumber: data.altPhone,
    emailAddress: data.email,
  };
};
