import Partner from "../Partner";
import amcatLogo from "../../assets/amcat.svg";
import eLitmusLogo from "../../assets/eLitmus.svg";
import hackerearthLogo from "../../assets/hackerearth.svg";
import coCubesLogo from "../../assets/coCubes.svg";
import iMochaLogo from "../../assets/imocha.svg";
import flocareerLogo from "../../assets/flocareer.svg";
import cangraTalentsLogo from "../../assets/cangraTalents.svg";
import hireProLogo from "../../assets/hirePro.svg";
import zetyLogo from "../../assets/zety.svg";
import novoResumeLogo from "../../assets/novoResume.svg";
import cvMakerLogo from "../../assets/cvMaker.svg";
import visualCVLogo from "../../assets/visualCV.svg";
import hirationLogo from "../../assets/hiration.svg";
import kickResumeLogo from "../../assets/kickResume.svg";
import "./styles.css";

const Partners = (props) => {
  const { partners = [] } = props;

  const assesmentServices = [
    {
      name: "Amcat",
      logo: amcatLogo,
      background: "#FFFFFF",
      boxShadow: "0 0 1.5rem rgba(223, 34, 47, 0.16)",
    },
    {
      name: "eLitmus",
      logo: eLitmusLogo,
      background: "#2D2727",
      boxShadow: "0 0 1.5rem rgba(77, 108, 217, 0.16)",
    },
    {
      name: "Hackerearth",
      logo: hackerearthLogo,
      background: "#FFFFFF",
      boxShadow: "0 0 1.5rem rgba(44, 54, 85, 0.16)",
    },
    {
      name: "CoCubes",
      logo: coCubesLogo,
      background: "#FFFFFF",
      boxShadow: "0 0 1.5rem rgba(174, 43, 52, 0.16)",
    },
  ];

  const interviewServices = [
    {
      name: "iMocha",
      logo: iMochaLogo,
      background: "#FFFFFF",
      boxShadow: "0 0 1.5rem rgba(77, 108, 217, 0.16)",
    },
    {
      name: "Flocareer",
      logo: flocareerLogo,
      background: "#FFFFFF",
      boxShadow: "0 0 1.5rem rgba(77, 108, 217, 0.16)",
    },
    {
      name: "Cangra Talents",
      logo: cangraTalentsLogo,
      background: "#FFFFFF",
      boxShadow: "0 0 1.5rem rgba(245, 124, 81, 0.16)",
    },
    {
      name: "Hire Pro",
      logo: hireProLogo,
      background: "#FFFFFF",
      boxShadow: "0 0 1.5rem rgba(77, 108, 217, 0.16)",
    },
  ];

  const resumeBuilderServices = [
    {
      name: "Zety",
      logo: zetyLogo,
      background: "#FFFFFF",
      boxShadow: "0 0 1.5rem rgba(73, 142, 247, 0.24)",
    },
    {
      name: "Novo Resume",
      logo: novoResumeLogo,
      background: "linear-gradient(180deg, #35ACD1 0%, #22C7AA 100%)",
      boxShadow: "0 0 1.5rem rgba(77, 108, 217, 0.16)",
    },
    {
      name: "CV Maker",
      logo: cvMakerLogo,
      background: "#19A0BE",
      boxShadow: "0 0 1.5rem rgba(77, 108, 217, 0.16)",
    },
    {
      name: "Visual CV",
      logo: visualCVLogo,
      background: "#FFFFFF",
      boxShadow: "0 0 1.5rem rgba(77, 108, 217, 0.16)",
    },
    {
      name: "Hiration",
      logo: hirationLogo,
      background: "#FFFFFF",
      boxShadow: "0 0 1.5rem rgba(237, 170, 51, 0.24)",
    },
    {
      name: "Kick Resume",
      logo: kickResumeLogo,
      background: "#FC5F5D",
      boxShadow: "0 0 1.5rem rgba(77, 108, 217, 0.16)",
    },
  ];

  const hasAssesmentServices = assesmentServices.some((service) => {
    return partners.includes(service.name);
  });

  const hasInterviewServices = interviewServices.some((service) => {
    return partners.includes(service.name);
  });

  const hasResumeBuilderServices = resumeBuilderServices.some((service) => {
    return partners.includes(service.name);
  });

  const renderPartners = (title, services) => {
    return (
      <div>
        <p className="partners-title">{title}</p>
        <div className="partners-container">
          {services.map((service) => {
            return (
              partners.includes(service.name) && (
                <Partner
                  name={service.name}
                  logo={service.logo}
                  background={service.background}
                  boxShadow={service.boxShadow}
                />
              )
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className="partners-wrapper">
      {partners.length > 0 && (
        <>
          {hasAssesmentServices && (
            <>
              {renderPartners("Assessment Services", assesmentServices)}
              <hr className="partners-separator"></hr>
            </>
          )}
          {hasInterviewServices && (
            <>
              {renderPartners("Interview as Service", interviewServices)}
              <hr className="partners-separator"></hr>
            </>
          )}
          {hasResumeBuilderServices && (
            <>{renderPartners("Resume Builder", resumeBuilderServices)}</>
          )}
        </>
      )}
      {partners.length === 0 && (
        <p className="partners-no-partners">
          There are no partners associated with this contest
        </p>
      )}
    </div>
  );
};

export default Partners;
