import { GET_CONTEST, setContest } from "../actions/contest";
import { setError } from "../actions/common";
import parse from "../../utils/parsers/contest-parser";

const contestFlow = ({ api, log }) => ({ dispatch }) => (next) => async (
  action
) => {
  next(action);

  if (action.type === GET_CONTEST) {
    try {
      dispatch(setContest({ loading: true }));
      const contest = await api.contest.getById(action.payload);
      log("Successfully fetched the contest data");
      dispatch(setError(null));
      dispatch(
        setContest({
          contest: parse(contest, action.payload.recruiterId),
          loading: false,
        })
      );
    } catch (error) {
      const payload = {
        error,
        message: "Error while fetching the contest details",
      };
      dispatch(setError(payload));
      dispatch(setContest({ loading: false }));
      log("Error while fetching the contest details");
    }
  }
};

export default [contestFlow];
