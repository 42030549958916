export const GET_PARTNER = "GET_PARTNER";
export const SET_PARTNER = "SET_PARTNER";

export const getPartner = (id) => {
  return {
    type: GET_PARTNER,
    payload: id,
  };
};

export const setPartner = (data) => {
  return {
    type: SET_PARTNER,
    payload: data,
  };
};
