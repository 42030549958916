import CustomerSaysCard from "../CustomerSaysCard";
import "./styles.scss";
import { whatCustomersAreSayingArray } from "../../utils/constants/site-data";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { createChunks } from "../../utils/util";
import Button from "../Button";

const WhatCustomersAreSaying = () => {
  const viewMoreOnClick = (event) => {
    return;
  };

  return (
    <div className="what-customer-says-container">
      <div className="what-customer-says-cart-wheel">
        <Carousel
          showIndicators={false}
          autoPlay={false}
          infiniteLoop={true}
          stopOnHover={true}
        >
          {createChunks(whatCustomersAreSayingArray).map(
            (item, index, chunk) => (
              <div key={index} className="what-customer-says-slide">
                {chunk[index].map((card, cardIndex) => (
                  <CustomerSaysCard data={card} key={cardIndex} />
                ))}
              </div>
            )
          )}
        </Carousel>
      </div>
      <div hidden>
        <Button
          variant="secondary"
          label="View More"
          onClick={viewMoreOnClick}
        />
      </div>
    </div>
  );
};
export default WhatCustomersAreSaying;
