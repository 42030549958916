export const GET_CONTEST = "GET_CONTEST";
export const SET_CONTEST = "SET_CONTEST";

export const getContest = (id, recruiterId) => {
  return {
    type: GET_CONTEST,
    payload: { id, recruiterId },
  };
};

export const setContest = (data) => {
  return {
    type: SET_CONTEST,
    payload: data,
  };
};
