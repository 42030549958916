import React, { useRef } from "react";
import { Tooltip as MuiTooltip } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

interface Props {
  // Child element to wrap
  children: React.ReactElement<any, any>;
  // Tooltip to show
  title: React.ReactElement<any, any>;
  variant?: string;
}

const Tooltip: React.FC<Props> = (props) => {
  const { children, title, variant = "default" } = props;

  const popperRef = useRef(null);
  const childRef = useRef(null);

  const background =
    variant === "outlined" ? "#ffffff !important" : "#4d6cd9 !important";

  const useStyles = makeStyles(() => ({
    customArrow: {
      color: background,
    },
    customTooltip: {
      padding: "1rem !important",
      background,
      boxShadow: "0 0.25rem 1rem rgba(206, 212, 240, 0.8)",
      borderRadius: "0.5rem",
      fontFamily: "Nunito !important",
      fontSize: "0.875rem  !important",
      lineHeight: "1.375rem",
    },
  }));

  const classes = useStyles();

  return (
    <MuiTooltip
      title={title}
      arrow
      placement="top"
      PopperProps={{
        popperRef,
        anchorEl: {
          getBoundingClientRect: () => {
            return new DOMRect(
              childRef.current.getBoundingClientRect().x,
              childRef.current.getBoundingClientRect().y,
              0,
              0
            );
          },
        },
      }}
      classes={{ arrow: classes.customArrow, tooltip: classes.customTooltip }}
    >
      <div ref={childRef}>{children}</div>
    </MuiTooltip>
  );
};

export default Tooltip;
