import { useState } from "react";
import Checkbox from "../Checkbox";
import chevronDownImage from "../../assets/chevron-down.svg";
import chevronRightImage from "../../assets/chevron-right.svg";
import styles from "./styles.module.scss";

const CheckboxFilter = (props) => {
  const [expanded, setExpanded] = useState(false);
  const [selectedIndices, setSelectedIndices] = useState([]);
  const { filterName, filters, field, list, onFilterChanged } = props;

  filters.sort((item1, item2) => item1.localeCompare(item2));

  const getListItemWithFilter = (filter) => {
    return list.filter((item) => item[field].includes(filter));
  };

  const updateList = (indices) => {
    const updatedList = [];
    updatedList.length = 0;
    filters.forEach((filter, index) => {
      if (indices.includes(index)) {
        updatedList.push(...getListItemWithFilter(filter));
      }
    });

    if (indices.length === 0) {
      onFilterChanged([...list]);
    } else {
      onFilterChanged([...new Set(updatedList)]);
    }
  };

  const handleCheckbox = (event, index) => {
    let updatedIndices = selectedIndices;
    updatedIndices = updatedIndices.filter((item) => item !== index);
    if (event.target.checked) {
      updatedIndices.push(index);
    }
    setSelectedIndices([...updatedIndices]);
    updateList(updatedIndices);
  };

  return (
    <>
      <div className={styles["checkbox-filter-container"]}>
        <p className={styles["checkbox-filter-filter-title"]}>{filterName}</p>
        <img
          src={expanded ? chevronDownImage : chevronRightImage}
          className={styles["checkbox-filter-expand-icon"]}
          onClick={() => {
            setExpanded(!expanded);
          }}
        />
      </div>
      {expanded && (
        <div className={styles["checkbox-filter-filters"]}>
          {filters.map((filter, index) => {
            return (
              <div
                key={filter}
                className={`${styles["checkbox-filter-filter"]} ${
                  selectedIndices.includes(index)
                    ? styles["checkbox-filter-filter-selected"]
                    : ""
                }`}
              >
                <Checkbox
                  onChange={(event) => {
                    handleCheckbox(event, index);
                  }}
                />
                <p className={styles["checkbox-filter-filter-name"]}>
                  {filter}
                </p>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default CheckboxFilter;
