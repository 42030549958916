import { useState } from "react";
import plusImage from "../../assets/plus.svg";
import minusImage from "../../assets/minus.svg";
import "./styles.css";

interface Props {
  title: string;
  children: React.ReactNode;
  isExpanded?: boolean;
}

const Accordion: React.FC<Props> = (props) => {
  const { title, children, isExpanded = false } = props;
  const [expanded, setExpoanded] = useState(isExpanded);

  return (
    <div
      className={`accordion-container ${
        expanded ? "accordion-expanded-container" : ""
      }`}
      tabIndex={0}
      onClick={() => {
        setExpoanded(!expanded);
      }}
      onKeyDown={(event) => {
        event.keyCode === 13 && setExpoanded(!expanded);
      }}
    >
      <p className="accordion-title">{title}</p>
      <img
        src={expanded ? minusImage : plusImage}
        alt="Plus"
        className="accordion-expand-icon"
        onClick={() => {
          setExpoanded(!expanded);
        }}
      />
      {expanded && <div className="accordion-children">{children}</div>}
    </div>
  );
};

export default Accordion;
