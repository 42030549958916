import { GET_LINKS, setLinks } from "../actions/links";
import parse from "../../utils/parsers/links-parser";

const linksFlow = ({ api, log }) => ({ dispatch }) => (next) => async (
  action
) => {
  next(action);

  if (action.type === GET_LINKS) {
    try {
      const links = await api.links.getAll(action.payload);
      log("Successfully fetched the links data");
      dispatch(setLinks({ links: parse(links) }));
    } catch (error) {
      log("Error while fetching the links data");
    }
  }
};

export default [linksFlow];
