import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import logout from "../../assets/logout.png";
import profileImage from "../../assets/profile.svg";
import dashboard from "../../assets/dashboard.png";
import contestActivity from "../../assets/contest-activity.svg";
import bookmark from "../../assets/bookmark.svg";
import alertCircle from "../../assets/alert-circle.png";
import { useHistory } from "react-router-dom";
import { EDIT_PROFILE, HOME, LOGOUT } from "../../utils/constants/routes";
import { ClickAwayListener, Fade, MenuList, Popper } from "@mui/material";
import { getLocalStorage } from "../../utils/storage-manager";
import { getLinks } from "../../redux/actions/links";
import { linksSelector } from "../../redux/selectors";
import "./styles.scss";

const ProfileMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const history = useHistory();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    dispatch(getLinks());
  }, [dispatch]);

  const links = useSelector(linksSelector) || {};

  const menuItems = [
    {
      label: getLocalStorage("user", null),
      link: EDIT_PROFILE,
      image: getLocalStorage("profilePic", null)
        ? getLocalStorage("profilePic", null)
        : profileImage,
    },
    {
      label: "Dashboard",
      link: links.dashboard,
      image: dashboard,
    },
    {
      label: "Contest Activity",
      link: links.contestActivity,
      image: contestActivity,
    },
    {
      label: "Contest Bookmarked",
      link: links.contestBookmarked,
      image: bookmark,
    },
    {
      label: "Contest Not Interested",
      link: links.contestRejected,
      image: alertCircle,
    },
    {
      label: "Sign Out",
      link: HOME,
      image: logout,
    },
  ];

  const handleMenuClick = (item: any, index: number) => {
    setAnchorEl(null);
    if (index !== 0 && index !== menuItems.length - 1) {
      window.open(item.link);
      return;
    } else {
      if (item.label === "Sign Out") {
        history.push(LOGOUT);
        return;
      }

      history.push(item.link);
    }
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setAnchorEl(null);
    }
  }

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <img
              src={
                getLocalStorage("profilePic", null)
                  ? getLocalStorage("profilePic", null)
                  : profileImage
              }
              alt="Icon"
              title=""
              className="profile-menu-icon"
            />
          </IconButton>
        </Tooltip>
      </Box>
      <Popper
        open={open}
        anchorEl={anchorEl}
        transition
        style={{ zIndex: 999 }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <Box
              sx={{
                borderRadius: "1rem",
                borderWidth: ".0938rem",
                borderColor: "#CED4F0",
                borderStyle: "solid",
                boxShadow: "0rem .25rem 1rem rgba(206, 212, 240, 0.8)",
                padding: "1",
                background: "#FFFFFF",
                mr: "3.5rem",
                ".MuiDivider-root": {
                  ml: 1,
                  mr: 1,
                },
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} onKeyDown={handleListKeyDown}>
                  {menuItems.map((menuItem, index) => {
                    return (
                      <>
                        <MenuItem
                          onClick={() => handleMenuClick(menuItem, index)}
                          key={index}
                        >
                          <ListItemIcon>
                            {menuItem.image && (
                              <img
                                src={menuItem.image}
                                alt="Icon"
                                title={menuItem.label}
                                className="profile-menu-icon-item"
                              />
                            )}
                          </ListItemIcon>
                          <p className="menu-label">{menuItem.label}</p>
                        </MenuItem>
                        {index < menuItems.length - 1 && <Divider />}
                      </>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Box>
          </Fade>
        )}
      </Popper>
    </>
  );
};

export default ProfileMenu;
