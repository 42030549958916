import { useSelector } from "react-redux";
import { employerSelector } from "../../redux/selectors";
import Tag from "../Tag";
import styles from "./style.module.scss";

interface Props {
  employerDetails?: any;
}

const EmployerDetails: React.FC<Props> = (props) => {
  const employerDetails = props.employerDetails
    ? props.employerDetails
    : useSelector(employerSelector);

  const handleWebsiteClick = () => {
    window.open(employerDetails.website);
  };

  return (
    <div className={styles["employer-details-wrapper"]}>
      <img
        src={employerDetails.coverImage}
        alt={employerDetails.brandName}
        className={styles["employer-details-cover-image"]}
      />
      <div className={styles["employer-details-logo-container"]}>
        <img
          src={employerDetails.logo}
          alt={employerDetails.brandName}
          className={styles["employer-details-logo"]}
        />
      </div>
      <div className={styles["employer-details-container"]}>
        <p className={styles["employer-details-name"]}>
          {employerDetails.brandName}
        </p>
        <p className={styles["employer-details-joining-date"]}>
          {`Empaneled Date: ${employerDetails.joiningDate}`}
        </p>
        <hr className={styles["employer-details-separator"]} />
        <div className={styles["employer-details-flex-container"]}>
          <div className={styles["employer-details-flex-container"]}>
            <div>
              <div className={styles["employer-details-flex-container"]}>
                <p
                  className={`${styles["employer-details-label"]} ${styles["employer-details-max-width-label"]}`}
                >
                  Brand Name
                </p>
                <p className={styles["employer-details-text"]}>
                  {employerDetails.brandName}
                </p>
              </div>
              <div
                className={`${styles["employer-details-flex-container"]} ${styles["employer-details-margin-top-container"]}`}
              >
                <p
                  className={`${styles["employer-details-label"]} ${styles["employer-details-max-width-label"]}`}
                >
                  Year of registration
                </p>
                <p className={styles["employer-details-text"]}>
                  {employerDetails.yearOfRegistration}
                </p>
              </div>
              <div
                className={`${styles["employer-details-flex-container"]} ${styles["employer-details-margin-top-container"]}`}
              >
                <p
                  className={`${styles["employer-details-label"]} ${styles["employer-details-max-width-label"]}`}
                >
                  Website
                </p>
                <p
                  className={`${styles["employer-details-text"]} ${styles["employer-details-website-text"]}`}
                  onClick={handleWebsiteClick}
                >
                  {employerDetails.website}
                </p>
              </div>
              <div
                className={`${styles["employer-details-flex-container"]} ${styles["employer-details-margin-top-container"]}`}
              >
                <p
                  className={`${styles["employer-details-label"]} ${styles["employer-details-max-width-label"]}`}
                >
                  Number of employees
                </p>
                <p className={styles["employer-details-text"]}>
                  {employerDetails.numberOfEmployees.toLocaleString("en-IN")}
                </p>
              </div>
              <div
                className={`${styles["employer-details-flex-container"]} ${styles["employer-details-margin-top-container"]}`}
              >
                <p
                  className={`${styles["employer-details-label"]} ${styles["employer-details-max-width-label"]}`}
                >
                  Source
                </p>
                <p className={styles["employer-details-text"]}>
                  {employerDetails.source}
                </p>
              </div>
            </div>
          </div>
          <div className={styles["employer-details-flex-container"]}>
            <div>
              {employerDetails.companyStats.locations && (
                <div className={styles["employer-details-flex-container"]}>
                  <p
                    className={`${styles["employer-details-label"]} ${styles["employer-details-max-width-label"]}`}
                  >
                    Location(s)
                  </p>
                  <div className={styles["employer-details-tags-container"]}>
                    {employerDetails.companyStats.locations
                      .split(",")
                      .map((tag) => {
                        return <Tag key={tag} title={tag} />;
                      })}
                  </div>
                </div>
              )}
              {employerDetails.tags && employerDetails.tags.length > 0 && (
                <div className={styles["employer-details-flex-container"]}>
                  <p
                    className={`${styles["employer-details-label"]} ${styles["employer-details-max-width-label"]}`}
                  >
                    Tags
                  </p>
                  <div className={styles["employer-details-tags-container"]}>
                    {employerDetails.tags.map((tag) => {
                      return <Tag key={tag} title={tag} />;
                    })}
                  </div>
                </div>
              )}
              {employerDetails.skills && employerDetails.skills.length > 0 && (
                <div className={styles["employer-details-flex-container"]}>
                  <p
                    className={`${styles["employer-details-label"]} ${styles["employer-details-max-width-label"]}`}
                  >
                    Skills hiring for
                  </p>
                  <div className={styles["employer-details-tags-container"]}>
                    {employerDetails.skills.map((tag) => {
                      return <Tag key={tag} title={tag} />;
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <hr className={styles["employer-details-separator"]} />
        <div className={styles["employer-details-flex-container"]}>
          <p
            className={`${styles["employer-details-label"]} ${styles["employer-details-max-width-label"]}`}
          >
            About {employerDetails.brandName}
          </p>
          <p
            className={styles["employer-details-label"]}
            dangerouslySetInnerHTML={{
              __html: employerDetails.description,
            }}
          ></p>
        </div>
        <hr className={styles["employer-details-separator"]} />
        <p className={styles["employer-details-headquarter-title"]}>
          Headquarter Address
        </p>
        <div className={styles["employer-details-flex-container"]}>
          <div className={styles["employer-details-flex-container"]}>
            <div>
              <div className={styles["employer-details-flex-container"]}>
                <p
                  className={`${styles["employer-details-label"]} ${styles["employer-details-max-width-label"]}`}
                >
                  Line 1
                </p>
                <p className={styles["employer-details-text"]}>
                  {employerDetails.address.line1}
                </p>
              </div>
              <div
                className={`${styles["employer-details-flex-container"]} ${styles["employer-details-margin-top-container"]}`}
              >
                <p
                  className={`${styles["employer-details-label"]} ${styles["employer-details-max-width-label"]}`}
                >
                  Line 2
                </p>
                <p className={styles["employer-details-text"]}>
                  {employerDetails.address.line2}
                </p>
              </div>
              <div
                className={`${styles["employer-details-flex-container"]} ${styles["employer-details-margin-top-container"]}`}
              >
                <p
                  className={`${styles["employer-details-label"]} ${styles["employer-details-max-width-label"]}`}
                >
                  Pincode
                </p>
                <p className={styles["employer-details-text"]}>
                  {employerDetails.address.pincode}
                </p>
              </div>
              <div
                className={`${styles["employer-details-flex-container"]} ${styles["employer-details-margin-top-container"]}`}
              >
                <p
                  className={`${styles["employer-details-label"]} ${styles["employer-details-max-width-label"]}`}
                >
                  City
                </p>
                <p className={styles["employer-details-text"]}>
                  {employerDetails.address.city}
                </p>
              </div>
            </div>
          </div>
          <div className={styles["employer-details-flex-container"]}>
            <div>
              <div className={styles["employer-details-flex-container"]}>
                <p
                  className={`${styles["employer-details-label"]} ${styles["employer-details-max-width-label"]}`}
                >
                  State
                </p>
                <p className={styles["employer-details-text"]}>
                  {employerDetails.address.state}
                </p>
              </div>
              <div
                className={`${styles["employer-details-flex-container"]} ${styles["employer-details-margin-top-container"]}`}
              >
                <p
                  className={`${styles["employer-details-label"]} ${styles["employer-details-max-width-label"]}`}
                >
                  Country
                </p>
                <p className={styles["employer-details-text"]}>
                  {employerDetails.address.country}
                </p>
              </div>
              <div
                className={`${styles["employer-details-flex-container"]} ${styles["employer-details-margin-top-container"]}`}
              >
                <p
                  className={`${styles["employer-details-label"]} ${styles["employer-details-max-width-label"]}`}
                >
                  ISD Code
                </p>
                <p className={styles["employer-details-text"]}>
                  {employerDetails.address.isdCode}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployerDetails;
