import "./styles.css";

const Partner = (props: any) => {
  const { name, logo, background, boxShadow } = props;

  return (
    <div className="partner-container">
      <div className="partner-logo-container" style={{ background, boxShadow }}>
        <img src={logo} alt={name} className="partner-logo" />
      </div>
      <p className="partner-name">{name}</p>
    </div>
  );
};

export default Partner;
