import recruiterStatsFunnel from "../../assets/recruiter-stats-funnel.svg";
import styles from "./styles.module.scss";

interface Stats {
  profilesSubmitted: number;
  profilesShortlisted: number;
  offerRolledOut: number;
  jobSeekersJoined: number;
}

interface Props {
  stats: Stats;
}

const RecruiterStats: React.FC<Props> = (props) => {
  const { stats } = props;

  const getRoundedNumber = (num) => {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  };

  return (
    <div className={styles["recruiter-stats-container"]}>
      <div className={styles["recruiter-stats-percentage-container"]}>
        <div
          className={styles["recruiter-stats-percentage-profiles-submitted"]}
        >
          <p className={styles["recruiter-stats-precentage-text"]}>100%</p>
        </div>
        <div
          className={styles["recruiter-stats-percentage-profiles-shortlisted"]}
        >
          <p className={styles["recruiter-stats-precentage-text"]}>
            {`${getRoundedNumber(
              (stats.profilesShortlisted / stats.profilesSubmitted) * 100
            )}%`}
          </p>
        </div>
        <div className={styles["recruiter-stats-percentage-offer-rolled-out"]}>
          <p className={styles["recruiter-stats-precentage-text"]}>
            {`${getRoundedNumber(
              (stats.offerRolledOut / stats.profilesSubmitted) * 100
            )}%`}
          </p>
        </div>
        <div className={styles["recruiter-stats-percentage-joined"]}>
          <p className={styles["recruiter-stats-precentage-text"]}>
            {`${getRoundedNumber(
              (stats.jobSeekersJoined / stats.profilesSubmitted) * 100
            )}%`}
          </p>
        </div>
      </div>
      <img
        src={recruiterStatsFunnel}
        alt="Stats"
        className={styles["recruiter-stats-funnel"]}
      />
      <div className={styles["recruiter-stats-stats-container"]}>
        <div className={styles["recruiter-stats-stats-profiles-submitted"]}>
          <p className={styles["recruiter-stats-stats-text"]}>
            {stats.profilesSubmitted}
          </p>
        </div>
        <div className={styles["recruiter-stats-stats-profiles-shortlisted"]}>
          <p className={styles["recruiter-stats-stats-text"]}>
            {stats.profilesShortlisted}
          </p>
        </div>
        <div className={styles["recruiter-stats-stats-offer-rolled-out"]}>
          <p className={styles["recruiter-stats-stats-text"]}>
            {stats.offerRolledOut}
          </p>
        </div>
        <div className={styles["recruiter-stats-stats-joined"]}>
          <p className={styles["recruiter-stats-stats-text"]}>
            {stats.jobSeekersJoined}
          </p>
        </div>
      </div>
    </div>
  );
};

export default RecruiterStats;
