import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { setActiveLink } from "../../redux/actions/menu";
import { getRecruiterById } from "../../redux/actions/recruiterProfile";
import {
  recruiterDetailsSelector,
  recruiterLoadingSelector,
  errorSelector,
} from "../../redux/selectors";
import RecruiterProfileDetailCard from "../../components/RecruiterProfileDetailCard";
import RecruiterTabs from "../../components/RecruiterTabs";
import Error from "../../components/Error";
import { RECRUITER_TYPES } from "../../utils/constants";
import "./styles.css";

const RecruiterDeailsPage = () => {
  const dispatch = useDispatch();

  const { id } = useParams<{ id: string }>();

  const recruiterDetails = useSelector(recruiterDetailsSelector);
  const loading = useSelector(recruiterLoadingSelector);
  const error = useSelector(errorSelector);

  const [canRenderComponent, setCanRenderComponent] = useState(false);

  useEffect(() => {
    dispatch(setActiveLink("Recruiters"));
    dispatch(getRecruiterById(id));
    setCanRenderComponent(true);
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {loading && <CircularProgress className="loading" />}
      {!loading && canRenderComponent && !error && (
        <div className="recruiter-details-wrapper">
          <div className="recruiter-details-container">
            <div className="recruiter-details-details">
              <RecruiterProfileDetailCard recruiterDetails={recruiterDetails} />
            </div>
          </div>
          <RecruiterTabs
            recruiterDetails={recruiterDetails}
            showCertificates={recruiterDetails["type"] === RECRUITER_TYPES[0]}
          />
        </div>
      )}
      {!loading && error && <Error />}
    </div>
  );
};

export default RecruiterDeailsPage;
