export const GET_BANNERS = "GET_BANNERS";
export const SET_BANNERS = "SET_BANNERS";

export const getBanners = () => {
  return {
    type: GET_BANNERS,
  };
};

export const setBanners = (data) => {
  return {
    type: SET_BANNERS,
    payload: data,
  };
};
