import { useState } from "react";
import Card from "../Card";
import FAQ from "../FAQ";
import PartnerDetails from "../PartnerDetails";
import "./styles.css";

interface Props {
  partnerDetails?: any;
}

const PartnerTabs: React.FC<Props> = (props) => {
  const { partnerDetails } = props;

  const tabs = ["Partner Details", "FAQs"];

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  return (
    <>
      <Card>
        <div className="partner-tabs-container">
          {tabs.map((tab, index) => {
            return (
              <div
                key={index}
                className={`partner-tabs-tab ${
                  activeTabIndex === index ? "partner-tabs-active-tab" : ""
                }`}
              >
                <p
                  className={`partner-tabs-text ${
                    activeTabIndex === index ? "partner-tabs-active-text" : ""
                  }`}
                  onClick={() => {
                    setActiveTabIndex(index);
                  }}
                  tabIndex={0}
                  onKeyDown={(event) => {
                    event.keyCode === 13 && setActiveTabIndex(index);
                  }}
                >
                  {tab}
                </p>
              </div>
            );
          })}
        </div>
      </Card>
      {activeTabIndex === 0 && (
        <div className="partner-tabs-details-container">
          <PartnerDetails partnerDetails={partnerDetails} />
        </div>
      )}
      {activeTabIndex === 1 && (
        <div className="partner-tab-container">
          <FAQ faq={partnerDetails.faq} />
        </div>
      )}
    </>
  );
};

export default PartnerTabs;
