import { Box, Grid } from "@mui/material";
import InfoCard from "../InfoCard";
import "./styles.scss";
import { whatsInItForRecrutiersInfoArray } from "../../utils/constants/site-data";

const WhatsInItForRecrutiers = () => {
  return (
    <div className="info-card-grid">
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={{ xs: 1, md: 2 }}
          columns={{ xs: 8, sm: 8, md: 12 }}
        >
          {whatsInItForRecrutiersInfoArray.map((infoItem, index) => (
            <Grid item xs={8} sm={8} md={4} key={index}>
              <InfoCard
                icon={infoItem.icon}
                title={infoItem.title}
                description={infoItem.description}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
};
export default WhatsInItForRecrutiers;
