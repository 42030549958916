import Tag from "../Tag";
import "./styles.css";

interface Props {
  aboutContest: string;
  domains: string;
}

const Overview: React.FC<Props> = (props) => {
  const { aboutContest, domains } = props;
  return (
    <div>
      {aboutContest && (
        <div className="overview-container">
          <div>
            <p className="overview-title">About Contest</p>
            <div className="overview-description">{aboutContest}</div>
          </div>
          {domains && domains.length > 0 && (
            <div className="overview-domain-wrapper">
              <p className="overview-title">Domain</p>
              <div className="overview-domain-container">
                {domains.split(",").map((domain) => {
                  return <Tag key={domain} title={domain} />;
                })}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Overview;
