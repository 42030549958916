import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import HeroImageContainer from "../../components/HeroImageContainer";
import Recruiters from "../../components/Recruiters";
import Error from "../../components/Error";
import { setActiveLink } from "../../redux/actions/menu";
import { getRecruiters } from "../../redux/actions/recruiterProfile";
import { getBanners } from "../../redux/actions/banners";
import {
  errorSelector,
  recruitersLoadingSelector,
  recruitersSelector,
  bannersSelector,
  bannersLoadingSelector,
} from "../../redux/selectors";
import "./styles.css";

const RecruitersPage = () => {
  const dispatch = useDispatch();

  const recruitersLooading = useSelector(recruitersLoadingSelector);
  const bannersLoading = useSelector(bannersLoadingSelector);
  const loading = recruitersLooading || bannersLoading;

  const error = useSelector(errorSelector);
  const recruitersData = useSelector(recruitersSelector);
  const heroImages = useSelector(bannersSelector) || [];
  const [canRenderComponent, setCanRenderComponent] = useState(false);

  const [rerender, setRerender] = useState(false);

  function handleReset() {
    setRerender(true);
  }

  useEffect(() => {
    dispatch(setActiveLink("Recruiters"));
    dispatch(getRecruiters());
    dispatch(getBanners());
    setCanRenderComponent(true);
    if (rerender) {
      setRerender(false);
    }
  }, [rerender]);

  return (
    <div>
      {loading && <CircularProgress className="loading" />}
      {!loading && !error && canRenderComponent && (
        <>
          {heroImages.length > 0 && (
            <div className="recruiters-hero-image-container">
              <HeroImageContainer heroImages={heroImages} />
            </div>
          )}
          <div className="recruiters-contest-cards-container">
            <Recruiters
              recruiters={recruitersData.recruiters}
              recruitersData={recruitersData}
              onReset={handleReset}
            />
          </div>
        </>
      )}
      {!loading && error && <Error />}
    </div>
  );
};

export default RecruitersPage;
