import axios from "axios";
import { UPLOAD_FILE } from "../../../utils/constants/urls";
import { getLocalStorage } from "../../../utils/storage-manager";

const addToken = () => {
  const token = getLocalStorage("token", null);
  if (token) {
    axios.defaults.headers["Authorization"] = "Bearer " + token;
  } else {
    axios.defaults.headers["Authorization"] = null;
  }
};

export default {
  uploadFile: async (payload) => {
    addToken();
    const response = await axios.post(`${UPLOAD_FILE}`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  },
};
