import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Signup from "../../components/Signup";
import { CONTESTS } from "../../utils/constants/routes";
import loginBackground from "../../assets/login-background.svg";
import { getLocalStorage } from "../../utils/storage-manager";
import "./styles.css";

const SignupPage = () => {
  const history = useHistory();
  useEffect(() => {
    const isAuthenticated = getLocalStorage("token", null);

    if (isAuthenticated) {
      history.push(CONTESTS);
    }
  }, [history]);

  return (
    <div
      className="signup-page-wrapper"
      style={{
        backgroundImage: `url(${loginBackground})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="signup-page-container">
        <Signup />
      </div>
    </div>
  );
};

export default SignupPage;
