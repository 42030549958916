import React, { useState, useEffect } from "react";
import Breadcrumbs from "../Breadcrumbs";
import Button from "../Button";
import * as Constants from "../../utils/constants/index";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import TextValidator from "../TextValidator";
import SelectValidator from "../SelectValidator";
import Calendar from "../Calendar";
import { getCities, getStates } from "../../utils/constants/locations";
import PhoneNumberValidator from "../../components/PhoneNumberValidator";
import { CircularProgress } from "@material-ui/core";
import { useSelector } from "react-redux";
import { userInfoSelector } from "../../redux/selectors";
import CustomUploadFile from "../CustomUploadFile";

interface Props {
  currentStep: number;
  onNext: any;
  onSkip: any;
  onGotoStep: any;
  data: any;
  userStage: number;
  loading?: boolean;
  error?: any;
  disabled?: boolean;
}

interface Address {
  line1: string;
  line2: string;
  pincode: string;
  city: string;
  state: string;
  country: string;
}

interface FRDetails {
  firstName: string;
  lastName: string;
  linkedInURL: string;
  emailAddress: string;
  alternateEmailAddress: string;
  dob: string;
  gender: string;
  education: string;
  phoneExt: string;
  phoneNumber: string;
  altPhoneNumber: string;
  about: string;
  logo: string;
  address: Address;
}

const initializeAddress = () => {
  return {
    line1: "",
    line2: "",
    pincode: "",
    city: "",
    state: "",
    country: "India",
  };
};

const initialize = (userInfo) => {
  return {
    firstName: userInfo.firstName,
    lastName: userInfo.lastName,
    linkedInURL: "",
    emailAddress: userInfo.emailAddress,
    alternateEmailAddress: "",
    dob: Date.now.toString(),
    gender: "",
    education: "",
    phoneExt: userInfo.phoneExt,
    phoneNumber: userInfo.phoneNumber,
    altPhoneNumber: userInfo.altPhoneNumber,
    about: "",
    logo: "",
    address: initializeAddress(),
  };
};

const FreelanceRecruiterDetails = (props: Props) => {
  const {
    currentStep,
    onNext,
    onSkip,
    onGotoStep,
    data,
    userStage,
    loading = false,
    error = {},
    disabled = false,
  } = props;
  const userInfo = useSelector(userInfoSelector);
  const [freelancerDetails, setFreelancerDetails] = useState(
    initialize(userInfo)
  );
  const [freelancerAddress, setFreelancerAddress] = useState(
    initializeAddress()
  );

  useEffect(() => {
    let validData = initialize(userInfo);
    if (data && data.firstName) {
      validData = data;
    }
    setFreelancerDetails(validData);
    setFreelancerAddress(validData.address);
    Object.keys(validData).map((key) => {
      if (key === "address") {
        Object.keys(validData.address).map((addressKey) => {
          setValue(addressKey, validData[key][addressKey]);
        });
      }
      setValue(key, validData[key]);
    });
  }, [data]);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().trim().required("Recruiter first name is required"),
    lastName: Yup.string().trim().required("Recruiter last name is required"),
    linkedInURL: Yup.string()
      .trim()
      .url("LinkedIn URL is not valid")
      .required("LinkedIn is required"),
    emailAddress: Yup.string().notRequired(),
    alternateEmailAddress: Yup.string()
      .trim()
      .nullable()
      .notRequired()
      .email("Enter valid email address")
      .when("emailAddress", {
        is: (value) => value.length > 0,
        then: Yup.string()
          .lowercase()
          .notOneOf(
            [
              freelancerDetails.emailAddress &&
                freelancerDetails.emailAddress.toString().toLowerCase(),
            ],
            "Alternater email address cannot be same as primary email address"
          ),
      }),
    dob: Yup.date().required("Date of birth is required"),
    gender: Yup.string().required("Gender is required"),
    education: Yup.string().required("Education is required"),
    phoneExt: Yup.string()
      .trim()
      .nullable()
      .notRequired()
      .default("+91")
      .matches(Constants.PHONE_EXT_REGEX, {
        message: "Phone extension is not valid",
        excludeEmptyString: true,
      }),
    phoneNumber: Yup.string()
      .trim()
      .nullable()
      .notRequired()
      .matches(Constants.PHONE_REGEX, {
        message: "Phone number is not valid",
        excludeEmptyString: true,
      }),
    altPhoneNumber: Yup.string()
      .trim()
      .nullable()
      .notRequired()
      .matches(Constants.LANDLINE_REGEX, {
        message: "Phone number is not valid",
        excludeEmptyString: true,
      })
      .test({
        name: "altPhoneNumber",
        message:
          "Alternate phone number cannot be same as primary phone number",
        test: function (value) {
          if (!value) {
            return true;
          }
          return !(
            value === this.parent.phoneNumber ||
            value === this.parent.phoneExt + this.parent.phoneNumber
          );
        },
      }),
    about: Yup.string()
      .trim()
      .required("Description about yourself is required"),
    line1: Yup.string().trim().required("Address line 1 is required"),
    line2: Yup.string().trim().required("Address line 2 is required"),
    pincode: Yup.string()
      .trim()
      .required("Pincode is required")
      .matches(Constants.PINCODE_REGEX, "Pincode is not valid"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    country: Yup.string().default("India").required("Country is required"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    reValidateMode: "onChange",
    shouldFocusError: true,
  });

  const handleChange = (formItem: string, value: any) => {
    setFreelancerDetails({
      ...freelancerDetails,
      [formItem]: value,
    });
    setValue(formItem, value, { shouldValidate: true });
    return;
  };

  const handleAddressChange = (formItem: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFreelancerAddress({
      ...freelancerAddress,
      [formItem]: event.target.value,
    });
    setFreelancerDetails({
      ...freelancerDetails,
      ["address"]: freelancerAddress,
    });

    setValue(formItem, event.target.value, { shouldValidate: true });
    return;
  };

  const onClickNext = (data: any) => {
    onNext(data);
  };

  const gotoStep = (toStep: number) => {
    onGotoStep(toStep);
  };

  const stepNames = Constants.EDIT_FR_PROFILE_STEPS;
  const totalSteps = stepNames.length;

  return (
    <div className="form-container">
      {loading && <CircularProgress className="loading" />}
      {!loading && (
        <>
          <div className="div-breadcrumb">
            <Breadcrumbs
              currentStep={currentStep}
              totalSteps={totalSteps}
              callBackFunction={gotoStep}
              stepNames={stepNames}
              userStage={userStage}
            />
          </div>

          <form onSubmit={handleSubmit(onClickNext)} className="div-form">
            <div className="div-row-flex">
              <TextValidator
                id="firstName"
                className="name-input"
                label="Recruiter First Name*"
                register={register}
                value={freelancerDetails.firstName}
                onChange={(event) =>
                  handleChange("firstName", event.target.value)
                }
                errorMessage={errors.firstName?.message}
                disabled={disabled}
              />
              <TextValidator
                id="lastName"
                className="name-input"
                label="Recruiter Last Name*"
                register={register}
                value={freelancerDetails.lastName}
                onChange={(event) =>
                  handleChange("lastName", event.target.value)
                }
                errorMessage={errors.lastName?.message}
                disabled={disabled}
              />
            </div>
            <div className="div-row-flex">
              <div className="textfield-wrapper">
                <label
                  htmlFor="about"
                  className={
                    freelancerDetails.about &&
                    freelancerDetails.about.length > 0
                      ? "active"
                      : ""
                  }
                >
                  Description about recruiter*
                </label>
                <textarea
                  className="certificate"
                  maxLength={150}
                  name="about"
                  value={freelancerDetails.about}
                  {...register("about")}
                  onChange={(event) =>
                    handleChange("about", event.target.value)
                  }
                  disabled={disabled}
                />
                <p className="invalid-feedback">{errors.about?.message}</p>
              </div>
            </div>
            <div>
              <CustomUploadFile
                id="upload-freelancer-image"
                onUploadSuccess={(value) => handleChange("logo", value)}
                fileName={freelancerDetails.logo}
                label="Upload Your Image"
                buttonLabel="Upload Image"
                accept=".jpg,.jpeg,.png"
                showDropArea={true}
                showAvatarOptions={true}
                disabled={disabled}
              />
            </div>
            <div className="div-row-flex">
              <TextValidator
                id="linkedInURL"
                className="email-input"
                label="LinkedIn Profile URL*"
                register={register}
                value={freelancerDetails.linkedInURL}
                onChange={(event) =>
                  handleChange("linkedInURL", event.target.value)
                }
                errorMessage={errors.linkedInURL?.message}
                disabled={disabled}
              />
            </div>
            <div className="div-row-flex">
              <TextValidator
                id="emailAddress"
                className="email-input"
                label="Enter Email Address"
                register={register}
                value={freelancerDetails.emailAddress}
                disabled
                onChange={(event) =>
                  handleChange("emailAddress", event.target.value)
                }
                errorMessage={errors.emailAddress?.message}
              />
            </div>
            <div className="div-row-flex">
              <Calendar
                id="dob"
                label="Date Of Birth*"
                value={freelancerDetails.dob}
                callback={(value) => {
                  handleChange("dob", value);
                }}
                register={register}
                format="dd/MM/yyyy"
                errorMessage={errors.dob?.message}
                disabled={disabled}
              />
            </div>
            <div className="div-row-flex">
              <SelectValidator
                label="Select Gender*"
                name="gender"
                register={register}
                value={freelancerDetails.gender}
                list={Constants.GENDER}
                callback={(event) => handleChange("gender", event.target.value)}
                errorMessage={errors.gender?.message}
                disabled={disabled}
              />
            </div>
            <div className="div-row-flex">
              <SelectValidator
                label="Enter Education*"
                name="education"
                register={register}
                value={freelancerDetails.education}
                list={Constants.EDUCATION}
                callback={(event) =>
                  handleChange("education", event.target.value)
                }
                errorMessage={errors.education?.message}
                disabled={disabled}
              />
            </div>
            <div className="div-row-flex">
              <TextValidator
                id="alternateEmailAddress"
                className="email-input"
                label="Enter Alternate Email Address"
                register={register}
                value={freelancerDetails.alternateEmailAddress}
                onChange={(event) =>
                  handleChange("alternateEmailAddress", event.target.value)
                }
                errorMessage={errors.alternateEmailAddress?.message}
                disabled={disabled}
              />
            </div>
            <div className="div-row-flex">
              <PhoneNumberValidator
                id="phoneNumber"
                label="Enter Phone Number"
                register={register}
                phone={freelancerDetails.phoneNumber}
                phoneExt={freelancerDetails.phoneExt}
                onPhoneChange={(event) => {
                  handleChange("phoneNumber", event.target.value);
                  trigger("altPhoneNumber");
                }}
                onExtChange={(event) => {
                  handleChange("phoneExt", event.target.value);
                  trigger("altPhoneNumber");
                }}
                errorMessage={
                  errors.phoneExt
                    ? errors.phoneExt.message
                    : errors.phoneNumber?.message
                }
                disabled={disabled}
              />
            </div>
            <div className="div-row-flex">
              <TextValidator
                id="altPhoneNumber"
                className="email-input"
                label="Enter Alternate Phone Number"
                register={register}
                value={freelancerDetails.altPhoneNumber}
                onChange={(event) =>
                  handleChange("altPhoneNumber", event.target.value)
                }
                errorMessage={errors.altPhoneNumber?.message}
                disabled={disabled}
              />
            </div>
            <div className="div-row-flex">
              <p className="sub-heading">Address Details*</p>
            </div>
            <div className="div-row-flex">
              <TextValidator
                id="line1"
                className="email-input"
                label="Enter Line 1 Address"
                register={register}
                value={freelancerAddress.line1}
                onChange={handleAddressChange("line1")}
                errorMessage={errors.line1?.message}
                disabled={disabled}
              />
            </div>
            <div className="div-row-flex">
              <TextValidator
                id="line2"
                className="email-input"
                label="Enter Line 2 Address"
                register={register}
                value={freelancerAddress.line2}
                onChange={handleAddressChange("line2")}
                errorMessage={errors.line2?.message}
                disabled={disabled}
              />
            </div>
            <div className="div-row-flex">
              <TextValidator
                id="pincode"
                className="email-input"
                label="Enter Pincode"
                register={register}
                value={freelancerAddress.pincode}
                onChange={handleAddressChange("pincode")}
                errorMessage={errors.pincode?.message}
                disabled={disabled}
              />
            </div>
            <div className="div-row-flex">
              <SelectValidator
                label="Select City"
                name="city"
                register={register}
                value={freelancerAddress.city}
                list={getCities()}
                callback={handleAddressChange("city")}
                errorMessage={errors.city?.message}
                disabled={disabled}
              />
            </div>
            <div className="div-row-flex">
              <SelectValidator
                label="Select State"
                name="state"
                register={register}
                value={freelancerAddress.state}
                list={getStates()}
                callback={handleAddressChange("state")}
                errorMessage={errors.state?.message}
                disabled={disabled}
              />
            </div>
            <div className="div-row-flex">
              <SelectValidator
                label="Select Country"
                name="country"
                register={register}
                value={freelancerAddress.country}
                list={Constants.COUNTRIES}
                callback={handleAddressChange("country")}
                errorMessage={errors.country?.message}
                disabled={disabled}
              />
            </div>
            <div className="div-submit-button">
              <Button
                label="Proceed"
                className="submit"
                variant="primary"
                disabled={!isValid || disabled}
              />
            </div>
            <p className={"invalid-feedback"}>{error?.message}</p>
            <div className="div-submit-button">
              <p>* All Mandatory Fields</p>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default FreelanceRecruiterDetails;
