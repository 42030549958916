import {
  SET_BACKGROUND_DETAILS,
  SET_COMPANY_DETAILS,
  SET_BILLING_DETAILS,
  SET_RECRUITER_DETAILS,
  SET_RECRUITERS,
} from "../actions/recruiterProfile";

const recruiterProfileReducer = (
  state = {
    companyDetails: null,
    recruiterBackground: null,
    recruiterBilling: null,
    recruiterData: {},
    recruiters: [],
  },
  action
) => {
  switch (action.type) {
    case SET_COMPANY_DETAILS:
      return action.payload;
    case SET_BACKGROUND_DETAILS:
      return action.payload;
    case SET_BILLING_DETAILS:
      return action.payload;
    case SET_RECRUITER_DETAILS:
      return action.payload;
    case SET_RECRUITERS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default recruiterProfileReducer;
