import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { getEmployer } from "../../redux/actions/employer";
import {
  employerSelector,
  employerLoadingSelector,
} from "../../redux/selectors";
import StarIcon from "@mui/icons-material/Star";
import Button from "../Button";
import Tag from "../Tag";
import employerLogoImage from "../../assets/default-employer-logo.svg";
import candidatesImg from "../../assets/candidates.svg";
import trophyImage from "../../assets/prizes.svg";
import positionsImage from "../../assets/positions.svg";
import followersImage from "../../assets/followers.svg";
import calendarImage from "../../assets/calendar.svg";
import techStackImage from "../../assets/techStack.svg";
import rankImage from "../../assets/rank.svg";
import viewImage from "../../assets/view.svg";
import contestTrophyImage from "../../assets/contest-trophy.svg";
import hiringhoodImage from "../../assets/hiringhood.svg";
import locationImage from "../../assets/location.svg";
import defaultBenefitImage from "../../assets/default-benefit.svg";
import styles from "./style.module.scss";

interface Props {
  id: number;
}

const AboutEmployer: React.FC<Props> = (props) => {
  const { id } = props;

  const employerDetails = useSelector(employerSelector);

  const dispatch = useDispatch();
  const loading = useSelector(employerLoadingSelector);

  useEffect(() => {
    dispatch(getEmployer(id));
  }, [dispatch, id]);

  const renderStat = (image: string, text: any, tooltip: string) => {
    return (
      text && (
        <div className={styles["about-employer-company-stats-container"]}>
          <img src={image} alt={tooltip} title={tooltip} />
          <p className={styles["about-employer-company-stats-text"]}>{text}</p>
        </div>
      )
    );
  };

  const renderBenefit = (benefit) => {
    return (
      <div className={styles["about-employer-benefit-container"]}>
        <img
          src={benefit.logo ? benefit.logo : defaultBenefitImage}
          alt={benefit.name}
          title={benefit.name}
        />
        <p className={styles["about-employer-benefit-text"]}>{benefit.name}</p>
      </div>
    );
  };

  return (
    <div className={styles["about-employer-wrapper"]}>
      {loading && <CircularProgress className="loading" />}
      {!loading && (
        <>
          <div className={styles["about-employer-container"]}>
            <div className={styles["about-employer-company-details"]}>
              <div className={styles["about-employer-company-logo"]}>
                <img
                  src={
                    employerDetails.logo
                      ? employerDetails.logo
                      : employerLogoImage
                  }
                  alt="Logo"
                />
              </div>
              <div className={styles["about-employer-company-name-container"]}>
                <div>
                  <p className={styles["about-employer-company-name"]}>
                    {employerDetails.companyName}
                  </p>
                  {employerDetails.rating && employerDetails.ratings && (
                    <p className={styles["about-employer-company-rating"]}>
                      (
                      <StarIcon
                        style={{
                          fill: "#F5BC2F",
                        }}
                      />
                      {employerDetails.rating}) {employerDetails.ratings}{" "}
                      Ratings
                    </p>
                  )}
                </div>
                <Button
                  label="Follow"
                  variant="outlined"
                  className={styles["about-employer-follow"]}
                />
              </div>
              <div
                className={styles["about-employer-company-description"]}
                dangerouslySetInnerHTML={{
                  __html: employerDetails.description,
                }}
              />
              <div className={styles["about-employer-company-contest-info"]}>
                <img
                  src={trophyImage}
                  alt="Contests Conducted"
                  className={styles["about-employer-company-contest-info-img"]}
                />

                <div
                  className={
                    styles["about-employer-company-contest-info-text-container"]
                  }
                >
                  <div>
                    <p
                      className={
                        styles["about-employer-company-contest-info-text"]
                      }
                    >
                      {employerDetails.contestsConducted}
                    </p>
                    <p
                      className={
                        styles["about-employer-company-contest-info-label"]
                      }
                    >
                      Contests Conducted
                    </p>
                  </div>
                </div>

                {employerDetails.candidatesPlaced && (
                  <img
                    src={candidatesImg}
                    alt="Contests Conducted"
                    className={
                      styles["about-employer-company-contest-info-img"]
                    }
                  />
                )}
                {employerDetails.candidatesPlaced && (
                  <div
                    className={
                      styles[
                        "about-employer-company-contest-info-text-container"
                      ]
                    }
                  >
                    <div>
                      <p
                        className={
                          styles["about-employer-company-contest-info-text"]
                        }
                      >
                        {employerDetails.candidatesPlaced}
                      </p>
                      <p
                        className={
                          styles["about-employer-company-contest-info-label"]
                        }
                      >
                        Got Placed
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className={styles["about-employer-company-stats"]}>
              <div className={styles["about-employer-tags-container"]}>
                {employerDetails.tags &&
                  employerDetails.tags.length > 0 &&
                  employerDetails.tags.map((tag) => {
                    return <Tag key={tag} title={tag} />;
                  })}
              </div>
              {employerDetails.companyStats && (
                <div
                  className={styles["about-employer-company-stats-sections"]}
                >
                  <div
                    className={styles["about-employer-company-stats-section1"]}
                  >
                    {renderStat(
                      positionsImage,
                      employerDetails.companyStats.numberOfEmployees,
                      "Number of Employees"
                    )}
                    {renderStat(
                      followersImage,
                      employerDetails.companyStats.followers
                        ? `${employerDetails.companyStats.followers} Followers`
                        : null,
                      "Followers"
                    )}
                    {renderStat(
                      calendarImage,
                      employerDetails.companyStats.hireDuration,
                      "Hiring Duration"
                    )}
                    {renderStat(
                      techStackImage,
                      employerDetails.companyStats.techStack,
                      "Tech Stack"
                    )}
                    {renderStat(
                      rankImage,
                      employerDetails.companyStats.hiringhoodRank
                        ? employerDetails.companyStats.hiringhoodRank
                        : null,
                      "Hiringhood Rank"
                    )}
                  </div>
                  <div
                    className={styles["about-employer-company-stats-section2"]}
                  >
                    {renderStat(
                      viewImage,
                      employerDetails.companyStats.reviews
                        ? `${employerDetails.companyStats.reviews} Reviews`
                        : null,
                      "Reviews"
                    )}
                    {renderStat(
                      contestTrophyImage,
                      employerDetails.companyStats.openContests
                        ? `${employerDetails.companyStats.openContests} Open Contests`
                        : null,
                      "Open Contests"
                    )}
                    {renderStat(
                      hiringhoodImage,
                      employerDetails.companyStats.partnership,
                      "Hiringhood Partnership"
                    )}
                    {renderStat(
                      locationImage,
                      employerDetails.companyStats.locations,
                      "Locations"
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          {employerDetails.benefitsOffered &&
            employerDetails.benefitsOffered.length > 0 && (
              <>
                <hr className={styles["about-employer-separator"]} />
                <p className={styles["about-employer-benefits-offered"]}>
                  Benefits Offered
                </p>
                <div className={styles["about-employer-benefits-container"]}>
                  {employerDetails.benefitsOffered.map((benefit) => {
                    return renderBenefit(benefit);
                  })}
                </div>
              </>
            )}
        </>
      )}
    </div>
  );
};

export default AboutEmployer;
