import Button from "../Button";
import styles from "./style.module.scss";

type button = {
  label: string;
  variant: string;
};

interface Props {
  title: string;
  content: string;
  button?: button;
  image?: string;
}

const HeroImage: React.FC<Props> = (props) => {
  const { title, content, button, image } = props;
  return (
    <div
      className={styles["hero-image"]}
      style={{ backgroundImage: `url(${image})` }}
    >
      <div className={styles["hero-image-content-container"]}>
        <p className={styles["hero-image-title"]}>{title}</p>
        <p className={styles["hero-image-content"]}>{content}</p>
        {button && (
          <Button
            label={button.label}
            variant={button.variant}
            className={styles["hero-image-button"]}
          />
        )}
      </div>
    </div>
  );
};

export default HeroImage;
