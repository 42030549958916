import { useState } from "react";
import Card from "../Card";
import SliderFilter from "../SliderFilter";
import CheckboxFilter from "../CheckboxFilter";
import filterImage from "../../assets/filter.svg";
import styles from "./styles.module.scss";
import Button from "../Button";

const ratingMarks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 5,
    label: "5",
  },
];

const positionMarks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 100,
    label: "100",
  },
  {
    value: 200,
    label: "200",
  },
  {
    value: 300,
    label: "300",
  },
  {
    value: 400,
    label: "400",
  },
];

const EmployerFilters = (props) => {
  const { employersData, onFiltersChanged, onReset } = props;
  const employers = employersData.employers;

  const [ratingEmployers, setRatingEmployers] = useState(employers);
  const [locationEmployers, setLocationEmployers] = useState(employers);
  const [skillEmployers, setSkillEmployers] = useState(employers);
  const [positionEmployers, setPositionEmployers] = useState(employers);

  const hasObject = (array, object) => {
    return array.filter((item) => item.id === object.id).length > 0;
  };

  const getCommonEmployers = (
    skillEmployers,
    ratingEmployers,
    locationEmployers,
    positionEmployers
  ) => {
    const commonEmployers = [];
    const clonedEmployers = JSON.parse(JSON.stringify(employers));
    clonedEmployers.forEach((employer) => {
      if (
        hasObject(ratingEmployers, employer) &&
        hasObject(locationEmployers, employer) &&
        hasObject(skillEmployers, employer) &&
        hasObject(positionEmployers, employer)
      ) {
        commonEmployers.push(employer);
      }
    });

    onFiltersChanged(commonEmployers);
  };

  const onLocationChange = (list) => {
    setLocationEmployers([...list]);
    setLocationEmployers((state) => {
      getCommonEmployers(
        skillEmployers,
        ratingEmployers,
        state,
        positionEmployers
      );
      return state;
    });
  };

  const onSkillChange = (list) => {
    setSkillEmployers([...list]);
    setSkillEmployers((state) => {
      getCommonEmployers(
        state,
        ratingEmployers,
        locationEmployers,
        positionEmployers
      );
      return state;
    });
  };

  const onRatingChange = (list) => {
    setRatingEmployers([...list]);
    setRatingEmployers((state) => {
      getCommonEmployers(
        skillEmployers,
        state,
        locationEmployers,
        positionEmployers
      );
      return state;
    });
  };

  const onPositionsChange = (list) => {
    setPositionEmployers([...list]);
    setPositionEmployers((state) => {
      getCommonEmployers(
        skillEmployers,
        ratingEmployers,
        locationEmployers,
        state
      );
      return state;
    });
  };

  return (
    <Card>
      <div className={styles["employer-filters-container"]}>
        <div className={styles["employer-filters-title-container"]}>
          <img src={filterImage} />
          <p className={styles["employer-filters-title"]}>Filter Search</p>
        </div>
        <Button
          className={styles["employer-filters-clear-filters-button"]}
          label="Clear Filters"
          onClick={onReset}
          variant="outlined"
        />
        <hr className={styles["employer-filters-separator"]} />
        <CheckboxFilter
          filterName="Skills"
          filters={employersData.allSkills}
          field="filterSkills"
          list={employers}
          onFilterChanged={onSkillChange}
        />
        <hr className={styles["employer-filters-separator"]} />
        <SliderFilter
          filterName="Rating"
          marks={ratingMarks}
          min={0}
          max={5}
          step={1}
          range={[0, 5]}
          list={employers}
          field="filterRating"
          onFilterChanged={onRatingChange}
        />
        <hr className={styles["employer-filters-separator"]} />
        <SliderFilter
          filterName="Number of positions"
          marks={positionMarks}
          min={0}
          max={400}
          step={100}
          range={[0, 400]}
          list={employers}
          field="filterPositions"
          onFilterChanged={onPositionsChange}
        />
        <hr className={styles["employer-filters-separator"]} />
        <CheckboxFilter
          filterName="Locations"
          filters={employersData.allLocations}
          field="filterLocations"
          list={employers}
          onFilterChanged={onLocationChange}
        />
      </div>
    </Card>
  );
};

export default EmployerFilters;
