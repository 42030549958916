import TitleMultiColor from "../TitleMultiColor";
import { partners } from "../../utils/constants/site-data";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { createChunks } from "../../utils/util";
import styles from "./style.module.scss";

const labels = ["Our", "Partners"];
const colors = ["#626880", "#4D6CD9"];

const OurPartners = () => {
  const handleClick = (url) => {
    window.open(url);
  };

  return (
    <>
      <div className={styles["our-partners-title"]}>
        <TitleMultiColor labels={labels} colors={colors} />
      </div>
      <div className={styles["our-partners-partners"]}>
        {partners.map((item) => {
          return (
            <div key={item.category}>
              <p className={styles["our-partners-category"]}>{item.category}</p>
              <Carousel
                key={item.category}
                showThumbs={false}
                showStatus={false}
                infiniteLoop
                stopOnHover={false}
                showArrows={false}
                showIndicators={item.partners.length > 3}
              >
                {createChunks(item.partners).map((item, index, chunk) => {
                  return (
                    <div key={index} className={styles["our-partners-slide"]}>
                      {chunk[index].map((partner) => {
                        return (
                          <div
                            key={partner.name}
                            className={styles["our-partners-partner"]}
                            onClick={() => {
                              handleClick(partner.website);
                            }}
                            title={partner.name}
                          >
                            <img
                              src={`${process.env.REACT_APP_PUBLIC_BUCKET_URL}${partner.logo}`}
                              alt={partner.name}
                              className={styles["our-partners-partner-logo"]}
                            />
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </Carousel>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default OurPartners;
