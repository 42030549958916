import axios from "axios";
import { EMPLOYER_API } from "../../../utils/constants/urls";

export default {
  getById: async (id) => {
    const response = await axios.get(`${EMPLOYER_API}/${id}`);

    return response.data;
  },
};
