import { useState } from "react";
import chevronDownImage from "../../assets/chevron-down.svg";
import chevronRightImage from "../../assets/chevron-right.svg";
import TextField from "../TextField";
import styles from "./styles.module.scss";

const TextFilter = (props) => {
  const [expanded, setExpanded] = useState(false);
  const { filterName, field, list, onFilterChanged, placeHolder } = props;

  const onChange = (event) => {
    const filteredList = list.filter(
      (item) =>
        item[field] &&
        item[field]
          .toString()
          .toLowerCase()
          .includes(event.target.value.toLowerCase())
    );
    onFilterChanged(filteredList);
  };

  return (
    <>
      <div className={styles["text-filter-container"]}>
        <p className={styles["text-filter-filter-title"]}>{filterName}</p>
        <img
          src={expanded ? chevronDownImage : chevronRightImage}
          className={styles["text-filter-expand-icon"]}
          onClick={() => {
            setExpanded(!expanded);
          }}
        />
      </div>
      {expanded && (
        <div className={styles["text-filter-text"]}>
          <TextField
            placeholder={`Enter ${placeHolder ? placeHolder : filterName}`}
            className={styles["text-filter-text-input"]}
            onChange={onChange}
          />
        </div>
      )}
    </>
  );
};

export default TextFilter;
