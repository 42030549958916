import contestStats from "../../assets/contest-stats.svg";
import myyStats from "../../assets/my-stats.svg";
import funnel from "../../assets/funnel.svg";
import "./styles.css";
import Card from "../Card";

function ContestStats(props) {
  const { stats, showTitles = true } = props;
  const { contestStatsData, myStatsData } = stats;

  return (
    <Card>
      <div className="contest-stats-container">
        {showTitles && (
          <text className="contest-stats-heading-text">Contest Stats</text>
        )}
        {showTitles && <text className="my-stats-heading-text">My Stats</text>}
        <div
          className="group-contest-stats"
          style={{
            backgroundImage: `url(${contestStats})`,
            backgroundSize: "contain",
          }}
        >
          <text className="contest-stats-profiles-submitted-text-pos">
            {contestStatsData.profilesSubmitted}
          </text>
          <text className="contest-stats-pro-shortlisted-text-pos">
            {contestStatsData.profilesShortListed}
          </text>
          <text className="contest-stats-l1-done-text-pos">
            {contestStatsData.l1Done}
          </text>
          <text className="contest-stats-l2-done-text-pos">
            {contestStatsData.l2Done}
          </text>
          <text className="contest-stats-hr-done-text-pos">
            {contestStatsData.hrDone}
          </text>
          <text className="contest-stats-offer-out-text-pos">
            {contestStatsData.offerOut}
          </text>
          <text className="contest-stats-joined-text-pos">
            {contestStatsData.joined}
          </text>
        </div>

        <div className="group-pyramid-boxes">
          <img src={funnel} alt="Funnel" className="contest-stats-img" />
        </div>

        <div
          className="group-my-stats"
          style={{
            backgroundImage: `url(${myyStats})`,
            backgroundSize: "contain",
          }}
        >
          <text className="my-stats-profiles-submitted-text-pos">
            {myStatsData.profilesSubmitted}
          </text>
          <text className="my-stats-pro-shortlisted-text-pos">
            {myStatsData.profilesShortListed}
          </text>
          <text className="my-stats-l1-done-text-pos">
            {myStatsData.l1Done}
          </text>
          <text className="my-stats-l2-done-text-pos">
            {myStatsData.l2Done}
          </text>
          <text className="my-stats-hr-done-text-pos">
            {myStatsData.hrDone}
          </text>
          <text className="my-stats-offer-out-text-pos">
            {myStatsData.offerOut}
          </text>
          <text className="my-stats-joined-text-pos">{myStatsData.joined}</text>
        </div>
      </div>
    </Card>
  );
}

export default ContestStats;
