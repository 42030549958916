import React from "react";
import Card from "../Card";
import styles from "./style.module.scss";

interface TimelineItemProps {
  date: string;
  event: string;
}

interface Props {
  // Array of timeline items
  timelineData: TimelineItemProps[];
  // Active stop in the timeline
  activeStopIndex?: number;
}

const Timeline: React.FC<Props> = (props) => {
  const { timelineData } = props;

  let activeStopIndex = props.activeStopIndex;

  if (activeStopIndex < 0) {
    activeStopIndex = 0;
  }

  return (
    <Card>
      <div className={styles["timeline-wrapper"]}>
        <p className={styles["timeline-title"]}>Timeline</p>
        <div className={styles["timeline-container"]}>
          <ul>
            {timelineData.map((timeline: TimelineItemProps, index: number) => {
              return (
                <li
                  key={index}
                  className={`${
                    index > activeStopIndex
                      ? styles["timeline-item-container"]
                      : index === activeStopIndex
                      ? styles["timeline-active-item-container"]
                      : styles["timeline-inactive-item-container"]
                  } ${
                    index === timelineData.length - 1
                      ? styles["timeline-last-item-container"]
                      : ""
                  } `}
                >
                  <div
                    className={`${
                      index > activeStopIndex
                        ? styles["timeline-item"]
                        : index === activeStopIndex
                        ? styles["timeline-active-item"]
                        : styles["timeline-inactive-item"]
                    }`}
                  >
                    <p
                      className={`${styles["timeline-item-title"]} ${
                        index === activeStopIndex
                          ? styles["timeline-active-item-title"]
                          : ""
                      } `}
                    >
                      {timeline.date}
                    </p>
                    <p
                      className={`${styles["timeline-item-description"]} ${
                        index === activeStopIndex
                          ? styles["timeline-active-item-description"]
                          : ""
                      }`}
                    >
                      {timeline.event}
                    </p>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </Card>
  );
};

export default Timeline;
