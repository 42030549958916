const parse = (response: any) => {
  const partner = response.data.attributes;

  const parsedPartnerDetails: any = {};

  parsedPartnerDetails.id = response.data.id;
  parsedPartnerDetails.logo = partner["partner_logo"];
  parsedPartnerDetails.coverImage = partner["cover_image"];
  parsedPartnerDetails.name = partner["partner_name"];
  parsedPartnerDetails.description = partner["short_descriptions"];
  parsedPartnerDetails.website = partner["website"];
  parsedPartnerDetails.joiningDate = partner["joining_date"];
  parsedPartnerDetails.source = partner["source"];
  parsedPartnerDetails.yearOfRegistration =
    partner["partner_company_year_of_incorporation"];
  parsedPartnerDetails.category = partner["partner_category"];
  parsedPartnerDetails.locations =
    partner["locations"] && partner["locations"].length > 0
      ? partner["locations"].toString()
      : "";
  parsedPartnerDetails.address = {
    line1: partner["headquater_address"]
      ? partner["headquater_address"]["headquater_line_1"]
      : "",
    line2: partner["headquater_address"]
      ? partner["headquater_address"]["headquater_line_2"]
      : "",
    pincode: partner["headquater_address"]
      ? partner["headquater_address"]["partner_pincode"]
      : "",
    city: partner["headquater_address"]
      ? partner["headquater_address"]["city"]
      : "",
    state: partner["headquater_address"]
      ? partner["headquater_address"]["state"]
      : "",
    country: partner["headquater_address"]
      ? partner["headquater_address"]["country"]
      : "",
    isdCode: partner["headquater_address"]
      ? partner["headquater_address"]["isd_code"]
      : "",
  };
  parsedPartnerDetails.faq = partner["frequently_asked_questions"];

  return parsedPartnerDetails;
};

export default parse;
