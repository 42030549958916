import taskImage from "../../assets/task.svg";
import backgroundImage from "../../assets/background.svg";
import skillImage from "../../assets/skill.svg";
import "./styles.css";

const JobDescription = (props: any) => {
  const { description, tasks, skills, background } = props;

  const renderTask = (icon, label, content) => {
    return (
      <div className="job-description-task-container">
        <img src={icon} alt={label} />
        <p className="job-description-task-title">{label}</p>
        <div
          className="job-description-task-content"
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        ></div>
      </div>
    );
  };

  return (
    <div className="job-description-container">
      <div className="job-description-tasks-container">
        {renderTask(taskImage, "Roles and Responsibilities", tasks)}
        {renderTask(backgroundImage, "Background", background)}
        {renderTask(skillImage, "Skills", skills)}
      </div>
      <p className="job-description-title">Full Job Description</p>
      <div
        className="job-description-content"
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      ></div>
    </div>
  );
};

export default JobDescription;
