import "./styles.scss";
import { COPYRIGHT } from "../../utils/constants/index";

const Copyright = (): JSX.Element => {
  return (
    <div className="copyright-container">
      <p className="copyright-text">{COPYRIGHT}</p>
    </div>
  );
};

export default Copyright;
