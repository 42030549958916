import TableCell from "../TableCell";
import "./styles.scss";

interface HeaderProps {
  index: number;
  text: string;
}
function HeaderColumn(props: HeaderProps) {
  const index: number = props.index;
  const text: string = props.text;
  return (
    <th>
      <p className="header-text" key={index}>
        {text}
      </p>
    </th>
  );
}

interface TableProps {
  headers: string[];
  data: any[];
}

const HHTable = (props: TableProps) => {
  return (
    <table>
      <thead>
        <tr>
          {props.headers.map((header, index, headers) => (
            <HeaderColumn index={index} text={header} key={index} />
          ))}
        </tr>
      </thead>
      <tbody>
        {props.data.map((rowData, dataIndex) => (
          <tr key={dataIndex}>
            {Object.values(rowData).map((cellValue, index, rowData) => (
              <td className="table-text" key={index}>
                <TableCell cellValue={cellValue} />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default HHTable;
