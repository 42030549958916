import { useSelector } from "react-redux";
import { partnerSelector } from "../../redux/selectors";
import Tag from "../Tag";
import styles from "./style.module.scss";

interface Props {
  partnerDetails?: any;
}

const PartnerDetails: React.FC<Props> = (props) => {
  const partnerDetails = props.partnerDetails
    ? props.partnerDetails
    : useSelector(partnerSelector);

  const handleWebsiteClick = () => {
    window.open(partnerDetails.website);
  };

  return (
    <div className={styles["partner-details-wrapper"]}>
      <img
        src={partnerDetails.coverImage}
        alt={partnerDetails.name}
        className={styles["partner-details-cover-image"]}
      />
      <div className={styles["partner-details-logo-container"]}>
        <img
          src={partnerDetails.logo}
          alt={partnerDetails.name}
          className={styles["partner-details-logo"]}
        />
      </div>
      <div className={styles["partner-details-container"]}>
        <p className={styles["partner-details-name"]}>{partnerDetails.name}</p>
        <p className={styles["partner-details-joining-date"]}>
          {`Emapneled Date: ${partnerDetails.joiningDate}`}
        </p>
        <hr className={styles["partner-details-separator"]} />
        <div className={styles["partner-details-flex-container"]}>
          <div className={styles["partner-details-flex-container"]}>
            <div>
              <div className={styles["partner-details-flex-container"]}>
                <p
                  className={`${styles["partner-details-label"]} ${styles["partner-details-max-width-label"]}`}
                >
                  Name
                </p>
                <p className={styles["partner-details-text"]}>
                  {partnerDetails.name}
                </p>
              </div>
              <div
                className={`${styles["partner-details-flex-container"]} ${styles["partner-details-margin-top-container"]}`}
              >
                <p
                  className={`${styles["partner-details-label"]} ${styles["partner-details-max-width-label"]}`}
                >
                  Year of incorporation
                </p>
                <p className={styles["partner-details-text"]}>
                  {partnerDetails.yearOfRegistration}
                </p>
              </div>
              <div
                className={`${styles["partner-details-flex-container"]} ${styles["partner-details-margin-top-container"]}`}
              >
                <p
                  className={`${styles["partner-details-label"]} ${styles["partner-details-max-width-label"]} `}
                >
                  Website
                </p>
                <p
                  className={`${styles["partner-details-text"]} ${styles["partner-details-website-text"]}`}
                  onClick={handleWebsiteClick}
                >
                  {partnerDetails.website}
                </p>
              </div>
              <div
                className={`${styles["partner-details-flex-container"]} ${styles["partner-details-margin-top-container"]}`}
              >
                <p
                  className={`${styles["partner-details-label"]} ${styles["partner-details-max-width-label"]}`}
                >
                  Source
                </p>
                <p className={styles["partner-details-text"]}>
                  {partnerDetails.source}
                </p>
              </div>
            </div>
          </div>
          <div className={styles["partner-details-flex-container"]}>
            <div>
              {partnerDetails.category && partnerDetails.category.length > 0 && (
                <div className={styles["partner-details-flex-container"]}>
                  <p
                    className={`${styles["partner-details-label"]} ${styles["partner-details-max-width-label"]}`}
                  >
                    Type
                  </p>
                  <div className={styles["partner-details-tags-container"]}>
                    {partnerDetails.category.map((tag) => {
                      return <Tag key={tag} title={tag} />;
                    })}
                  </div>
                </div>
              )}
              {partnerDetails.locations && partnerDetails.locations.length > 0 && (
                <div className={styles["partner-details-flex-container"]}>
                  <p
                    className={`${styles["partner-details-label"]} ${styles["partner-details-max-width-label"]}`}
                  >
                    Location(s)
                  </p>
                  <div className={styles["partner-details-tags-container"]}>
                    {partnerDetails.locations.split(",").map((tag) => {
                      return <Tag key={tag} title={tag} />;
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <hr className={styles["partner-details-separator"]} />
        <div className={styles["partner-details-flex-container"]}>
          <p
            className={`${styles["partner-details-label"]} ${styles["partner-details-max-width-label"]}`}
          >
            About {partnerDetails.name}
          </p>
          <p
            className={styles["partner-details-label"]}
            dangerouslySetInnerHTML={{
              __html: partnerDetails.description,
            }}
          ></p>
        </div>
        <hr className={styles["partner-details-separator"]} />
        <p className={styles["partner-details-headquarter-title"]}>
          Headquarter Address
        </p>
        <div className={styles["partner-details-flex-container"]}>
          <div className={styles["partner-details-flex-container"]}>
            <div>
              <div className={styles["partner-details-flex-container"]}>
                <p
                  className={`${styles["partner-details-label"]} ${styles["partner-details-max-width-label"]}`}
                >
                  Line 1
                </p>
                <p className={styles["partner-details-text"]}>
                  {partnerDetails.address.line1}
                </p>
              </div>
              <div
                className={`${styles["partner-details-flex-container"]} ${styles["partner-details-margin-top-container"]}`}
              >
                <p
                  className={`${styles["partner-details-label"]} ${styles["partner-details-max-width-label"]}`}
                >
                  Line 2
                </p>
                <p className={styles["partner-details-text"]}>
                  {partnerDetails.address.line2}
                </p>
              </div>
              <div
                className={`${styles["partner-details-flex-container"]} ${styles["partner-details-margin-top-container"]}`}
              >
                <p
                  className={`${styles["partner-details-label"]} ${styles["partner-details-max-width-label"]}`}
                >
                  Pincode
                </p>
                <p className={styles["partner-details-text"]}>
                  {partnerDetails.address.pincode}
                </p>
              </div>
              <div
                className={`${styles["partner-details-flex-container"]} ${styles["partner-details-margin-top-container"]}`}
              >
                <p
                  className={`${styles["partner-details-label"]} ${styles["partner-details-max-width-label"]}`}
                >
                  City
                </p>
                <p className={styles["partner-details-text"]}>
                  {partnerDetails.address.city}
                </p>
              </div>
            </div>
          </div>
          <div className={styles["partner-details-flex-container"]}>
            <div>
              <div className={styles["partner-details-flex-container"]}>
                <p
                  className={`${styles["partner-details-label"]} ${styles["partner-details-max-width-label"]}`}
                >
                  State
                </p>
                <p className={styles["partner-details-text"]}>
                  {partnerDetails.address.state}
                </p>
              </div>
              <div
                className={`${styles["partner-details-flex-container"]} ${styles["partner-details-margin-top-container"]}`}
              >
                <p
                  className={`${styles["partner-details-label"]} ${styles["partner-details-max-width-label"]}`}
                >
                  Country
                </p>
                <p className={styles["partner-details-text"]}>
                  {partnerDetails.address.country}
                </p>
              </div>
              <div
                className={`${styles["partner-details-flex-container"]} ${styles["partner-details-margin-top-container"]}`}
              >
                <p
                  className={`${styles["partner-details-label"]} ${styles["partner-details-max-width-label"]}`}
                >
                  ISD Code
                </p>
                <p className={styles["partner-details-text"]}>
                  {partnerDetails.address.isdCode}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerDetails;
