import axios from "axios";
import { CONTEST_API } from "../../../utils/constants/urls";

export default {
  getById: async (payload) => {
    let response;
    if (payload.recruiterId > 0) {
      response = await axios.get(
        `${CONTEST_API}/${payload.id}?recruiter_id=${payload.recruiterId}`
      );
    } else {
      response = await axios.get(`${CONTEST_API}/${payload.id}`);
    }

    return response.data;
  },
};
