import "./styles.scss";
import { ReactComponent as DeleteIcon } from "../../assets/delete.svg";
import Grid from "@mui/material/Grid";
import Button from "../Button";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: ".125rem solid #000",
  boxShadow: 24,
  p: 4,
};

interface Recruiter {
  name: string;
  email: string;
  phoneExtension: string;
  phoneNumber: string;
}

interface Props {
  recruiter: Recruiter;
  open: boolean;
  onClose: any;
  onConfirm: any;
}
const DeleteRecruiter = (props: Props) => {
  const { recruiter, open, onClose, onConfirm } = props;

  return (
    <div className="delete-recruiter-container">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box className="delete-modal">
            <DeleteIcon className="delete-recruiter" />
            <p className="delete-rec-heading">Delete Recruiter Details</p>
            <p className="delete-rec-question">
              Are you sure you want to delete the following details?
            </p>
            <div className="delete-rec-details-div">
              <Grid container spacing={4}>
                <Grid container item spacing={3}>
                  <Grid item xs={6}>
                    <p className="delete-rec-label">First Name</p>
                  </Grid>
                  <Grid item xs={6}>
                    <label className="delete-rec-data">{recruiter.name}</label>
                  </Grid>
                </Grid>
                <Grid container item spacing={3}>
                  <Grid item xs={6}>
                    <p className="delete-rec-label">Official Email Address</p>
                  </Grid>
                  <Grid item xs={6}>
                    <p className="delete-rec-data">{recruiter.email}</p>
                  </Grid>
                </Grid>
                <Grid container item spacing={3}>
                  <Grid item xs={6}>
                    <p className="delete-rec-label">Phone Number</p>
                  </Grid>
                  <Grid item xs={6}>
                    <p className="delete-rec-data">
                      {recruiter.phoneExtension} {recruiter.phoneNumber}
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            </div>

            <div className="div-row-flex">
              <Button
                label="Go Back"
                className="buttons"
                variant="outlined"
                onClick={onClose}
              />

              <Button
                label="Delete Details"
                className="buttons"
                variant="outlined"
                onClick={onConfirm}
              />
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default DeleteRecruiter;
