import { Grid } from "@mui/material";
import {
  whyHiringhoodRecruitersArray,
  whyHiringhoodEmployersArray,
  whyHiringHoodRecruitersText,
  whyHiringhoodEmployersText,
} from "../../utils/constants/site-data";
import InfoCard from "../InfoCard";
import WhyHiringhoodCard from "../WhyHiringhoodCard";
import "./styles.scss";
const whyHiringhood = () => {
  return (
    <div className="why-hiringhood-container">
      <Grid container spacing={4}>
        <Grid item container spacing={1}>
          <Grid item xs={4}>
            <WhyHiringhoodCard
              title={whyHiringHoodRecruitersText.title}
              description={whyHiringHoodRecruitersText.description}
            />
          </Grid>
          <Grid item xs={8}>
            <Grid container spacing={2.5}>
              {whyHiringhoodRecruitersArray.map((infoItem, index) => (
                <Grid item key={index}>
                  <InfoCard
                    icon={infoItem.icon}
                    title={infoItem.title}
                    description={infoItem.description}
                    height="16rem"
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid item container spacing={1}>
          <Grid item xs={8}>
            <Grid container spacing={2.5}>
              {whyHiringhoodEmployersArray.map((infoItem, index) => (
                <Grid item key={index}>
                  <InfoCard
                    icon={infoItem.icon}
                    title={infoItem.title}
                    description={infoItem.description}
                    height="16rem"
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <WhyHiringhoodCard
              title={whyHiringhoodEmployersText.title}
              description={whyHiringhoodEmployersText.description}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default whyHiringhood;
