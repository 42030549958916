import React from "react"

export const Timer=(props)=> {
    const [seconds, setSeconds] = React.useState(props.seconds);
  
    React.useEffect(() => {
      if (parseInt(seconds) > 0) {
        if(parseInt(seconds)<=10){
          setTimeout(() => setSeconds('0'+ (parseInt(seconds) - 1)), 1000);
        }else{
          setTimeout(() => setSeconds((parseInt(seconds) - 1).toString()), 1000);
        }
      } else if(props.restartTimer===false) {
        setSeconds('00');
        props.onEndTimer && props.onEndTimer();
      }

      if(props.restartTimer){
        if(parseInt(seconds) < 1){
          setSeconds(props.seconds)
          props.changeReset && props.changeReset();
        }
      }
    });

    return seconds;
}