import "./styles.css";

const TermsAndConditions = (props: any) => {
  const { termsAndConditions } = props;
  return (
    <>
      {!termsAndConditions && (
        <p className="terms-and-conditions-empty">
          There are no Terms & Conditions applicable to this contest
        </p>
      )}
      {termsAndConditions && (
        <div
          className="terms-and-conditions-container"
          dangerouslySetInnerHTML={{
            __html: termsAndConditions,
          }}
        ></div>
      )}
    </>
  );
};

export default TermsAndConditions;
