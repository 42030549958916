import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Success = (msg, id = 1, duration = 5000) => {
  return toast.success(msg, {
    toastId: id,
    position: "top-right",
    autoClose: duration,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const Error = (msg, id = 2, duration = 5000) => {
  return toast.error(msg, {
    toastId: id,
    position: "top-right",
    autoClose: duration,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const Toast = { Success, Error };

export default Toast;
