import {useEffect, useState} from "react";
import "./styles.scss";
import TextValidator from "../../components/TextValidator";
import * as Constants from "../../utils/constants";
import PhoneNumberValidator from "../../components/PhoneNumberValidator";
import SelectValidator from "../../components/SelectValidator";
import Checkbox from "../../components/Checkbox";
import Button from "../../components/Button";
import { CircularProgress } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {SalesforceContent} from "./salesforceContent";
import Background from "../../assets/login-background.svg";
import Card from "../../components/Card";
import Keycloak from "./keycloak";
import {EMAIL_NOTIFICATION_URL, GENERATE_OTP_URL, REGISTRATION_URL, VALIDATE_OTP_URL} from "./api-urls";
import axios from "axios";
import OtpInput from "react-otp-input";
import {EMAIL_OTP_REGISTER_MESSAGE, MOBILE_OTP_REGISTER_MESSAGE, TEMPLATE_ID} from "./site-data";
import {useSelector} from "react-redux";
import {hotlistJobSelected} from "../../redux/selectors";

const STRINGS = {
    PERSONA_REGISTRATION: {
        FOR_EMPLOYERS: "For Employers",
        REGISTRATION: "Registration",
        PERSONAS: {
            HEADER: "Persona",
            LABEL: "Select Persona *",
            PLACE_HOLDER: "Persona"
        },
        ORG_LEGAL_NAME: {
            HEADER: "Registered legal name of the Organisation",
            LABEL: "Organisation Name *",
            PLACE_HOLDER: "Organisation Legal Name"
        },
        SPOC: {
            HEADER: "SPOC Details",
            NAME: {
                HEADER: "Full name of SPOC *",
                LABEL: ["First Name *", "Middle Name", "Last Name *"],
                PLACE_HOLDER: ["First Name", "Middle Name", "Last Name"],
                ID: ["firstName", "middleName", "lastName"]
            },
            POSITION: {
                HEADER: "Official Position of SPOC",
                LABEL: "Enter Position *",
                PLACE_HOLDER: "Current Position / Designation"
            },
            EMAIL: {
                HEADER: "SPOC Official Email Address",
                LABEL: "Enter Email ID *",
                PLACE_HOLDER: "Official Email Address"
            },
            MOBILE: {
                HEADER: "SPOC Official Mobile Number",
                LABEL: "Enter Mobile Number *",
                PLACE_HOLDER: "Official Mobile Number"
            },
            ABOUT_US: {
                HEADER: "How did you come to know about us?",
                LABEL: "Select Option *",
                OPTIONS: ["Word of mouth", "Facebook", "LinkedIn", "Twitter", "Google Search", "Instagram", "Pinterest", "Hiringhood Website", "Others"]
            },
            SKILLS: {
                HEADER: "Which skills you would like to hire immediately?",
                LABEL: "Select Skills *",
            },
            CAPTCHA: "Please check the box below to proceed",
            AGREEMENT: "I have read and agreed to the Terms of Use and Privacy Policy"
        },
    },
};

interface FormProps {
    formData: FormData;
    callbackFunction: any;
}

interface FormData {
    persona: string;
    otherPersona: string;
    orgLegalName: string;
    firstName: string;
    middleName?: string;
    lastName: string;
    position: string;
    email: string;
    phoneExt: string;
    phone: string;
    surveyAnswer: string;
    surveyAnswerOther: string;
    hiringSkills: string;
}

const PersonaRegistration = () => {

    const formData = {
        persona: "",
        otherPersona: "",
        orgLegalName: "",
        firstName: "",
        middleName: "",
        lastName: "",
        position: "",
        email: "",
        phoneExt: "",
        phone: "",
        surveyAnswer: "",
        surveyAnswerOther: "",
        hiringSkills: ""
    } as FormData

    const jobSelected = useSelector(hotlistJobSelected);
    const [termsAgreed, setTermsAgreed] = useState(false);
    const [values, setValues] = useState(formData);
    const [otp, setOtp] = useState("");
    const [loading, setLoading] = useState(false);

    // Email OTP States
    const [emailOtpSent, setEmailOtpSent] = useState(false);
    const [disableVerify, setDisableVerify] = useState(true);
    const [isEmailOtpValid, setIsEmailOtpValid] = useState(false);
    const [waitOnEmailSendOtp, setWaitOnEmailSendOtp] = useState(false);
    const [waitOnEmailVerifyOtp, setWaitOnEmailVerifyOtp] = useState(false);
    const [sendEmailOtpError, setSendEmailOtpError] = useState("");
    const [verifyEmailOtpError, setVerifyEmailOtpError] = useState("");
    const [verifiedEmail, setVerifiedEmail] = useState("");

    // Phone OTP States
    const [phoneOtpSent, setPhoneOtpSent] = useState(false);
    const [isPhoneOtpValid, setIsPhoneOtpValid] = useState(false);
    const [waitOnPhoneSendOtp, setWaitOnPhoneSendOtp] = useState(false);
    const [waitOnPhoneVerifyOtp, setWaitOnPhoneVerifyOtp] = useState(false);
    const [sendPhoneOtpError, setSendPhoneOtpError] = useState("");
    const [verifyPhoneOtpError, setVerifyPhoneOtpError] = useState("");
    const [verifiedPhone, setVerifiedPhone] = useState("");

    const validationSchema = Yup.object().shape({
        persona: Yup.string().required("persona is required"),
        otherPersona: Yup.string()
            .trim()
            .notRequired()
            .default("other"),
        orgLegalName: Yup.string().required("Organisation legal name is required"),
        firstName: Yup.string().trim().required("First name is required"),
        middleName: Yup.string().trim().notRequired().default(""),
        lastName: Yup.string().trim().required("Last name is required"),
        position: Yup.string().trim().required("Position is required"),
        email: Yup.string()
            .trim()
            .required("Official email address is required")
            .matches(Constants.EMAIL_REGEX, "Email address is invalid"),
        phoneExt: Yup.string()
            .trim()
            .notRequired()
            .default("+91")
            .matches(Constants.PHONE_EXT_REGEX, "Phone extension is invalid"),
        phone: Yup.string()
            .trim()
            .required("Phone number is required")
            .matches(Constants.PHONE_REGEX, "Phone number is invalid"),
        surveyAnswer: Yup.string()
            .default(values.surveyAnswer)
            .required("Please select an option"),
        surveyAnswerOther: Yup.string()
            .trim()
            .notRequired()
            .default(""),
        hiringSkills: Yup.string().trim().required("Hiring skill(s) required")
    });

    const {
        register,
        handleSubmit,
        setValue,
        trigger,
        formState: { errors, isValid },
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: "onBlur",
        shouldFocusError: true,
        criteriaMode: "all",
    });

    useEffect(() => {
        Object.keys(values).map((key) => {
            setValue(key, values[key]);
        });
    }, []);

    const handleChange = (formItem: string) => (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setValues({ ...values, [formItem]: event.target.value });
        setValue(formItem, event.target.value, { shouldValidate: true });
        return;
    };

    const handleOtp = (otp: string) => {
        setOtp(otp);
        if (otp.length === 4) {
            setDisableVerify(false);
        } else {
            setDisableVerify(true);
        }
    };

    const registerPersona = async () => {

        setLoading(true);
        await axios.post(REGISTRATION_URL,
            {email_id: values.email,
                persona: values.persona,
                phone_number: values.phoneExt + values.phone
            }).then(response => {
            if(response.status === 200) {
                setLoading(false)
                return;
            }
        }).catch(error => {
            setLoading(false);
        });
    }

    const sendJSProfilesToSales = async () => {
        const jsIdsArray: string[] = [];
        jobSelected.map((item) => {
            jsIdsArray.push(item.id);
        });
        const requestPayload = {
            "processDefinitionKey": "Process_rm46fob",
            "businessKey": "10003",
            "variables": {
                "templateId": TEMPLATE_ID,
                "to": process.env.REACT_APP_SALES_EMAIL,
                "from": process.env.REACT_APP_SUPPORT_EMAIL,
                "subject": "Request for JS details",
                "persona": values.persona,
                "email": values.email,
                "phoneNumber": values.phoneExt + values.phone,
                "jobSeekers": jsIdsArray.toString()
            }
        };

        const token = await Keycloak.fetchToken();

        return await axios.post(EMAIL_NOTIFICATION_URL, requestPayload, {
            headers: {
                Authorization: `Bearer ${token.token}`,
            },
        });
    }

    const handleSendOTP = (otpType: string) => async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        otpType === "email" ? setWaitOnEmailSendOtp(true) : setWaitOnPhoneSendOtp(true);
        const token = await Keycloak.fetchToken();
        const otpSentResponse: any = await axios
            .post(
                GENERATE_OTP_URL,
                {
                    channel: otpType,
                    from: otpType === "email" ? process.env.REACT_APP_SUPPORT_EMAIL : process.env.REACT_APP_SUPPORT_MOBILE,
                    to: otpType === "email" ? values.email : values.phoneExt.trim() + values.phone,
                    subject: "Hiringhood Registration",
                    body: otpType === "email" ? EMAIL_OTP_REGISTER_MESSAGE : MOBILE_OTP_REGISTER_MESSAGE,
                },
                { headers: { Authorization: `Bearer ${token.token}` } }
            )
            .catch((err) => {
                if(otpType === "email") {
                    setSendEmailOtpError(
                        "There was an error while trying to send an OTP to your email address. Please try again later"
                    );
                    setWaitOnEmailSendOtp(false);
                } else {
                    setSendPhoneOtpError(
                        "There was an error while trying to send an OTP to your mobile number. Please try again later"
                    );
                    setWaitOnPhoneSendOtp(false);
                }

            });
        if (otpSentResponse?.data?.success) {
            if(otpType === "email") {
                setEmailOtpSent(true);
                setWaitOnEmailSendOtp(false);
                setSendEmailOtpError("");
            } else {
                setPhoneOtpSent(true);
                setWaitOnPhoneSendOtp(false);
                setSendPhoneOtpError("");
            }
        }
    };

    const handleVerifyOTP = (otpType: string) => async (event: React.ChangeEvent<HTMLInputElement>) => {
        otpType === "email" ? setWaitOnEmailVerifyOtp(true) : setWaitOnPhoneVerifyOtp(true);
        const token = await Keycloak.fetchToken();
        const verifyResponse: any = await axios
            .post(
                VALIDATE_OTP_URL,
                {
                    channel: otpType,
                    to: otpType === "email" ? values.email : values.phoneExt.trim() + values.phone,
                    otp: otp,
                },
                { headers: { Authorization: `Bearer ${token.token}` } }
            )
            .catch((err) => {
                console.log(err);
                const otpErrorMsg = "There was an error validating the OTP. Please make sure you have entered the correct OTP and try again."
                if(otpType === "email") {
                    setIsEmailOtpValid(false);
                    setWaitOnEmailVerifyOtp(false);
                    setVerifyEmailOtpError(otpErrorMsg);
                } else {
                  setIsPhoneOtpValid(false);
                  setWaitOnPhoneVerifyOtp(false);
                    setVerifyPhoneOtpError(otpErrorMsg);
                }
            })
            .catch((err) => {
                console.log(err);
            });
        if (verifyResponse?.data?.success) {
            if(otpType === "email") {
                setIsEmailOtpValid(true);
                setWaitOnEmailVerifyOtp(false);
                setVerifyEmailOtpError("");
                setVerifiedEmail(values.email);
            } else {
                setIsPhoneOtpValid(true);
                setWaitOnPhoneVerifyOtp(false);
                setVerifyPhoneOtpError("");
                setVerifiedPhone(values.phoneExt.trim() + values.phone.trim())
            }
        }
    };


    const onDeclaration = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTermsAgreed(e.target.checked);
    };

    const onClickProceed = async (data) => {
        setSalesForceData(data);
        await registerPersona();
        await sendJSProfilesToSales();
        (document.getElementById("submit")as HTMLInputElement).click();
    };

    const setSalesForceData = (data: any) => {
        (document.getElementById("00N5i00000IgObK") as HTMLInputElement).value = data.persona;
        (document.getElementById("company")as HTMLInputElement).value = data.orgLegalName;
        (document.getElementById("first_name")as HTMLInputElement).value = data.firstName;
        (document.getElementById("00N5i00000IfhC1")as HTMLInputElement).value = data.middleName;
        (document.getElementById("last_name")as HTMLInputElement).value = data.lastName;
        (document.getElementById("title")as HTMLInputElement).value = data.position;
        (document.getElementById("email")as HTMLInputElement).value = data.email;
        (document.getElementById("00N5i00000IfhCL")as HTMLInputElement).value = data.phoneExt;
        (document.getElementById("phone")as HTMLInputElement).value = data.phone;
        (document.getElementById("00N5i00000IfhC6") as HTMLInputElement).value = data.surveyAnswer;
        (document.getElementById("00N5i00000IfhCB")as HTMLInputElement).value = data.surveyAnswerOther;
        (document.getElementById("00N5i00000IfhCG")as HTMLInputElement).value = data.hiringSkills;
        let returnURL = window.location.href.split("/register/persona")[0];
        if (returnURL.includes("localhost")) {
            returnURL = "https://dev.hiringhood.com/hotlist"
        } else {
            returnURL = returnURL + "/hotlist";
        }
        (document.getElementById("retURL")as HTMLInputElement).value = returnURL;
    }

    const renderPage = () => {
        return (
            <div className={"employer-registration-form-container"}>
                <form onSubmit={handleSubmit(onClickProceed)}>
                    <p className={"form-row-header org-legal-name"}>{STRINGS.PERSONA_REGISTRATION.PERSONAS.HEADER}</p>
                    <div className={"form-row"}>
                        <p>{STRINGS.PERSONA_REGISTRATION.PERSONAS.LABEL}</p>
                        <div className="employer-registration-div-field-survey info-dropdown">
                            <SelectValidator
                                name="persona"
                                label=""
                                value={values.persona}
                                list={Constants.PERSONAS}
                                callback={handleChange("persona")}
                                register={register}
                                errorMessage={errors.persona?.message}
                            />
                            {values.persona.toLowerCase() === "other" && (
                                <TextValidator
                                    id="otherPersona"
                                    className="form-row-input"
                                    label=""
                                    placeholder="Enter other persona"
                                    value={values.otherPersona}
                                    register={register}
                                    onChange={handleChange("otherPersona")}
                                    errorMessage={errors.otherPersona?.message}
                                />
                            )}
                        </div>
                    </div>

                    <p className={"form-row-header"}>{STRINGS.PERSONA_REGISTRATION.ORG_LEGAL_NAME.HEADER}</p>
                    <div className={"form-row"}>
                        <p>{STRINGS.PERSONA_REGISTRATION.ORG_LEGAL_NAME.LABEL}</p>
                        <TextValidator
                            id="orgLegalName"
                            label=""
                            placeholder={STRINGS.PERSONA_REGISTRATION.ORG_LEGAL_NAME.PLACE_HOLDER}
                            value={values.orgLegalName}
                            register={register}
                            onChange={handleChange("orgLegalName")}
                            errorMessage={errors.orgLegalName?.message}
                        />
                    </div>

                    <div>
                        <p className={"form-row-header"}>{STRINGS.PERSONA_REGISTRATION.SPOC.HEADER}</p>
                        {STRINGS.PERSONA_REGISTRATION.SPOC.NAME.ID.map((name, idx) => (
                            <div className={"form-row"} key={idx}>
                                <label>{STRINGS.PERSONA_REGISTRATION.SPOC.NAME.LABEL[idx]}</label>
                                <TextValidator
                                    id={name}
                                    label=""
                                    placeholder={STRINGS.PERSONA_REGISTRATION.SPOC.NAME.PLACE_HOLDER[idx]}
                                    value={values[name]}
                                    register={register}
                                    onChange={handleChange(name)}
                                    errorMessage={errors[name]?.message}
                                />
                            </div>
                        ))}
                    </div>

                    <p className={"form-row-header"}>{STRINGS.PERSONA_REGISTRATION.SPOC.POSITION.HEADER}</p>
                    <div className={"form-row"}>
                        <p>{STRINGS.PERSONA_REGISTRATION.SPOC.POSITION.LABEL}</p>
                        <TextValidator
                            id={"position"}
                            label=""
                            placeholder={STRINGS.PERSONA_REGISTRATION.SPOC.POSITION.PLACE_HOLDER}
                            value={values.position}
                            register={register}
                            onChange={handleChange("position")}
                            errorMessage={errors.position?.message}
                        />
                    </div>

                    <p className={"form-row-header"}>{STRINGS.PERSONA_REGISTRATION.SPOC.EMAIL.HEADER}</p>
                    {!emailOtpSent && (
                        <>
                            <div className={"form-row"}>
                                <p>{STRINGS.PERSONA_REGISTRATION.SPOC.EMAIL.LABEL}</p>
                                <div className={"verify-text"}>
                                    <TextValidator
                                        className={"verify-text-email"}
                                        id={"emailAddress"}
                                        label=""
                                        placeholder={STRINGS.PERSONA_REGISTRATION.SPOC.EMAIL.PLACE_HOLDER}
                                        value={values.email}
                                        register={register}
                                        onChange={handleChange("email")}
                                        errorMessage={errors.email?.message}
                                    />
                                </div>
                                <div className={"verify-btn"}>
                                    <Button
                                        label="Verify"
                                        onClick={handleSendOTP("email")}
                                        disabled={waitOnEmailSendOtp || !values.email || errors.email}
                                    />
                                    {waitOnEmailSendOtp && <CircularProgress size={32} />}
                                </div>
                                <p className="register-error">{sendEmailOtpError}</p>
                            </div>
                        </>
                    )}


                    {emailOtpSent && !isEmailOtpValid && (
                        <>
                            <p className="register-enter-otp">{`Enter the OTP received on your email address: ${values.email}`}</p>
                            <div className="register-email-container">
                                <OtpInput
                                    shouldAutoFocus
                                    numInputs={4}
                                    isInputNum
                                    inputStyle={"register-otp-input"}
                                    onChange={handleOtp}
                                    value={otp}
                                    isDisabled={waitOnEmailVerifyOtp}
                                />
                                <div className="register-verify-otp-container">
                                    <Button
                                        label="Verify OTP"
                                        className="register-verify-otp"
                                        onClick={handleVerifyOTP("email")}
                                        disabled={disableVerify || waitOnEmailVerifyOtp}
                                    />
                                    {!isEmailOtpValid && waitOnEmailVerifyOtp && <CircularProgress size={32} />}
                                </div>
                            </div>
                            {verifyEmailOtpError && <p className="register-error">{verifyEmailOtpError}</p>}
                        </>
                    )}
                    {isEmailOtpValid && (
                        <div className={"otp-verification-done"}>
                            {`Email : ${verifiedEmail}`}{" "}
                            <DoneIcon
                                color="success"
                                style={{ marginLeft: "0.5rem", fontWeight: "bold" }}
                            />
                        </div>
                    )}

                    <p className={"form-row-header"}>{STRINGS.PERSONA_REGISTRATION.SPOC.MOBILE.HEADER}</p>
                    {!phoneOtpSent && (
                        <>
                            <div className={"form-row"}>
                                <p>{STRINGS.PERSONA_REGISTRATION.SPOC.MOBILE.LABEL}</p>
                                <div className={"verify-text"}>
                                    <PhoneNumberValidator
                                        id="phoneNumber"
                                        label=""
                                        placeholder={STRINGS.PERSONA_REGISTRATION.SPOC.MOBILE.PLACE_HOLDER}
                                        register={register}
                                        phone={values.phone}
                                        phoneExt={values.phoneExt}
                                        onPhoneChange={handleChange("phone")}
                                        onExtChange={handleChange("phoneExt")}
                                        errorMessage={
                                            errors.phoneExt
                                                ? errors.phoneExt.message
                                                : errors.phone?.message
                                        }
                                    />
                                </div>
                                <div className={"verify-btn"}>
                                    <Button
                                        label="Verify"
                                        onClick={handleSendOTP("mobile")}
                                        disabled={waitOnPhoneSendOtp || values.phoneExt.length !== 3 || (values.phone.length !== 10)}
                                    />
                                    {waitOnPhoneSendOtp && <CircularProgress size={32} />}
                                </div>
                                <p className="register-error">{sendPhoneOtpError}</p>
                            </div>
                        </>
                    )}


                    {phoneOtpSent && !isPhoneOtpValid && (
                        <>
                            <p className="register-enter-otp">{`Enter the OTP received on your mobile phone: ${values.phone}`}</p>
                            <div className="register-email-container">
                                <OtpInput
                                    shouldAutoFocus
                                    numInputs={4}
                                    isInputNum
                                    inputStyle={"register-otp-input"}
                                    onChange={handleOtp}
                                    value={otp}
                                    isDisabled={waitOnPhoneVerifyOtp}
                                />
                                <div className="register-verify-otp-container">
                                    <Button
                                        label="Verify OTP"
                                        className="register-verify-otp"
                                        onClick={handleVerifyOTP("mobile")}
                                        disabled={disableVerify || waitOnPhoneVerifyOtp}
                                    />
                                    {!isPhoneOtpValid && waitOnPhoneVerifyOtp && <CircularProgress size={32} />}
                                </div>
                            </div>
                            {verifyPhoneOtpError && <p className="register-error">{verifyPhoneOtpError}</p>}
                        </>
                    )}
                    {isPhoneOtpValid && (
                        <div className={"otp-verification-done"}>
                            {`Phone : ${verifiedPhone}`}{" "}
                            <DoneIcon
                                color="success"
                                style={{ marginLeft: "0.5rem", fontWeight: "bold" }}
                            />
                        </div>
                    )}

                    <p className={"form-row-header"}>{STRINGS.PERSONA_REGISTRATION.SPOC.ABOUT_US.HEADER}</p>
                    <div className={"form-row"}>
                        <p>{STRINGS.PERSONA_REGISTRATION.SPOC.ABOUT_US.LABEL}</p>
                        <div className="employer-registration-div-field-survey info-dropdown">
                            <SelectValidator
                                name="surveyAnswer"
                                label=""
                                value={values.surveyAnswer}
                                list={Constants.SURVEY_LIST}
                                callback={handleChange("surveyAnswer")}
                                register={register}
                                errorMessage={errors.surveyAnswer?.message}
                            />
                            {values.surveyAnswer.toLowerCase() === "other" && (
                                <TextValidator
                                    id="surveyAnswerOther"
                                    className="form-row-input"
                                    label=""
                                    placeholder="Enter other answer"
                                    value={values.surveyAnswerOther}
                                    register={register}
                                    onChange={handleChange("surveyAnswerOther")}
                                    errorMessage={errors.surveyAnswerOther?.message}
                                />
                            )}
                        </div>
                    </div>

                    <p className={"form-row-header"}>{STRINGS.PERSONA_REGISTRATION.SPOC.SKILLS.HEADER}</p>
                    <div className={"form-row"}>
                        <p>{STRINGS.PERSONA_REGISTRATION.SPOC.SKILLS.LABEL}</p>
                        <div className="employer-registration-div-field-survey info-dropdown">
                            <SelectValidator
                                name="hiringSkills"
                                label=""
                                value={values.hiringSkills}
                                list={Constants.SKILL_SETS}
                                callback={handleChange("hiringSkills")}
                                register={register}
                                errorMessage={errors.hiringSkills?.message}
                            />
                        </div>
                    </div>

                    <div className="emp-registration-disclaimer-container">
                        <div className="emp-disclamer-info-container">
                            <Checkbox
                                aria-labelledby="disclaimer-text"
                                onChange={onDeclaration}
                            />
                            <p id="disclaimer-text">
                                I have read and agreed to the{" "}
                                <a
                                    href={`${process.env.REACT_APP_PUBLIC_BUCKET_URL}legal-docs/Terms+of+Use_Recruitment+Agency.pdf`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Terms of Use
                                </a>{" "}
                                and{" "}
                                <a
                                    href={`${process.env.REACT_APP_PUBLIC_BUCKET_URL}legal-docs/Privacy+Policy_HiringHood.pdf`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Privacy Policy
                                </a>
                            </p>
                        </div>
                        <Button
                            variant="primary"
                            label="Proceed"
                            disabled={!isValid || !termsAgreed }
                        />
                    </div>
                    <p className={"invalid-feedback"}>{errors?.message}</p>
                </form>
            </div>
        )
    };

    return (
        <>
            <div>
                <div className="edit-profile-banner-container">
                    <img
                        className="edit-profile-banner"
                        style={{
                            backgroundImage: `url(${Background})`,
                            backgroundSize: "contain",
                            position: "relative",
                            backgroundRepeat: "no-repeat",
                        }}
                    />
                    <div className="edit-profile-heading-container">
                        <p className="edit-profile-heading">
                            Registration
                        </p>
                    </div>
                </div>
                <div className="edit-profile-background">
                    <div className="edit-profile-card">
                        <Card>{renderPage()}</Card>
                    </div>
                </div>
            </div>
            <div className={"emp-salesforce-content"} dangerouslySetInnerHTML={{__html: SalesforceContent}} />
        </>
    );
};

export default PersonaRegistration;
