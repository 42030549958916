import { useState } from "react";
import TitleMultiColor from "../TitleMultiColor";
import Button from "../Button";
import styles from "./style.module.scss";
import Contact from "../Contact";

const BookACall = () => {
  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  return (
    <div
      className={styles["book-a-call-wrapper"]}
      style={{ filter: open ? "blur(3px)" : "none" }}
    >
      <div className={styles["book-a-call-container"]}>
        <TitleMultiColor
          labels={["Let's Chat", "We Are User Friendly"]}
          colors={["#FFFFFF", "#00C15D"]}
        />
        <p className={styles["book-a-call-text"]}>
          Struggling with building a team and falling behind deadlines ? Need to
          throttle recruitment for your existing teams ?
        </p>
        <p className={styles["book-a-call-text"]}>
          We’re here to help you. We work with Indian companies to fill their
          immediate requirement and help them to scale manpower at break-neck
          speed!
        </p>
        <Button
          label="Book a call"
          variant="secondary"
          className={styles["book-a-call-button"]}
          onClick={() => {
            setOpen(true);
          }}
        />
        {open && <Contact onClose={onClose} />}
      </div>
    </div>
  );
};

export default BookACall;
