import { SET_EMPLOYER } from "../actions/employer";

const emploeyrReducer = (state = { employer: {} }, action) => {
  switch (action.type) {
    case SET_EMPLOYER:
      return action.payload;
    default:
      return state;
  }
};

export default emploeyrReducer;
