import React, { useState, useEffect } from "react";
import "./styles.scss";
import Breadcrumbs from "../Breadcrumbs";
import Button from "../Button";
import * as Constants from "../../utils/constants/index";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import TextValidator from "../TextValidator";
import SelectValidator from "../SelectValidator";
import { getCities, getStates } from "../../utils/constants/locations";
import PhoneNumberValidator from "../PhoneNumberValidator";
import { CircularProgress } from "@material-ui/core";
import CustomUploadFile from "../CustomUploadFile";
import { useSelector } from "react-redux";
import { userInfoSelector } from "../../redux/selectors";

interface Props {
  currentStep: number;
  onNext: any;
  onSkip: any;
  onGotoStep: any;
  userStage: number;
  data: any;
  loading?: boolean;
  error?: any;
  disabled?: boolean;
}

interface Address {
  line1: string;
  line2: string;
  pincode: string;
  city: string;
  state: string;
  country: string;
}

interface CompanyDetails {
  companyName: string;
  certificate: any;
  certificateNote: string;
  logo: string;
  yearOfRegistration: string;
  firstName: string;
  lastName: string;
  designation: string;
  website: string;
  emailAddress: string;
  type: string;
  alternateEmailAddress: string;
  phoneExt: string;
  phoneNumber: string;
  altPhoneNumber: string;
  about: string;
  companyAddress: Address;
}

const initializeAddress = () => {
  return {
    line1: "",
    line2: "",
    pincode: "",
    city: "",
    state: "",
    country: "India",
  };
};

const initialize = (userInfo) => {
  return {
    companyName: "",
    certificate: null,
    certificateNote: "",
    logo: "",
    yearOfRegistration: "",
    firstName: userInfo.firstName,
    lastName: userInfo.lastName,
    designation: "",
    website: "",
    emailAddress: userInfo.emailAddress,
    type: "",
    alternateEmailAddress: "",
    phoneExt: userInfo.phoneExt,
    phoneNumber: userInfo.phoneNumber,
    altPhoneNumber: userInfo.altPhoneNumber,
    about: "",
    companyAddress: initializeAddress(),
  };
};

const CompanyDetails = (props: Props) => {
  const {
    currentStep,
    onNext,
    onSkip,
    onGotoStep,
    userStage,
    data,
    loading = false,
    error = {},
    disabled = false,
  } = props;
  const userInfo = useSelector(userInfoSelector);
  const [companyDetails, setCompanyDetails] = useState(userInfo);
  const [companyAddress, setCompanyAddress] = useState<Address>(
    data && data.companyAddress ? data.companyAddress : initializeAddress()
  );
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const years = [];
  for (let i = year; i >= year - 100; i--) years.push(i);

  useEffect(() => {
    let validData = initialize(userInfo);
    if (data && data.firstName) {
      validData = data;
    }
    setCompanyDetails(validData);
    setCompanyAddress(
      validData.companyAddress ? validData.companyAddress : initializeAddress()
    );
    Object.keys(validData).map((key) => {
      if (key === "companyAddress") {
        Object.keys(validData[key]).map((addressKey) => {
          setValue(addressKey, validData[key][addressKey]);
        });
      }
      setValue(key, validData[key]);
    });
  }, [data]);
  const websiteRegex =
    "(https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,})";

  const validationSchema = Yup.object().shape({
    companyName: Yup.string().trim().min(1, "Company name is required"),
    firstName: Yup.string().trim().required("SPOC first name is required"),
    lastName: Yup.string().trim().required("SPOC last name is required"),
    designation: Yup.string().trim().required("Designation is required"),
    yearOfRegistration: Yup.string().required(
      "Year of company registration is required"
    ),
    website: Yup.string()
      .trim()
      .matches(new RegExp(websiteRegex), "Website URL is not valid")
      .required("Website is required"),
    emailAddress: Yup.string()
      .trim()
      .nullable()
      .notRequired()
      .email("Enter valid email address"),
    type: Yup.string().required("Company type is required"),
    alternateEmailAddress: Yup.string()
      .trim()
      .nullable()
      .notRequired()
      .email("Enter valid email address")
      .when("emailAddress", {
        is: (value) => value.length > 0,
        then: Yup.string()
          .lowercase()
          .notOneOf(
            [
              companyDetails.emailAddress &&
                companyDetails.emailAddress.toString().toLowerCase(),
            ],
            "Alternate email address cannot be same as primary email address"
          ),
      }),
    phoneExt: Yup.string()
      .trim()
      .nullable()
      .notRequired()
      .default("+91")
      .matches(Constants.PHONE_EXT_REGEX, {
        message: "Phone extension is not valid",
        excludeEmptyString: true,
      }),
    phoneNumber: Yup.string()
      .trim()
      .nullable()
      .notRequired()
      .matches(Constants.PHONE_REGEX, {
        message: "Phone number is not valid",
        excludeEmptyString: true,
      }),
    altPhoneNumber: Yup.string()
      .trim()
      .nullable()
      .notRequired()
      .matches(Constants.LANDLINE_REGEX, {
        message: "Phone number is not valid",
        excludeEmptyString: true,
      })
      .test({
        name: "altPhoneNumber",
        message:
          "Alternate phone number cannot be same as primary phone number",
        test: function (value) {
          if (!value) {
            return true;
          }
          if (
            value === this.parent.phoneNumber ||
            value === this.parent.phoneExt + this.parent.phoneNumber
          )
            return false;
          return true;
        },
      }),
    about: Yup.string()
      .trim()
      .required("Description about the company is required"),
    line1: Yup.string().trim().required("Company address line 1 is required"),
    line2: Yup.string().trim().required("Company address line 2 is required"),
    pincode: Yup.string()
      .trim()
      .required("Pincode is required")
      .matches(Constants.PINCODE_REGEX, "Pincode is not valid"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    country: Yup.string().default("India").required("Country is required"),
    certificateNote: Yup.string().trim().nullable().notRequired(),
  });
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(validationSchema, {
      abortEarly: false,
      recursive: true,
    }),
    mode: "onBlur",
    shouldFocusError: true,
    criteriaMode: "all",
  });

  const handleChange = (formItem: string, value: any) => {
    setCompanyDetails({ ...companyDetails, [formItem]: value });
    setValue(formItem, value, { shouldValidate: true });
    return;
  };

  const handleAddressChange = (formItem: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCompanyAddress({ ...companyAddress, [formItem]: event.target.value });
    setCompanyDetails({
      ...companyDetails,
      ["companyAddress"]: companyAddress,
    });

    setValue(formItem, event.target.value, { shouldValidate: true });
    return;
  };

  const onClickNext = (data: any) => {
    onNext(data);
  };

  const gotoStep = (toStep: number) => {
    onGotoStep(toStep);
  };

  const stepNames = Constants.EDIT_PROFILE_STEPS;
  const totalSteps = stepNames.length;

  return (
    <div className="form-container">
      {loading && <CircularProgress className="loading" />}
      {!loading && (
        <>
          <div className="div-breadcrumb">
            <Breadcrumbs
              currentStep={currentStep}
              totalSteps={totalSteps}
              callBackFunction={gotoStep}
              stepNames={stepNames}
              userStage={userStage}
            />
          </div>

          <form onSubmit={handleSubmit(onClickNext)} className="div-form">
            <div className="div-row-flex">
              <TextValidator
                id="companyName"
                className="email-input"
                label="Enter Company Name*"
                register={register}
                value={companyDetails.companyName}
                onChange={(event) =>
                  handleChange("companyName", event.target.value)
                }
                errorMessage={errors.companyName?.message}
                disabled={disabled}
              />
            </div>

            <div className="div-row-flex">
              <div className="textfield-wrapper">
                <label
                  htmlFor="about"
                  className={
                    companyDetails.about && companyDetails.about.length > 0
                      ? "active"
                      : ""
                  }
                >
                  Description about the company*
                </label>
                <textarea
                  className="certificate"
                  maxLength={150}
                  name="about"
                  value={companyDetails.about}
                  {...register("about")}
                  onChange={(event) =>
                    handleChange("about", event.target.value)
                  }
                  disabled={disabled}
                />
                <p className="invalid-feedback">{errors.about?.message}</p>
              </div>
            </div>
            <div>
              <CustomUploadFile
                id="upload-company-logo"
                onUploadSuccess={(value) => handleChange("logo", value)}
                fileName={companyDetails.logo}
                label="Upload Company Logo"
                buttonLabel="Upload Logo"
                accept=".jpg,.jpeg,.png"
                showDropArea={true}
                disabled={disabled}
              />
            </div>

            <div>
              <CustomUploadFile
                id="upload-company-certificate"
                note={companyDetails.certificateNote}
                fileName={companyDetails.certificate}
                notePlaceholder="Upload Certificate of Incorporation. Please specify?"
                register={register}
                buttonLabel="Upload Certificate"
                accept=".pdf"
                showNote={true}
                handleChange={(event) =>
                  handleChange("certificateNote", event.target.value)
                }
                onUploadSuccess={(value) => handleChange("certificate", value)}
                disabled={disabled}
              />
            </div>

            <div className="div-row-flex">
              <SelectValidator
                label="Company Year Of Registration *"
                name="yearOfRegistration"
                list={years}
                register={register}
                value={companyDetails.yearOfRegistration}
                callback={(event) =>
                  handleChange("yearOfRegistration", event.target.value)
                }
                disabled={disabled}
              />
            </div>
            <div className="div-row-flex">
              <TextValidator
                id="firstName"
                className="name-input"
                label="SPOC First Name*"
                register={register}
                value={companyDetails.firstName}
                onChange={(event) =>
                  handleChange("firstName", event.target.value)
                }
                errorMessage={errors.firstName?.message}
                disabled={disabled}
              />
              <TextValidator
                id="lastName"
                className="name-input"
                label="SPOC Last Name*"
                register={register}
                value={companyDetails.lastName}
                onChange={(event) =>
                  handleChange("lastName", event.target.value)
                }
                errorMessage={errors.lastName?.message}
                disabled={disabled}
              />
            </div>
            <div className="div-row-flex">
              <TextValidator
                id="designation"
                className="email-input"
                label="Enter Designation*"
                register={register}
                value={companyDetails.designation}
                onChange={(event) =>
                  handleChange("designation", event.target.value)
                }
                errorMessage={errors.designation?.message}
                disabled={disabled}
              />
            </div>
            <div className="div-row-flex">
              <TextValidator
                id="website"
                className="email-input"
                label="Your Website*"
                register={register}
                value={companyDetails.website}
                onChange={(event) =>
                  handleChange("website", event.target.value)
                }
                errorMessage={errors.website?.message}
                disabled={disabled}
              />
            </div>
            <div className="div-row-flex">
              <SelectValidator
                label="Select Company Type*"
                name="type"
                register={register}
                value={companyDetails.type}
                list={Constants.COMPANY_TYPES}
                callback={(event) => handleChange("type", event.target.value)}
                errorMessage={errors.type?.message}
                disabled={disabled}
              />
            </div>
            <div className="div-row-flex">
              <TextValidator
                id="emailAddress"
                className="email-input"
                label="Enter Official Email Address"
                register={register}
                value={companyDetails.emailAddress}
                onChange={(event) => {
                  handleChange("emailAddress", event.target.value);
                  trigger("alternateEmailAddress");
                }}
                errorMessage={errors.emailAddress?.message}
                disabled={disabled}
              />
            </div>

            <div className="div-row-flex">
              <TextValidator
                id="alternateEmailAddress"
                className="email-input"
                label="Enter Alternate Email Address"
                register={register}
                value={companyDetails.alternateEmailAddress}
                onChange={(event) =>
                  handleChange("alternateEmailAddress", event.target.value)
                }
                errorMessage={errors.alternateEmailAddress?.message}
                disabled={disabled}
              />
            </div>
            <div className="div-row-flex">
              <PhoneNumberValidator
                id="phoneNumber"
                label="Enter Phone Number"
                register={register}
                phone={companyDetails.phoneNumber}
                phoneExt={companyDetails.phoneExt}
                onPhoneChange={(event) => {
                  handleChange("phoneNumber", event.target.value);
                  trigger("altPhoneNumber");
                }}
                onExtChange={(event) => {
                  handleChange("phoneExt", event.target.value);
                  trigger("altPhoneNumber");
                }}
                errorMessage={
                  errors.phoneExt
                    ? errors.phoneExt.message
                    : errors.phoneNumber?.message
                }
                disabled={disabled}
              />
            </div>
            <div className="div-row-flex">
              <TextValidator
                id="altPhoneNumber"
                className="email-input"
                label="Enter Alternate Phone Number"
                register={register}
                value={companyDetails.altPhoneNumber}
                onChange={(event) =>
                  handleChange("altPhoneNumber", event.target.value)
                }
                errorMessage={errors.altPhoneNumber?.message}
                disabled={disabled}
              />
            </div>
            <div className="div-row-flex">
              <p className="sub-heading">Company Full Address*</p>
            </div>
            <div className="div-row-flex">
              <TextValidator
                id="line1"
                className="email-input"
                label="Enter Line 1 Address"
                register={register}
                value={companyAddress.line1}
                onChange={handleAddressChange("line1")}
                errorMessage={errors.line1?.message}
                disabled={disabled}
              />
            </div>
            <div className="div-row-flex">
              <TextValidator
                id="line2"
                className="email-input"
                label="Enter Line 2 Address"
                register={register}
                value={companyAddress.line2}
                onChange={handleAddressChange("line2")}
                errorMessage={errors.line2?.message}
                disabled={disabled}
              />
            </div>
            <div className="div-row-flex">
              <TextValidator
                id="pincode"
                className="email-input"
                label="Enter Pincode"
                register={register}
                value={companyAddress.pincode}
                onChange={handleAddressChange("pincode")}
                errorMessage={errors.pincode?.message}
                disabled={disabled}
              />
            </div>
            <div className="div-row-flex">
              <SelectValidator
                label="Select City"
                name="city"
                register={register}
                value={companyAddress.city}
                list={getCities()}
                callback={handleAddressChange("city")}
                errorMessage={errors.city?.message}
                disabled={disabled}
              />
            </div>
            <div className="div-row-flex">
              <SelectValidator
                label="Select State"
                name="state"
                register={register}
                value={companyAddress.state}
                list={getStates()}
                callback={handleAddressChange("state")}
                errorMessage={errors.state?.message}
                disabled={disabled}
              />
            </div>
            <div className="div-row-flex">
              <SelectValidator
                label="Select Country"
                name="country"
                register={register}
                value={companyAddress.country}
                list={Constants.COUNTRIES}
                callback={handleAddressChange("country")}
                errorMessage={errors.country?.message}
                disabled={disabled}
              />
            </div>
            <div className="div-submit-button">
              <Button
                label="Proceed"
                className="submit"
                variant="primary"
                disabled={!isValid || disabled}
              />
            </div>
            <p className={"invalid-feedback"}>{error?.message}</p>
            <div className="div-submit-button">
              <p>* All Mandatory Fields</p>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default CompanyDetails;
