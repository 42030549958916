const parse = (response: any) => {
  const allLocations = [];
  const allCategories = [];
  const partners = response.data.map((item: any) => {
    const partner = item["attributes"];

    const parsedPartnerDetails: any = {};

    parsedPartnerDetails.id = item.id;
    parsedPartnerDetails.logo = partner["partner_logo"];
    parsedPartnerDetails.coverImage = partner["cover_image"];

    parsedPartnerDetails.name = partner["partner_name"];
    parsedPartnerDetails.filterCategories = partner["partner_category"]
      ? partner["partner_category"]
      : [];
    parsedPartnerDetails.filterLocations = partner["locations"]
      ? partner["locations"]
      : [];

    allLocations.push(...parsedPartnerDetails.filterLocations);
    allCategories.push(...parsedPartnerDetails.filterCategories);

    return parsedPartnerDetails;
  });

  const parsedResponse = {
    partners,
    allLocations: [...new Set(allLocations)],
    allCategories: [...new Set(allCategories)],
  };

  return parsedResponse;
};

export default parse;
