import Card from "../Card";
import RecruiterStats from "../RecruiterStats";
import CashReward from "../CashReward";
import rolesImage from "../../assets/laptop.svg";
import consolationPrizeImage from "../../assets/consolation-prize.svg";
import positionsImage from "../../assets/user-closed.svg";
import usersImage from "../../assets/users.svg";
import styles from "./styles.module.scss";

interface Stats {
  profilesSubmitted: number;
  profilesShortlisted: number;
  offerRolledOut: number;
  jobSeekersJoined: number;
}

interface Props {
  stats: Stats;
  endDate: string;
  logo: string;
  name: string;
  title: string;
  role: string;
  status: string;
  bounty: string;
}

const RecruiterContest: React.FC<Props> = (props) => {
  const { stats, endDate, logo, name, title, role, status, bounty } = props;

  return (
    <div className={styles["recruiter-contest-container"]}>
      <p className={styles["recruiter-contest-end-date"]}>
        Contest End Date: <b>{endDate}</b>
      </p>
      <div>
        <div className={styles["recruiter-contest-company-details-container"]}>
          <Card>
            <div className={styles["recruiter-contest-logo-container"]}>
              <img src={logo} alt="Company Logo" />
            </div>
          </Card>
          <div className={styles["recruiter-contest-company-container"]}>
            <p className={styles["recruiter-contest-company-name"]}>{name}</p>
            <p className={styles["recruiter-contest-contest-title"]}>{title}</p>
          </div>
        </div>
        <div className={styles["recruiter-contest-metrics-container"]}>
          <div className={styles["recruiter-contest-metrics-icons-container"]}>
            <div>
              <div
                className={styles["recruiter-contest-metrics-icon-container"]}
              >
                <img src={rolesImage} alt="Roles" />
                <div
                  className={
                    styles["recruiter-contest-metrics-icon-text-container"]
                  }
                >
                  <p className={styles["recruiter-contest-metrics-icon-text"]}>
                    {role}
                  </p>
                  <p className={styles["recruiter-contest-metrics-icon-label"]}>
                    Role
                  </p>
                </div>
              </div>
              <div
                className={styles["recruiter-contest-metrics-icon-container"]}
              >
                <img src={usersImage} alt="Total Positions" />
                <div
                  className={
                    styles["recruiter-contest-metrics-icon-text-container"]
                  }
                >
                  <p className={styles["recruiter-contest-metrics-icon-text"]}>
                    {stats.jobSeekersJoined}
                  </p>
                  <p className={styles["recruiter-contest-metrics-icon-label"]}>
                    Total Positions
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div
                className={`${styles["recruiter-contest-metrics-icon-container"]} ${styles["recruiter-contest-metrics-icon-right-container"]}`}
              >
                <img src={consolationPrizeImage} alt="Status" />
                <div
                  className={
                    styles["recruiter-contest-metrics-icon-text-container"]
                  }
                >
                  <p className={styles["recruiter-contest-metrics-icon-text"]}>
                    {status}
                  </p>
                  <p className={styles["recruiter-contest-metrics-icon-label"]}>
                    Status
                  </p>
                </div>
              </div>
              <div
                className={`${styles["recruiter-contest-metrics-icon-container"]} ${styles["recruiter-contest-metrics-icon-right-container"]}`}
              >
                <img src={positionsImage} alt="Positions Closed" />
                <div
                  className={
                    styles["recruiter-contest-metrics-icon-text-container"]
                  }
                >
                  <p className={styles["recruiter-contest-metrics-icon-text"]}>
                    {stats.offerRolledOut}
                  </p>
                  <p className={styles["recruiter-contest-metrics-icon-label"]}>
                    Positions Closed
                  </p>
                </div>
              </div>
            </div>
          </div>
          <hr className={styles["recruiter-contest-metrics-separator"]} />
          <CashReward amount={bounty} title="Bounty" showStar={false} />
        </div>
      </div>
      <div className={styles["recruiter-contest-stats-container"]}>
        <RecruiterStats stats={stats} />
      </div>
    </div>
  );
};

export default RecruiterContest;
