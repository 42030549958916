import Button from "../Button";
import Card from "../Card";
import Share from "../Share";
import Tooltip from "../Tooltip";
import { RECRUITER_TYPES } from "../../utils/constants";
import awardImage from "../../assets/award.svg";
import starImage from "../../assets/star.svg";
import trophyImage from "../../assets/trophy.svg";
import positionsImage from "../../assets/user-closed.svg";
import freelanceRecruiterImage from "../../assets/recruiter-avatar.svg";
import targetImage from "../../assets/target.svg";
import badgesImage from "../../assets/certificate-badge.svg";
import experienceImage from "../../assets/experience.svg";
import prizeImage from "../../assets/prize.svg";
import trophyCircleImage from "../../assets/trophy-circle.svg";
import badgesCircleImage from "../../assets/certificate-badge-circle.svg";
import hiringhoodImage from "../../assets/hiringhood.svg";
import consolationPrizeImage from "../../assets/consolation-prize.svg";
import styles from "./styles.module.scss";

type RecruitersType = typeof RECRUITER_TYPES[number];

interface RecruiterDetails {
  id: number;
  name: string;
  location: string;
  type: RecruitersType;
  rank: number;
  bountyEarned: string;
  rating: number;
  positionsClosed: number;
  hitRatio: string;
  contestsWon?: number;
  experience?: number;
  competitionsParticipated?: number;
  numberOfRecruiters?: number;
  industry: string;
  consolationPrizesWon: number;
  activeSince: number;
  speedOfUploading: number;
  uploadQuality: number;
  jsReviews: number;
  employerReviews: number;
  responsiveness: number;
  profileImage?: string;
}

interface Props {
  recruiterDetails: RecruiterDetails;
}

const RecruiterProfileDetailCard: React.FC<Props> = (props) => {
  const { recruiterDetails } = props;
  const {
    id,
    name,
    location,
    type,
    rank,
    rating,
    bountyEarned,
    positionsClosed,
    hitRatio,
    contestsWon,
    experience,
    competitionsParticipated,
    numberOfRecruiters,
    consolationPrizesWon,
    activeSince,
    speedOfUploading,
    uploadQuality,
    jsReviews,
    employerReviews,
    responsiveness,
    profileImage,
  } = recruiterDetails;

  const urlToShare = `www.hiringhood.com/recruiters/`;

  const renderTooltipForBadges = () => {
    return (
      <>
        {competitionsParticipated > 0 && (
          <div
            className={
              styles["recruiter-profile-detail-card-stats-container-tootltip"]
            }
          >
            <img src={trophyCircleImage} alt="Competitions Participated" />
            <p
              className={
                styles[
                  "recruiter-profile-detail-card-stats-container-tootltip-text"
                ]
              }
            >
              {competitionsParticipated} Competitions Participated
            </p>
          </div>
        )}
        {positionsClosed > 0 && (
          <div
            className={
              styles["recruiter-profile-detail-card-stats-container-tootltip"]
            }
          >
            <img src={badgesCircleImage} alt="Positions Closed" />
            <p
              className={
                styles[
                  "recruiter-profile-detail-card-stats-container-tootltip-text"
                ]
              }
            >
              {positionsClosed} Positions Closed
            </p>
          </div>
        )}
        {contestsWon > 0 && (
          <div
            className={
              styles["recruiter-profile-detail-card-stats-container-tootltip"]
            }
          >
            <img src={prizeImage} alt="Contests Won" />
            <p
              className={
                styles[
                  "recruiter-profile-detail-card-stats-container-tootltip-text"
                ]
              }
            >
              {contestsWon} Contests Won
            </p>
          </div>
        )}
      </>
    );
  };

  const renderDetails = (icon, label, text) => {
    return (
      <div
        className={
          styles["recruiter-profile-detail-card-profile-icon-container"]
        }
      >
        <div
          className={
            styles["recruiter-profile-detail-card-profile-icon-image-container"]
          }
        >
          <img src={icon} alt={label} />
          <p
            className={
              styles[
                "recruiter-profile-detail-card-profile-icons-container-label"
              ]
            }
          >
            {label}
          </p>
        </div>
        <div>
          <p
            className={
              styles[
                "recruiter-profile-detail-card-profile-icons-container-text"
              ]
            }
          >
            {text}
          </p>
        </div>
      </div>
    );
  };

  const renderStat = (name, value) => {
    return (
      <div className={styles["recruiter-profile-detail-card-stats-container"]}>
        <p className={styles["recruiter-profile-detail-card-stats-text"]}>
          {name}
        </p>
        <p
          className={
            styles["recruiter-profile-detail-card-stats-container-text"]
          }
        >
          {value}
        </p>
      </div>
    );
  };

  return (
    <div className={styles["recruiter-profile-detail-card-wrapper"]}>
      <Card>
        <div className={styles["recruiter-profile-detail-card-container"]}>
          <p
            className={
              styles["recruiter-profile-detail-card-container-active-since"]
            }
          >
            Active Since {activeSince}
          </p>
          <div
            className={styles["recruiter-profile-detail-card-container-share"]}
          >
            <Share url={`${urlToShare}${id}`} />
          </div>

          <div
            className={
              styles[
                "recruiter-profile-detail-card-container-profile-container"
              ]
            }
          >
            <img
              src={profileImage ? profileImage : freelanceRecruiterImage}
              alt="Avatar"
              className={
                styles["recruiter-profile-detail-card-container-avatar"]
              }
            />
            <div
              className={
                styles[
                  "recruiter-profile-detail-card-profile-details-container"
                ]
              }
            >
              <p className={styles["recruiter-profile-detail-card-name"]}>
                {name}
              </p>
              <p className={styles["recruiter-profile-detail-card-location"]}>
                {location}
              </p>
              <p className={styles["recruiter-profile-detail-card-type"]}>
                {type}
              </p>
            </div>
            {/* <Button
              label="Message"
              className={styles["recruiter-profile-detail-card-message-button"]}
            /> */}
          </div>
          <div
            className={
              styles["recruiter-profile-detail-card-profile-icons-container"]
            }
          >
            {renderDetails(awardImage, "Rank", rank < 10 ? `0${rank}` : rank)}
            {renderDetails(
              positionsImage,
              "Number of Positions Closed",
              positionsClosed
            )}
            {renderDetails(trophyImage, "Total Bounty Earned", bountyEarned)}
            {renderDetails(
              consolationPrizeImage,
              "Consolation Prizes Won",
              consolationPrizesWon
            )}
            {renderDetails(targetImage, "Hit Ratio", hitRatio)}
            {experience &&
              renderDetails(
                experienceImage,
                "Experience",
                experience !== 1 ? `${experience} Years` : `${experience} Year`
              )}
            {numberOfRecruiters &&
              renderDetails(
                hiringhoodImage,
                "Recruiters on Hiringhood",
                numberOfRecruiters
              )}
          </div>
          <div>
            <div
              className={
                styles["recruiter-profile-detail-card-badges-container"]
              }
            >
              <div
                className={
                  styles["recruiter-profile-detail-card-stats-image-container"]
                }
              >
                <img src={badgesImage} alt="Badges" />
                <p
                  className={`${styles["recruiter-profile-detail-card-stats-text"]} ${styles["recruiter-profile-detail-card-profile-icons-container-label"]}`}
                >
                  Badges
                </p>
              </div>
              <Tooltip variant="outlined" title={renderTooltipForBadges()}>
                <>
                  {competitionsParticipated > 0 && (
                    <img
                      src={trophyCircleImage}
                      alt="Competitions Participated"
                      className={
                        styles[
                          "recruiter-profile-detail-card-stats-container-icon"
                        ]
                      }
                    />
                  )}
                  {positionsClosed > 0 && (
                    <img
                      src={badgesCircleImage}
                      alt="Positions Closed"
                      className={
                        styles[
                          "recruiter-profile-detail-card-stats-container-icon"
                        ]
                      }
                    />
                  )}
                  {contestsWon > 0 && (
                    <img
                      src={prizeImage}
                      alt="Contests Won"
                      className={
                        styles[
                          "recruiter-profile-detail-card-stats-container-icon"
                        ]
                      }
                    />
                  )}
                </>
              </Tooltip>
            </div>
            {renderDetails(starImage, "Overall Rating", rating)}
            <div
              className={styles["recruiter-profile-detail-card-stats-wrapper"]}
            >
              {renderStat("Speed of Uploading", speedOfUploading)}
              {renderStat("Upload Quality", uploadQuality)}
              {renderStat("Job Seekers Reviews", jsReviews)}
              {renderStat("Employer Reviews", employerReviews)}
              {renderStat("Responsiveness", responsiveness)}
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default RecruiterProfileDetailCard;
