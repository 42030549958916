import React, { useEffect, useState } from "react";
import "./styles.scss";
import Breadcrumbs from "../Breadcrumbs";
import Button from "../Button";
import AddRecruiter from "../AddRecruiter";
import { EDIT_PROFILE_STEPS } from "../../utils/constants/index";
import { CircularProgress } from "@material-ui/core";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import * as Constants from "../../utils/constants/index";

interface Props {
  currentStep: number;
  onNext: any;
  onSkip: any;
  onGotoStep: any;
  data?: any;
  userStage: number;
  loading?: boolean;
  error?: any;
  disabled?: boolean;
}

const initialize = () => {
  return [
    {
      name: "",
      email: "",
      phoneExtension: "",
      phoneNumber: "",
    },
  ];
};

const AddTeam = (props: Props) => {
  const {
    currentStep,
    onNext,
    onSkip,
    onGotoStep,
    data,
    userStage,
    loading = false,
    error = {},
    disabled = false,
  } = props;

  const [companyId, setCompanyId] = useState(0);

  useEffect(() => {
    data && setCompanyId(data.companyId);
  }, [data]);

  const validationSchema = Yup.object().shape({
    recruitersData: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string()
            .trim()
            .nullable()
            .notRequired()
            .matches(/^[a-zA-z]/, {
              message: "Recruiter name is invalid",
              excludeEmptyString: true,
            }),
          email: Yup.string()
            .trim()
            .required("Email address is required")
            .email("Enter valid email address"),
          phoneExtension: Yup.string()
            .nullable()
            .notRequired()
            .default("+91")
            .matches(Constants.PHONE_EXT_REGEX, {
              message: "Phone extension is not valid",
              excludeEmptyString: true,
            }),
          phoneNumber: Yup.string()
            .trim()
            .required("Phone number is required")
            .matches(Constants.PHONE_REGEX, {
              message: "Phone number is invalid",
            }),
        })
      )
      .required("Add a recruiter from your company"),
  });
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(validationSchema, {
      abortEarly: false,
      recursive: true,
    }),
    mode: "onBlur",
    shouldFocusError: true,
    criteriaMode: "all",
  });

  const { fields, append, remove, update } = useFieldArray({
    name: "recruitersData",
    control,
  });

  const onClickNext = (data) => {
    const payload = {
      companyId: companyId,
      recruiters: data.recruitersData,
    };
    onNext(payload);
  };

  const gotoStep = (toStep: number) => {
    onGotoStep(toStep);
  };

  const onClickBack = () => {
    gotoStep(currentStep - 1);
  };

  const OnAddTeam = () => {
    append(initialize());
  };

  const OnDeleteRecruiter = (index: number) => () => {
    remove(index);
  };

  const OnChangeHandler = (index: number, rec: any) => {
    if (fields[index] === rec) {
      return;
    }
    Object.keys(rec).map((key) => {
      setValue(`recruitersData.${index}.${key}`, rec[key], {
        shouldValidate: true,
      });
    });
  };

  const stepNames = EDIT_PROFILE_STEPS;
  const totalSteps = stepNames.length;

  return (
    <div className="form-container">
      {loading && <CircularProgress className="loading" />}
      {!loading && (
        <>
          <div className="div-breadcrumb">
            <Breadcrumbs
              currentStep={currentStep}
              totalSteps={totalSteps}
              callBackFunction={gotoStep}
              stepNames={stepNames}
              userStage={userStage}
            />
          </div>
          <form onSubmit={handleSubmit(onClickNext)} className="div-form">
            <fieldset disabled={disabled}>
              <div className="add-team-short-div">
                <label>
                  Please add the list of recruiter logins required by your
                  company
                </label>
              </div>
              <div className="add-team-add-recruiters">
                {fields.map((item, index) => (
                  <div className="add-team-div" key={item.id}>
                    <AddRecruiter
                      name={`recruitersData.${index}`}
                      index={index}
                      key={index}
                      onDelete={OnDeleteRecruiter(index)}
                      onChange={OnChangeHandler}
                      register={register}
                      errors={errors.recruitersData?.[index]}
                    />
                  </div>
                ))}
              </div>

              <div className="div-row-flex">
                <Button
                  label="+ Add Recruiter Details"
                  className="buttons add-recruiter-button"
                  variant="outlined"
                  onClick={(event) => {
                    OnAddTeam();
                    event.preventDefault();
                  }}
                />
              </div>

              <div className="div-row-flex">
                <Button
                  label="Back"
                  className="buttons"
                  variant="outlined"
                  onClick={(event) => {
                    onClickBack();
                    event.preventDefault();
                  }}
                />

                <Button
                  label="Submit All Details"
                  className="buttons"
                  variant="primary"
                  disabled={!isValid}
                />
              </div>
              <p className={"invalid-feedback"}>{error?.message}</p>
            </fieldset>
          </form>
        </>
      )}
    </div>
  );
};

export default AddTeam;
