import { useState } from "react";
import Card from "../Card";
import SliderFilter from "../SliderFilter";
import CheckboxFilter from "../CheckboxFilter";
import TextFilter from "../TextFilter";
import filterImage from "../../assets/filter.svg";
import { RECRUITER_TYPES } from "../../utils/constants";
import styles from "./styles.module.scss";
import Button from "../Button";

const ratingMarks = [
  {
    value: 2.5,
    label: "2.5",
  },
  {
    value: 5,
    label: "5",
  },
];

const RecruiterFilters = (props) => {
  const { recruitersData, onFiltersChanged, onReset } = props;
  const recruiters = recruitersData.recruiters;

  const [typeRecruiters, setTypeRecruiters] = useState(recruiters);
  const [nameRecruiters, setNameRecruiters] = useState(recruiters);
  const [positionRecruiters, setPositionRecruiters] = useState(recruiters);
  const [ratingRecruiters, setRatingRecruiters] = useState(recruiters);
  const [cityRecruiters, setCityRecruiters] = useState(recruiters);

  const hasObject = (array, object) => {
    return array.filter((item) => item.id === object.id).length > 0;
  };

  const getCommonRecruiters = (
    typeRecruiters,
    nameRecruiters,
    positionRecruiters,
    ratingRecruiters,
    cityRecruiters
  ) => {
    const commonRecruiters = [];
    const clonedContests = JSON.parse(JSON.stringify(recruiters));
    clonedContests.forEach((recruiter) => {
      if (
        hasObject(typeRecruiters, recruiter) &&
        hasObject(nameRecruiters, recruiter) &&
        hasObject(positionRecruiters, recruiter) &&
        hasObject(ratingRecruiters, recruiter) &&
        hasObject(cityRecruiters, recruiter)
      ) {
        commonRecruiters.push(recruiter);
      }
    });

    onFiltersChanged(commonRecruiters);
  };

  const onTypeChange = (list) => {
    setTypeRecruiters([...list]);
    setTypeRecruiters((state) => {
      getCommonRecruiters(
        state,
        nameRecruiters,
        positionRecruiters,
        ratingRecruiters,
        cityRecruiters
      );
      return state;
    });
  };

  const onNameChange = (list) => {
    setNameRecruiters([...list]);
    setNameRecruiters((state) => {
      getCommonRecruiters(
        typeRecruiters,
        state,
        positionRecruiters,
        ratingRecruiters,
        cityRecruiters
      );
      return state;
    });
  };

  const onCityChange = (list) => {
    setCityRecruiters([...list]);
    setCityRecruiters((state) => {
      getCommonRecruiters(
        typeRecruiters,
        nameRecruiters,
        positionRecruiters,
        ratingRecruiters,
        state
      );
      return state;
    });
  };

  const onPositionsChange = (list) => {
    setPositionRecruiters([...list]);
    setPositionRecruiters((state) => {
      getCommonRecruiters(
        typeRecruiters,
        nameRecruiters,
        state,
        ratingRecruiters,
        cityRecruiters
      );
      return state;
    });
  };

  const onRatingChange = (list) => {
    setRatingRecruiters([...list]);
    setRatingRecruiters((state) => {
      getCommonRecruiters(
        typeRecruiters,
        nameRecruiters,
        positionRecruiters,
        state,
        cityRecruiters
      );
      return state;
    });
  };

  return (
    <Card>
      <div className={styles["recruiter-filters-container"]}>
        <div className={styles["recruiter-filters-title-container"]}>
          <img src={filterImage} />
          <p className={styles["recruiter-filters-title"]}>Filter Search</p>
        </div>
        <Button
          className={styles["recruiter-filters-clear-filters-button"]}
          label="Clear Filters"
          onClick={onReset}
          variant="outlined"
        />
        <hr className={styles["recruiter-filters-separator"]} />
        <CheckboxFilter
          filterName="Recruiter Type"
          filters={[RECRUITER_TYPES[1], RECRUITER_TYPES[0]]}
          field="filterRecruiter"
          list={recruiters}
          onFilterChanged={onTypeChange}
        />
        <hr className={styles["recruiter-filters-separator"]} />
        <TextFilter
          filterName="Name"
          list={recruiters}
          field="name"
          onFilterChanged={onNameChange}
        />
        <hr className={styles["recruiter-filters-separator"]} />
        <CheckboxFilter
          filterName="City"
          filters={recruitersData.allCities}
          field="filterCity"
          list={recruiters}
          onFilterChanged={onCityChange}
        />
        <hr className={styles["recruiter-filters-separator"]} />
        <SliderFilter
          filterName="Rating"
          marks={ratingMarks}
          min={2.5}
          max={5}
          step={0.5}
          range={[2.5, 5]}
          list={recruiters}
          field="filterRating"
          onFilterChanged={onRatingChange}
        />
        <hr className={styles["recruiter-filters-separator"]} />
        <TextFilter
          filterName="Positions Closed"
          list={recruiters}
          field="positionsClosed"
          onFilterChanged={onPositionsChange}
        />
      </div>
    </Card>
  );
};

export default RecruiterFilters;
