import { useState, useEffect } from "react";
import "./styles.scss";
import Breadcrumbs from "../Breadcrumbs";
import Button from "../Button";
import CustomizedSlider from "../Slider";
import * as Constants from "../../utils/constants/index";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import SelectValidator from "../SelectValidator";
import MultiSelectValidator from "../MultiSelectValidator";
import { getCities } from "../../utils/constants/locations";
import { CircularProgress } from "@material-ui/core";
import CustomUploadFile from "../CustomUploadFile";

interface Props {
  currentStep: number;
  onNext: any;
  onSkip: any;
  onGotoStep: any;
  data: any;
  userStage: number;
  loading?: boolean;
  error?: any;
  disabled?: boolean;
}

interface Background {
  recruitersCount: string;
  domesticRecruitementPeriod: number;
  expertiseList: string[];
  typesOfRequirements: string[];
  levelsList: string[];
  jobLocations: string[];
  skillsList: string[];
  industriesList: string[];
  handledRecruitements: boolean;
  haveCertifications: boolean;
  certificateNote: string;
  certificate: string;
  freelancingExperience: number;
  placements: string;
}

const initialize = () => {
  return {
    recruitersCount: "",
    domesticRecruitementPeriod: 0,
    expertiseList: [],
    typesOfRequirements: [],
    levelsList: [],
    jobLocations: [],
    skillsList: [],
    industriesList: [],
    handledRecruitements: false,
    haveCertifications: false,
    certificateNote: "",
    certificate: "",
    freelancingExperience: 0,
    placements: "",
  };
};

const MAX_COUNT = 5;
const MIN_SLIDER_VALUE = 1;
const MAX_SLIDER_VALUE = 10;
const DEFAULT_SLIDER_VALUE = 3;
const STEP_VALUE = 1;

const FreelanceRecruiterBackground = (props: Props) => {
  const {
    currentStep,
    onNext,
    onSkip,
    onGotoStep,
    data,
    userStage,
    loading = false,
    error = {},
    disabled = false,
  } = props;
  const [freelancerBackground, setFreelancerBackground] = useState(initialize);

  useEffect(() => {
    if (data) {
      setFreelancerBackground(data);
      Object.keys(data).map((key) => {
        setValue(key, data[key]);
      });
    }
  }, [data]);

  const validationSchema = Yup.object().shape({
    placements: Yup.string().notRequired(),
    expertiseList: Yup.array().min(1, "Select functional roles"),
    typesOfRequirements: Yup.array().min(1, "Select types of requirements"),
    levelsList: Yup.array().min(1, "Select the levels you hire talent"),
    industriesList: Yup.array().min(
      1,
      "Select industries of your recruitment expertise"
    ),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    shouldFocusError: true,
  });

  const changeHandler = (fieldName: string, value: any) => {
    setFreelancerBackground({
      ...freelancerBackground,
      [fieldName]: value,
    });
    setValue(fieldName, value, {
      shouldValidate: true,
    });
  };

  const onClickNext = (data: any) => {
    onNext(data);
  };

  const onClickBack = () => {
    gotoStep(currentStep - 1);
  };

  const gotoStep = (toStep: number) => {
    onGotoStep(toStep);
  };

  const stepNames = Constants.EDIT_FR_PROFILE_STEPS;
  const totalSteps = stepNames.length;

  return (
    <div className="form-container">
      {loading && <CircularProgress className="loading" />}
      {!loading && (
        <>
          <div className="div-breadcrumb">
            <Breadcrumbs
              currentStep={currentStep}
              totalSteps={totalSteps}
              callBackFunction={gotoStep}
              stepNames={stepNames}
              userStage={userStage}
            />
          </div>

          <form onSubmit={handleSubmit(onClickNext)} className="div-form">
            <div className="background-div-row-flex">
              <MultiSelectValidator
                name="jobLocations"
                label="Which job locations do you mostly hire for? "
                names={getCities()}
                maxCount={0}
                register={register}
                callback={(values) => changeHandler("jobLocations", values)}
                value={freelancerBackground.jobLocations}
                errorMessage={errors.jobLocations?.message}
                placeholder="E.g Hyderabad"
                disabled={disabled}
              />
            </div>

            <div className="div-row-flex">
              <SelectValidator
                label="How many placements have you made in the last 12 months?"
                list={Constants.PLACEMENTS_COUNT}
                register={register}
                value={freelancerBackground.placements}
                callback={(event) =>
                  changeHandler("placements", event.target.value)
                }
                errorMessage={errors.placements?.message}
                placeholder="E.g 5 to 10"
                disabled={disabled}
              />
            </div>
            <div className="background-div-row-flex">
              <MultiSelectValidator
                name="expertiseList"
                label={`Please select up to ${MAX_COUNT} functional roles that represent your recruiting expertise *`}
                names={Constants.ROLES}
                maxCount={MAX_COUNT}
                register={register}
                callback={(values) => changeHandler("expertiseList", values)}
                value={freelancerBackground.expertiseList}
                errorMessage={errors.expertiseList?.message}
                placeholder="E.g Administrative"
                disabled={disabled}
              />
            </div>

            <div className="background-div-row-flex">
              <MultiSelectValidator
                name="typesOfRequirements"
                label="Type of Requirements you have handled? *"
                names={Constants.REQUIREMENTS}
                maxCount={0}
                register={register}
                callback={(values) =>
                  changeHandler("typesOfRequirements", values)
                }
                value={freelancerBackground.typesOfRequirements}
                errorMessage={errors.typesOfRequirements?.message}
                placeholder="E.g Super Niche"
                showSelectAll={true}
                disabled={disabled}
              />
            </div>

            <div className="background-div-row-flex">
              <MultiSelectValidator
                name="levelsList"
                label="At what levels you hire talent mostly? *"
                names={Constants.LEVELS}
                maxCount={0}
                register={register}
                callback={(values) => changeHandler("levelsList", values)}
                value={freelancerBackground.levelsList}
                errorMessage={errors.levelsList?.message}
                placeholder="E.g Senior Level"
                showSelectAll={true}
                disabled={disabled}
              />
            </div>
            <div className="background-div-row-flex">
              <div>
                <label className="recruiter-label">
                  Do you have any certifications in Recruiting ?
                </label>
                <div className="div-row-flex-short">
                  <Button
                    label="Yes"
                    variant="option"
                    className="option-button"
                    selected={freelancerBackground.haveCertifications}
                    onClick={(event) => {
                      changeHandler("haveCertifications", true);
                      event.preventDefault();
                    }}
                    disabled={disabled}
                  />
                  <Button
                    label="No"
                    variant="option"
                    className="option-button"
                    selected={!freelancerBackground.haveCertifications}
                    onClick={(event) => {
                      changeHandler("haveCertifications", false);
                      event.preventDefault();
                    }}
                    disabled={disabled}
                  />
                </div>
                <p className="invalid-feedback">
                  {errors.haveCertifications?.message}
                </p>
              </div>
            </div>

            {freelancerBackground.haveCertifications && (
              <div>
                <CustomUploadFile
                  id="upload-freelancer-certificate"
                  note={freelancerBackground.certificateNote}
                  fileName={freelancerBackground.certificate}
                  notePlaceholder="Details of the certificate"
                  register={register}
                  buttonLabel="Upload Certificate"
                  accept=".pdf"
                  showNote={true}
                  handleChange={(event) =>
                    changeHandler("certificateNote", event.target.value)
                  }
                  onUploadSuccess={(value) =>
                    changeHandler("certificate", value)
                  }
                  disabled={disabled}
                />
              </div>
            )}

            <div className="background-div-row-flex">
              <div>
                <label className="recruiter-label">
                  Handled Recruitment Drives in the past? *
                </label>
                <div className="div-row-flex-short">
                  <Button
                    label="Yes"
                    variant="option"
                    className="option-button"
                    selected={freelancerBackground.handledRecruitements}
                    onClick={(event) => {
                      changeHandler("handledRecruitements", true);
                      event.preventDefault();
                    }}
                    disabled={disabled}
                  />
                  <Button
                    label="No"
                    variant="option"
                    className="option-button"
                    selected={!freelancerBackground.handledRecruitements}
                    onClick={(event) => {
                      changeHandler("handledRecruitements", false);
                      event.preventDefault();
                    }}
                    disabled={disabled}
                  />
                </div>
                <p className="invalid-feedback">
                  {errors.handledRecruitements?.message}
                </p>
              </div>
            </div>

            <div className="background-div-row-flex">
              <div>
                <label className="recruiter-label">
                  How long have you been in domestic recruiting?
                </label>
                <CustomizedSlider
                  marks={Constants.SLIDER_MARKS}
                  min={MIN_SLIDER_VALUE}
                  max={MAX_SLIDER_VALUE}
                  step={STEP_VALUE}
                  defaultValue={DEFAULT_SLIDER_VALUE}
                  width={400}
                  sliderValue={freelancerBackground.domesticRecruitementPeriod}
                  onSliderChange={(event, value) =>
                    changeHandler("domesticRecruitementPeriod", value)
                  }
                  disabled={disabled}
                />
              </div>
            </div>

            <div className="background-div-row-flex">
              <div>
                <label className="recruiter-label">
                  How long have you been working as a freelance recruiter?
                </label>
                <CustomizedSlider
                  marks={Constants.SLIDER_MARKS}
                  min={MIN_SLIDER_VALUE}
                  max={MAX_SLIDER_VALUE}
                  step={STEP_VALUE}
                  defaultValue={DEFAULT_SLIDER_VALUE}
                  width={400}
                  sliderValue={freelancerBackground.freelancingExperience}
                  onSliderChange={(event, value) =>
                    changeHandler("freelancingExperience", value)
                  }
                  disabled={disabled}
                />
              </div>
            </div>

            <div className="background-div-row-flex">
              <MultiSelectValidator
                name="skillsList"
                label={`Please select top ${MAX_COUNT} Skill Sets that you specialize in sourcing talent`}
                names={Constants.SKILL_SETS}
                maxCount={MAX_COUNT}
                register={register}
                callback={(values) => changeHandler("skillsList", values)}
                value={freelancerBackground.skillsList}
                errorMessage={errors.skillsList?.message}
                placeholder="E.g  Administrative"
                disabled={disabled}
              />
            </div>

            <div className="background-div-row-flex">
              <MultiSelectValidator
                name="industriesList"
                label={`Please select up to ${MAX_COUNT} industries that represent your recruiting expertise. *`}
                names={Constants.INDUSTRIES}
                maxCount={MAX_COUNT}
                register={register}
                callback={(values) => changeHandler("industriesList", values)}
                value={freelancerBackground.industriesList}
                errorMessage={errors.industriesList?.message}
                placeholder="E.g Software and Technology"
                disabled={disabled}
              />
            </div>

            <div className="div-row-flex">
              <Button
                label="Go Back"
                className="next-button"
                variant="outlined"
                onClick={onClickBack}
              />

              <Button
                label="Next"
                className="next-button"
                variant="primary"
                disabled={!isValid || disabled}
              />
            </div>
          </form>

          <p className={"invalid-feedback"}>{error?.message}</p>
          <div className="div-submit-button">
            <p>* All Mandatory Fields</p>
          </div>
        </>
      )}
    </div>
  );
};

export default FreelanceRecruiterBackground;
