import { useState, useMemo } from "react";
import Card from "../Card";
import Pagination from "../Pagination";
import RecruiterContest from "../RecruiterContest";
import Checkbox from "../Checkbox";
import SelectValidator from "../SelectValidator";
import styles from "./styles.module.scss";

interface Props {
  contests: Array<any>;
}

const RecruiterContests: React.FC<Props> = (props) => {
  const { contests } = props;
  const [sortedContests, setSortedContests] = useState(contests);
  const [currentPage, setCurrentPage] = useState(1);

  const numberPerPage = ["10", "20", "40", "60"];
  const [perPage, setPerPage] = useState(10);

  const handlePerPage = (event) => {
    setPerPage(Number(event.target.value));
  };

  const onPageChange = (page: number) => {
    setCurrentPage(page);
    window.scrollTo(0, 0);
  };

  const onCheckboxChange = (event) => {
    if (event.target.checked) {
      setSortedContests([
        ...sortedContests.filter((contest) => {
          return contest.status === "Won";
        }),
      ]);
    } else {
      setSortedContests([...contests]);
    }
  };

  const pageData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * perPage;
    const lastPageIndex = firstPageIndex + perPage;
    return sortedContests.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, sortedContests, perPage]);

  return (
    <div className={styles["recruiter-contests-container"]}>
      {pageData.length > 0 && (
        <>
          <div
            className={styles["recruiter-contests-show-contests-won-container"]}
          >
            <Checkbox onChange={onCheckboxChange} /> Show Contests Won Only
          </div>
          <div className={styles["recruiter-contests-contests-container"]}>
            {pageData.map((contest, index) => {
              return (
                <>
                  <div
                    key={contest.title}
                    className={styles["recruiter-contests-contest-container"]}
                  >
                    <RecruiterContest
                      endDate={contest.endDate}
                      name={contest.companyName}
                      logo={contest.logo}
                      title={contest.contestTitle}
                      role={contest.role}
                      status={contest.status}
                      bounty={contest.bounty}
                      stats={contest.stats}
                    />
                  </div>
                  {index !== pageData.length - 1 && (
                    <hr
                      className={styles["recruiter-contests-contest-separator"]}
                    />
                  )}
                </>
              );
            })}
          </div>
          <div>
            <div className={styles["recruiter-contests-per-page-container"]}>
              <div className={styles["recruiter-contests-per-page"]}>
                <SelectValidator
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  register={() => {}}
                  label=""
                  list={numberPerPage}
                  value={numberPerPage[0]}
                  callback={handlePerPage}
                  disabled={currentPage !== 1}
                />
              </div>
              <p className={styles["recruiter-contests-per-page-text"]}>
                Per Page
              </p>
            </div>
            <Pagination
              className={styles["recruiter-contests-pagination-container"]}
              currentPage={currentPage}
              totalCount={sortedContests.length}
              pageSize={perPage}
              onPageChange={onPageChange}
            />
          </div>
        </>
      )}
      {pageData.length === 0 && (
        <p className={styles["recruiter-contests-no-contests"]}>
          There are no contests to display
        </p>
      )}
    </div>
  );
};

export default RecruiterContests;
