import { useState } from "react";
import { Link } from "react-router-dom";
import Button from "../Button";
import Card from "../Card";
import Share from "../Share";
import Tooltip from "../Tooltip";
import { RECRUITER_TYPES } from "../../utils/constants";
import { RECRUITER_DETAILS } from "../../utils/constants/routes";
import awardImage from "../../assets/award.svg";
import starImage from "../../assets/star.svg";
import trophyImage from "../../assets/trophy.svg";
import positionsImage from "../../assets/user-closed.svg";
import freelanceRecruiterImage from "../../assets/recruiter-avatar.svg";
import targetImage from "../../assets/target.svg";
import badgesImage from "../../assets/certificate-badge.svg";
import experienceImage from "../../assets/experience.svg";
import prizeImage from "../../assets/prize.svg";
import trophyCircleImage from "../../assets/trophy-circle.svg";
import badgesCircleImage from "../../assets/certificate-badge-circle.svg";
import hiringhoodImage from "../../assets/hiringhood.svg";
import industryImage from "../../assets/industry.svg";
import consolationPrizeImage from "../../assets/consolation-prize.svg";
import styles from "./styles.module.scss";

type RecruitersType = typeof RECRUITER_TYPES[number];

interface RecruiterDetails {
  id: number;
  name: string;
  location: string;
  type: RecruitersType;
  rank: number;
  bountyEarned: string;
  rating: number;
  positionsClosed: number;
  hitRatio: string;
  contestsWon?: number;
  experience?: number;
  competitionsParticipated?: number;
  numberOfRecruiters?: number;
  industry: string;
  consolationPrizesWon: number;
  activeSince: number;
  profileImage?: string;
}

interface Props {
  recruiterDetails: RecruiterDetails;
}

const RecruiterProfileListCard: React.FC<Props> = (props) => {
  const { recruiterDetails } = props;
  const {
    id,
    name,
    location,
    type,
    rank,
    rating,
    bountyEarned,
    positionsClosed,
    hitRatio,
    contestsWon,
    experience,
    competitionsParticipated,
    numberOfRecruiters,
    industry,
    consolationPrizesWon,
    activeSince,
    profileImage,
  } = recruiterDetails;

  const urlToShare = `www.hiringhood.com/recruiter/`;

  const [anchorEl, setAnchorEl] = useState(null);

  const renderTooltipForBadges = () => {
    return (
      <>
        {competitionsParticipated > 0 && (
          <div
            className={
              styles["recruiter-profile-list-card-stats-container-tootltip"]
            }
          >
            <img src={trophyCircleImage} alt="Competitions Participated" />
            <p
              className={
                styles[
                  "recruiter-profile-list-card-stats-container-tootltip-text"
                ]
              }
            >
              {competitionsParticipated} Competitions Participated
            </p>
          </div>
        )}
        {positionsClosed > 0 && (
          <div
            className={
              styles["recruiter-profile-list-card-stats-container-tootltip"]
            }
          >
            <img src={badgesCircleImage} alt="Positions Closed" />
            <p
              className={
                styles[
                  "recruiter-profile-list-card-stats-container-tootltip-text"
                ]
              }
            >
              {positionsClosed} Positions Closed
            </p>
          </div>
        )}
        {contestsWon > 0 && (
          <div
            className={
              styles["recruiter-profile-list-card-stats-container-tootltip"]
            }
          >
            <img src={prizeImage} alt="Contests Won" />
            <p
              className={
                styles[
                  "recruiter-profile-list-card-stats-container-tootltip-text"
                ]
              }
            >
              {contestsWon} Contests Won
            </p>
          </div>
        )}
      </>
    );
  };

  return (
    <div className={styles["recruiter-profile-list-card-wrapper"]}>
      <Card>
        <div className={styles["recruiter-profile-list-card-container"]}>
          <p
            className={
              styles["recruiter-profile-list-card-container-active-since"]
            }
          >
            Active Since {activeSince}
          </p>
          <div
            className={styles["recruiter-profile-list-card-container-share"]}
          >
            <Share url={`${urlToShare}${id}`} />
          </div>
          <img
            src={profileImage ? profileImage : freelanceRecruiterImage}
            alt="Avatar"
            className={styles["recruiter-profile-list-card-container-avatar"]}
          />
          <div
            className={
              styles["recruiter-profile-list-card-profile-details-container"]
            }
          >
            <p className={styles["recruiter-profile-list-card-name"]}>{name}</p>
            <p className={styles["recruiter-profile-list-card-location"]}>
              {location}
            </p>
            <p className={styles["recruiter-profile-list-card-type"]}>{type}</p>
            <div
              className={
                styles["recruiter-profile-list-card-profile-icons-container"]
              }
            >
              <div
                className={
                  styles["recruiter-profile-list-card-profile-icon-container"]
                }
              >
                <div
                  className={
                    styles[
                      "recruiter-profile-list-card-profile-icon-image-container"
                    ]
                  }
                >
                  <img src={awardImage} alt="Rank" />
                  <p
                    className={
                      styles[
                        "recruiter-profile-list-card-profile-icons-container-label"
                      ]
                    }
                  >
                    Rank
                  </p>
                </div>
                <div>
                  <p
                    className={
                      styles[
                        "recruiter-profile-list-card-profile-icons-container-text"
                      ]
                    }
                  >
                    {rank < 10 ? `0${rank}` : rank}
                  </p>
                </div>
              </div>
              <div
                className={
                  styles["recruiter-profile-list-card-profile-icon-container"]
                }
              >
                <div
                  className={
                    styles[
                      "recruiter-profile-list-card-profile-icon-image-container"
                    ]
                  }
                >
                  <img src={starImage} alt="Rating" />
                  <p
                    className={
                      styles[
                        "recruiter-profile-list-card-profile-icons-container-label"
                      ]
                    }
                  >
                    Rating
                  </p>
                </div>
                <div>
                  <p
                    className={
                      styles[
                        "recruiter-profile-list-card-profile-icons-container-text"
                      ]
                    }
                  >
                    {rating}
                  </p>
                </div>
              </div>
              <div
                className={
                  styles["recruiter-profile-list-card-profile-icon-container"]
                }
              >
                <div
                  className={
                    styles[
                      "recruiter-profile-list-card-profile-icon-image-container"
                    ]
                  }
                >
                  <img src={positionsImage} alt="Positions Closed" />
                  <p
                    className={
                      styles[
                        "recruiter-profile-list-card-profile-icons-container-label"
                      ]
                    }
                  >
                    Positions Closed
                  </p>
                </div>
                <div>
                  <p
                    className={
                      styles[
                        "recruiter-profile-list-card-profile-icons-container-text"
                      ]
                    }
                  >
                    {positionsClosed}
                  </p>
                </div>
              </div>
              <div
                className={
                  styles["recruiter-profile-list-card-profile-icon-container"]
                }
              >
                <div
                  className={
                    styles[
                      "recruiter-profile-list-card-profile-icon-image-container"
                    ]
                  }
                >
                  <img src={trophyImage} alt="Bounty Earned" />
                  <p
                    className={
                      styles[
                        "recruiter-profile-list-card-profile-icons-container-label"
                      ]
                    }
                  >
                    Total Bounty Earned
                  </p>
                </div>
                <div>
                  <p
                    className={
                      styles[
                        "recruiter-profile-list-card-profile-icons-container-text"
                      ]
                    }
                  >
                    {bountyEarned}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              className={styles["recruiter-profile-list-card-stats-wrapper"]}
            >
              {industry && (
                <div
                  className={
                    styles["recruiter-profile-list-card-stats-container"]
                  }
                >
                  <div
                    className={
                      styles[
                        "recruiter-profile-list-card-stats-image-container"
                      ]
                    }
                  >
                    <img src={industryImage} alt="Industry" />
                    <p
                      className={
                        styles["recruiter-profile-list-card-stats-image-text"]
                      }
                    >
                      Industry
                    </p>
                  </div>
                  <p
                    className={
                      styles["recruiter-profile-list-card-stats-container-text"]
                    }
                  >
                    {industry}
                  </p>
                </div>
              )}
              <div
                className={
                  styles["recruiter-profile-list-card-stats-container"]
                }
              >
                <div
                  className={
                    styles["recruiter-profile-list-card-stats-image-container"]
                  }
                >
                  <img src={targetImage} alt="Hit Ratio" />
                  <p
                    className={
                      styles["recruiter-profile-list-card-stats-image-text"]
                    }
                  >
                    Hit Ratio
                  </p>
                </div>
                <p
                  className={
                    styles["recruiter-profile-list-card-stats-container-text"]
                  }
                >
                  {hitRatio}
                </p>
              </div>
              <div
                className={
                  styles["recruiter-profile-list-card-stats-container"]
                }
              >
                <div
                  className={
                    styles["recruiter-profile-list-card-stats-image-container"]
                  }
                >
                  <img
                    src={consolationPrizeImage}
                    alt="Consolation Prizes Won"
                  />
                  <p
                    className={
                      styles["recruiter-profile-list-card-stats-image-text"]
                    }
                  >
                    Consolation Prizes Won
                  </p>
                </div>
                <p
                  className={
                    styles["recruiter-profile-list-card-stats-container-text"]
                  }
                >
                  {consolationPrizesWon}
                </p>
              </div>
              <div
                className={
                  styles["recruiter-profile-list-card-stats-container"]
                }
              >
                <div
                  className={
                    styles["recruiter-profile-list-card-stats-image-container"]
                  }
                >
                  <img src={badgesImage} alt="Badges" />
                  <p
                    className={
                      styles["recruiter-profile-list-card-stats-image-text"]
                    }
                  >
                    Badges
                  </p>
                </div>
                <Tooltip variant="outlined" title={renderTooltipForBadges()}>
                  <>
                    {competitionsParticipated > 0 && (
                      <img
                        src={trophyCircleImage}
                        alt="Competitions Participated"
                        className={
                          styles[
                            "recruiter-profile-list-card-stats-container-icon"
                          ]
                        }
                      />
                    )}
                    {positionsClosed > 0 && (
                      <img
                        src={badgesCircleImage}
                        alt="Positions Closed"
                        className={
                          styles[
                            "recruiter-profile-list-card-stats-container-icon"
                          ]
                        }
                      />
                    )}
                    {contestsWon > 0 && (
                      <img
                        src={prizeImage}
                        alt="Contests Won"
                        className={
                          styles[
                            "recruiter-profile-list-card-stats-container-icon"
                          ]
                        }
                      />
                    )}
                  </>
                </Tooltip>
              </div>
              {experience && (
                <div
                  className={
                    styles["recruiter-profile-list-card-stats-container"]
                  }
                >
                  <div
                    className={
                      styles[
                        "recruiter-profile-list-card-stats-image-container"
                      ]
                    }
                  >
                    <img src={experienceImage} alt="Experience" />
                    <p
                      className={
                        styles["recruiter-profile-list-card-stats-image-text"]
                      }
                    >
                      Experience
                    </p>
                  </div>
                  <p
                    className={
                      styles["recruiter-profile-list-card-stats-container-text"]
                    }
                  >
                    {experience !== 1
                      ? `${experience} Years`
                      : `${experience} Year`}
                  </p>
                </div>
              )}
              {numberOfRecruiters && (
                <div
                  className={
                    styles["recruiter-profile-list-card-stats-container"]
                  }
                >
                  <div
                    className={
                      styles[
                        "recruiter-profile-list-card-stats-image-container"
                      ]
                    }
                  >
                    <img src={hiringhoodImage} alt="Hiringhood" />
                    <p
                      className={
                        styles["recruiter-profile-list-card-stats-image-text"]
                      }
                    >
                      Recruiters on Hiringhood
                    </p>
                  </div>
                  <p
                    className={
                      styles["recruiter-profile-list-card-stats-container-text"]
                    }
                  >
                    {numberOfRecruiters}
                  </p>
                </div>
              )}
            </div>
            <Link to={RECRUITER_DETAILS.replace(":id", `${id}`)}>
              <Button
                label="View Profile"
                className={
                  styles["recruiter-profile-list-card-view-profile-button"]
                }
              />
            </Link>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default RecruiterProfileListCard;
