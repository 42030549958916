import { useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import BootstrapButton from "../BootstrapButton";
import GreenCheckImage from "../../assets/mark-green.svg";
import RedErrorImage from "../../assets/mark-red.svg";
import "./styles.scss";
import {
  MAX_FILE_SIZE,
  PROFILE_AVATAR_OPTIONS,
} from "../../utils/constants/index";
import { useDispatch, useSelector } from "react-redux";
import {
  uploadFileSelector,
  uploadFileLoadingSelector,
  errorSelector,
} from "../../redux/selectors/index";
import { uploadFile } from "../../redux/actions/uploadFile";
import PlusIcon from "../../assets/plus.svg";
import { useDropzone } from "react-dropzone";

const CustomUploadFile = (props) => {
  const {
    id,
    note,
    fileName,
    notePlaceholder,
    register,
    handleChange,
    onUploadSuccess,
    showDropArea = false,
    showNote = false,
    showAvatarOptions = false,
    label = "",
    buttonLabel = "Upload File",
    accept,
    disabled = false,
  } = props;
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState<any>(
    fileName ? fileName : null
  );
  const [selectedFileInfo, setSelectedFileInfo] = useState<File>();
  const [isFilePicked, setIsFilePicked] = useState(fileName ? true : false);
  const [isValidFile, setIsValidFile] = useState(true);
  const error = useSelector(errorSelector);
  const formData = new FormData();
  const uploadedFile = useSelector(uploadFileSelector);
  const fileLoading = useSelector(uploadFileLoadingSelector);
  const fileErrorHandler = (msg: string) => {
    setIsValidFile(false);
    setErrorMessage(msg);
  };

  const validateFile = (file: any) => {
    setIsValidFile(true);
    setErrorMessage("");

    const acceptedFileTypes = accept.split(",");
    acceptedFileTypes.map((filetype, index) => {
      acceptedFileTypes[index] = filetype.slice(1);
    });
    const uploadedFileType = file.type.split("/")[1];
    if (!acceptedFileTypes.includes(uploadedFileType)) {
      const fileTypeSuported = accept.replaceAll(",", " /").toUpperCase();
      fileErrorHandler(`Select a ${fileTypeSuported} file`);
      return false;
    }
    if (file.size > MAX_FILE_SIZE) {
      fileErrorHandler("File is too large");
      return false;
    }
    return true;
  };

  const validateAndUpload = (selectedFile) => {
    setIsFilePicked(true);
    setSelectedFileInfo(selectedFile);
    if (validateFile(selectedFile)) {
      uploadSelectedFile(selectedFile);
    }
  };

  const uploadSelectedFile = (selectedFile) => {
    setIsFilePicked(true);
    formData.append("file", selectedFile);
    dispatch(uploadFile(formData, id));
  };

  useEffect(() => {
    if (uploadedFile && uploadedFile.key === id) {
      setSelectedFile(uploadedFile);
      onUploadSuccess(uploadedFile);
    }
    if (error) {
      fileErrorHandler(
        "Something went wrong while uploading file. Please try again."
      );
    }
  }, [uploadedFile, error]);

  const fileSelectHandler = (target) => {
    validateAndUpload(target[0]);
  };

  const onDrop = (acceptedFiles) => {
    fileSelectHandler(acceptedFiles);
  };

  const convertDataUrlToBlob = (dataUrl) => {
    const arr = dataUrl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  };

  const getFileDisplayName = (url) => {
    if (!url) {
      return "";
    }
    const urlChunks = url.split("/");
    return urlChunks[urlChunks.length - 1];
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <>
      {showNote && (
        <div className="div-row-flex">
          <textarea
            placeholder={notePlaceholder}
            className="certificate"
            name={`note-${id}`}
            value={note}
            {...register}
            onChange={handleChange}
            disabled={disabled}
          />
        </div>
      )}

      {showDropArea && (
        <div className="div-row-flex">
          <div>
            <div className="div-avatar-options">
              <label className="drop-zone-label">{label}</label>
              {showAvatarOptions &&
                PROFILE_AVATAR_OPTIONS.map((avatar, index) => {
                  return (
                    <button
                      disabled={disabled}
                      key={index}
                      className="avatar-button"
                      onClick={(event) => {
                        uploadSelectedFile(
                          new File(
                            [convertDataUrlToBlob(avatar.dataURL)],
                            avatar.name,
                            {
                              type: "image/png",
                            }
                          )
                        );
                        event.preventDefault();
                      }}
                    >
                      <img
                        src={avatar.dataURL}
                        alt={avatar.name}
                        className="avatar-option-button"
                      />
                    </button>
                  );
                })}
            </div>

            <div
              {...getRootProps()}
              className="upload_logo_drag_drop logo-dragdrop-card-wrapper"
            >
              <input
                {...getInputProps()}
                accept={accept}
                multiple={false}
                className="upload-button"
                disabled={disabled}
              />

              <div className="logo-dragdrop-card-container">
                <div className="logo-dragdrop-text-container">
                  {selectedFile ? (
                    <img
                      className="logo-display-container"
                      src={fileName.url}
                      alt="Profile Image"
                    />
                  ) : (
                    <>
                      <img
                        className="logo-plus-icon"
                        src={PlusIcon}
                        alt="Plus"
                      />
                      <p className="logo-dragdrop-text">Drag and Drop</p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="div-row-flex-short div-padding-bottom">
        <div className="div-browse">
          {isFilePicked ? (
            <>
              <div className="browse-label">
                <img
                  className="file-status-image"
                  src={isValidFile ? GreenCheckImage : RedErrorImage}
                  alt="ValidFile"
                />
                <label className="file-name">
                  {fileName
                    ? getFileDisplayName(fileName.url)
                    : "No file uploaded"}
                </label>
              </div>
              {!isValidFile && (
                <p className="invalid-feedback">{errorMessage}</p>
              )}
            </>
          ) : (
            <>
              <label className="browse-label">
                {selectedFileInfo ? selectedFileInfo.name : "No file selected"}
              </label>
              <label className="browse-note">
                {`Max size: 5MB, ${accept.toUpperCase()}`}{" "}
              </label>
            </>
          )}
        </div>

        <label htmlFor={id} style={{ width: "12rem" }}>
          <input
            type="file"
            accept={accept}
            id={id}
            onChange={({ target }) => fileSelectHandler(target.files)}
            hidden
            disabled={disabled}
          />
          <BootstrapButton disabled={disabled}>
            {selectedFile ? "Re-Upload" : buttonLabel}
          </BootstrapButton>
        </label>
        {fileLoading && <CircularProgress className="file-loading" size={35} />}
      </div>
    </>
  );
};
export default CustomUploadFile;
