import { Link } from "react-router-dom";
import Card from "../Card";
import { PARTNER_DETAILS } from "../../utils/constants/routes";
import styles from "./styles.module.scss";

interface PartnerDetails {
  id: number;
  name: string;
  coverImage: string;
  logo: string;
}

interface Props {
  partnerDetails: PartnerDetails;
}

const PartnerCard: React.FC<Props> = (props) => {
  const { partnerDetails } = props;
  const { id, name, coverImage, logo } = partnerDetails;

  return (
    <div className={styles["partner-card-wrapper"]}>
      <Link to={PARTNER_DETAILS.replace(":id", `${id}`)}>
        <Card>
          <div className={styles["partner-card-container"]}>
            <img
              src={coverImage}
              alt={name}
              className={styles["partner-card-cover-image"]}
            />
            <div className={styles["partner-card-logo-container"]}>
              <img
                src={logo}
                alt={name}
                className={styles["partner-card-logo"]}
              />
            </div>
            <div className={styles["partner-card-name-container"]}>
              <p className={styles["partner-card-name"]}>{name}</p>
            </div>
          </div>
        </Card>
      </Link>
    </div>
  );
};

export default PartnerCard;
