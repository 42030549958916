import Card from "../Card";
import linkedInIcon from "../../assets/linkedin-color.svg";
import "./styles.css";

const ProfileCard = (props) => {
  const { image, title, role, description, linkedIn } = props;

  return (
    <div className="profile-card-wrapper">
      <Card>
        <div className="profile-card-container">
          <img src={image} alt={title} className="profile-card-image" />
          <p className="profile-card-title">{title}</p>
          <p className="profile-card-role">{role}</p>
          <p className="profile-card-description">{description}</p>
          <hr className="profile-card-separator" />
          <a href={linkedIn} target="_blank" rel="noreferrer">
            <img src={linkedInIcon} alt="LinkedIn" />
          </a>
        </div>
      </Card>
    </div>
  );
};

export default ProfileCard;
