export const GET_HOTLIST = "GET_HOTLIST";
export const SET_HOTLIST = "SET_HOTLIST";
export const GET_HOTLIST_ANALYTICS = "GET_HOTLIST_ANALYTICS";
export const GET_HOTLIST_BY_IDS = "GET_HOTLIST_BY_IDS";
export const SET_SELECTED_HOTLIST = "SET_SELECTED_HOTLIST";
export const UNSET_SELECTED_HOTLIST = "UNSET_SELECTED_HOTLIST";
export const getHotlist = () => {
  return {
    type: GET_HOTLIST,
  };
};

export const getHotlistAnalytics = () => {
  return {
    type: GET_HOTLIST_ANALYTICS,
  };
};

export const getHotlistByIds = (ids) => {
  return {
    type: GET_HOTLIST_BY_IDS,
    payload: { ids },
  };
};

export const setHotlist = (data) => {
  return {
    type: SET_HOTLIST,
    payload: data,
  };
};

export const setSelectedHotlist = (data) => {
  return {
    type: SET_SELECTED_HOTLIST,
    payload: data,
  };
};

export const unsetSelectedHotlist = (data) => {
  return {
    type: UNSET_SELECTED_HOTLIST,
    payload: data,
  };
};
