import { SET_PARTNERS } from "../actions/partners";

const partnersReducer = (state = { partners: [] }, action) => {
  switch (action.type) {
    case SET_PARTNERS:
      return action.payload;
    default:
      return state;
  }
};

export default partnersReducer;
