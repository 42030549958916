import MetricCard from "../MetricCard";
import "./styles.scss";
import { siteMetricsArray } from "../../utils/constants/site-data";

const siteMetrics = () => {
  return (
    <div className="site-metrics-div">
      {siteMetricsArray.map((metricItem, index) => (
        <span className="site-metrics-card-container" key={index}>
          <MetricCard
            icon={metricItem.icon}
            metric={metricItem.metric}
            description={metricItem.description}
            bgColor={metricItem.bgColor}
            key={index}
          />
        </span>
      ))}
    </div>
  );
};

export default siteMetrics;
