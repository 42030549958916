import { openPopupWidget } from "react-calendly";
import sarath from "../../assets/meet-the-founder.svg";
import findTimeToConnect from "../../assets/find-time-to-connect.svg";
import Button from "../Button";
import styles from "./style.module.scss";

const MeetTheFounder = () => {
  const handleScheduleDemo = () => {
    return openPopupWidget({
      url: "https://calendly.com/sarath-hiringhood/30min",
    });
  };
  const screenWidth = window.innerWidth;

  return (
    <>
      <p className={styles["meet-the-founder-title"]}>
        Meet The <span style={{ color: "rgb(77, 108, 217)" }}>Founder</span>
      </p>
      <div className={styles["meet-the-founder-wrapper"]}>
        <div>
          <img
            src={sarath}
            alt="Sarath Yandrapalli"
            className={styles["meet-the-founder-profile-image"]}
          />
          <p className={styles["meet-the-founder-name"]}>Sarath Yandrapalli</p>
          <p className={styles["meet-the-founder-designation"]}>
            Founder and CEO
          </p>
          <p className={styles["meet-the-founder-description"]}>
            Schedule a meeting with our CEO
          </p>
          <Button
            onClick={handleScheduleDemo}
            label="Schedule Meeting"
            variant="outlined"
          />
        </div>
        {screenWidth > 1500 && (
          <img src={findTimeToConnect} alt="Find Time to Connect" />
        )}
      </div>
    </>
  );
};

export default MeetTheFounder;
