import { GET_EMPLOYERS, setEmployers } from "../actions/employers";
import { setError } from "../actions/common";
import parse from "../../utils/parsers/employers-parser";

const employersFlow = ({ api, log }) => ({ dispatch }) => (next) => async (
  action
) => {
  next(action);

  if (action.type === GET_EMPLOYERS) {
    try {
      dispatch(setEmployers({ loading: true }));
      const employers = await api.employers.getAll(action.payload);
      log("Successfully fetched the employers data");
      dispatch(setError(null));
      dispatch(
        setEmployers({ employersData: parse(employers), loading: false })
      );
    } catch (error) {
      const payload = {
        error,
        message: "Error while fetching the employers details",
      };
      dispatch(setError(payload));
      dispatch(setEmployers({ loading: false }));
      log("Error while fetching the employers details");
    }
  }
};

export default [employersFlow];
