import menuReducer from "./menu";
import loginReducer from "./login";
import signupReducer from "./signup";
import contestsReducer from "./contests";
import contestReducer from "./contest";
import commonReducer from "./common";
import recruiterProfileReducer from "./recruiterProfile";
import employerReducer from "./employer";
import employersReducer from "./employers";
import userReducer from "./user";
import uploadFileReducer from "./uploadFile";
import onboardReducer from "./onboard";
import blogsReducer from "./blogs";
import partnerReducer from "./partner";
import bannersReducer from "./banners";
import partnersReducer from "./partners";
import linksReduccer from "./links";
import hotlistReducer from "./hotlist";
import { combineReducers } from "redux";

const reducer = combineReducers({
  menu: menuReducer,
  session: loginReducer,
  contestsRepository: contestsReducer,
  contestRepository: contestReducer,
  application: commonReducer,
  signup: signupReducer,
  recruiterProfile: recruiterProfileReducer,
  employerRepository: employerReducer,
  employersRepository: employersReducer,
  userRepository: userReducer,
  uploadFile: uploadFileReducer,
  onboardRecruiter: onboardReducer,
  blogsRepository: blogsReducer,
  partnerRepository: partnerReducer,
  bannersRepository: bannersReducer,
  partnersRepository: partnersReducer,
  linksRepository: linksReduccer,
  hotlistRepository: hotlistReducer,
});

export default reducer;
