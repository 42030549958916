import axios from "axios";
import { PARTNER_API } from "../../../utils/constants/urls";

export default {
  getAll: async () => {
    const response = await axios.get(PARTNER_API);

    return response.data;
  },
};
