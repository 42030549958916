import contests from "./contests";
import contest from "./contest";
import recruiterProfile from "./recruiterProfile";
import employer from "./employer";
import employers from "./employers";
import uploadFile from "./uploadFile";
import onboard from "./onboard";
import blogs from "./blogs";
import partner from "./partner";
import banners from "./banners";
import partners from "./partners";
import links from "./links";
import hotlist from "./hotlist";

export default {
  contests,
  contest,
  recruiterProfile,
  employer,
  employers,
  uploadFile,
  onboard,
  blogs,
  partner,
  banners,
  partners,
  links,
  hotlist,
};
