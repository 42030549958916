import React from "react";
import "./styles.scss";
import SuccessImage from "../../assets/submitted-successfull.svg";
import Button from "../Button";
import Copyright from "../Copyright";

interface StatusProps {
  message: string;
  buttonLabel: string;
  onNext: any;
}
const Status = (props: StatusProps) => {
  const { message, buttonLabel, onNext } = props;

  const onClickHandler = () => {
    onNext("OnSuccess");
    window.scrollTo(0, 0);
  };

  return (
    <React.Fragment>
      <div className="success-image">
        <div>
          <img src={SuccessImage} alt="Successfully Submitted" />
        </div>
        <div>
          <p className="success-text">{message}</p>
        </div>

        <Button
          variant="primary"
          label={buttonLabel}
          className="edit-button"
          onClick={onClickHandler}
        />
      </div>
      <Copyright />
    </React.Fragment>
  );
};

export default Status;
