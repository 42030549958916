import "./styles.scss";
import { drivingImpactInfo } from "../../utils/constants/site-data";
import DrivingImpactCard from "../DrivingImpactCard";
const DrivingImpact = () => {
  return (
    <div className="driving-impact-div">
      {drivingImpactInfo.map((item, index) => (
        <DrivingImpactCard
          icon={item.icon}
          title={item.title}
          stat={item.stat}
          description={item.description}
          key={index}
        />
      ))}
    </div>
  );
};
export default DrivingImpact;
