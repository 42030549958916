import { SET_BANNERS } from "../actions/banners";

const bannersReducer = (state = { banners: [] }, action) => {
  switch (action.type) {
    case SET_BANNERS:
      return action.payload;
    default:
      return state;
  }
};

export default bannersReducer;
