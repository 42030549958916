import { SET_EMPLOYERS } from "../actions/employers";

const employersReducer = (state = { employers: [] }, action) => {
  switch (action.type) {
    case SET_EMPLOYERS:
      return action.payload;
    default:
      return state;
  }
};

export default employersReducer;
