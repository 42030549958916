import { Grid } from "@mui/material";
import MetricCard from "../MetricCard";
import {
  ourCommitmentArray,
  ourCommitmentTopics,
} from "../../utils/constants/site-data";
import "./styles.scss";
import Button from "../Button";
const OurCommitment = () => {
  const readMoreOnClick = (event) => {
    return;
  };
  return (
    <div className="our-commitment-container">
      <div className="our-commitment-topics">
        <Grid
          container
          rowSpacing={1}
          columnSpacing={1}
          className="mobile-grid-style"
        >
          {ourCommitmentTopics.map((item, index) => (
            <Grid item xs={4} key={index} className="out-commitment-grid">
              <div className="our-commitment-item-container">
                <img src={item.icon} className="our-commitment-icon" />
                <p className="our-commitment-topic">{item.title}</p>
                <p className="our-commitment-desc">{item.description}</p>
              </div>
              <div hidden>
                <Button
                  variant="outlined"
                  label="Read More"
                  onClick={readMoreOnClick}
                />
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
      <div className="our-commitment-tiles">
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {ourCommitmentArray.map((item, index) => (
            <Grid item xs={2} sm={2} md={4} key={index} className="custom-grid">
              <MetricCard
                bgColor={item.bgColor}
                metric={item.metric}
                description={item.description}
                icon={item.icon}
                descFontSize="normal"
                cardSize="18.75rem"
              />
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};
export default OurCommitment;
