export const GET_PARTNERS = "GET_PARTNERS";
export const SET_PARTNERS = "SET_PARTNERS";

export const getPartners = () => {
  return {
    type: GET_PARTNERS,
  };
};

export const setPartners = (data) => {
  return {
    type: SET_PARTNERS,
    payload: data,
  };
};
