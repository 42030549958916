import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import Error from "../../components/Error";
import { setActiveLink } from "../../redux/actions/menu";
import { getPartner } from "../../redux/actions/partner";
import {
  partnerLoadingSelector,
  errorSelector,
  partnerSelector,
} from "../../redux/selectors";
import PartnerTabs from "../../components/PartnerTabs";
import "./styles.css";

const EmployerPage = () => {
  const dispatch = useDispatch();
  const loading = useSelector(partnerLoadingSelector);
  const error = useSelector(errorSelector);
  const { id } = useParams<{ id: string }>();

  const partnerDetails = useSelector(partnerSelector);

  useEffect(() => {
    dispatch(setActiveLink("Partners"));
    dispatch(getPartner(id));
    window.scrollTo(0, 0);
  }, [dispatch, id]);

  return (
    <div>
      {loading && <CircularProgress className="loading" />}
      {!loading && !error && (
        <div className="partner-wrapper">
          <PartnerTabs partnerDetails={partnerDetails} />
        </div>
      )}
      {!loading && error && <Error />}
    </div>
  );
};

export default EmployerPage;
