import Button from "../Button";
import Share from "../Share";
import styles from "./styles.module.scss";

interface Props {
  url: string;
}

const Certificate: React.FC<Props> = (props) => {
  const { url } = props;

  return (
    <div className={styles["certificate-image-container"]}>
      <img
        className={styles["certificate-image"]}
        src={url}
        alt="Certificate"
      />
      <div className={styles["certificate-buttons-container"]}>
        <a href={url} target="_blank" rel="noreferrer">
          <Button
            label="View Certificate"
            variant="outlined"
            className={styles["certificate-view-certificate"]}
          />
        </a>
        <Share url={url} />
      </div>
    </div>
  );
};

export default Certificate;
