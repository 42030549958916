export const SET_UPLOADED_FILE_INFO = "SET_UPLOADED_FILE_INFO";
export const UPLOAD_FILE = "UPLOAD_FILE";

export const setUploadedFileInfo = (data) => {
  return {
    type: SET_UPLOADED_FILE_INFO,
    payload: data,
  };
};

export const uploadFile = (data, key) => {
  return {
    type: UPLOAD_FILE,
    payload: data,
    key: key,
  };
};
