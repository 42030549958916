export const GET_COMPANY_DETAILS = "GET_COMPANY_DETAILS";
export const SET_COMPANY_DETAILS = "SET_COMPANY_DETAILS";
export const POST_COMPANY_DETAILS = "POST_COMPANY_DETAILS";
export const POST_TEAM_DETAILS = "POST_TEAM_DETAILS";
export const SET_TEAM_DETAILS = "SET_TEAM_DETAILS";
export const GET_RECRUITER_DETAILS = "GET_RECRUITER_DETAILS";
export const SET_RECRUITER_DETAILS = "SET_RECRUITER_DETAILS";
export const GET_BILLING_DETAILS = "GET_BILLING_DETAILS";
export const SET_BILLING_DETAILS = "SET_BILLING_DETAILS";
export const POST_BILLING_DETAILS = "POST_BILLING_DETAILS";
export const GET_RECRUITERS = "GET_RECRUITERS";
export const SET_RECRUITERS = "SET_RECRUITERS";
export const GET_RECRUITER = "GET_RECRUITER";
export const GET_BACKGROUND_DETAILS = "GET_BACKGROUND_DETAILS";
export const SET_BACKGROUND_DETAILS = "SET_BACKGROUND_DETAILS";
export const POST_BACKGROUND_DETAILS = "POST_BACKGROUND_DETAILS";
export const SET_RECRUITER_PROFILE_LOADING = "SET_RECRUITER_PROFILE_LOADING";

export const getRecruiterCompanyDetails = (id, role, currentStep) => {
  return {
    type: GET_COMPANY_DETAILS,
    payload: id,
    role: role,
    stage: currentStep,
  };
};
export const getRecruiterBillingDetails = (id, role, currentStep) => {
  return {
    type: GET_BILLING_DETAILS,
    payload: id,
    role: role,
    stage: currentStep,
  };
};

export const getRecruiterBackground = (id, role, currentStep) => {
  return {
    type: GET_BACKGROUND_DETAILS,
    payload: id,
    role: role,
    stage: currentStep,
  };
};

export const getRecruiter = (id, role, currentStep) => {
  return {
    type: GET_RECRUITER_DETAILS,
    payload: id,
    role: role,
    stage: currentStep,
  };
};

export const setRecruiterCompanyDetails = (data) => {
  return {
    type: SET_COMPANY_DETAILS,
    payload: data,
  };
};

export const setRecruiterTeamDetails = (data) => {
  return {
    type: SET_TEAM_DETAILS,
    payload: data,
  };
};

export const setRecruiterBillingDetails = (data) => {
  return {
    type: SET_BILLING_DETAILS,
    payload: data,
  };
};

export const setRecruiterBackground = (data) => {
  return {
    type: SET_BACKGROUND_DETAILS,
    payload: data,
  };
};

export const postRecruiterCompanyDetails = (id, data, role, currentStep) => {
  return {
    type: POST_COMPANY_DETAILS,
    payload: data,
    id: id,
    role: role,
    stage: currentStep,
  };
};

export const postRecruiterTeamDetails = (id, data, currentStep) => {
  return {
    type: POST_TEAM_DETAILS,
    payload: data,
    id: id,
    stage: currentStep,
  };
};

export const setRecruiter = (data) => {
  return {
    type: SET_RECRUITER_DETAILS,
    payload: data,
  };
};

export const postRecruiterBillingDetails = (id, data, role, currentStep) => {
  return {
    type: POST_BILLING_DETAILS,
    payload: data,
    id: id,
    role: role,
    stage: currentStep,
  };
};

export const postRecruiterBackground = (id, data, role, currentStep) => {
  return {
    type: POST_BACKGROUND_DETAILS,
    payload: data,
    id: id,
    role: role,
    stage: currentStep,
  };
};

export const getRecruiters = () => {
  return {
    type: GET_RECRUITERS,
  };
};

export const setRecruiters = (data) => {
  return {
    type: SET_RECRUITERS,
    payload: data,
  };
};

export const getRecruiterById = (id) => {
  return {
    type: GET_RECRUITER,
    payload: id,
  };
};
