import React, { useState } from "react";
import "./styles.scss";
import LoginImage from "../../assets/login-image.svg";
import Card from "../Card";
import Heading from "../Heading";
import Copyright from "../Copyright";
import OnboardOptions from "../OnboardOptions";
import { useHistory } from "react-router-dom";
import { ON_BOARDING } from "../../utils/constants/routes";

const OnboardAs = (props): JSX.Element => {
  const history = useHistory();
  const [radioState, setRadioState] = useState(
    props.preSelected ? props.preSelected : ""
  );

  const onboardSelect = (value: string) => {
    setRadioState(value);
    history.push({
      pathname: ON_BOARDING,
      state: value,
    });
  };

  return (
    <div className="onboard-wrapper">
      <Card>
        <div className="onboard-container onboard-card-container-height">
          <React.Fragment>
            <img src={LoginImage} alt="Login" />
          </React.Fragment>
          <div className="onboard-form-container">
            <div className="onboard-main-container">
              <div className="onboard-top-container">
                <div className="onboard-form">
                  <Heading text="Select the following" />
                  <p className="we-describe">We describe ourself as a </p>

                  <OnboardOptions
                    onClick={onboardSelect}
                    radioState={radioState}
                    preSelected={props.preSelected}
                  />
                </div>
              </div>
            </div>
            <Copyright />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default OnboardAs;
