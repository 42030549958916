import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import ClearIcon from "@mui/icons-material/Clear";
import { ListItemIcon, ListItemText } from "@material-ui/core";
import Checkbox from "../Checkbox";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginTop: theme.spacing(0),
      "& .MuiInput-formControl": {
        paddingLeft: "0rem",
        backgroundColor: "#FFFFFF",
        marginTop: ".25rem",
      },
      "& .MuiInputBase-input": {
        color: "#626880",
        fontSize: "1rem",
        lineHeight: "1.375rem",
        fontFamily: "Nunito",
      },
    },

    inputLabel: {
      left: 0,
      position: "relative",
      fontFamily: "Nunito",
      color: "#626880 !important",
      fontWeight: "bold",
      fontSize: "0.8125rem",
      lineHeight: "1.125rem",
      transform: "translate(0, 0rem) scale(1)",
    },

    select: {
      width: "23rem",
      height: "1.276rem",
      color: "#626880",
      fontSize: "1rem",
      lineHeight: "1.375rem",
      margin: 0,
      backgroundColor: "#FFFFFF",
      border: ".0938rem solid #AAB0CB",
      borderRadius: 12,
      padding: ".55rem .75rem",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      "&:focus": {
        borderRadius: 12,
        borderColor: "#4D6CD9",
        boxShadow: "0rem .25rem 1rem rgba(206, 212, 240, 0.8)",
      },
    },

    menuItem: {
      "&:hover": {
        backgroundColor: "#E9F0FF",
        borderRadius: ".5rem",
      },
      "&.Mui-selected": {
        backgroundColor: "#E9F0FF",
        borderRadius: ".5rem",
      },
      "&.Mui-selected:hover": { backgroundColor: "#E9F0FF" },
      paddingLeft: ".375rem",
      paddingTop: ".125rem",
      paddingBottom: ".125rem",
      marginLeft: "1rem",
      marginRight: "1rem",
      marginTop: "0.25rem",
      height: "1.875rem",
      width: "23.75rem",
    },

    label: {
      fontFamily: "Nunito",
      color: "#30374C",
      fontSize: "1rem",
    },
  })
);

const gridStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "25.375rem",
      marginTop: "0.5rem",
    },
    item: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      width: "28.75rem",
    },
  })
);

const chipStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: "#4D6CD9",
      border: `.09375rem solid #4D6CD9`,
      backgroundColor: "#FBFBFB",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      borderRadius: 8,
      marginRight: "0.5rem",
      marginBottom: "0.5rem",
      "&:hover": {
        backgroundColor: "#FBFBFB",
        boxShadow: `0rem .25rem 1.5rem rgba(77, 108, 217, 0.24)`,
      },
      "&:active": {
        backgroundColor: "#E8EAFA",
      },
      "&:disabled": {
        backgroundColor: "#FBFBFB",
        border: `.09375rem solid #A4A4A4`,
      },
    },

    label: {
      color: "#4D6CD9",
      width: "100%",
    },
  })
);

const deleteIconStyles = makeStyles(() =>
  createStyles({
    root: {
      color: "#4D6CD9",
      "&:hover": {
        color: "#4D6CD9",
      },
      "&:active": {
        color: "#4D6CD9",
      },
      "&:disabled": {
        color: "#FBFBFB",
      },
    },
  })
);

interface Props {
  name: string;
  names: string[];
  label?: string;
  callback: (any) => void;
  maxCount?: number; //max number of items that can be selected
  value: string[];
  register?: any;
  errorMessage?: string;
  placeholder?: string;
  showSelectAll?: boolean;
  disabled?: boolean;
}

const MultiSelectValidator = (props: Props): JSX.Element => {
  const classes = useStyles();
  const gridClasses = gridStyles();
  const chipClasses = chipStyles();
  const deleteIconClasses = deleteIconStyles();
  const {
    name,
    label,
    callback,
    names,
    maxCount = 0, // default 0 indicates, unlimited number of selections are allowed
    value = [],
    register,
    errorMessage,
    placeholder = "Please select",
    showSelectAll = false,
    disabled = false,
  } = props;
  const [selected, setSelected] = React.useState(value);
  const isAllSelected = names.length > 0 && selected.length === names.length;

  const handleChange = (event) => {
    let selectedItems = event.target.value;
    selectedItems[0] === placeholder && selectedItems.splice(0, 1);
    if (selectedItems.length === maxCount + 1 && maxCount > 0) {
      event.preventDefault();
      return;
    }
    if (selectedItems[selectedItems.length - 1] === "all")
      selectedItems = selected.length === names.length ? [] : names;
    setSelected(selectedItems);
    callback(selectedItems);
  };

  const handleDelete = (item: string) => {
    const index = selected.indexOf(item);
    const list = selected
      .slice(0, index)
      .concat(selected.slice(index + 1, selected.length));
    setSelected(list);
    callback(list);
  };
  return (
    <React.Fragment>
      <FormControl className={classes.formControl}>
        <InputLabel
          id="mutiple-checkbox-label"
          className={classes.inputLabel}
          shrink={false}
          disableAnimation
          focused={false}
        >
          {label}
        </InputLabel>
        <Select
          disableUnderline
          labelId="mutiple-checkbox-label"
          id="mutiple-checkbox"
          multiple
          value={selected.length > 0 ? selected : [placeholder]}
          input={<Input name={name} {...register} value={selected} />}
          renderValue={(selected: string[]) =>
            selected.length > 0 ? selected.join(", ") : placeholder
          }
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: "31.25rem",
                width: "25.375rem",
                color: "#30374C",
                fontSize: "1rem",
                borderRadius: "1rem",
              },
            },
            variant: "menu",
            getContentAnchorEl: null,
          }}
          classes={{ root: classes.select }}
          onChange={handleChange}
          disabled={disabled}
        >
          {showSelectAll && (
            <MenuItem value="all" className={classes.menuItem}>
              <ListItemIcon>
                <Checkbox
                  checked={isAllSelected}
                  indeterminate={
                    selected.length > 0 && selected.length < names.length
                  }
                />
              </ListItemIcon>
              <ListItemText className={classes.label} primary="Select All" />
            </MenuItem>
          )}

          {names.map((name, index) => {
            return (
              <MenuItem key={index} value={name} className={classes.menuItem}>
                <ListItemIcon>
                  <Checkbox checked={selected.indexOf(name) > -1} />
                </ListItemIcon>
                <ListItemText primary={name} />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <div className={gridClasses.root}>
        <Grid container spacing={3}>
          <Grid item className={gridClasses.item}>
            {selected.map((name: string, index: number) => (
              <Chip
                key={index}
                variant="outlined"
                label={<span className={chipClasses.label}>{name}</span>}
                onClick={(event) => handleDelete(name)}
                onDelete={(event) => handleDelete(name)}
                deleteIcon={<ClearIcon className={deleteIconClasses.root} />}
                className={chipClasses.root}
                disabled={disabled}
              />
            ))}
          </Grid>
        </Grid>
      </div>
      <p className="invalid-feedback">{errorMessage}</p>
    </React.Fragment>
  );
};

export default MultiSelectValidator;
