import { SET_CONTESTS } from "../actions/contests";

const contestsReducer = (state = { contests: [] }, action) => {
  switch (action.type) {
    case SET_CONTESTS:
      return action.payload;
    default:
      return state;
  }
};

export default contestsReducer;
