export const GET_LINKS = "GET_LINKS";
export const SET_LINKS = "SET_LINKS";

export const getLinks = () => {
  return {
    type: GET_LINKS,
  };
};

export const setLinks = (data) => {
  return {
    type: SET_LINKS,
    payload: data,
  };
};
