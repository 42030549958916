import { useState } from "react";
import { Link } from "react-router-dom";
import { RECRUITER_TYPES } from "../../utils/constants";
import { SIGNUP } from "../../utils/constants/routes";
import Card from "../Card";
import Button from "../Button";
import AboutRecruiter from "../AboutRecruiter";
import RecruiterCertificates from "../RecruiterCertificates";
import RecruiterContests from "../RecruiterContests";
import ContestStats from "../ContestStats";
import partnersImage from "../../assets/partners.svg";
import rewardsBadge from "../../assets/rewards-badge.svg";
import groupsBadge from "../../assets/group-badge.svg";
import prizeBadge from "../../assets/prize-badge.svg";
import resumeBadge from "../../assets/resume-badge.svg";
import positionsClosedBadge from "../../assets/positions-closed.svg";
import "./styles.css";
import { getLocalStorage } from "../../utils/storage-manager";

type RecruitersType = typeof RECRUITER_TYPES[number];

interface RecruiterDetails {
  type: RecruitersType;
  description: string;
  industry: string;
  roles: string;
  levels: string;
  skills: string;
  locations: string;
  contests?: Array<any>;
  contestsWon?: number;
  competitionsParticipated?: number;
  bountyEarned?: string;
  profilesSubmitted?: number;
  stats?: any;
  hitRatio?: string;
  positionsClosed?: number;
  certificates?: any;
}

interface Props {
  recruiterDetails: RecruiterDetails;
  showCertificates?: boolean;
}

const RecruiterTabs: React.FC<Props> = (props) => {
  const { recruiterDetails, showCertificates = false } = props;
  const tabs = ["About", "Contests", "Statistics"];
  if (showCertificates) {
    tabs.splice(1, 0, "Certificates");
  }

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  return (
    <>
      <div className="recruiter-tabs-container">
        {tabs.map((tab, index) => {
          return (
            <div
              key={index}
              className={`recruiter-tabs-tab ${
                activeTabIndex === index ? "recruiter-tabs-active-tab" : ""
              }`}
            >
              <p
                className={`recruiter-tabs-text ${
                  activeTabIndex === index ? "recruiter-tabs-active-text" : ""
                }`}
                onClick={() => {
                  setActiveTabIndex(index);
                }}
                tabIndex={0}
                onKeyDown={(event) => {
                  event.keyCode === 13 && setActiveTabIndex(index);
                }}
              >
                {tab}
              </p>
            </div>
          );
        })}
      </div>
      {activeTabIndex === 0 && (
        <>
          <AboutRecruiter recruiterDetails={recruiterDetails} />
          {!getLocalStorage("token", null) && (
            <div className="recruiter-tabs-join-hh">
              <img src={partnersImage} alt="Partners"></img>
              <p className="recruiter-tabs-join-hh-text">
                Become a Hiringhood Recruiter Today
              </p>
              <Link to={SIGNUP}>
                <Button
                  label="Join Us"
                  variant="secondary"
                  className="recruiter-tabs-join-hh-button"
                />
              </Link>
            </div>
          )}
        </>
      )}
      {showCertificates && activeTabIndex === 1 && (
        <RecruiterCertificates certificates={recruiterDetails.certificates} />
      )}
      {((showCertificates && activeTabIndex === 2) ||
        (!showCertificates && activeTabIndex === 1)) && (
        <>
          <div className="recruiter-tabs-contests-metrics">
            <div className="recruiter-tabs-contests-icon-container">
              <img src={rewardsBadge} alt="Contests Won" />
              <div className="recruiter-tabs-contests-icon-text-container">
                <p className="recruiter-tabs-contests-icon-text">
                  {recruiterDetails.contestsWon}
                </p>
                <p className="recruiter-tabs-contests-icon-label">
                  Contests Won
                </p>
              </div>
            </div>
            <div className="recruiter-tabs-contests-icon-container">
              <img src={groupsBadge} alt="Contests Particpated" />
              <div className="recruiter-tabs-contests-icon-text-container">
                <p className="recruiter-tabs-contests-icon-text">
                  {recruiterDetails.competitionsParticipated}
                </p>
                <p className="recruiter-tabs-contests-icon-label">
                  Contests Particpated
                </p>
              </div>
            </div>
            <div className="recruiter-tabs-contests-icon-container">
              <img src={prizeBadge} alt="Total Bounty Earned" />
              <div className="recruiter-tabs-contests-icon-text-container">
                <p className="recruiter-tabs-contests-icon-text">
                  {recruiterDetails.bountyEarned}
                </p>
                <p className="recruiter-tabs-contests-icon-label">
                  Total Bounty Earned
                </p>
              </div>
            </div>
            <div className="recruiter-tabs-contests-icon-container">
              <img src={resumeBadge} alt="Profiles Submitted" />
              <div className="recruiter-tabs-contests-icon-text-container">
                <p className="recruiter-tabs-contests-icon-text">
                  {recruiterDetails.profilesSubmitted}
                </p>
                <p className="recruiter-tabs-contests-icon-label">
                  Profiles Submitted
                </p>
              </div>
            </div>
          </div>
          <RecruiterContests contests={recruiterDetails.contests} />
        </>
      )}
      {((showCertificates && activeTabIndex === 3) ||
        (!showCertificates && activeTabIndex === 2)) && (
        <>
          <div className="recruiter-tabs-overall-stats-container">
            <Card>
              <div className="recruiter-tabs-overall-statistics-container">
                <p className="recruiter-tabs-overall-statistics">
                  Overall Statistics
                </p>
                <div className="recruiter-tabs-overall-statistics-icon-container">
                  <img src={groupsBadge} alt="Positions Particpated" />
                  <div className="recruiter-tabs-overall-statistics-text-container">
                    <p className="recruiter-tabs-overall-statistics-text">
                      {recruiterDetails.profilesSubmitted}
                    </p>
                    <p className="recruiter-tabs-overall-statistics-label">
                      Positions Participated
                    </p>
                  </div>
                </div>
                <div className="recruiter-tabs-overall-statistics-icon-container">
                  <img src={positionsClosedBadge} alt="Positions Closed" />
                  <div className="recruiter-tabs-overall-statistics-text-container">
                    <p className="recruiter-tabs-overall-statistics-text">
                      {recruiterDetails.positionsClosed}
                    </p>
                    <p className="recruiter-tabs-overall-statistics-label">
                      Positions Closed
                    </p>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <Card>
            <div className="recruiter-tabs-hit-ratio-container">
              <p className="recruiter-tabs-hit-ratio">
                Hit Ratio: {recruiterDetails.hitRatio}
              </p>
              <ContestStats stats={recruiterDetails.stats} showTitles={false} />
            </div>
          </Card>
        </>
      )}
    </>
  );
};

export default RecruiterTabs;
