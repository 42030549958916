import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import Error from "../../components/Error";
import Employers from "../../components/Employers";
import { setActiveLink } from "../../redux/actions/menu";
import { getEmployers } from "../../redux/actions/employers";
import {
  errorSelector,
  employersLoadingSelector,
  employersSelector,
} from "../../redux/selectors";
import "./styles.css";

const EmployersPage = () => {
  const dispatch = useDispatch();

  const loading = useSelector(employersLoadingSelector);
  const error = useSelector(errorSelector);
  const employersData = useSelector(employersSelector);
  const [canRenderComponent, setCanRenderComponent] = useState(false);

  const [rerender, setRerender] = useState(false);

  function handleReset() {
    setRerender(true);
  }

  useEffect(() => {
    dispatch(setActiveLink("Employers"));
    dispatch(getEmployers());
    setCanRenderComponent(true);
    if (rerender) {
      setRerender(false);
    }
  }, [rerender]);

  return (
    <div>
      {loading && <CircularProgress className="loading" />}
      {!loading && !error && canRenderComponent && (
        <>
          <div className="recruiters-contest-cards-container">
            <Employers
              employers={employersData.employers}
              employersData={employersData}
              onReset={handleReset}
            />
          </div>
        </>
      )}
      {!loading && error && <Error />}
    </div>
  );
};

export default EmployersPage;
