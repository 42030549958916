import styles from "./style.module.scss";
import logo from "../../assets/logo-color.svg";
import Menu from "../Menu";
import { Link } from "react-router-dom";
import { LOGIN, SIGNUP } from "../../utils/constants/routes";
import Button from "../Button";
import { useSelector, RootStateOrAny } from "react-redux";
import ProfileMenu from "../ProfileMenu";
import { getLocalStorage } from "../../utils/storage-manager";

const Header = () => {
  const isAuthenticated = useSelector(
    (state: RootStateOrAny) =>
      state.session.authenticated || getLocalStorage("token", null)
  );

  return (
    <div className={styles["header-wrapper"]}>
      <div className={styles.header}>
        <div className={styles["logo-wrapper"]}>
          <Link to="/">
            <img src={logo} alt="Logo" className={styles.logo}></img>
          </Link>
        </div>
        <div className={styles["header-menu"]}>
          <Menu />
        </div>
        {isAuthenticated && (
          <div className={styles["header-buttons"]}>
            <div className={styles["header-profile-icon"]}>
              <ProfileMenu />
            </div>
          </div>
        )}
        {!isAuthenticated && (
          <div className={styles["header-buttons"]}>
            {/* <Link to={SIGNUP}>
              <Button
                variant="primary"
                label="Sign Up"
                className={styles["header-signup-button"]}
              />
            </Link> */}

            <Link to={LOGIN}>
              <Button
                variant="primary"
                label="Sign In"
                className={styles["header-login-button"]}
              />
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
