import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setActiveLink } from "../../redux/actions/menu";
import HomePageFAQ from "../../components/HomePageFAQ";
import styles from "./style.module.scss";

const FAQPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setActiveLink(""));
  }, [dispatch]);

  return (
    <div className={styles["faq-page-faq"]}>
      <HomePageFAQ />
    </div>
  );
};

export default FAQPage;
