import cashReward from "../../assets/reward.svg";
import "./styles.css";

interface Props {
  amount: string;
  title?: string;
  showStar?: boolean;
}

const CashReward: React.FC<Props> = (props) => {
  const { amount, title = "Cash Reward", showStar = true } = props;
  return (
    <div className="cash-reward-container">
      {amount && (
        <div style={{ display: "flex" }}>
          <img className="cash-reward-image" src={cashReward} alt="reward" />
          <div className="cash-reward-content">
            <p className="cash-reward-amount">
              {showStar ? `${amount}*` : amount}
            </p>
            <p>{title}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default CashReward;
