import { useState } from "react";
import SelectValidator from "../SelectValidator";
import Certificate from "../Certificate";
import styles from "./styles.module.scss";

interface Props {
  certificates: any;
}

const sortOptions = ["Winning", "Participation"];

const RecruiterCertificates: React.FC<Props> = (props) => {
  const { certificates } = props;
  const [certifcatesToRender, setCertificatesToRender] = useState(
    certificates["winning"]
  );

  const handleSort = (event) => {
    if (event.target.value === sortOptions[0]) {
      setCertificatesToRender([...certificates["winning"]]);
    } else {
      setCertificatesToRender([...certificates["participation"]]);
    }
  };

  return (
    <div className={styles["recruiter-certificates-container"]}>
      <div className={styles["recruiter-certificates-title-container"]}>
        <p className={styles["recruiter-certificates-title"]}>
          All Certificates
        </p>
        <div className={styles["recruiter-certificates-sort"]}>
          <SelectValidator
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            register={() => {}}
            label="Certificate Type"
            list={sortOptions}
            value={sortOptions[0]}
            callback={handleSort}
          />
        </div>
      </div>
      <div className={styles["recruiter-certificates-certificates-container"]}>
        {certifcatesToRender &&
          certifcatesToRender.length > 0 &&
          certifcatesToRender.map((url) => {
            return (
              <div
                key={url}
                className={styles["recruiter-certificates-certificate"]}
              >
                <Certificate url={url} />
              </div>
            );
          })}
        {(!certifcatesToRender || certifcatesToRender.length == 0) && (
          <p>There are no certificates to display</p>
        )}
      </div>
    </div>
  );
};

export default RecruiterCertificates;
