import { GET_BANNERS, setBanners } from "../actions/banners";
import { setError } from "../actions/common";
import parse from "../../utils/parsers/banners-parser";

const bannersFlow = ({ api, log }) => ({ dispatch }) => (next) => async (
  action
) => {
  next(action);

  if (action.type === GET_BANNERS) {
    try {
      dispatch(setBanners({ loading: true }));
      const banners = await api.banners.getAll(action.payload);
      log("Successfully fetched the banners");
      dispatch(setError(null));
      dispatch(setBanners({ banners: parse(banners), loading: false }));
    } catch (error) {
      dispatch(setBanners({ loading: false }));
      log("Error while fetching the banners");
    }
  }
};

export default [bannersFlow];
